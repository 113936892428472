import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import reportsLoader from '../../public/icons/loader.png';

class GroupsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      batch_id: this.props.location.state?.batch_id,
      classs: parseInt(this.props.match.params.classs),
      class_id: this.props.location.state?.class_id,
      name: '',
      code: '',
      order: '',
      description: '',
      page_url: this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      section_name: this.props.location.state?.section_name,
      section_code: this.props.location.state?.section_code,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin: false,
      session: 0,
      getlistingData: [],
      loaderShow: true,
      loaderShow2: false,

      openDialog: false,
      openThanksDialog: false,
      formBlocks: [
        { id: this.generateUID(), topic: '', no_of_groups: '', description: '' }
      ],
      groupTitle: '',
      numberOfGroups: 0,
      // noOfStudents: '',
      noOfStudents: this.props.location.state?.no_of_students,
      session_id: this.props.location.state?.session_id,
      username: localStorage.getItem('name'),
      center_id: parseInt(localStorage.getItem('center_id')),
      groupPurpose: '',
      breakOutRoomName: '',
      openDiscussionDialog: false,
      openConfirmationGrpupDialog: false,
      deleteGroupId: null,
      deletingGroup: false

    }
  }

  componentDidMount() {
    if(this.props.location.state == undefined){
      window.location.href = "/admin/classes"
    }
    localStorage.setItem("page_url", this.state.page_url)

    this.getListingData()
  }




  generateUID = () => {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
    const randomStr = Math.random().toString(36).substring(2, 8); // Generate random string
    return `${timestamp}-${randomStr}`;
  }



  getListingData = async () => {
    this.setState({ loaderShow: true });
    const response = await this.props.breakoutRoomListing(this.state.class_id);
    if (response?.status == 1) {
      this.setState({ loaderShow: false });
      const sortedData = response.data.sort((a, b) => new Date(b.added_on) - new Date(a.added_on));
      this.setState({ loaderShow: false, getlistingData: sortedData });

    }
  }



  OpenThanksDialog = (section) => {
    this.setState({ openThanksDialog: true })

  }


  ThanksCloseDialog = () => {
    this.setState({ openThanksDialog: false })

  }




  OpenDialog = (section) => {
    this.setState({ openDialog: true })
    this.setState({ noOfStudents: section });
    this.setState({ formBlocks: [{ id: this.generateUID() }] });

  }

  CloseDialog = () => {
    this.setState({ openDialog: false });
    this.setState({ formBlocks: [{ id: this.generateUID(), topic: '', no_of_groups: '', description: '' }] });
  }


  addDiv = () => {
    this.setState(prevState => ({
      formBlocks: [...prevState.formBlocks, { id: this.generateUID(), topic: '', no_of_groups: '', description: '' }]
    }), () => {
      console.log('Previous state of formBlocks:', this.state.formBlocks);
    });
  }


  handleChange = (id, event) => {
    const { name, value } = event.target;
    const newValue = name === "no_of_groups" ? Number(value) : value;

    this.setState(prevState => ({
      formBlocks: prevState.formBlocks.map(block => block.id === id ? { ...block, [name]: newValue, class_id: this.props.location.state?.class_id, center_id: this.state.center_id, batch_id: this.state.batch_id, title: this.state.breakOutRoomName, session_id: this.state.session_id, description: '' } : block)
    }));

    let sum = 0;
    const newState = this.state.formBlocks.map(block => block.id === id ? { ...block, [name]: newValue, class_id: this.props.location.state?.class_id, center_id: this.state.center_id, batch_id: this.state.batch_id, title: this.state.breakOutRoomName, session_id: this.state.session_id, description: '' } : block);

    newState.forEach((data) => {
      try {
        sum = (parseInt(data.no_of_groups) || 0) + sum;
      } catch (e) {
        console.error("is not a number ", e);
      }
    });

    this.setState({ numberOfGroups: sum });
  };


  deleteChange = (id, removeValue) => {

    this.setState(prevState => ({
      formBlocks: prevState.formBlocks.filter(block => {
        return block.id != id;
      })
    }));

    this.setState(prevState => ({
      numberOfGroups: (parseInt(prevState.numberOfGroups) || 0) - (parseInt(removeValue) || 0)
    }));


  }


  handlePurposeChange = (e) => {
    console.log(e.target.value);
    this.setState({ groupPurpose: e.target.value })
  }



  handleBreakOutNameChange = (e) => {
    console.log("breakOutRoomName", e.target.value);
    this.setState({ breakOutRoomName: e.target.value })
  }

  handleSubmit = async (event) => {
    this.setState({ loaderShow2: true })
    console.log("formBlocks", this.state.formBlocks);
    const response = await this.props.createBreakoutRoom(this.state.formBlocks);
    if (response?.status == 1) {
      this.setState({ openThanksDialog: true })
      this.setState({ openDialog: false })
      this.setState({ loaderShow2: false })
      console.log("response", response.data);
      this.getListingData()
    }
  };

  formatDate = (getdate) => {
    const timestamp = getdate;
    const dateObj = new Date(timestamp);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });

    const now = new Date();
    const diffInSeconds = Math.floor((now - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
      return "(Just now)";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hr ago`;
    } else if (diffInDays === 1) {
      return "Yesterday";
    } else if (diffInDays < 30) {
      return `${formattedDate} ${formattedTime}`;
    } else if (diffInMonths < 12) {
      return `${formattedDate} ${formattedTime}`;
    } else {
      return `${formattedDate} ${formattedTime}`;
    }
  };



  openDiscussionCloseDialog = (data) => {
    this.setState({ openDiscussionDialog: false })
  }

  confirmationCloseDialog = () => {
    this.setState({ openConfirmationGrpupDialog: false })
    this.setState({ deleteGroupId: null })
  }
  confirmationOpenDialog = (id) => {
    this.setState({ openConfirmationGrpupDialog: true })
    this.setState({ deleteGroupId: id })
  }

  handleDeleteGroup = async () => {
    this.setState({ deletingGroup: true })
    try {
      const response = await this.props.deleteBreakoutRoom(this.state.deleteGroupId)
      if (response?.status == 1) {
        this.setState({ getlistingData: this.state.getlistingData.filter((data) => data.id != this.state.deleteGroupId) });
        this.confirmationCloseDialog()
      }
    }
    catch (e) {
      console.error('Error while deleting group ', e)
    }
    this.setState({ deletingGroup: false })

  }





  render() {

    return (
      <div className="admin-div">
        <Helmet>
          <title>{`${this.state.class_label} | ${this.props.pagewiseLanguageData.sections}`}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_sections} />

        <div className={this.state.openDialog ? "createGroupDialog open" : "createGroupDialog"} >
          <div className='createGroupDialogContainer'>
            <h3>{this.state.class_label}<span onClick={this.CloseDialog} className='fa fa-times'></span></h3>
            <h6>Faculty - <span>{this.state.username}</span></h6>
            <h5>Breakout rooms / Groups <span>Number of students - {this.state.noOfStudents}</span></h5>
            <div className='formWrapper'>
              <div className='formGroup'>
                <div className='formBlock noStyle'>
                  <label>Breakout Room Name<input type="text" name="title" onChange={(e) => this.handleBreakOutNameChange(e)} placeholder='Enter breakout room name' /></label>
                </div>
                {/* <div className='formBlock noStyle'>
                <label>Purpose<textarea onChange={(e) => this.handlePurposeChange(e)}  placeholder='Enter your purpose'></textarea></label>
              </div> */}


                {this.state.formBlocks.map((block, index) => (
                  <div className='formBlock' key={block.id}>
                    <label>Topic Title<input type="text" name="topic" onChange={(e) => this.handleChange(block.id, e)} /></label>
                    <label>Number of Groups<input type="number" name="no_of_groups" onChange={(e) => this.handleChange(block.id, e)} /></label>
                    {this.state.formBlocks.length > 1 && index !== 0 && (<i onClick={() => this.deleteChange(block.id, block.no_of_groups)} className='fa fa-minus'></i>)}
                  </div>
                ))}
              </div>

              <div className='addMoreLink'>
                <span>Number of Groups - {this.state.numberOfGroups}</span>
                {this.state.numberOfGroups < this.state.noOfStudents && <span onClick={this.addDiv}><i className='fa fa-plus'></i>Add more</span>}
              </div>

              {this.state.numberOfGroups > this.state.noOfStudents ? <div className='errorMessage'>Number of group is more than number of students.<br />Kindly check</div> : <div></div>}

              <div className='actions'><button className={`${(parseInt(`${this.state.numberOfGroups}`) || 0) <= 0 || this.state.numberOfGroups > this.state.noOfStudents || this.state.breakOutRoomName == '' ? 'is_disabled' : ''}`} onClick={this.handleSubmit}>Submit</button> {this.state.loaderShow2 && <div className='LoadingMessage'><img src={reportsLoader} /></div>}</div>
            </div>
          </div>
        </div>


        <div className={this.state.openDiscussionDialog ? "openDiscussionDialog open" : "openDiscussionDialog "} >
          <div className='openDiscussionContainer'>
            <h3>Discussion Name <span onClick={this.openDiscussionCloseDialog} className='fa fa-times'></span></h3>
            <div className='formWrapper'>
              sdfdsfsdfsfsfsdfs
            </div>
          </div>
        </div>


        <div className={this.state.openConfirmationGrpupDialog ? "confirmationGroupDialog open" : "confirmationGroupDialog "} >
          <div className='confirmationGroupContainer'>
            <h6>Confirmation</h6>
            <div className='formWrapper'>
              <p>Are you sure you wants to delete this<br />Breakout room</p>

              <div className='actions'>
                <a href='javascript:void(0);' onClick={this.confirmationCloseDialog}>Cancel</a>


                {
                  this.state.deletingGroup ? <div style={{width: 'auto'}} className='LoadingMessage'><img style={{maxWidth: '40px'}} src={reportsLoader} /></div> : <button onClick={this.handleDeleteGroup}>Ok</button>
                }

              </div>
            </div>
          </div>
        </div>



        <div className={this.state.openThanksDialog ? "thanksGroupDialog open" : "thanksGroupDialog "} >
          <div className='thanksGroupContainer'>
            <span onClick={this.ThanksCloseDialog} className='fa fa-times'></span>
            <i className='icon fa fa-thumbs-up'></i>
            <h6>ThankYou</h6>
            <div className='formWrapper'>
              <p>Group are Created</p>
            </div>
          </div>
        </div>


        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1 setMaxWidth">
              <div className="heading marginBottomZero">
                <Link id="trace-back" to={`/admin/classes`}>
                  <img src={Back} alt="back" />
                </Link>
                <h1>{this.state.class_label} |  {this.state.section_name}</h1>
              </div>
              <h6>Code - {this.state.section_code}</h6>
            </div>
            {this.state.noOfStudents != '' && <div className='createBreakoutRooms'><span onClick={(e) => this.OpenDialog(this.state.noOfStudents)}>Create Breakout Rooms</span></div>}
          </div>
          {this.state.noOfStudents != '' && <div className='totalStudents'>Total No of Students - {this.state.noOfStudents}</div>}
          <div className='blog-list-table'>

            {

              <table>
                <thead>
                  <tr>
                    <th>Class Name</th>
                    <th>Breakout Room</th>
                    <th>Topic</th>
                    <th className='textAlignCenter'>#Students</th>
                    <th>Faculty Email</th>
                    <th>Added On</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.getlistingData && this.state.getlistingData.map((data, index) => (
                    <tr key={data?.id}>
                      <td>{this.state.class_label}</td>
                      <td>{data?.title ?? "Untitled"}</td>
                      <td>{data?.topic ?? "Untitled"}</td>
                      <td className='textAlignCenter'>{data?.no_of_students}</td>
                      <td>{localStorage.getItem('username')}</td>
                      <td>{this.formatDate(data?.added_on)}</td>
                      <td>
                        <div className='actionsLink'>
                          <a href={`${'/app/admin/doubt-responses/'}${data.id}`}><span className='PreviewLink'><i className='fa fa-eye'></i>View</span></a>
                          <a href="javascript:void(0)" onClick={() => {
                            this.confirmationOpenDialog(data?.id)
                          }}><i className='fa fa-trash'></i>Delete</a>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {this.state.getlistingData.length == 0 && this.state.loaderShow == false &&
                    <tr>
                      <td colSpan="7" className='noContentMessage'>No Records found</td>
                    </tr>
                  }

                  {this.state.loaderShow &&
                    <tr>
                      <td colSpan="7" className=''>{this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}</td>
                    </tr>
                  }




                </tbody>


              </table>
            }

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, updateSection, getSectionList, getClassList, getPageLanguageWiseData, breakoutRoomListing, createBreakoutRoom, deleteBreakoutRoom } = actions;
  return {
    getSectionList: (center_id, class_id, show_all, page) => dispatch(getSectionList(center_id, class_id, show_all, page)),
    getClassList: () => dispatch(getClassList()),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
    breakoutRoomListing: (class_id) => dispatch(breakoutRoomListing(class_id)),
    createBreakoutRoom: (data) => dispatch(createBreakoutRoom(data)),
    deleteBreakoutRoom: (id) => dispatch(deleteBreakoutRoom(id)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsListing);
