import React, { Component } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Shimmer from 'react-js-loading-shimmer';
import Pagination from "react-js-pagination";
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Trash from '../../public/icons/trash.svg';
import Stamp from '../../public/icons/stamp.svg';
import Calendar from 'react-calendar';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import download from '../../public/icons/down-arrow.svg';
import common from '../../common';
import axios from 'axios';
import './styles.css';
import Calendar2 from '../../public/icons/calendar2.svg';
import Back from '../../public/icons/back.svg';
import { CsvToHtmlTable } from 'react-csv-to-table';
import reportsLoader from '../../public/icons/loader.png';
import Multiselect from 'multiselect-react-dropdown';
import DataTable from 'react-data-table-component';
import { capitalize } from 'lodash-es';
import Select from 'react-select'
import { type } from 'os';
import ReactNotifications from 'react-notifications-component';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import absentIcon from '../../public/icons/attendance-report-absent.svg';
import notViewedIcon from '../../public/icons/attendance-report-not-viewed.svg';
import presentIcon from '../../public/icons/attendance-report-present.svg';
import viewedIcon from '../../public/icons/attendance-report-viewed-video.svg';
import AttendanceReports from './AttendanceReports';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CourseDashboard from './CourseDashboard';

class AdminAttendanceReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      batch_id: '',
      current_date_records: [],
      current_date: new Date(),
      showCalendar: false,
      saveMonthNumber: '',
      saveMonthName: '',
      saveYear: '',
      attendanceList: [],
      token: localStorage.getItem('token'),
      getUserAllData: [],
      current_page: 0,
      totalItems: 0,
      attendanceListDisplay: true,
      user_id: '',
      loaderShow: true,
      courseDashboardOpen: this.props?.location?.state?.openCourseDashboard ? true : false,
      attendanceListDisplay: this.props?.location?.state?.openCourseDashboard ? false : true,
      recordingListDisplay: false,



    }

    this.wrapperRef = React.createRef();
  }


  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    let getuserId = this.props.location?.state?.user_id ?? localStorage.getItem('user_id');

    if (getuserId) {
      this.setState({ user_id: getuserId });
      console.log('getuserId', getuserId);

      if (this.state.current_date) {
        setTimeout(() => {
          this.getAllData();
        }, 1500);
      }
    }




  }


  getAllData = async (pageNumber) => {
    this.setState({ loaderShow: true });
    console.log("pageNumber", pageNumber);
    const response = await this.props.adminAttendanceAllReports(this.state.user_id, this.getDate(this.state.current_date), 'backward', pageNumber ? pageNumber : 1)
    console.log(response);
    console.log(response?.status);
    if (response?.status == 1) {
      this.setState({ loaderShow: false });
      this.setState({ attendanceList: response?.data?.data, current_page: response.data?.pagination?.current_page, totalItems: response.data?.pagination?.total_page * 20 })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  handleClickOutside = (event) => {
    if (this?.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.setState({ showCalendar: false })
    }
  }


  getDate(value) {
    console.log("value getDate", value)
    const today = value;
    const monthName = today.toLocaleString('default', { month: 'short' });
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    this.setState({ saveMonthNumber: month });
    this.setState({ saveMonthName: monthName });
    this.setState({ saveYear: year });
    return `${year}-${month}-${date}`;
  }

  openCalendar = () => {
    this.setState({ showCalendar: true });
  }

  // CalenderOnChange = (value, event) =>{
  //   this.setState({current_date:value});
  //   setTimeout(() => {
  //     this.getAllData()
  //   }, 200);

  // }

  CalenderOnChange = (value, event) => {
    console.log("value", value);
    const year = value.getFullYear();
    const month = value.getMonth();
    const firstDayNextMonth = new Date(year, month + 1, 1);

    // Subtract one day to get the last day of the current month
    const lastDayOfMonth = new Date(firstDayNextMonth - 1);

    console.log("firstDayNextMonth", firstDayNextMonth)
    console.log("lastDayOfMonth", lastDayOfMonth)

    this.setState({ current_date: lastDayOfMonth });
    setTimeout(() => {
      this.getAllData()
    }, 200);
    this.setState({ showCalendar: false });
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ onLoad: true })
    this.getAllData(pageNumber);
    this.setState({ current_page: pageNumber });
  }

  convertStartTime = (value) => {
    const today = value;
    const indexOfT = today.indexOf('T');
    const dateonly = today.substring(0, indexOfT);
    const timeonly = today.substring(indexOfT + 1, today.length).substring(0, 5);
    console.log("dateonly", dateonly);
    console.log("timeonly", timeonly);
    return timeonly;
  }

  displayDateTime(start_date_time, end_date_time) {
    const startDateTime = new Date(start_date_time);
    const endDateTime = new Date(end_date_time);
    const startDate = startDateTime.toLocaleDateString('en-GB'); // 'en-GB' for day-month-year format
    const startTime = startDateTime.toTimeString().slice(0, 5); // Get HH:MM from start_date_time
    const endTime = endDateTime.toTimeString().slice(0, 5); // Get HH:MM from end_date_time
    const formattedDateTime = `${startDate} - ${startTime} to ${endTime}`;
    return `${formattedDateTime}`;
  }


  formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('default', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return `${formattedDate} at ${formattedTime}`;
  }



  TabClick(tabName) {
    console.log(tabName);
    if (tabName == "attendance") {
      this.setState({ attendanceListDisplay: true })
      this.setState({ recordingListDisplay: false })
      this.setState({ courseDashboardOpen: false })
    }
    if (tabName == "recording") {
      this.setState({ attendanceListDisplay: false })
      this.setState({ recordingListDisplay: true })
      this.setState({ courseDashboardOpen: false })
    }
    if (tabName == "courseDashboard") {
      this.setState({ attendanceListDisplay: false })
      this.setState({ recordingListDisplay: false })
      this.setState({ courseDashboardOpen: true })

    }
  }


  render() {
    const today = new Date();
    const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const customStyles = {
      headCells: {
        style: {
          fontFamily: 'Lato', // override the row height
          fontSize: '14px',
          fontWeight: 700,
        },
      },
      cells: {
        style: {
          fontFamily: 'Lato', // override the row height
          fontSize: '16px',
          fontWeight: 400,
        },
      },

    }
    return (
      <div className="admin-div learnerAttendance-listing">
        <Helmet>
          <title>Attendance Reports</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder="Search institutes" />
        <div className="page-header-faculty">


          {/*Assessments Reports start*/}
          <div className="attendanceReportsWrapper">
            <div className="attendanceReportsContainer">

              <div className='tabsWrapper'>
                <span className={this.state.courseDashboardOpen == true ? 'selected' : ''} onClick={() => this.TabClick('courseDashboard')}>Course Dashboard</span>
                <span className={this.state.attendanceListDisplay == true ? 'selected' : ''} onClick={() => this.TabClick('attendance')}>Attendance List</span>
                <span className={this.state.recordingListDisplay == true ? 'selected' : ''} onClick={() => this.TabClick('recording')}>Recording List</span>
              </div>



              {/*Attendance List Reports start*/}
              {this.state.attendanceListDisplay == true &&
                <div className='AttendanceListWrapper'>

                  <h2>Attendance List</h2>
                  <div className='header-op'>
                    <div className='calenderBlock'>
                      <div className='currentDate'>
                        <div>
                          <div id="trace-back" onClick={() => this.props.history.goBack()}>
                            <img src={BackArrow} alt="back" />
                          </div>
                          {this.state.saveMonthName} {this.state.saveYear}
                          <div className="react-calendar-date" onClick={this.openCalendar}
                            ref={this.wrapperRef}>
                            <span>
                              <img src={Calendar2} alt="cal" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ref={this.wrapperRef} className={this.state.showCalendar ? "calendarView" : "calendarView hide"} >
                    {/* <Calendar onClickMonth={this.onClickMonth}  nextLabel={null} next2Label={null}  prev2Label={null}  prevLabel={null} value={this.state.current_date} defaultView='year' maxDetail="year"/> */}
                    <Calendar nextLabel={null} next2Label={null} prev2Label={null} prevLabel={null} defaultView='year' maxDetail="year" onChange={this.CalenderOnChange} value={this.state.current_date} />
                  </div>
                  <div className='attendanceListingTableWrapper'>
                    <ol>
                      {/* {JSON.stringify(this.state.attendanceList.data)} */}
                      {this.state.attendanceList && this.state.attendanceList.length > 0 && this.state.attendanceList.map((items, index) =>
                        <li key={index}>
                          <div className='info'>
                            <h2>{items?.title}</h2>
                            <div className='className'>Class - <span>{items?.class_name}</span></div>
                            <div className='batchName'>Batch - <span>{items?.batch_name}</span></div>
                            {items?.start_date_time && <div className='startTime'>Date - <span>{this.displayDateTime(items?.start_date_time, items?.end_date_time)}</span></div>}
                          </div>
                          <div className='status'>
                            {items?.attendance == "Absent" && <div className='status-absent'><span>{items?.attendance}</span></div>}
                            {items?.attendance == "Present" && <div className='status-present'><span>{items?.attendance}</span></div>}
                          </div>
                        </li>
                      )}
                    </ol>

                    {this.state.attendanceList.length == 0 && this.state.loaderShow == false && <div className='noDataFound'>No Attendance Found</div>}
                    {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

                    {this.state.attendanceList?.length ? <div className="pagination-list">
                      <Pagination
                        activePage={this.state.current_page}
                        itemsCountPerPage={20}
                        totalItemsCount={this.state.totalItems}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div> : ''}
                  </div>


                </div>
              }
              {/*Attendance List Reports start*/}


              {/*Recording List Reports start*/}
              {this.state.recordingListDisplay == true &&
                <div className='RecordingListWrapper'>
                  <div className="attendanceReportsWrapper">
                    <div className="attendanceReportsContainer">
                      <h2>Recording List</h2>
                      <div className='header-op'>
                        <div className='calenderBlock'>
                          <div className='currentDate'>
                            <div>
                              <div id="trace-back" onClick={() => this.props.history.goBack()}>
                                <img src={BackArrow} alt="back" />
                              </div>
                              {this.state.saveMonthName} {this.state.saveYear}
                              <div className="react-calendar-date" onClick={this.openCalendar}
                                ref={this.wrapperRef}>
                                <span>
                                  <img src={Calendar2} alt="cal" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={this.wrapperRef} className={this.state.showCalendar ? "calendarView" : "calendarView hide"} >
                        {/* <Calendar onClickMonth={this.onClickMonth}  nextLabel={null} next2Label={null}  prev2Label={null}  prevLabel={null} value={this.state.current_date} defaultView='year' maxDetail="year"/> */}
                        <Calendar nextLabel={null} next2Label={null} prev2Label={null} prevLabel={null} defaultView='year' maxDetail="year" onChange={this.CalenderOnChange} value={this.state.current_date} />
                      </div>
                      <div className='attendanceListingTableWrapper'>
                        <ol>
                          {/* {JSON.stringify(this.state.attendanceList.data)} */}
                          {this.state.attendanceList && this.state.attendanceList.length > 0 && this.state.attendanceList.map((items, index) =>
                            <li key={index}>
                              <div className='info'>
                                <h2>{items?.title}</h2>
                                <div className='className'>Class - <span>{items?.class_name}</span></div>
                                <div className='batchName'>Batch - <span>{items?.batch_name}</span></div>
                                <div className='className'>Duration - <span>{items?.duration_hrs} hours</span></div>
                                {items?.start_date_time && <div className='startTime'>Date - <span>{this.displayDateTime(items?.start_date_time, items?.end_date_time)}</span></div>}

                              </div>
                              <div className='status'>
                                {items.record_url && <div className='viewRecording'>
                                  <a target="_blank" href={items.record_url}>View Recording</a>
                                  {items.viewed_time != null && <div className='viewedTime'>Viewed - <span>{this.formatDateTime(items.viewed_time)}</span></div>}
                                </div>}
                              </div>
                            </li>
                          )}
                        </ol>

                        {this.state.attendanceList.length == 0 && this.state.loaderShow == false && <div className='noDataFound'>No Recording Found</div>}
                        {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}


                        {this.state.attendanceList?.length ? <div className="pagination-list">
                          <Pagination
                            activePage={this.state.current_page}
                            itemsCountPerPage={20}
                            totalItemsCount={this.state.totalItems}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div> : ''}
                      </div>


                    </div>
                  </div>
                </div>
              }
              {/*Recording Reports start*/}


              {this.state.courseDashboardOpen == true &&

                <CourseDashboard user_id={this.props.location.state.user_id} />
                // <div className='RecordingListWrapper'>
                //   <div className="attendanceReportsWrapper">
                //     <div className="attendanceReportsContainer">
                //       <h2>Course Compeltion</h2>
                //       <div className='courseDashboardWrapper'>
                //         <div className='courseDashboardAccordian'>
                //            <div className='accordianData'>
                //               <div className='accordianTop'>
                //                   <div className='thumb'></div>
                //                   <div className='thumbContent'>
                //                       <div className='left'>
                //                         <h3>Master of Business Administration (JAN - 2023) - Semester III</h3>
                //                         <p>The Master of Business Administration (M.B.A) programme (Online Mode) of SASTRA Deemed University is a unique programme carefully curated to address the career progression aspirations of the present-day working professionals.While the academic content covers all the traditional domains of management, due consideration has been given to incorporate demands of a modern and complex business environment.</p>
                //                       </div>
                //                       <div className='circular-progress'>
                //                       <CircularProgressbar value={20} strokeWidth={11} text={`20%`}
                //                         styles={buildStyles({
                //                           textColor: "var(--textBlack2)",
                //                           pathColor: "green",
                //                           trailColor: "#eeeeee"
                //                         })}
                //                       />
                //                       </div>
                //                       <div><i className='fa fa-chevron-down'></i> </div>
                //                   </div>
                //               </div>
                //                <div className='accordianInner'>             
                //               <div className='accordianTop'>
                //                   <i className='fa fa-chevron-down'></i>      
                //                   <div className='thumb'></div>
                //                   <div className='thumbContent'>
                //                         <div className='left'>
                //                           <h3>Statigic Management</h3>
                //                           {/* <p>Course Desc</p>   */}
                //                       </div>
                //                       <div className='completePer'>Completed - 8.42%</div>
                //                   </div>
                //               </div>   

                //                <div className='accordianSubInner'>

                //                      <div className='weekWrapper'>
                //                         <div className='accordianTop'><i className='fa fa-chevron-down'></i>Week 1 <div className='completePer'>Completed - 8.42%</div></div>
                //                         <div className='accordianSubContent' style={{opacity:'1',visibility:'visible',display:'flex',height:'auto'}}>
                //                           <ol>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Statigic and operational Effectiveness</div>
                //                               <div className='completePer'>Completed - 8.42%</div>
                //                             </li>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Statigic and operational Effectiveness</div>
                //                               <div className='completePer'><span className='red'>Pending</span></div>
                //                             </li>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Testing Assignment</div>
                //                               <div className='completePer'>Completed - 100%</div>
                //                             </li>
                //                           </ol>
                //                         </div>
                //                       </div> 
                //                       <div className='weekWrapper'>
                //                         <div className='accordianTop'><i className='fa fa-chevron-down'></i>Week 2 <div className='completePer'>Completed - 8.42%</div></div>
                //                         <div className='accordianSubContent' style={{opacity:'1',visibility:'visible',display:'flex',height:'auto'}}>
                //                           <ol>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Statigic and operational Effectiveness</div>
                //                               <div className='completePer'>Completed - 8.42%</div>
                //                             </li>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Statigic and operational Effectiveness</div>
                //                               <div className='completePer'><span className='red'>Pending</span></div>
                //                             </li>
                //                             <li>
                //                               <div><i className='fa fa-video'></i>Testing Assignment</div>
                //                               <div className='completePer'>Completed - 100%</div>
                //                             </li>
                //                           </ol>
                //                         </div>
                //                       </div>   

                //                 </div> 
                //               </div>          
                //           </div>

                //         </div>
                //       </div>

                //     </div>
                //   </div>
                // </div>
              }

            </div>
          </div>
          {/*Assessments Reports start*/}









        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { adminAttendanceAllReports } = actions;
  return {
    adminAttendanceAllReports: (user_id, start_date, direction, pageNumber) => dispatch(adminAttendanceAllReports(user_id, start_date, direction, pageNumber)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAttendanceReports);