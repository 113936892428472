import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import './UserUploadCertificate.css';
import Popup from 'reactjs-popup';
import './UserUploadCertificateListing.css';

const UserCertificateUpload = (props) => {
    const {userCertificateBulkUpload, getUserUploadCertificateList} = props;

    const [UploadCertificateList, setUploadCertificateList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [uploadfile, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                parseCsvData(text);
            };
            reader.readAsText(uploadedFile);
        }
    };

    const parseCsvData = (text) => {
        const rows = text.split('\n');
        const parsedData = rows.map(row => row.split(','));
        setCsvData(parsedData);
    };

    const handleSubmit = async (close) => {
        if (uploadfile) {
            setLoading(true);
            try {
                await userCertificateBulkUpload(uploadfile);
                close(); // Close the popup after the file is uploaded
            } catch (error) {
                console.error("Upload failed:", error);
            } finally {
                setLoading(false);
            }
        }
    };


    useEffect(() => {
        const getAllCertificate = async () => {
            try {
                const response = await getUserUploadCertificateList();
                if (response && response.data) {
                    setUploadCertificateList(response.data);
                } else {
                    console.error("Unexpected response structure:", response);
                }
            } catch (error) {
                console.error("Failed to fetch certificate list:", error);
            }
        };
        getAllCertificate();
    }, [getUserUploadCertificateList]);

    return (
        <div className="admin-div">
            <div className="main-part">
                <div className="sub-head">
                    <div className="heading-part1">


                        <div className="heading"><h1>Certificates</h1></div>
                    </div>
                    <div className="heading-part2">
                        <Popup
                            trigger={<span className="add-new-button">Add New</span>}
                            modal>
                            {close => (
                                <div className="popup-content adminCertificatesList">
                                    <span className="close" onClick={close}>
                                        &times;
                                    </span>
                                    <h2>Upload New Certificates</h2>
                                    <input type="file" accept=".csv" onChange={handleFileUpload} />
                                    {csvData.length > 0 && (
                                        <table>
                                            <thead>
                                                <tr>
                                                    {csvData[0].map((header, index) => (
                                                        <th key={index}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {csvData.slice(1).map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <button onClick={() => handleSubmit(close)} disabled={csvData.length === 0 || loading}>
                                        {loading ? 'Uploading...' : 'Submit'}
                                    </button>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

                <div className="flex-container">
                    {UploadCertificateList && UploadCertificateList.map((certificate, index) => (
                        <div key={index} className="certificate">
                            <img src={certificate?.certificate_url} alt={`Certificate ${index}`} />
                            <h3>{certificate?.program}</h3>
                        </div>
                    ))}
                </div>
                {UploadCertificateList.length === 0 && <div className='noContentMessage' style={{ textAlign: 'center', marginTop: '100px' }}>No Certificates</div>}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { userCertificateBulkUpload, getUserUploadCertificateList } = actions;
    return {
        userCertificateBulkUpload: (uploadfile) => dispatch(userCertificateBulkUpload(uploadfile)),
        getUserUploadCertificateList: () => dispatch(getUserUploadCertificateList()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCertificateUpload);
