import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import reportsLoader from '../../public/icons/loader.png';
import Trash from '../../public/icons/trash.svg';
import actions from '../../redux/actions';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import { Link } from 'react-router-dom';
class SessionStudentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderShow: true,
            id: parseInt(this.props.match.params.id),
            sessionStudentList: []
        };
    }

    componentDidMount() {
        const liveclass_id = parseInt(this.props.match.params.id);
        if(liveclass_id){
            this.getPreviewStudentList(liveclass_id);
        }
    }


    getPreviewStudentList = async (id) => {
        console.log("id",id);
        if(id){
          const response = await this.props.getStudentListInSession(id);
          if(response !== undefined || response?.status == 1){
            console.log("response Data",response?.data?.users)
            this.setState({sessionStudentList : response?.data?.users})
            
            }
          }
          this.setState({loaderShow : false})
        }

    render() {
        const { loaderShow, sessionStudentList } = this.state;

        return (
            <div className='app-body'>
                <div className={window.location.href.includes('app/faculty/session-student-list') ? "blog-post-container app-div faculty" : "blog-post-container app-div"}>
                    <div className='action-btn'>
                        <h1>
                        <Link id="trace-back" 
                            to={{ pathname: `/faculty/sessions`,
                            state: {currentClassId: this.state.getCurrentClassId}}}>
                            <img src={BackArrow} alt="back with class id"/>
                        </Link>
                            Student List</h1>
                    </div>

                    <div className='blog-list'>
                        {loaderShow && <div className='LoadingMessage'><img src={reportsLoader} alt="Loading" /></div>}

                        {!loaderShow && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sessionStudentList.length > 0 ? (
                                        this.state.sessionStudentList.map((data,index) => (
                                            <tr key={index}>
                                                <td>{data?.username}</td>
                                                <td>{data?.name}</td>
                                                <td>{data?.email}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>
                                                <div className='marginTop60 noContentMessage'>No Queries Found</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.commonReducer.loading,
    logindetails: state.commonReducer.logindetails,
    saveLanguageWiseData: state.commonReducer.languageData
});

const mapDispatchToProps = (dispatch) => {
    const { getStudentListInSession } = actions;
    return {
        getStudentListInSession: (liveclass_id) => dispatch(getStudentListInSession(liveclass_id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionStudentList);
