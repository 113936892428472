import React, { useState, useEffect } from 'react';

const InternetSpeedTest = (props) => {
    const { closePop } = props
    const [speed, setSpeed] = useState(null);
    const [browserInfo, setBrowserInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [ipDetails, setIpDetails] = useState(null);
    const fetchIPDetails = async () => {
        try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            setIpDetails(data);
        } catch (error) {
            console.error('Error fetching IP details:', error);
        }
    };
    useEffect(() => {
        fetchIPDetails()
        const info = {
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            hardwareConcurrency: window.navigator.hardwareConcurrency,
            deviceMemory: window.navigator.deviceMemory,
            languages: window.navigator.languages,
        };
        
        setBrowserInfo(info);
    }, []);

    const checkSpeed = () => {
        setLoading(true);
        const startTime = new Date().getTime();
        const image = new Image();
        image.onload = () => {
            const endTime = new Date().getTime();
            const duration = (endTime - startTime) / 1000; // seconds
            const fileSize = 5000000; // 5MB in bytes
            const speedMbps = (fileSize / (1024 * 1024) / duration * 8).toFixed(2); // Mbps
            setSpeed(speedMbps);
            setLoading(false);
        };
        image.src = 'https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg' + '?cache_buster=' + startTime; // Replace with an actual URL to a 5MB file
    };

    return (
        <div>
            <span onClick={closePop} className='close'>X</span>
            <div style={{display: 'flex', justifyContent:"space-between"}}>
            <h2>Internet Speed Test</h2>
            <h3 style={{marginRight: '20px'}}>Date: {new Date().toLocaleString() + ""}</h3>
            </div>

            <div style={{display: 'flex', justifyContent:"space-between"}}>
            <button onClick={checkSpeed} disabled={loading}>
                {loading ? 'Checking...' : 'Check Speed'}
            </button>
            <h3 style={{marginRight: '20px'}}>  {JSON.parse(localStorage.getItem('logindetails'))?.name}

              <br/>
              {JSON.parse(localStorage.getItem('logindetails'))?.email}
            </h3>
            </div>
            

          
            {speed && <p><strong>Download Speed:</strong> {speed} Mbps</p>}

            <div>
                <h2>Browser Information</h2>
                <ul>
                    {Object.entries(browserInfo).map(([key, value]) => (
                        <li key={key}>
                            <span style={{ marginRight: '0.5rem', color: 'green', fontSize: '22px' }}>{'\u2713'}</span>
                            <strong style={{ marginRight: '0.5rem', color: 'green', fontSize: '16px' }}>{key}:</strong> {value}
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                {ipDetails ? (
                    <div>
                        

                        <h3>Your current IP address is: {ipDetails.ip}</h3>
                        <p>Provider: {ipDetails.org}</p>
                        <p>Location: {ipDetails.city}, {ipDetails.region}, {ipDetails.country}</p>
                    </div>
                ) : (
                    <p>Loading IP details...</p>
                )}
            </div>
        </div>
    );
};

export default InternetSpeedTest;
