import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import { useState } from 'react';
import { useEffect } from 'react';
import courseImg from '../../public/images/courses.svg';
import reportsLoader from '../../public/icons/loader.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AttendanceReportSubjectCard from './AttendanceReportSubjectCard';


const CourseDashboard = (props) => {
    const { user_id, getUserProfile, saveTocCompletion } = props
    const [closedClass, setClosedClass] = useState(true)
    const [couseCompeltionPer, setCouseCompeltionPer] = useState(0)
    const [countOfSubjectPerCalcuated, setCountOfSubjectPerCalcuated] = useState(0)
    const [userDetail, setUserDetail] = useState(null)
    const [loading, setLoading] = useState(false)

    const getUserDetail = async () => {
        setLoading(true)
        try {
            const response = await getUserProfile(user_id)
            setUserDetail(response?.data)
        } catch (error) {

        }
        setLoading(false)

    }
    useEffect(() => {
        getUserDetail()
    }, [user_id])



    return (
        <div>
            <h2>Course Dashboard</h2>

            {loading && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

            {
                !loading && !userDetail && <div className='noDataFound'>No Data Found</div>
            }


            {
                !loading && userDetail &&
                <div className='courseDashboardWrapper'>
                    <div className='courseDashboardAccordian'>

                        {/* complete Accordian Start */}
                        <div className='accordianData'>
                            <div onClick={() => {
                                // this.setState({ closedClass: !closedClass })
                                setClosedClass((prev) => !prev)
                                setCouseCompeltionPer(0)
                                setCountOfSubjectPerCalcuated(0)
                                // this.setState({ couseCompeltionPer: 0 })
                            }} className='accordianTop'>
                                <div className='thumb'><img src={userDetail?.classs?.image ?? courseImg} alt="cal" /></div>
                                <div className='thumbContent'>
                                    <div className='left'>
                                        <h3>{userDetail?.classs?.label}</h3>
                                        <p>{userDetail?.classs?.description}</p>
                                    </div>

                                    {
                                        (userDetail?.classs?.completion_percentage == 100 || (!closedClass && countOfSubjectPerCalcuated != userDetail?.subjects.length)) && <div className='circular-progress'>
                                            <div style={{ width: "auto" }} className='LoadingMessage'><img style={{ maxWidth: '34px' }} src={reportsLoader} /></div>
                                        </div>
                                    }

                                    {
                                        (userDetail?.classs?.completion_percentage == 100 || (!closedClass && countOfSubjectPerCalcuated == userDetail?.subjects.length && (couseCompeltionPer / userDetail?.subjects.length).toFixed(0) != 0)) &&
                                        <div className='circular-progress'>
                                            <CircularProgressbar value={userDetail?.classs?.completion_percentage == 100 ? userDetail?.classs?.completion_percentage : (couseCompeltionPer / userDetail?.subjects.length)} text={`${(couseCompeltionPer / userDetail?.subjects.length).toFixed(2)}%`} strokeWidth={11}
                                                styles={buildStyles({
                                                    textColor: "var(--textBlack2)",
                                                    pathColor: "green",
                                                    trailColor: "#eeeeee"
                                                })}
                                            />
                                        </div>
                                    }



                                    <div>
                                        <i className={`${!closedClass ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`}></i>
                                    </div>
                                </div>
                            </div>
                            {
                                !closedClass &&
                                userDetail?.subjects && userDetail?.subjects.map((subject) =>
                                    <AttendanceReportSubjectCard
                                        subject={subject}
                                        sendPercentage={(value) => {

                                            if (userDetail?.classs?.completion_percentage == 100) {
                                                setCouseCompeltionPer(userDetail?.classs?.completion_percentage)
                                                saveTocCompletion(userDetail?.classs?.id, 'class', null)
                                            }
                                            else {
                                                setCountOfSubjectPerCalcuated(prev => prev + 1)
                                                setCouseCompeltionPer(prev => prev + value)
                                            }

                                            // setCountOfSubjectPerCalcuated(prev => prev + 1)
                                            // setCouseCompeltionPer(prev => prev + value)
                                        }}
                                    />
                                )
                            }

                        </div>
                        {/* complete Accordian ends */}




                    </div>


                </div>
            }






        </div>
    )
}


const mapStateToProps = (state) => {

}

const mapDispatchToProps = (dispatch) => {
    const { getUserProfile, saveTocCompletion } = actions;
    return {
        getUserProfile: (user_id) => dispatch(getUserProfile(user_id)),
        saveTocCompletion: (id, type, subject_id) => dispatch(saveTocCompletion(id, type, subject_id)),



    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDashboard);