import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import assignmentImg from '../../public/icons/c_assignment.png';
import notesImg from '../../public/icons/c_notes.png';
import quizImg from '../../public/icons/c_quizz.png';
import videoImg from '../../public/icons/c_video.png';
import reportsLoader from '../../public/icons/loader.png';
import courseImg from '../../public/images/content-lib.png';
import actions from '../../redux/actions';
import './styles.css';
import { Link } from 'react-router-dom';





const AttendanceReportSubjectCard = (props) => {
    const { subject, getTocList, sendPercentage, saveTocCompletion } = props
    const [tocList, setTocList] = useState([])
    const [currentOpenWeek, setCurrentOpenWeek] = useState(0)
    const [isWeekOpen, setIsWeekOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [subjectPer, setSubjectPer] = useState(0)
    const [percentageLoading, setPercentageLoading] = useState(true)

    // type: toc, subject, class
    const calculatePercentageWeek = (subjectId, units, type) => {

        if ((units?.length ?? 0) == 0) {
            setSubjectPer(0)
            setPercentageLoading(false)
            sendPercentage(0)
            return;
        }

        let totalPer = 0;
        for (let index = 0; index < units?.length; index++) {
            const element = units[index];
            const pre = calculateWeekPercentage(element?.content)

            totalPer = totalPer + pre;


        }
        const totalPercentage = totalPer / (units?.length)
        if (totalPercentage.toFixed() == 100) {
            saveTocCompletion(subject?.id, 'subject', subject?.id)
        }

        setSubjectPer(totalPercentage)
        setPercentageLoading(false)
        sendPercentage(totalPercentage)
    }


    const getSubjectUnit = async () => {
        setLoading(true)
        try {
            console.log("response ", subject?.id)
            const response = await getTocList(subject?.id)
            console.log(response?.toc_data?.units)
            if (response?.subject_completion_percentage == 100) {
                setSubjectPer(response?.subject_completion_percentage)
                setPercentageLoading(false)
                sendPercentage(response?.subject_completion_percentage)
            }
            else {
                calculatePercentageWeek(subject?.id, response?.toc_data?.units, 'toc')
            }

            setTocList(response?.toc_data?.units)
        }
        catch (e) {
            console.error("Error while fetching getSubjectUnit", e)
        }
        setLoading(false)
    }

    useEffect(() => {
        console.log("isWeekOpen", isWeekOpen, " and ", tocList)
        if (tocList.length == 0) {
            getSubjectUnit()
        }
    }, [isWeekOpen])







    const getContentTypeClass = (type) => {
        switch (type) {

            case 1:
            case 2:
                return quizImg;
            case 3:
            case 0:
                return assignmentImg;
            case 4:
                return notesImg;
            case 5:
                return videoImg;




            default:
                break;
        }
    }

    const calculateWeekPercentage = (weekContent, week_id) => {
        if ((weekContent?.length ?? 0) == 0) {
            return 0;
        }
        try {
            const sum = weekContent.reduce((prev, current) => {
                return prev + (current?.completion_percentage ?? 0);
            }, 0);
            const percentage = (sum / (weekContent.length))
            if(percentage.toFixed() == 100){
                saveTocCompletion(week_id, 'toc', subject?.id)

            }


            return percentage;
        }
        catch (e) {
            console.log("can not able to calcualte percentage", e)
            return 0;
        }
    }


    return (
        <div className='accordianInner'>
            <div onClick={() => setIsWeekOpen((prev) => !prev)} className='accordianTop'>
                <i className={`${isWeekOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`}></i>
                <div className='thumb'><img src={subject?.url ?? courseImg} alt="cal" /></div>
                <div className='thumbContent'>
                    <div className='left'>
                        <h3>{subject?.label}</h3>

                        {/* <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    {percentageLoading ? <div style={{ width: "auto" }} className='LoadingMessage'><img style={{ maxWidth: '34px' }} src={reportsLoader} /></div> : <div className='completedPer'>

                        {subjectPer.toFixed(0) == 0 ? <span className='red'>Pending</span> : <>Completed - {subjectPer.toFixed()}%</>}

                    </div>}

                </div>



            </div>
            {isWeekOpen && loading && <div className='LoadingMessage'><img src={reportsLoader} /></div>}
            {isWeekOpen && !loading && tocList && tocList.length != 0 && <div className='accordianSubInner'>

                {
                    tocList.map((week, weekIndex) => <div className='weekWrapper'>
                        <div className={currentOpenWeek == weekIndex ? 'accordianTop active' : 'accordianTop'} onClick={() => {
                            setCurrentOpenWeek(weekIndex)
                        }}>
                            <i className='fa fa-chevron-down'></i>{week?.label}
                            {/* <div className='completedPer'>Completed - <span style={{ marginLeft: '5px' }}> {calculateWeekPercentage(week?.content).toFixed()}%</span></div> */}
                            <CompeletionPercantage value={week?.completion_percentage == 100 ? week?.completion_percentage : calculateWeekPercentage(week?.content, week?.id)} />

                        </div>
                        <div className='accordianSubContent'>
                            <ol>
                                {week?.content && week?.content.map((content, index) => <li key={index}>

                                    {
                                        window.location.href.includes('app/admin') ? <div>
                                            <i className={getContentTypeClass(content?.content_subtype)}>
                                                <img
                                                    src={getContentTypeClass(content?.content_subtype)}
                                                    style={{ width: '20px', height: '20px' }}
                                                    alt="Content"
                                                />
                                            </i>{content?.title}
                                        </div> : <Link to={{
                                            pathname: `/learner/classroom/${subject?.id}`,
                                            state: {
                                                table_id: 256,
                                                content_id: content?.content_id,
                                                title: content?.title,
                                            }
                                        }}

                                        >
                                            <div>
                                                <i className={getContentTypeClass(content?.content_subtype)}>
                                                    <img
                                                        src={getContentTypeClass(content?.content_subtype)}
                                                        style={{ width: '20px', height: '20px' }}
                                                        alt="Content"
                                                    />
                                                </i>{content?.title}
                                            </div></Link>
                                    }

                                    <CompeletionPercantage value={content?.completion_percentage ?? 0} />
                                    {/* <div className='completedPer'>Completed - <span>{content?.completion_percentage}%</span></div> */}
                                </li>
                                )
                                }
                                {
                                /* <li><div><i className='fa fa-file-pdf'></i>Strategy and Operational Effectiveness </div><div className='completedPer'>Completed - <span>15.5%</span></div></li>
                                <li><div><i className='fa fa-video'></i>Type of Strategies</div> <div className='completedPer'>Completed - <span>15.5%</span></div></li>
                                <li><div><i className='fa fa-file'></i>Type of Strategies</div><div className='completedPer'>Completed - <span>15.5%</span></div></li> */}
                            </ol>
                        </div>
                    </div>)
                }

            </div>}
        </div>
    )
}



const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    const { getTocList, saveTocCompletion } = actions;
    return {
        getTocList: (subjectId) => dispatch(getTocList(subjectId)),
        saveTocCompletion: (id, type, subject_id) => dispatch(saveTocCompletion(id, type, subject_id)),

    }
}


export const CompeletionPercantage = (props) => {
    const { value } = props
    return (
        <div className='completedPer'>
            {value.toFixed(0) == 0 ? <span className='red'>Pending</span> : <> Completed - <span style={{ marginLeft: '5px' }}> {value.toFixed()}%
            </span></>}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReportSubjectCard);
