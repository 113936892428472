import React, { Component } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Trash from '../../public/icons/trash.svg';
import Stamp from '../../public/icons/stamp.svg';
import Calendar from '../../public/icons/calendar.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import download from '../../public/icons/down-arrow.svg';
import common from '../../common';
import axios from 'axios';
import './styles.css';
import Back from '../../public/icons/back.svg';
import { CsvToHtmlTable } from 'react-csv-to-table';
import reportsLoader from '../../public/icons/loader.png';
import Multiselect from 'multiselect-react-dropdown';
import  DataTable from 'react-data-table-component';
import { capitalize } from 'lodash-es';
import Select from 'react-select'
import { type } from 'os';
import ReactNotifications from 'react-notifications-component';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';


class FacultyReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
      filterClassName: '',
      filterSubjectName: '',
      filterAssessments: [],
      is_faculty_admin:false,
      user_id: localStorage.getItem("user_id"),
      classes: [],
      batches: [],
      subjects: [],
      assessment: [],
      batchesFilter: [],
      subjectsFilter: [],
      assessmentsReportsDataJson:[],
      columnData:[],
      
      classs: '',
      assessmentLabel: '',
      batch: 0,
      subject:0,
      assessment_id:0,
      
      
 
    }
    
  
  }
  
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getFacultyProfile();
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  classs: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0]?.class_id,
                  subjectsFilter: response.data.subjects.filter(subject => subject.class_id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batchesFilter: response.data.batches.filter(batch => batch.classs?.id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batches: response.data.batches });
  }


  getClassList = async () => {
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ), 
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }
  
  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: this.state.subjects, subject: 0,
                        batchesFilter: this.state.batches, batch: 0 });
    }
 }

  onSectionChange = (e) => {
    this.setState({ batch: parseInt(e.target.value)});
    
  }

  onSubjectChange = async(e) => {
    this.setState({ subject:parseInt(e.target.value)});
    console.log("onSubjectChange",e.target.value);
    const response = await this.props.getChildSelectData(e.target.value,"assessment");
    if(response !== undefined) {
      console.log("response.data",response.data);
      this.setState({ assessment: response.data.assessment});
    }
  }

  onAssessmentChange = async (e) => {
    this.setState({ assessment_id: parseInt(e.target.value)});
    console.log("assessment_id",e.target.value);
    const response = await this.props.facultyAssessmentReport(this.state.classs, e.target.value);
    if(response !== undefined) {
      console.log("assesment_detailed",response.data);
      this.setState({assessmentsReportsDataJson: response.data});
        let columnData = Object.keys(response.data.length > 0 && response.data[0]).map(val => ({name:val.replace("_"," ").toUpperCase(), selector: row => row[val]}));
        console.log('columnData');
        console.log(columnData);
        this.setState({columnData:columnData})
    }
    
    
  }

  
  

  exportFile = async() => {
    let dateObj= new Date();
    axios.get(`${common.apiBase}/${common.userLogReport}`,
      {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
        hiddenElement.click();
    }).catch(err => { console.error('error', err.message) })
  }


	render() {
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const customStyles = {
      headCells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '14px',
			      fontWeight: 700,
        },
      },
      cells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '16px',
			      fontWeight: 400,
        },
      },

    }
		return (
      <div  id="appBody" className='app-body'>
       <ReactNotifications/>
        
        <div className="app-div">
          <Helmet>
            <title>Lectures - Faculty</title>
          </Helmet>
          <Popup open={this.state.getUrl} closeOnDocumentClick onClose={this.closeUrl} className="content-geturl">
            <span className="close" onClick={this.closeUrl}>&#x2715;</span>
            <div className="url-child1">GET URL</div>
            <div className="url-child2">{this.state?.open_url}</div>
          </Popup>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="page-header-faculty">
        

          {/*Assessments Reports start*/}
              <div className="facultyReportsWrapper">
                <div className="facultyReporttsContainer">
                <h1 className='marginBottomZero'>Reports</h1>
                  <div className='filtersWrapper'>
                      <div className='labelWrapper'>
                          <div className='labelBlock'>
                              <label>Select Class</label>
                              <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                              <option value={parseInt(0)}>
                                  Select Class
                                </option>
                                {this.state.classes.map(classs => 
                                  <option value={parseInt(classs.class_id)} key={classs.class_id}>
                                    {classs.class_name}
                                  </option>
                                )}
                              </select>
                          </div>
                          <div className='labelBlock'>
                          <label>Select Section</label>
                          <select onChange={this.onSectionChange} value={parseInt(this.state.batch)}>
                            <option value={parseInt(0)}>
                              Select Section
                            </option>
                            {this.state.batchesFilter.map(batch => 
                              <option value={parseInt(batch.id)} key={batch.id}>
                                {batch.label}
                              </option>
                            )}
                          </select>
                          </div>
                          <div className='labelBlock'>
                          <label>Select Subject</label>
                          <select onChange={this.onSubjectChange} value={parseInt(this.state.subject)}>
                            <option value={parseInt(0)}>
                            Select Subject
                            </option> 
                            {this.state.subjectsFilter.map(subject =>
                              <option value={parseInt(subject.id)} key={subject.id}>
                                {subject.label}
                              </option>
                            )}
                          </select>
                          </div>
                          <div className='labelBlock'>
                          <label>Select Assessment</label>
                          <select onChange={this.onAssessmentChange} value={this.state.assessment}>
                            {this.state.assessment.length == 0 && <option value={parseInt(0)}>
                             Select Assessment
                            </option> }

                            {this.state.assessment && this.state.assessment.map((assessment) => (
                              <option value={parseInt(assessment.id)} key={assessment.id}>
                                {assessment.label}
                              </option>
                            ))}
                          </select>
                          </div>
                      </div>

                  </div>

                  <div className='assessmentsTableWrapper'>
                    <h2>Results</h2>
                    <DataTable
                        columns={this.state.columnData}
                        data={this.state.assessmentsReportsDataJson}
                        customStyles={customStyles}
                        highlightOnHover
                        pagination
                      />

                  </div>

                  
                </div>
            </div>       
        {/*Assessments Reports start*/}



          
        
        
        </div>

        </div>

			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getChildSelectData,facultyAssessmentReport} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getChildSelectData:(id,nested_filter_type) =>dispatch(getChildSelectData(id,nested_filter_type)),
    facultyAssessmentReport:(class_id,assessment_id) =>dispatch(facultyAssessmentReport(class_id,assessment_id)),
    
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyReports);