import Practice from '../pages/assessment/Practice';
import Branches from '../pages/branches/Branches';
import BranchAdmins from '../pages/branches/BranchAdmins';
import Classes from '../pages/classes/Classes';
import Sections from '../pages/classes/Sections';
import Teachers from '../pages/teachers/Teachers';
import Students from '../pages/students/Students';
import Subjects from '../pages/subjects/Subjects';
import ClassSubjects from '../pages/subjects/ClassSubjects';
import Institute from '../pages/institute/Institute';
import ScheduleDPP from '../pages/assessment/ScheduleDPP';
import AssessmentIncreaseAttempt from '../pages/admin/AssessmentIncreaseAttempt';
import ScheduledTests from '../pages/assessment/ScheduledTests';
import Sessions from '../pages/sessions/Sessions';
import NewAssessment from '../pages/assessment/NewAssessment';
import NewAssignment from '../pages/admin/NewAssignment';
import Settings from '../pages/assessment/Settings';
import Questions from '../pages/assessment/Questions';
import StudyMaterial from '../pages/content/StudyMaterial';
import LessonUnits from '../pages/content/LessonUnits';
import LessonChapters from '../pages/content/LessonChapters';
import LessonTopics from '../pages/content/LessonTopics';
import LessonSubtopics from '../pages/content/LessonSubtopics';
import ContentView from '../pages/content/ContentView';
import PracticeSections from '../pages/assessment/PracticeSections';
import QuestionBank from '../pages/questions/QuestionBank';
import Assignment from '../pages/admin/Assignment';
import ScheduleAssignment from '../pages/admin/ScheduleAssignment';
import Report from '../pages/report/Report';
import ScheduledQuestions from '../pages/questions/ScheduledQuestions';
import StudentSemPromotion from '../pages/promotion/StudentSemPromotion';
import Programs from '../pages/programs/Programs';
import AdminSessions from '../pages/programs/AdminSessions';
import AdminForm from '../pages/form/AdminForm';
import AdminBanner from '../pages/banner/AdminBanner';
import AdminSetting from '../pages/admin/AdminSetting';
import MyGoal from '../pages/mycoach/myGoal';
import UserProfile from '../pages/students/user-profile';
import curriculums from '../pages/curriculums/curriculums';
import Notifications from '../pages/firebase-notification/notifications';
import AssignmentIncreaseAttempt from '../pages/admin/AssignmentIncreaseAttempt';
import markAttendance from '../pages/mark-attendance/mark-attendance';
import BuildCache from '../pages/build-cache/BuildCache';
import AttendanceReports from '../pages/learners-reports/AdminAttendanceReports';
import Groups from '../pages/classes/Groups';
import GroupsListing from '../pages/classes/GroupsListing';
import OpenGroupsListing from '../pages/classes/OpenGroupsListing';
import FacultyDoubtResponses from '../pages/doubts/FacultyDoubtResponses';
import ElectiveSubjects from '../pages/classes/ElectiveSubjects';
import AdminDoubtResponse from '../pages/doubts/AdminDoubtResponse';
import CourseDashboard from '../pages/classes/CourseDashboard';
import UserCertificateUpload from '../pages/admin/UserUploadCertificate';
import { AdminDashbaord } from '../pages/admin/Dashboard/AdminDashbaord.jsx';
import { UserLog } from '../pages/admin/UserLog.jsx';
import UserAttendanceReport from '../pages/admin/AttendanceReport.jsx';
import CollegeMetaInfoList from '../pages/admin/CollegeMetaInfoList.jsx';


export default [
  {
    exact: true,
    component: Institute,
    path: '/admin/institute'
  },
  {
    exact: true,
    component: Branches,
    path: '/admin/branches'
  },
  {
    exact: true,
    component: BranchAdmins,
    path: '/admin/branch-admins'
  },
  {
    exact: true,
    component: Classes,
    path: '/admin/classes'
  },
  {
    exact: true,
    component: Sections,
    path: '/admin/class-sections/:classs'
  },
  {
    exact: true,
    component: Teachers,
    path: '/admin/teachers'
  },
  {
    exact: true,
    component: Students,
    path: '/admin/students'
  },
  {
    exact: true,
    component: UserProfile,
    path: '/admin/user-profile'
  },
  {
    exact: true,
    component: Subjects,
    path: '/admin/subjects'
  },
  {
    exact: true,
    component: ClassSubjects,
    path: '/admin/subjects/:id'
  },
  {
    exact: true,
    component: StudyMaterial,
    path: '/admin/study-material'
  },
  {
    exact: true,
    component: LessonUnits,
    path: '/admin/study-material/units'
  },
  {
    exact: true,
    component: LessonChapters,
    path: '/admin/study-material/units/chapters'
  },
  {
    exact: true,
    component: LessonTopics,
    path: '/admin/study-material/units/chapters/topics'
  },
  {
    exact: true,
    component: LessonSubtopics,
    path: '/admin/study-material/units/chapters/topics/subtopic'
  },
  {
    exact: true,
    component: ContentView,
    path: '/admin/content-view/:id'
  },
  {
    exact: true,
    component: Practice,
    path: '/admin/practice-tests'
  },
  {
    exact: true,
    component: PracticeSections,
    path: '/admin/practice-tests/assign-sections'
  },
  {
    exact: true,
    component: ScheduleDPP,
    path: '/admin/dpp-scheduling'
  },

  {
    exact: true,
    component: AssessmentIncreaseAttempt,
    path: '/admin/assessment-increase-attempt'
  },

  {
    exact: true,
    component: AssignmentIncreaseAttempt,
    path: '/admin/assignment-increase-attempt'
  },


  {
    exact: true,
    component: ScheduleAssignment,
    path: '/admin/scheduled-assignment'
  },
  {
    exact: true,
    component: ScheduledTests,
    path: '/admin/scheduled-tests'
  },
  {
    exact: true,
    component: Assignment,
    path: '/admin/assignment'
  },
  {
    exact: true,
    component: Sessions,
    path: '/admin/sessions'
  },
  {
    exact: true,
    component: NewAssessment,
    path: '/admin/new-assessment'
  },
  {
    exact: true,
    component: NewAssignment,
    path: '/admin/new-assignment'
  },
  {
    exact: true,
    component: NewAssignment,
    path: '/admin/new-assignment/:id'
  },
  {
    exact: true,
    component: Settings,
    path: '/admin/assessment-settings/:id'
  },
  {
    exact: true,
    component: Questions,
    path: '/admin/assessment-questions/:id'
  },
  {
    exact: true,
    component: QuestionBank,
    path: '/admin/question-bank'
  },
  {
    exact: true,
    component: ScheduledQuestions,
    path: '/admin/scheduled-questions'
  },
  {
    exact: true,
    component: Report,
    path: '/admin/report'
  },
  {
    exact: true,
    component: StudentSemPromotion,
    path: '/admin/student-sem-promotion'
  },
  {
    exact: true,
    component: curriculums,
    path: '/admin/curriculums'
  },
  {
    exact: true,
    component: Programs,
    path: '/admin/programs'
  },
  {
    exact: true,
    component: AdminSessions,
    path: '/admin/admin-sessions'
  },
  {
    exact: true,
    component: AdminForm,
    path: '/admin/form'
  },
  {
    exact: true,
    component: AdminBanner,
    path: '/admin/banner'
  },
  {
    exact: true,
    component: AdminSetting,
    path: '/admin/setting'
  },
  {
    exact: true,
    component: MyGoal,
    path: '/admin/mygoal/:id'
  },
  {
    exact: true,
    component: Notifications,
    path: '/admin/firebase-notifications'
  },
  {
    exact: true,
    component: markAttendance,
    path: '/admin/mark-attendance'
  },
  {
    exact: true,
    component: BuildCache,
    path: '/admin/build-cache'
  },
  {
    exact: true,
    component: AttendanceReports,
    path: '/admin/attendance-list'
  },

  {
    exact: true,
    component: Groups,
    path: '/admin/create-group/:classs'
  },

  {
    exact: true,
    component: GroupsListing,
    path: '/admin/groups-listing'
  },

  {
    exact: true,
    component: OpenGroupsListing,
    path: '/admin/open-group-listing'
  },

  {
    exact: true,
    component: AdminDoubtResponse,
    path: '/admin/doubt-responses/:id'
  },


  {
    exact: true,
    component: ElectiveSubjects,
    path: '/admin/elective-subjects/'
  },

  {
    exact: true,
    component: CourseDashboard,
    path: '/admin/course-dashboard'
  },

  {
    exact: true,
    component: UserCertificateUpload,
    path: '/admin/certificate-upload'
  },

  {
    exact: true,
    component: UserAttendanceReport,
    path: '/admin/attendance-report'
  },

  {
    exact: true,
    component: AdminDashbaord,
    path: '/admin/dashboard'
  },
  {
    exact: true,
    component: UserLog,
    path: '/admin/user_log'
  },
  {
    exact: true,
    component:CollegeMetaInfoList,
    path: '/admin/college-info'
  },

 

]
