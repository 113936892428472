import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import Logo from '../../public/icons/favicon.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import ForgotImage from '../../public/icons/Group 270.svg';
import validator from 'validator';
import './styles.css';
import config from '../../config';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      userid: '',
      otp_status:0,
      user_status:0,
      if_user_exists:0,
      otp: '',
      password: '',
      password_change: false,
      roll: '',
      dob: '',
      message: '',
      wrong_otp: false,
      hide: true,
      is_otp_on:false,
      saveLanguageWiseData:[],
    }
  }

  componentDidMount() {
    let btn = document.getElementsByClassName("forgot-pw")[0];
    if(btn)
      btn.parentNode.parentNode.style.backgroundColor = "#ffffff";

      if(localStorage.getItem('lang')){
        let language = localStorage.getItem('lang');
        console.log("language",language);
        this.fetchLanguageWiseData(language);
      }
     
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"login");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      this.setState({ saveLanguageWiseData: response?.data});
     
      
    }
  }
  
  

  handleChangeUserID = (e) => {
    this.setState({ userid: e.target.value })
  } 

  handleChangeOTP = (e) => {
    this.setState({ otp: e.target.value })
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.sendOTP(this.state.userid);
    }
  }

  handleChangeDOB =(e) =>{
    this.setState({ dob: e.target.value })
  }

  handleChangeMessage = (e) =>{
    this.setState({ message: e.target.value })
  }

  sendOTP = async (username) => {
    const response = await this.props.sendOtp(username,localStorage.getItem('lang'));
    if(response != undefined){
      this.setState({loading: false,otp_status:response.status,message:response.data,is_otp_on:true})
    }else{
      this.setState({if_user_exists:1});
    }
  }

  updatePassword = async (username,otp,password) => {

    const response = await this.props.updatePassword(username,otp,password,localStorage.getItem('lang'));
    if(response != undefined){
      this.setState({loading: false,user_status:response.status,message:response.data});
    }
  }

  sendQuery = async () => {
    const {userid,dob,message} = this.state;
    await this.props.sendQuery(userid,dob,message);
  }

  handleKeyPress2 = (e) => {
    if (e.key === 'Enter') {
      this.updatePassword(this.state.userid,this.state.otp,this.state.password);
    }
  }

  handleKeyPress3 = (e) => {
    if (e.key === 'Enter') {
      this.sendQuery();
    }
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  render() {
          console.log(this.state.userid + this.state.dob +this.state.message)
      const isEnabled= !this.state.userid || !this.state.dob || !this.state.message;
      if(this.state.user_status==1){

        localStorage.setItem('username',this.state.userid);
        localStorage.setItem('password', this.state.password);

        return <Redirect to="/login" />
        //this.props.userLogin(this.state.userid,this.state.password);
      }

      
      return (
        <div className="forgot-pw">
          <ReactNotifications />
          <Helmet>
            <title>{this.props.saveLanguageWiseData.forgot_password}</title>
          </Helmet>
          <div className='forgotWrapper'>
          <div className="forgot-left">
            <div className="forgot-image">
              <img src={ForgotImage}/>
            </div>
          </div>
            
            <div className="forgot-right">
            <div className="forgot_header">
              {/* <span> <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span> */}
              {localStorage.getItem('logo_url') != 'undefined'? 
              <span><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
            : 
            <span><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span>
            }
            </div>
            <div className="forgot_title">
             { this.state.if_user_exists === 1 ? this.props.saveLanguageWiseData.report_problem : this.state.is_otp_on === true ? this.props.saveLanguageWiseData.set_new_password: this.props.saveLanguageWiseData.reset_password}
            </div>
            <div className="forgot-sub-title">
              { this.state.if_user_exists === 1 ? this.props.saveLanguageWiseData.reset_text :this.state.is_otp_on === true ? this.props.saveLanguageWiseData.code_on_email : this.props.saveLanguageWiseData.reset_text}
            </div>
            {this.state.otp_status === 0 ? this.state.if_user_exists === 1 ?
              <div className="fw_input-forgot fw_input-forgot2">
                
                <input placeholder={this.props.saveLanguageWiseData.username} name='username' value={this.state.username} onKeyPress={this.handleKeyPress3}
                  onChange={this.handleChangeUserID}/>
                <input placeholder='Date of birth as (ddmmyyyy)' type='date' name='dob'
                  value={this.state.dob} onKeyPress={this.handleKeyPress3}
                  onChange={this.handleChangeDOB}/>
                <input placeholder={this.props.saveLanguageWiseData.add_message} name='message'
                  value={this.state.message} onKeyPress={this.handleKeyPress3}
                  onChange={this.handleChangeMessage}/>
                <button onClick={() => this.sendQuery()} disabled={isEnabled}>
                {this.props.saveLanguageWiseData.send_query}
                </button>
              </div>
              :<div className="fw_input-forgot">
            <div class="user-input-symbol"><img src=""/></div>
                <input placeholder={this.props.saveLanguageWiseData.username} name='userid' value={this.state.userid} onKeyPress={this.handleKeyPress}
                  onChange={this.handleChangeUserID}/>
                <button onClick={() => this.sendOTP(this.state.userid)} disabled="">
                  {this.props.saveLanguageWiseData.getcode}
                </button>
              </div> :<div className="fw_input-forgot fw_input-forgot2">
                <p style={this.state.wrong_otp ? {display: 'block'} 
                  : {display: 'none'}}>{this.props.saveLanguageWiseData.wrong_otp}.</p>
                <input placeholder={this.props.saveLanguageWiseData.enter_otp} name='otp'
                  value={this.state.otp} onKeyPress={this.handleKeyPress2}
                  onChange={this.handleChangeOTP} type="text"/>

                <div className="input_field">
                  <input placeholder={this.props.saveLanguageWiseData.enter_new_password} name='password' type={this.state.hide ? "password" : "text"} 
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword-on-forgot" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={See} alt="see"/> 
                      : <img src={Hide} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.updatePassword(this.state.userid,this.state.otp,this.state.password,localStorage.getItem('lang'))} disabled="">
                    {this.props.saveLanguageWiseData.change_password}
                  </button>
                  <button onClick={() => this.sendOTP(this.state.userid)}>{this.props.saveLanguageWiseData.send_otp_again}</button>
                </div>
              </div>}

            </div>
            </div>
          </div>
      )
    }
  }

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { sendOtp,updatePassword,userLogin,sendQuery,getLanguageWiseData} = actions;
  return {
    sendOtp: (username,language) => dispatch(sendOtp(username,language)),
    updatePassword: (username,otp,password,language) => dispatch(updatePassword(username,otp,password,language)),
    userLogin: (username, password) => dispatch(userLogin(username, password)),
    sendQuery:(email,dob,message) => dispatch(sendQuery(email,dob,message)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);