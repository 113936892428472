import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Popup from 'reactjs-popup';
import common from '../../common';
import axios from 'axios';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Expand from '../../public/icons/expand.svg';
import Collapse from '../../public/icons/collapse.svg';
import Right from '../../public/icons/right-arrow-black.svg';
import Notes from '../../public/icons/notes.svg';
import Video from '../../public/icons/video.svg';
import Refresh from '../../public/icons/refresh.png';
import Assessment from '../../public/icons/assessment.svg';
import Back from '../../public/icons/back.svg';
import Trash from '../../public/icons/trash.svg';
import Stamp from '../../public/icons/stamp.svg';
import Header from '../../components/navigation/Header';
import ImgHolder from '../../public/images/img-holder.svg';
import Assignment from '../../public/icons/assignment.svg';
import Empty from '../../components/error/Empty';
import data from '../../mock/mockData';
import Listing from '../../public/icons/listing.svg';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';
import config from '../../config'

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 590 },
    items: 2,
    slidesToSlide: 2
  },
};


class LessonUnits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      d_id2: null,
      token: localStorage.getItem('token'),
      action_type: null,
      copy: {},
      open: false,
      open2: false,
      open3: false,
      open4: false,
      openx: false,
      openx2: false,
      openx3: false,
      opend: false,
      opend2: false,
      openp: false,
      is_published: false,
      upload_new: false,
      to_delete: '',
      delete_fw: 0,
      subject: this.props.location.search?.split('&')[1].substring(8, this.props.location.search?.split('&')[1].length),
      classs: this.props.location.search?.split('&')[0].substring(7, this.props.location.search?.split('&')[0].length),
      class_label: this.props.location.state?.classs,
      subject_label: this.props.location.state?.subject,
      unit_name: '',
      content_id: '',
      expand: {},
      content: data.content,
      center_id: localStorage.getItem('center_id'),
      unit_data: [],
      label: '',
      label2: '',
      order: '',
      order2: '',
      toc_id: null,
      title: '',
      duration_mins: '',
      file: null,
      type: null,
      is_released: true,
      planner_id: null,
      level: null,
      level_name: null,
      parent: null,
      image: null,
      imageURL: null,
      scheduled_date: '',
      due_date: '',
      videoUrl: null,
      notesUrl: null,
      assignment_id: '',
      user_id: localStorage.getItem("user_id"),
      currentTocId: parseInt(localStorage.getItem("expandVal")),

      openScheduleDialog: false,
      assessmentScheduledDate: '',
      assessmentScheduledDueDate: '',
      assessmentId: '',
      assessmentTitle: '',
      assessmentSolutionDate: '',
      assessmentResultDate: '',
      assessmentBatchId: '',
      assessmentReleaseDate: '',
      assessmentPlanner_id: '',

      fileURlInputShow: true,
      notesURLInputShow: false,
      videoURLInputShow: false,

      fileURlInputShowEdit: false,
      notesURLInputShowEdit: false,
      videoURLInputShowEdit: false,
      is_admin: localStorage.getItem('is_admin'),
      fileDownloadable: false,
      skip_cache: 1,




    }
  }

  handleClearCache = async () => {
    const { subject, skip_cache } = this.state;
    console.log("subject", subject);
    try {
      const response = await this.props.getClearCache(subject, skip_cache);
      if (response) {
        this.setState({ subject_id: subject, skip_cache: 1 }); // Resetting skip_cache
      }
    } catch (error) {
      console.error("Error clearing cache:", error);
    }
  };

  componentDidMount() {

    this.getContentTOC();
    setTimeout(function () { localStorage.setItem("expandVal", "") }, 60000);
  }

  show = () => this.setState({ open: true, level_name: 'Unit', label: '', order: '' });

  close = () => this.setState({ open: false });

  show2 = (val) => this.setState({
    open2: true, parent: val, level_name: 'Chapter',
    label2: '', order2: ''
  });

  close2 = () => this.setState({ open2: false });

  closeSchedule = () => this.setState({ openSchedule: false });

  closeScheduleDialog = () => {
    this.setState({ openScheduleDialog: false });
  }

  show3 = (val, id) => this.setState({ open3: true, level: val, toc_id: id });

  close3 = () => this.setState({ open3: false });

  show4 = (val) => this.setState({
    open4: true, open3: false, type: val,
    duration_mins: '', title: ''
  });

  close4 = () => this.setState({ open4: false });

  showx = (id, val) => this.setState({
    openx: true, label: val.label, order: val.order,
    to_delete: id
  });

  closex = () => this.setState({ openx: false });

  showx2 = (id, val) => this.setState({
    openx2: true, label2: val.label, order2: val.order,
    to_delete: id
  });

  closex2 = () => this.setState({ openx2: false });

  showx3 = (id, val) => {
    console.log("value");
    console.log(val);
    if (val.direct_url === null) {
      this.setState({ fileURlInputShowEdit: true })
    } else {
      this.setState({ fileURlInputShowEdit: false })
    }




    this.setState({
      openx3: true, title: val.title, to_delete: val.content_id,
      file: null, type: (val.content_subtype === 4 ? 0 : val.content_subtype === 5 ? 1 : 2), toc_id: id,
      upload_new: false, image: val.thumbnail, imageURL: val.thumbnail,
      duration_mins: val.duration_mins, videoUrl: val.direct_url, notesUrl: val.direct_url, fileDownloadable: val.downloadable
    });
    if (val.content_subtype === 5 && val.direct_url !== null) {
      this.setState({ videoURLInputShowEdit: true })
    } else {
      this.setState({ videoURLInputShowEdit: false })
    }
    if (val.content_subtype === 4 && val.direct_url !== null) {
      this.setState({ notesURLInputShowEdit: true })
    } else {
      this.setState({ notesURLInputShowEdit: false })
    }
  }

  closex3 = () => this.setState({ openx3: false });

  showd = (val, val2) => this.setState({
    opend: true, d_id: val.id, to_delete: val2,
    delete_fw: val2 === 'unit' ? val.chapters.length : val.topics.length
  });

  closed = () => this.setState({ opend: false });



  showSchedule = (val, action, id) => {
    //const response = await this.props.getSectionList2(this.state.center_id, this.state.filterClass?this.state.filterClass:val?.class_id);
    this.setState({ scheduled_date: val?.scheduled_date, due_date: val?.due_date, openSchedule: true, assignment_id: val?.assignment_id, title: val.title, is_released: true, toc_id: id, planner_id: val?.planner_id });
  }

  showScheduleDialog = (val, action, id) => {
    console.log(val);
    console.log(action);
    console.log(id);
    console.log(val.planner_id);
    this.setState({
      assessmentScheduledDate: val?.scheduled_date,
      assessmentScheduledDueDate: val?.due_date,
      assessmentId: val?.assessment_id,
      assessmentTitle: val.title,
      assessmentPlanner_id: val.planner_id,
      openScheduleDialog: true,
    });
    //const response = await this.props.getSectionList2(this.state.center_id, this.state.filterClass?this.state.filterClass:val?.class_id);
    //this.setState({scheduled_date: val?.scheduled_date,due_date:val?.due_date ,openSchedule: true, assignment_id: val?.assignment_id,title: val.title,is_released: true,toc_id:id,planner_id:val?.planner_id});
  }



  assessmentScheduleChange = (e) => {
    this.setState({ assessmentScheduledDate: e.target.value })
    this.setState({ assessmentReleaseDate: e.target.value })
    this.setState({ assessmentResultDate: e.target.value })
    this.setState({ assessmentSolutionDate: e.target.value })
  }

  assessmentDueChange = (e) => {
    const { assessmentScheduledDate } = this.state;
    this.setState({ assessmentScheduledDueDate: e.target.value })
    this.setState({ assessmentReleaseDate: assessmentScheduledDate })
    this.setState({ assessmentResultDate: assessmentScheduledDate })
    this.setState({ assessmentSolutionDate: assessmentScheduledDate })
  }


  showd2 = (val, action) => this.setState({
    opend2: true, d_id2: action === 1 ? val.assessment_id
      : action === 0 ? val.assignment_id : val.content_id, action_type: action
  });

  closed2 = () => this.setState({ opend2: false });

  showp = (val, action) => this.setState({
    openp: true, d_id2: action === 1 ? val.assessment_id
      : action === 0 ? val.assignment_id : val.content_id, is_published: !val.is_published,
    action_type: action
  });

  export = async (val, action) => {
    let url, fileName;
    let dateObj = new Date();

    if (action === 4) {
      fileName = 'notes-' + dateObj.toISOString().split('T')[0]
      url = `${common.apiBase}/${common.exportNotes}?content_id=${val.content_id}`;
    }
    if (action === 5) {
      fileName = 'videos-' + dateObj.toISOString().split('T')[0]
      url = `${common.apiBase}/${common.exportVideos}?content_id=${val.content_id}`;
    }

    if (action === 1) {
      fileName = 'assessment-' + dateObj.toISOString().split('T')[0]
      url = `${common.apiBase}/${common.exportAssessment}?content_id=${val.content_id}`;
    }
    if (action === 0) {
      fileName = 'assignment-' + dateObj.toISOString().split('T')[0]
      url = `${common.apiBase}/${common.exportAssignment}?content_id=${val.content_id}`;
    }

    axios.get(url,
      {
        headers: {
          'Authorization': 'token ' + this.state.token
        }
      }).then(res => {
        console.log(res)
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName + '.csv';
        hiddenElement.click();
      }).catch(err => { console.error('error', err.message) })

    //action === 4 ? this.props.exportNotes(val.content_id) : this.props.exportVideos(val.content_id);
  }

  closep = () => this.setState({ openp: false });

  scheduleChange = (e) => {
    this.setState({ scheduled_date: e.target.value })
  }

  dueChange = (e) => {
    this.setState({ due_date: e.target.value })
  }

  getContentTOC = async () => {
    const { subject } = this.state;
    const response = await this.props.getUnitTOC(parseInt(subject));
    if (response !== undefined) {
      this.setState({ unit_data: response.data, expand: this.getExpansion(response.data) });
    }

    var expansion = this.state.expand;
    let expandVal = parseInt(localStorage.getItem("expandVal"));
    expansion[expandVal] = localStorage.getItem("expand") == "true" ? true : false;
    this.setState({ expand: expansion });
  }

  getExpansion = (arr) => {
    var expand = {};
    for (var i = 0; i < arr.length; i++) {
      expand[arr[i].id] = true;
      if (arr[i].chapters !== null) {
        for (var j = 0; j < arr[i].chapters.length; j++) {
          expand[arr[i].chapters[j].id] = false;
        }
      }
    }
    return expand;
  }

  onExpand = (val) => {
    var expansion = this.state.expand;
    if (this.state.expand[val] === false) {
      expansion[val] = true;
      this.setState({ expand: expansion });
    }
    else {
      expansion[val] = false;
      this.setState({ expand: expansion });
      localStorage.setItem("expandVal", "")
      localStorage.setItem("expand", "")
    }
  }

  uploadNew = () => {
    this.setState({ upload_new: true })
  }

  handleChange2 = (event) => {

    if (event.target.files[0].type.split('/')[0] === 'image') {

      const fsize = event.target.files[0].size;
      console.log(fsize)
      //const file = Math.round((fsize / 1024));
      if (fsize >= config.fileSizeInKB * 1000) {
        store.addNotification({
          title: 'Error',
          message: `File too Big, please select a file less than 50kb`,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })

        return false
      }

      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `Only images can be uploaded here.`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  labelChange = (e) => {
    this.setState({ label: e.target.value })
  }

  orderChange = (e) => {
    const order = (e.target.validity.valid) ? e.target.value : this.state.order;
    this.setState({ order })
  }

  labelChange2 = (e) => {
    this.setState({ label2: e.target.value })
  }

  orderChange2 = (e) => {
    const order2 = (e.target.validity.valid) ? e.target.value : this.state.order2;
    this.setState({ order2 })
  }

  titleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  durationChange = (e) => {
    const duration_mins = (e.target.validity.valid) ? e.target.value : this.state.duration_mins;
    this.setState({ duration_mins })
  }

  VideoUrlAdd = (e) => {
    this.setState({ videoUrl: e.target.value })
    this.setState({ notesUrl: '' })
  }

  NotesUrlAdd = (e) => {
    this.setState({ notesUrl: e.target.value })
    this.setState({ videoUrl: '' })
  }

  handleChange = (event) => {
    if (event?.target?.files[0] === undefined) {
      this.setState({ file: null })
      document.getElementById('doc-input').value = "";
    }
    else {
      if (this.state.type === 1) {
        if (event?.target?.files[0]?.type?.split('/')[0] === 'video') {
          if (event?.target?.files[0] !== undefined) {
            this.setState({
              file: event?.target?.files[0],
              videoUrl: ''
            })
          }
          else {
            this.setState({
              file: null
            })
          }
        }
        else {
          this.setState({
            file: null
          })
          document.getElementById('doc-input').value = "";
          store.addNotification({
            title: 'Error',
            message: `Only video can be uploaded here.`,
            type: 'danger',
            container: 'top-right',
            dismiss: {
              duration: 3000
            }
          })
        }
      }
      else if (this.state.type === 0) {

        //newCode For Notes start updated
        var filename = event.target.files[0];
        var getFileExtensions = this.props.learnerprofile?.institute_settings?.allowed_file_extensions_for_notes;
        console.log("getFileExtensions");
        console.log(getFileExtensions);
        console.log(filename);
        if (filename) {
          var fileext = filename.name.split('.').pop();
          console.log("fileext notes in", fileext);
          console.log(getFileExtensions.indexOf(fileext))
          var allowedExtensions = getFileExtensions.split(',');
          if (allowedExtensions.indexOf(fileext) === -1) {
            this.setState({ uploadFileError: true });
            this.setState({
              file: null
            })
            document.getElementById('doc-input').value = "";
            store.addNotification({
              title: 'Error',
              message: `Please check your file format`,
              type: 'danger',
              container: 'top-right',
              dismiss: {
                duration: 3000
              }
            })
          } else {
            this.setState({
              file: event?.target?.files[0],
              notesUrl: ''
            })
          }
        }
        //newCode For Notes start updated


        // if(event?.target?.files[0]?.type?.split('/')[1] === 'pdf') {
        //   if(event?.target?.files[0] !== undefined) {
        //     this.setState({ 
        //       file: event?.target?.files[0],
        //       notesUrl:''
        //     })
        //   }
        //   else {
        //     this.setState({ 
        //       file: null
        //     })
        //   }
        // }
        // else {
        //   this.setState({ 
        //     file: null
        //   })
        //   document.getElementById('doc-input').value = "";
        //   store.addNotification({
        //     title: 'Error',
        //     message: `Only PDF can be uploaded here.`,
        //     type: 'danger',
        //     container: 'top-right',
        //     dismiss: {
        //       duration: 3000
        //     }
        //   })
        // }
      }
    }
  }



  removeFile = () => {
    this.setState({ file: null })
    document.getElementById('doc-input').value = "";
  }

  newUnit = async () => {
    const { label, order, level_name, subject } = this.state;
    await this.props.addUnit(label, order, 0, level_name, null, subject)
  }

  newChapter = async () => {
    const { label2, order2, level_name, parent, subject } = this.state;
    await this.props.addUnit(label2, order2, 1, level_name, parent, subject)
  }

  addContent = () => {
    const { level, file, title, duration_mins, type, toc_id, subject, classs, image, videoUrl, notesUrl, fileDownloadable } = this.state;
    this.props.addContent(level, file ? file : '', title, duration_mins, type, toc_id, parseInt(subject), parseInt(classs), image, this.state.type == 0 ? notesUrl : videoUrl, fileDownloadable ? 1 : 0);

    var expansion = this.state.expand;
    localStorage.setItem("expandVal", toc_id)
    localStorage.setItem("expand", "true")
    this.setState({ currentTocId: toc_id })
    this.setState({ open4: false })
  }

  updateContent = async () => {

    const { to_delete, file, title, duration_mins, type, toc_id, subject, classs, image, videoUrl, notesUrl, fileDownloadable } = this.state;
    await this.props.updateContent(to_delete, file ? file : '', title, duration_mins, type, toc_id, parseInt(subject), parseInt(classs), image, this.state.type == 0 ? notesUrl : videoUrl, fileDownloadable ? 1 : 0);
    this.setState({ openx3: false })
  }

  updateUnit = async () => {
    const { to_delete, label, order } = this.state;
    await this.props.updateUnit(to_delete, label, order);
    this.setState({ openx: false });
  }

  updateChapter = async () => {
    const { to_delete, label2, order2 } = this.state;
    await this.props.updateUnit(to_delete, label2, order2);
    this.setState({ openx2: false });
  }

  assessmentCreateDPP = async () => {
    this.setState({ openScheduleDialog: false })
    const { assessmentId, assessmentTitle, classs, subject, assessmentBatchId, assessmentScheduledDate, assessmentSolutionDate,
      assessmentResultDate, assessmentScheduledDueDate, is_released, assessmentReleaseDate } = this.state;

    console.log("assessmentId", assessmentId)
    console.log("assessmentTitle", assessmentTitle)
    console.log("classs", classs)
    console.log("subject", subject)
    console.log("assessmentScheduledDate", assessmentScheduledDate)
    console.log("assessmentScheduledDueDate", assessmentScheduledDueDate)
    console.log("assessmentSolutionDate", assessmentSolutionDate)
    console.log("assessmentResultDate", assessmentResultDate)
    console.log("assessmentBatchId", assessmentBatchId)
    console.log("release_date", assessmentReleaseDate)
    console.log("is_released", is_released);


    const responsesss = await this.props.assessmentCreateDPP(assessmentId, assessmentTitle, classs, parseInt(subject), null, assessmentScheduledDate, assessmentSolutionDate,
      assessmentResultDate, assessmentScheduledDueDate, is_released, assessmentReleaseDate);

  }

  assessmentEditDPP = async () => {
    this.setState({ openScheduleDialog: false })
    const { assessmentPlanner_id, assessmentScheduledDate, assessmentSolutionDate, assessmentResultDate, assessmentScheduledDueDate, assessmentReleaseDate } = this.state;

    console.log("assessmentPlanner_id", assessmentPlanner_id)
    console.log("assessmentScheduledDate", assessmentScheduledDate)
    console.log("assessmentSolutionDate", assessmentSolutionDate)
    console.log("assessmentResultDate", assessmentResultDate)
    console.log("assessmentScheduledDueDate", assessmentScheduledDueDate)
    console.log("release_date", assessmentReleaseDate)
    const responsesss = await this.props.assessmentEditDPP(assessmentPlanner_id, assessmentScheduledDate, assessmentSolutionDate,
      assessmentResultDate, assessmentScheduledDueDate, assessmentReleaseDate);

  }



  createDPP = async () => {
    const { assignment_id, toc_id, title, classs, subject, scheduled_date, due_date, is_released, planner_id } = this.state;


    if (planner_id) {
      const response = await this.props.updateAssignmentPlanner(planner_id, assignment_id, parseInt(subject), title, scheduled_date, scheduled_date, scheduled_date, scheduled_date, due_date,
        120, parseInt(classs), "", "", parseInt(this.state.section_id), is_released, "");
    }

    else {
      const response = await this.props.assignmentPlanner(assignment_id, parseInt(subject), title, scheduled_date, scheduled_date, scheduled_date, scheduled_date, due_date,
        120, parseInt(classs), "", "", "", is_released, "");
    }

    localStorage.setItem("expandVal", toc_id)
    localStorage.setItem("expand", "true")
    this.setState({ currentTocId: toc_id })
    this.setState({ openSchedule: false })


    window.location.reload();
    //this.setState({ schedule_step: true })
  }

  deleteUnit = async () => {
    const { to_delete, d_id } = this.state;
    /*if(this.state.delete_fw > 0) {
      this.setState({ opend: false });
      store.addNotification({
        title: `Cannot delete this ${to_delete}`,
        message: `This ${to_delete} contains ${to_delete === 'unit' ? 'chapters' : 'topics'}.`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
    else {
      await this.props.deleteUnit(d_id);
      this.setState({ opend: false });
    }*/
    await this.props.deleteUnit(d_id);
    this.setState({ opend: false });
  }

  deleteContent = async () => {
    const { d_id2 } = this.state;
    if (this.state.action_type === 1) {
      await this.props.deleteAssessment(d_id2);
    } else if (this.state.action_type === 0) {
      await this.props.deleteAssignment(d_id2);
    }
    else {
      await this.props.deleteContent(d_id2);
    }
    this.setState({ opend2: false });
  }

  publishContent = async () => {
    const { d_id2, is_published } = this.state;
    if (this.state.action_type === 1) {
      await this.props.publishAssessment(d_id2, is_published);
    } else if (this.state.action_type === 0) {
      await this.props.publishAssignment(d_id2, is_published);
    }
    else {
      await this.props.publishContent(d_id2, is_published);
    }
    this.setState({ openp: false });
  }

  copyUrl = (url, id) => {
    let { copy } = this.state

    if (navigator.clipboard.writeText(url)) {
      copy[id] = true
      this.setState({ copy })
    }
  }


  uploadInputChange = (e) => {
    console.log(e.target.value);
    this.setState({ fileURlInputShow: true })
    this.setState({ notesURLInputShow: false })
    this.setState({ videoURLInputShow: false })
  }

  uploadInputChangeVideoUrl = (e) => {
    this.setState({ videoURLInputShow: true })
    this.setState({ notesURLInputShow: false })
    this.setState({ fileURlInputShow: false })

  }

  uploadInputChangeNotesUrl = (e) => {
    this.setState({ notesURLInputShow: true })
    this.setState({ videoURLInputShow: false })
    this.setState({ fileURlInputShow: false })
  }


  uploadInputChangeEdit = (e) => {
    console.log(e.target.value);
    this.setState({ fileURlInputShowEdit: true })
    this.setState({ notesURLInputShowEdit: false })
    this.setState({ videoURLInputShowEdit: false })
    console.log("videoURLInputShowEdit")
    console.log(this.state.videoURLInputShowEdit)
  }

  uploadInputChangeVideoUrlEdit = () => {
    this.setState({ videoURLInputShowEdit: true })
    this.setState({ notesURLInputShowEdit: false })
    this.setState({ fileURlInputShowEdit: false })

  }


  uploadInputChangeNotesUrlEdit = () => {
    this.setState({ notesURLInputShowEdit: true })
    this.setState({ videoURLInputShowEdit: false })
    this.setState({ fileURlInputShowEdit: false })
  }


  fileDownloadableChange = (e) => {
    console.log(e.target.checked);
    if (e.target.checked == true) {
      this.setState({ fileDownloadable: 1 })
    } else {
      this.setState({ fileDownloadable: 0 })
    }
  }


  render() {
    const MAX_LENGTH = 60;
    const isEnabled = this.state.label.length > 0 && (this.state.order + '').length > 0;
    const isEnabled2 = this.state.label2.length > 0 && (this.state.order2 + '').length > 0;
    // const isEnabled3 = this.state.title.length > 0 && (this.state.duration_mins + '').length > 0
    //                     && this.state.type !== null && (this.state.file !== null || this.state.videoUrl !== null);

    //  const isEnabled3 = this.state.title.length > 0 && (this.state.duration_mins + '').length > 0
    //                       && this.state.type !== null && (this.state.file !== null || this.state.videoUrl !== '' && this.state.videoUrl !== null || this.state.notesUrl !== '' && this.state.notesUrl !== null);                    

    const isEnabled3 =
      this.state.type !== null &&
      (this.state.videoURLInputShow && this.state.videoUrl !== null && this.state.videoUrl !== '' && this.state.title.length > 0 && (this.state.duration_mins + '').length > 0 && this.state.file == null) ||
      (this.state.notesURLInputShow && this.state.notesUrl !== null && this.state.notesUrl !== '' && this.state.title.length > 0 && (this.state.duration_mins + '').length > 0) ||
      (this.state.title.length > 0 && (this.state.duration_mins + '').length > 0 && this.state.file !== null)




    const isEnabled4 = this.state.title.length > 0 && (this.state.duration_mins + '').length > 0;


    const isEnabledS = this.state.scheduled_date?.length > 0 && this.state.due_date?.length > 0;
    const isEnabledAssessment = this.state.assessmentScheduledDate?.length > 0 && this.state.assessmentScheduledDueDate?.length > 0 && this.state.assessmentSolutionDate.length > 0;
    if (this.props.loading) {
      return (
        <div className="admin-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>Units</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder="Search study material" />
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content5">



          <div className="popup-header">
            Add new unit

          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <input placeholder="Unit Label" onChange={this.labelChange} />
              <input type="text" placeholder="Unit Order" onInput={this.orderChange}
                pattern="[0-9]*" value={this.state.order} />
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.newUnit} disabled={!isEnabled}>Add new</button>
          </div>
        </Popup>
        <Popup open={this.state.openx} closeOnDocumentClick onClose={this.closex}
          className="popup-content5">
          <div className="popup-header">
            Edit unit
          </div>
          <span className="close" onClick={this.closex}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <input placeholder="Unit Label" onChange={this.labelChange}
                value={this.state.label} />
              <input placeholder="Unit Order" onInput={this.orderChange}
                value={this.state.order} type="text" pattern="[0-9]*" />
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.updateUnit} disabled={!isEnabled}>Save</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content5">
          <div className="popup-header">
            Add new chapter
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <input placeholder="Chapter Label" onChange={this.labelChange2} />
              <input placeholder="Chapter Order" onChange={this.orderChange2}
                type="text" pattern="[0-9]*" value={this.state.order2} />
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.newChapter} disabled={!isEnabled2}>Add new</button>
          </div>
        </Popup>
        <Popup open={this.state.openx2} closeOnDocumentClick onClose={this.closex2}
          className="popup-content5">
          <div className="popup-header">
            Edit chapter
          </div>
          <span className="close" onClick={this.closex2}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <input placeholder="Chapter Label" onChange={this.labelChange2}
                value={this.state.label2} />
              <input type="text" placeholder="Chapter Order" onInput={this.orderChange2}
                value={this.state.order2} pattern="[0-9]*" />
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.updateChapter} disabled={!isEnabled2}>Save</button>
          </div>
        </Popup>
        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-content2">
          <div className="choose-content">
            <div className="type-content" onClick={() => this.show4(0)}>
              <img src={Notes} alt="notes" />
              Add Notes
            </div>
            <hr />
            <div className="type-content" onClick={() => this.show4(1)}>
              <img src={Video} alt="video" />
              Add Videos
            </div>
            <hr />
            <Link className="type-content" to={{
              pathname: '/admin/new-assessment',
              state: {
                type: 1, toc_id: this.state.toc_id, class_id: this.state.classs,
                subject_id: this.state.subject, breads: this.props.location?.state,
                toc_url: this.props.location?.pathname, search: this.props.location?.search
              }
            }}>
              <img src={Assessment} alt="assessment" />
              Add Assessment
            </Link>
            <hr />
            <Link className="type-content" to={{
              pathname: '/admin/new-assignment',
              state: {
                type: 1, toc_id: this.state.toc_id, class_id: this.state.classs,
                subject_id: this.state.subject, breads: this.props.location?.state,
                toc_url: this.props.location?.pathname, search: this.props.location?.search, classname: this.state.class_label, subjectname: this.state.subject_label, fromStudymaterial: true
              }
            }}>
              <img src={Assignment} alt="assessment" />
              Add Assignment
            </Link>
          </div>
        </Popup>
        <Popup open={this.state.open4} closeOnDocumentClick onClose={this.close4}
          className="popup-content">
          <div className="popup-header">
            {this.state.type === 0 ? "Add content: Upload File"
              : "Add content: Upload video file"}
          </div>
          <span className="close" onClick={this.close4}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input id="sp-popup-inputs" placeholder="Title" onChange={this.titleChange} />
            </div>
            <div className="popup-inputs">
              <input type="text" placeholder="Duration (mins)" value={this.state.duration_mins}
                onInput={this.durationChange} pattern="[0-9]*" />
              <select onChange={this.changeType}>
                <option value={null} disabled>
                  Select type
                </option>
                {this.state.type === 0 ? <option value={0}>
                  Notes
                </option> : <option value={1}>
                  Video
                </option>}
              </select>
            </div>
            <div className='fileDownloadableAction'><label>File Downloadable<input onInput={this.fileDownloadableChange} type="checkbox" /></label></div>
            <div className="popup-admin-child">
              {this.state.image === '' || this.state.image === null
                ? <div className="img-prev" onClick={() => this.fileInput.click()}>
                  <img src={ImgHolder} alt="Prev" />
                </div> : <div className="img-prev">
                  <span onClick={this.removeImage}>&#x2715;</span>
                  <img src={this.state.imageURL} alt="Prev" />
                </div>}

              <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                onChange={this.handleChange2} ref={fileInput => this.fileInput = fileInput} />

            </div>

            <div className="uploadChoose">
              <label><input type="radio" onChange={this.uploadInputChange} checked={this.state.fileURlInputShow} name="uploadOption" />Upload {this.state.type === 0 ? "" : "Video"} File</label>
              {this.state.type === 1 &&
                <label><input type="radio" onChange={this.uploadInputChangeVideoUrl} checked={this.state.videoURLInputShow} value="1" name="uploadOption" />Enter URL</label>
              }
              {this.state.type === 0 &&
                <label><input type="radio" onChange={this.uploadInputChangeNotesUrl} checked={this.state.notesURLInputShow} value="1" name="uploadOption" />Enter URL</label>
              }
            </div>
            {this.state.fileURlInputShow &&
              <div className="popup-inputs direction">
                <input id="doc-input" type="file" accept={this.state.type === 0 ? "" : "video/*"}
                  onChange={this.handleChange} />
                <div className='uploadNoteText'>Supported file format {this.props.learnerprofile?.institute_settings?.allowed_file_extensions_for_notes}</div>
              </div>

            }
            {this.state.videoURLInputShow && this.state.type === 1 ? <div className="popup-inputs">
              <input type="text" placeholder="Video Url" className="video_url"
                onInput={this.VideoUrlAdd} />
            </div> : ""}

            {this.state.notesURLInputShow && this.state.type === 0 ? <div className="popup-inputs">
              <input type="text" placeholder="Notes Url" className="notes_url"
                onInput={this.NotesUrlAdd} />
            </div> : ""}
          </div>
          {/* {this.state.notesUrl} */}
          <div className="popup-actions">
            <button onClick={this.addContent} disabled={!isEnabled3}>Add new</button>
          </div>
        </Popup>
        <Popup open={this.state.openx3} closeOnDocumentClick onClose={this.closex3}
          className="popup-content">
          <div className="popup-header">
            Edit content
          </div>
          <span className="close" onClick={this.closex3}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input id="sp-popup-inputs" placeholder="Title" onChange={this.titleChange}
                value={this.state.title} />
            </div>
            <div className="popup-inputs">
              <input type="text" placeholder="Duration (mins)" value={this.state.duration_mins}
                onInput={this.durationChange} pattern="[0-9]*" />
              <select onChange={this.changeType}>
                <option value={null} disabled>
                  Select type
                </option>
                {this.state.type === 0 ? <option value={0}>
                  Notes
                </option> : <option value={1}>
                  Video
                </option>}
              </select>
            </div>
            <div className='fileDownloadableAction'><label>File Downloadable<input onInput={this.fileDownloadableChange} defaultChecked={this.state.fileDownloadable} type="checkbox" /></label></div>
            <div className="popup-inputs" id="popup-inputs-y">
              <div className="popup-admin-child">
                {this.state.image === '' || this.state.image === null
                  ? <div className="img-prev" onClick={() => this.fileInput.click()}>
                    <img src={ImgHolder} alt="Prev" />
                  </div> : <div className="img-prev">
                    <span onClick={this.removeImage}>&#x2715;</span>
                    <img src={common.addFileUrl(this.state.imageURL)} alt="Prev" />
                  </div>}
                <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange2} ref={fileInput => this.fileInput = fileInput} />
              </div>




              {/* {this.state.upload_new && 
                <div>
                  <input id="doc-input" type="file" accept={this.state.type === 0 ? "application/pdf" : "video/*"} 
                  onChange={this.handleChange} placeholder="Upload another file"/>
                </div>
                }
                {(!this.state.notesUrl || !this.state.videoUrl) &&
                <button onClick={this.uploadNew}>
                    {this.state.type === 0 ? "Upload new PDF" : "Upload new video"}
                  </button>
                } */}

            </div>
            {/* {this.state.videoUrl && this.state.type === 1 ? <div className="popup-inputs">
              <input type="text" placeholder="Video Url" value={this.state.videoUrl} className="video_url"
                onInput={this.VideoUrlAdd}/>
            </div> : "" } */}

            {/* {this.state.notesUrl && this.state.type === 0 ? <div className="popup-inputs">
              <input type="text" placeholder="Notes Url" value={this.state.notesUrl} className="notes_url"
                onInput={this.NotesUrlAdd}/>
            </div> : "" } */}
          </div>
          <div className="uploadChoose">
            <label><input type="radio" onChange={this.uploadInputChangeEdit} defaultChecked={this.state.fileURlInputShowEdit} id='uploadInputChangeEdit' name="uploadOption" />Upload {this.state.type === 0 ? "" : "video"} File</label>
            {this.state.type === 1 &&
              <label><input type="radio" onChange={this.uploadInputChangeVideoUrlEdit} defaultChecked={this.state.videoURLInputShowEdit} name="uploadOption" />Enter URL</label>
            }
            {this.state.type === 0 &&
              <label><input type="radio" onChange={this.uploadInputChangeNotesUrlEdit} defaultChecked={this.state.notesURLInputShowEdit} name="uploadOption" />Enter URL</label>
            }
          </div>
          {this.state.fileURlInputShowEdit &&
            <div className="popup-inputs uploadLabel direction">
              {this.state.fileURlInputShowEdit}
              {(!this.state.notesUrl || !this.state.videoUrl) &&
                <label>
                  {this.state.type === 0 ? "Upload new file" : "Upload new video"}
                </label>
              }

              <input id="doc-input" type="file" accept={this.state.type === 0 ? "" : "video/*"}
                onChange={this.handleChange} />
              <div className='uploadNoteText'>Supported file format {this.props.learnerprofile?.institute_settings?.allowed_file_extensions_for_notes}</div>
            </div>
          }
          {this.state.videoURLInputShowEdit && this.state.type === 1 ? <div className="popup-inputs">
            <input type="text" placeholder="Video Url" value={this.state.videoUrl} className="video_url"
              onInput={this.VideoUrlAdd} />
          </div> : ""}

          {this.state.notesURLInputShowEdit && this.state.type === 0 ? <div className="popup-inputs">
            <input type="text" placeholder="Notes Url" value={this.state.notesUrl} className="notes_url"
              onInput={this.NotesUrlAdd} />
          </div> : ""}
          <div className="popup-actions" onClick={this.updateContent}>
            <button disabled={!isEnabled4}>Save</button>
          </div>
        </Popup>
        <Popup open={this.state.openp} closeOnDocumentClick onClose={this.closep}
          className="popup-content3">
          <div className="delete-up">
            <img src={Stamp} alt="publish" />
            Are you sure you want to
            <br />{this.state.is_published ? "publish" : "unpublish"} this content?
          </div>
          <div className="delete-down">
            <button onClick={this.closep}>
              Cancel
            </button>
            <button onClick={this.publishContent}>
              {this.state.is_published ? "Publish" : "Unpublish"}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.openSchedule} closeOnDocumentClick onClose={this.closeSchedule}
          className="popup-content4-schedule">
          <div className="popup-header">
            Schedule: {this.state.title}
          </div>
          <span className="close" onClick={this.closeSchedule}>&#x2715;</span>
          <div className="popup-data4-schedule">
            <div className="popup-inputs5">
              <span>Scheduled Date</span>
              <input type="datetime-local" defaultValue={this.state.scheduled_date} placeholder="Scheduled Date & Time"
                onChange={this.scheduleChange} />
            </div>
            <div className="popup-inputs5">
              <span>Due Date</span>
              <input type="datetime-local" defaultValue={this.state.due_date} placeholder="Due Date & Time"
                onChange={this.dueChange} />
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabledS} onClick={this.createDPP}>Schedule</button>
          </div>
        </Popup>

        <Popup open={this.state.openScheduleDialog} closeOnDocumentClick onClose={this.closeScheduleDialog}
          className="popup-content4-schedule">
          <div className="popup-header">
            Schedule: {this.state.assessmentTitle}
          </div>
          <span className="close" onClick={this.closeScheduleDialog}>&#x2715;</span>
          <div className="popup-data4-schedule">
            <div className="popup-inputs5">
              <span>Scheduled Date</span>
              <input type="datetime-local" defaultValue={this.state.assessmentScheduledDate} placeholder="Scheduled Date & Time"
                onChange={this.assessmentScheduleChange} />
            </div>
            <div className="popup-inputs5">
              <span>Due Date</span>
              <input type="datetime-local" defaultValue={this.state.assessmentScheduledDueDate} placeholder="Due Date & Time"
                onChange={this.assessmentDueChange} />
            </div>
          </div>
          <div className="popup-actions">
            {this.state.assessmentScheduledDate != '' && this.state.assessmentPlanner_id == '' && <button disabled={!isEnabledAssessment} onClick={this.assessmentCreateDPP}>Schedule</button>}
            {this.state.assessmentScheduledDate != '' && this.state.assessmentPlanner_id !== '' && <button disabled={!isEnabledAssessment} onClick={this.assessmentEditDPP}>Re Schedule</button>}
          </div>
        </Popup>

        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete" />
            Are you sure you want to<br />delete this {this.state.to_delete}?
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              Cancel
            </button>
            <button onClick={this.deleteUnit}>
              Delete
            </button>
          </div>
        </Popup>
        <Popup open={this.state.opend2} closeOnDocumentClick onClose={this.closed2}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete" />
            Are you sure you want to<br />delete this content?
          </div>
          <div className="delete-down">
            <button onClick={this.closed2}>
              Cancel
            </button>
            <button onClick={this.deleteContent}>
              Delete
            </button>
          </div>
        </Popup>
        <div className="main-part" id="unit-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <Link id="trace-back" to={`/admin/study-material`}>
                  <img src={Back} alt="back" />
                </Link>
                <h2>{this.state.class_label} | {this.state.subject_label} | Units</h2>
              </div>
            </div>
            <div className="heading-part2">
              {/* <button onClick={this.}></button> */}
              <span onClick={this.handleClearCache}>
                <img src={Refresh} alt="Refresh" color='white' />Clear Cache
              </span>

              <span onClick={this.show}>
                <img src={Add} alt="img" />Add New Unit
              </span>
            </div>

          </div>
          {this.state.unit_data.length === 0 ? <Empty /> : null}
          {this.state.viewContent ? <div className="content_view">
          </div> : <>
            {this.state.unit_data.map(unit =>
              <div className="unit-data unitwrapper" key={unit.id}>
                <div className="unit-head-data">
                  <div className="unit-head-label">
                    {/* <img src={Right} id="unit-pointer" alt="units"/> */} {unit.label}
                  </div>
                  <div>
                    <span onClick={() => this.showx(unit.id, unit)} Style={unit.created_by != this.state.user_id ? "display:none" : "display: flex !important;"}>
                      <img src={Edit} alt="edit" />
                    </span>
                    <span onClick={() => this.showd(unit, 'unit')} Style={unit.created_by != this.state.user_id ? "display:none" : "display: flex !important;"}>
                      <img src={Delete} alt="delete" />
                    </span>
                    <button onClick={() => this.show3(1, unit.id)}>+ Add Content</button>
                    <button onClick={() => this.show2(unit.id)}>Add Chapter</button>
                  </div>
                </div>
                <div className="subunit-data3">
                  {unit.content.length === 0
                    ? <button onClick={() => this.show3(1, unit.id)}>Add content</button>
                    : <Carousel containerClass="subunit-data-layer" responsive={responsive}
                      keyBoardControl={false} swipeable={false} draggable={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}>

                      {unit.content.map((content, index) =>
                        <div className="content-item" key={content.content_id} id="content-item2" Style={unit.id === this.state.currentTocId && unit.content.length - 1 === index ? "border: 2px solid #05ecc2" : ""}>
                          <span id="section-button6" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}
                            onClick={() => this.export(content, content.content_subtype)}>
                            Export
                          </span>



                          {content.content_subtype === 0 && content.planner_id && content.is_published && <span id="section-button4" onClick={() => this.showSchedule(content, content.content_subtype, unit.id)}>
                            Re-Schedule
                          </span>}
                          {content.content_type == 2 && content.content_subtype == 1 && content.scheduled_date !== "" && content.is_published && <span id="section-button4" onClick={() => this.showScheduleDialog(content, content.content_subtype, unit.id)}>
                            Re-Schedule
                          </span>}
                          {content.content_type == 3 && content.content_subtype === 0 && content.scheduled_date !== "" && content.planner_id && content.is_published && <span id="section-button4" onClick={() => this.showSchedule(content, content.content_subtype, unit.id)}>
                            Re-Schedule
                          </span>}

                          {/* {content.content_subtype === 0 && content.planner_id && content.is_published && <span id="section-button4" onClick={() => this.showSchedule(content, content.content_subtype,unit.id)}>
                              Re-Schedule
                          </span> } */}
                          {content.is_published ? <span id="section-button3" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}
                            onClick={() => this.showp(content, content.content_subtype)}>
                            Unpublish
                          </span> : <div className="grid-actions">
                            {content.content_subtype === 1 ? <Link Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"} title="Edit"
                              to={{
                                pathname: `/admin/assessment-settings/${content.assessment_id}`,
                                state: {
                                  toc_id: unit.id,
                                  data: {
                                    toc_url: this.props.location?.pathname,
                                    search: this.props.location?.search,
                                    breads: {
                                      classs: this.state.class_label,
                                      subject: this.state.subject_label
                                    }
                                  }
                                }
                              }
                              }>
                              <img src={Edit} alt="edit" />
                              {/* dddddddddddd{this.state.is_admin} */}
                            </Link> : content.content_subtype === 0 ? <Link title="Edit" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}
                              to={{
                                pathname: `/admin/new-assignment/${content.assignment_id}`,
                                state: {
                                  toc_id: unit.id,
                                  url: this.props.location.pathname,
                                  classname: this.state.class_label,
                                  subjectname: this.state.subject_label,
                                  class_id: this.state.classs,
                                  subject_id: content.subject_id
                                }
                              }
                              }>
                              <img src={Edit} alt="edit" />

                            </Link> :
                              <span Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"} onClick={() => this.showx3(unit.id, content)}>
                                <img src={Edit} alt="edit" />
                                {/* ppppp{this.state.is_admin} */}
                              </span>}
                            {/*content.content_subtype === 0 && <span onClick={() => this.showSchedule(content, content.content_subtype,unit.id)} Style={content.created_by != this.state.user_id ? "display:none":""}>
                              <img src={Listing} alt="list"/>
                            </span>*/}
                            <span Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"} onClick={() => this.showd2(content, content.content_subtype)}>
                              <img src={Delete} alt="delete" />
                            </span>
                          </div>}
                          <div className="grid-up">
                            <Link className="title-grid3" id="content-grid" to={{
                              pathname: `${content.content_subtype === 1 ? `/admin/assessment-preview/${content.assessment_id}`
                                : content.content_subtype === 0 ? `/admin/assignment-preview/${content.assignment_id}` : `/admin/content-view/${content.content_id}`}`,
                              state: {
                                modal: false,
                                class: this.state.class_label,
                                subject: this.state.subject_label,
                                classname: this.state.class_label,
                                subjectname: this.state.subject_label,
                                unit: unit.label,
                                title: unit.label,
                                content: unit.content,
                                back_path: this.props.location.pathname,
                                back_search: this.props.location.search
                              }
                            }}>
                              {content.content_subtype === 4 ? <img src={Notes} alt="notes" />
                                : content.content_subtype === 5 ? <img src={Video} alt="notes" />
                                  : content.content_subtype === 1 ? <img src={Assessment} alt="test" /> : <img src={Assignment} alt="test" />}
                              <div>
                                {content.title.length <= MAX_LENGTH ? content.title : content.title.substring(0, MAX_LENGTH) + "..."}
                                {content.is_published ? <div className="published" ><span>Published</span></div>
                                  : <div className="draft" ><span>Draft</span></div>}
                              </div>
                            </Link>

                          </div>
                          {content?.assessment_id !== '' &&
                            <Link className='increaseAttempt' id={unit.id} to={{
                              pathname: `/admin/assessment-increase-attempt/`,
                              state: { classID: this.state.classs, assessmentID: content.assessment_id, fromDPP: true }
                            }}>
                              Increase Attempt
                            </Link>
                          }
                          {content?.assignment_id != '' &&
                            <Link className='increaseAttempt' id={unit.id} to={{
                              pathname: `/admin/assignment-increase-attempt/`,
                              state: { classID: this.state.classs, assessmentID: content.assignment_id }
                            }}>
                              Increase Attempt
                            </Link>
                          }
                          <div className="grid-up actions dddddddddd" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}>


                            {(content.content_type == 1 && content.content_subtype == 4 && !content.is_published) && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                            {(content.content_type == 1 && content.content_subtype == 5 && !content.is_published) && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                            {(content.content_type == 2 && content.content_subtype == 1 && !content.is_published && content.scheduled_date !== "") && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                            {(content.content_type == 2 && content.content_subtype == 1 && !content.is_published && content.scheduled_date == "") && <button onClick={() => this.showScheduleDialog(content, content.content_subtype, unit.id)}>Schedule</button>}
                            {(content.content_type == 3 && content.content_subtype == 0 && !content.is_published && content.scheduled_date !== "") && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                            {(content.content_type == 3 && content.content_subtype == 0 && !content.is_published && content.scheduled_date == "") && <button onClick={() => this.showSchedule(content, content.content_subtype, unit.id)}>Schedule</button>}




                            {/* {(content.content_subtype !== 0 && !content.is_published) || (content.planner_id && content.content_subtype === 0 && !content.is_published)? <button
                                onClick={() => this.showp(content, content.content_subtype)}>
                                Publish
                            </button> : <>{!content.is_published && content.content_subtype === 0 && <button onClick={() => this.showSchedule(content, content.content_subtype,unit.id)}>
                            Schedule
                          </button>}</>} */}
                          </div>
                        </div>
                      )}
                    </Carousel>}
                </div>

                {unit.chapters.map(chapter =>

                  <div className="single-unit" style={this.state.expand[chapter.id] ? { maxHeight: '350px' }
                    : { maxHeight: '72px' }} key={chapter.id}>

                    <div className="unit-handler">
                      <div className="unit-handler-left" id="sec-expand">
                        <span onClick={() => this.onExpand(chapter.id)}>

                          {this.state.expand[chapter.id] ? <img src={Collapse} alt="coll" />
                            : <img src={Expand} alt="exp" />}

                        </span>
                        <Link to={{
                          pathname: `/admin/study-material/units/chapters`,
                          search: `?class=${this.state.classs}&subject=${this.state.subject}&chapter=${chapter.id}`,
                          state: {
                            classs: this.state.class_label, subject: this.state.subject_label, unit: unit.label,
                            chapter: chapter.label, unit_id: unit.id
                          }
                        }}>
                          {this.state.expand[chapter.id]}
                          {chapter.label} <img src={Right} id="right-black" alt="GoTo" />
                        </Link>
                      </div>
                      <div className="unit-handler-right">
                        <span onClick={() => this.showx2(chapter.id, chapter)} Style={chapter.created_by != this.state.user_id ? "display:none" : "display: flex !important"}>
                          <img src={Edit} alt="edit" />
                        </span>
                        <span onClick={() => this.showd(chapter, 'chapter')} Style={chapter.created_by != this.state.user_id ? "display:none" : "display: flex !important"}>
                          <img src={Delete} alt="delete" />
                        </span>
                        <button onClick={() => this.show3(2, chapter.id)}>
                          + Add Content
                        </button>
                      </div>
                    </div>
                    <div className="subunit-data">
                      {chapter.content.length === 0
                        ? <button onClick={() => this.show3(2, chapter.id)}>Add content</button>
                        : <Carousel containerClass="subunit-data-layer" responsive={responsive}
                          keyBoardControl={false} swipeable={false} draggable={false}
                          removeArrowOnDeviceType={["tablet", "mobile"]}>
                          {chapter.content.map((content, index) =>
                            <div className="content-item" key={content.content_id} Style={chapter.id === this.state.currentTocId && chapter.content.length - 1 === index ? "border: 2px solid #05ecc2" : ""}>
                              <div id="section-button6">
                                <span Style={content.created_by != this.state.user_id ? "display:none,margin-right:10px" : "margin-right:10px"}
                                  onClick={() => this.export(content, content.content_subtype)}>
                                  Export
                                </span>
                                <span
                                  onClick={() => this.copyUrl(`/learner/classroom/${chapter.subject_id}/${content.content_id}/${chapter.id}/${content.content_subtype}`, content.content_id)}>
                                  {this.state.copy[content.content_id] ? "Copied!!" : "Copy Url"}
                                </span>
                              </div>

                              {content?.assessment_id !== '' &&
                                <Link className='increaseAttempt' id={unit.id} to={{
                                  pathname: `/admin/assessment-increase-attempt/`,
                                  state: { classID: this.state.classs, assessmentID: content.assessment_id, fromDPP: true }
                                }}>
                                  Increase Attempt
                                </Link>
                              }
                              {content?.assignment_id != '' &&
                                <Link className='increaseAttempt' id={unit.id} to={{
                                  pathname: `/admin/assignment-increase-attempt/`,
                                  state: { classID: this.state.classs, assessmentID: content.assignment_id }
                                }}>
                                  Increase Attempt
                                </Link>
                              }


                              {content.content_subtype === 0 && content.planner_id && content.is_published && <span id="section-button4" onClick={() => this.showSchedule(content, content.content_subtype, chapter.id)}>
                                Re-Schedule
                              </span>}
                              {content.content_type == 2 && content.content_subtype == 1 && content.scheduled_date !== "" && content.is_published && <span id="section-button4" onClick={() => this.showScheduleDialog(content, content.content_subtype, chapter.id)}>
                                Re-Schedule
                              </span>}
                              {content.content_type == 3 && content.content_subtype === 0 && content.scheduled_date !== "" && content.planner_id && content.is_published && <span id="section-button4" onClick={() => this.showSchedule(content, content.content_subtype, chapter.id)}>
                                Re-Schedule
                              </span>}
                              {content.is_published ? <span id="section-button3" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}
                                onClick={() => this.showp(content, content.content_subtype)}>
                                Unpublish
                              </span> : <div className="grid-actions">
                                {content.content_subtype === 1 ? <Link Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"} title="Editkkkkkkkkkk"
                                  to={{
                                    pathname: `/admin/assessment-settings/${content.assessment_id}`,
                                    state: {
                                      toc_id: chapter.id,
                                      data: {
                                        toc_url: this.props.location?.pathname,
                                        search: this.props.location?.search,
                                        breads: {
                                          classs: this.state.class_label,
                                          subject: this.state.subject_label
                                        }
                                      }
                                    }
                                  }
                                  }>
                                  <img src={Edit} alt="edit" />
                                </Link> : content.content_subtype === 0 ? <Link Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"} title="Editbbbbbbbbbbb"
                                  to={{
                                    pathname: `/admin/new-assignment/${content.assignment_id}`,
                                    state: {
                                      toc_id: chapter.id,
                                      url: this.props.location.pathname,
                                      classname: this.state.class_label,
                                      subjectname: this.state.subject_label,
                                      class_id: this.state.classs,
                                      subject_id: content.subject_id
                                    }
                                  }
                                  }>
                                  <img src={Edit} alt="edit" />
                                </Link> : <span onClick={() => this.showx3(chapter.id, content)} Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}>
                                  <img src={Edit} alt="edit" />
                                </span>}
                                {/*content.content_subtype === 0 && <span onClick={() => this.showSchedule(content, content.content_subtype,chapter.id)}>
                                  <img src={Listing} alt="list"/>
                                </span>*/}
                                <span onClick={() => this.showd2(content, content.content_subtype)} Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}>
                                  <img src={Delete} alt="delete" />
                                </span>
                              </div>}
                              <div className="grid-up">
                                <Link className="title-grid3" id="content-grid" to={{
                                  pathname: `${content.content_subtype === 1 ? `/admin/assessment-preview/${content.assessment_id}`
                                    : content.content_subtype === 0 ? `/admin/assignment-preview/${content.assignment_id}` : `/admin/content-view/${content.content_id}`}`,
                                  state: {
                                    modal: false,
                                    class: this.state.class_label,
                                    subject: this.state.subject_label,
                                    classname: this.state.class_label,
                                    subjectname: this.state.subject_label,
                                    unit: unit.label,
                                    chapter: chapter.label,
                                    title: chapter.label,
                                    content: chapter.content,
                                    back_path: this.props.location.pathname,
                                    back_search: this.props.location.search
                                  }
                                }}>
                                  {content.content_subtype === 4 ? <img src={Notes} alt="notes" />
                                    : content.content_subtype === 5 ? <img src={Video} alt="notes" />
                                      : content.content_subtype === 1 ? <img src={Assessment} alt="test" /> : <img src={Assignment} alt="test" />}
                                  <div>
                                    {content.title.length <= MAX_LENGTH ? content.title : content.title.substring(0, MAX_LENGTH) + "..."}
                                    {content.is_published ? <div className="published" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}><span>Published</span></div>
                                      : <div className="draft" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}><span>Draft</span></div>}
                                  </div>
                                </Link>

                              </div>
                              {<div className="grid-up actions" Style={(content.created_by == this.state.user_id || this.state.is_admin) ? "" : "display:none"}>

                                {(content.content_type == 1 && content.content_subtype == 4 && !content.is_published) && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                                {(content.content_type == 1 && content.content_subtype == 5 && !content.is_published) && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}

                                {(content.content_type == 2 && content.content_subtype == 1 && !content.is_published && content.scheduled_date !== "") && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                                {(content.content_type == 2 && content.content_subtype == 1 && !content.is_published && content.scheduled_date == "") && <button onClick={() => this.showScheduleDialog(content, content.content_subtype, unit.id)}>Schedule</button>}

                                {(content.content_type == 3 && content.content_subtype == 0 && !content.is_published && content.scheduled_date !== "") && <button onClick={() => this.showp(content, content.content_subtype)}>Publish</button>}
                                {(content.content_type == 3 && content.content_subtype == 0 && !content.is_published && content.scheduled_date == "") && <button onClick={() => this.showSchedule(content, content.content_subtype, unit.id)}>Schedule</button>}


                              </div>}

                            </div>
                          )}
                        </Carousel>}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    learnerprofile: state.commonReducer.logindetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getUnitTOC, addUnit, exportNotes, addContent, updateUnit, deleteUnit, deleteContent, getClearCache,
    updateContent, assignmentPlanner, updateAssignmentPlanner, publishContent, deleteAssignment, publishAssessment, deleteAssessment, publishAssignment, assessmentCreateDPP, assessmentEditDPP } = actions;
  return {
    getUnitTOC: (subject_id) => dispatch(getUnitTOC(subject_id)),
    addUnit: (label, order, level, level_name, parent, subject) => dispatch(addUnit(label, order, level, level_name, parent, subject)),
    addContent: (level, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail, videoUrl, downloadable) => dispatch(addContent(level, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail, videoUrl, downloadable)),
    updateContent: (id, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail, videoUrl, downloadable) => dispatch(updateContent(id, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail, videoUrl, downloadable)),
    updateUnit: (toc_id, label, order) => dispatch(updateUnit(toc_id, label, order)),
    publishContent: (toc_id, is_published) => dispatch(publishContent(toc_id, is_published)),
    deleteUnit: (toc_id) => dispatch(deleteUnit(toc_id)),
    deleteContent: (id) => dispatch(deleteContent(id)),
    deleteAssignment: (id) => dispatch(deleteAssignment(id)),
    assignmentPlanner: (assignment, subject, title, release_date, scheduled_date, solution_date, result_date, due_date, duration_mins, classs, session, phase, batch, is_released, faculty) => dispatch(assignmentPlanner(assignment, subject, title, release_date, scheduled_date, solution_date, result_date, due_date, duration_mins, classs, session, phase, batch, is_released, faculty)),
    updateAssignmentPlanner: (id, assignment, subject, title, release_date, scheduled_date, solution_date, result_date, due_date, duration_mins, classs, session, phase, batch, is_released, faculty) => dispatch(updateAssignmentPlanner(id, assignment, subject, title, release_date, scheduled_date, solution_date, result_date, due_date, duration_mins, classs, session, phase, batch, is_released, faculty)),
    publishAssessment: (id, is_published) => dispatch(publishAssessment(id, is_published)),
    publishAssignment: (id, is_published) => dispatch(publishAssignment(id, is_published)),
    deleteAssessment: (id) => dispatch(deleteAssessment(id)),
    exportNotes: (content_id) => dispatch(exportNotes(content_id)),
    getClearCache: (subject_id, skip_cache) => dispatch(getClearCache(subject_id, skip_cache)),
    assessmentCreateDPP: (assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date) => dispatch(assessmentCreateDPP(assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date)),
    assessmentEditDPP: (assessment, scheduled_date, solution_date, result_date, due_date, release_date) => dispatch(assessmentEditDPP(assessment, scheduled_date, solution_date, result_date, due_date, release_date))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonUnits);