import React, { useState, useEffect, useRef, } from 'react';
import 'react-quill/dist/quill.snow.css';
import ImgHolder from '../../public/icons/doubt-image.svg';
import ReactNotifications, { store } from 'react-notifications-component';

import './BlogPost.css';

export const StudentQueryViewReply = (props) => {
    const { previewContent, setPreviewContent, formatDate, postDoubt, getFacultyDoubtResponses } = props

    const [selectedImage, setSelectedImage] = useState(null)
    const [userAnswer, setUserAnswer] = useState()
    const [replyList, setReplyList] = useState([])
    const [fileSizeError, setFileSizeError] = useState(false)


    const fileInputRef = useRef(null);

    const getResponse = async () => {
        const response = await getFacultyDoubtResponses(previewContent?.id)
        setReplyList(response?.data?.forums)
    }

    useEffect(() => {
        getResponse()
    }, [previewContent])

    const handleImageUpload = (event) => {


        if (event.target.files[0]?.type.split('/')[0] === 'image') {
            console.log("filesize", event.target.files[0].size);
            if (event.target.files[0]?.size <= 2 * 1024 * 1024) {
                console.log("filesize ok",);
                setFileSizeError(false)
                setSelectedImage(event.target.files[0])
            } else {
                console.log("filesize excide",);
                setFileSizeError(true)
            }
        }
        else {
            setSelectedImage(null)

            store.addNotification({
                title: 'Error',
                message: `Only images can be uploaded here.`,
                type: 'danger',
                container: 'top-right',
                dismiss: {
                    duration: 3000
                }
            })
        }




    }

    const handleAnswerChange = (e) => {
        setUserAnswer(e.target.value)
    }
    const handleSubmitResponse = async () => {



        const response = await postDoubt(80, userAnswer, selectedImage, previewContent?.id);
        if (response !== undefined) {
            getResponse()
        }


    }

    return (
        <div className={`previewStudentQueryDialog ${previewContent ? 'open' : ''}`}>
            <div className='previewStudentQueryContainer'>
                <h3>Student Query <span onClick={() => setPreviewContent(null)} className='fa fa-times'></span></h3>
                <div className='block'>
                    <label>Date  <span>{formatDate(previewContent?.added_on)}</span></label>
                    <label>Name  <span>{previewContent?.user?.name}</span></label>
                    {/* <label>Class  <span>{previewContent?.added_on}</span></label> */}
                    <label>Subject  <span>{previewContent?.subject}</span></label>
                </div>
                <div className='block'>
                    <h3>Description</h3>
                    <div className='block1'>
                        {/* <div className='imagePreview'>img here</div> */}
                        <div className='description' dangerouslySetInnerHTML={{__html: previewContent?.description}}></div>
                    </div>
                </div>
                <div style={{display: "none"}} className='block'>
                    <h3>Answer by Faculty</h3>
                    {/* <div className='block1'>
                        <div className='description'>rajender.nanda@gmail.comrajender.nanda@gmail.comrajender.nanda@gmail.comrajender.nanda@gmail.comrajender.nanda@gmail.com</div>
                    </div> */}
                    <div className='reply-list'>
                        {replyList && replyList.map((reply) => (
                            <div className='replyblock' key={reply.id}>
                                <div className='reply' style={{ display: "flex" }}>
                     
                                    <div className="post-doubt-img">

                                       { reply?.media === null ?  <img src={ImgHolder} alt="img" /> :   <img src={reply?.media} />}

                                    </div>
                                    <p>{reply?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='block1'>
                        <div className="doubt-content-div" style={{ "marginTop": '0px' }}>
                            <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input doubt_img"
                                onChange={handleImageUpload} ref={fileInputRef}
                            />
                            <div className="image-upload designChanges" style={{ "width": '350px' }}>
                                <span>Add an image</span>
                                {selectedImage === null
                                    ? <img src={ImgHolder} alt="img" onClick={() => fileInputRef.current.click()} />
                                    : <div className="post-doubt-img"
                                        style={{ backgroundImage: "url(" + URL.createObjectURL(selectedImage) + ")" }}>
                                        <span className="close" onClick={() => {
                                            setSelectedImage(null)
                                        }}>&#x2715;</span>
                                    </div>
                                }
                                <div className='noteText'>Please upload less than 2 MB</div>
                                {fileSizeError && <div className='noteErrorMessage'>Please check file size</div>}


                            </div>
                            <div className='block'>
                                <textarea value={userAnswer} maxLength="500" placeholder="Start your answer..."
                                    onChange={handleAnswerChange}
                                />
                                <div className='noteText'>The max length of the field in 500 characters</div>
                            </div>
                        </div>

                    </div>
                    <div className='actions'><button onClick={handleSubmitResponse} type='submit'>Submit</button></div>
                </div>

            </div>

        </div>
    )
}
