import React, { useEffect, useState, useRef } from 'react';
import Pagination from "react-js-pagination";
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import reportsLoader from '../../public/icons/loader.png';
import Trash from '../../public/icons/trash.svg';
import ReactNotifications, { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import ImgHolder from '../../public/icons/doubt-image.svg';
import { Link } from 'react-router-dom';

import './BlogPost.css';
import { CreateBlog } from './CreateBlog';
import { StudentQueryViewReply } from './StudentQueryViewReply';


const StudentQueries = (props) => {
    const { getFacultyDoubtList, postDoubt, getBlogPost, deleteForum, getFacultyDoubtResponses } = props
    const [blogs, setBlogs] = useState([])
    const [filteredBlogs, setFiteredBlogs] = useState([])

    const [loaderShow, setloaderShow] = useState(true);
    const [selectedClassId, setSelectedClassId] = useState(null)
    const [selectedSubjectId, setSelectedSubjectId] = useState(null)
    const [selectedCourseId, setSelectedCourseId] = useState(null)
    const [subjectList, setSubjectList] = useState(JSON.parse(localStorage.getItem('logindetails'))?.subjects)

    // const [courseList, setCourseList] = [...new Set(subjectList.map(subject => subject.class_name))]

    const studentQueryType = 2
    const courseList = [];
    const classMap = new Map();

    subjectList.forEach(subject => {
        if (!classMap.has(subject.class_name)) {
            classMap.set(subject.class_name, subject.class_id);
            courseList.push({ class_name: subject.class_name, class_id: subject.class_id });
        }
    });


    const [paginationDetail, setPaginationDetail] = useState({
        "current_page": 1,
        "total_page": 0,
        "count": 0,
        "page_size": 0
    })
    const [previewContent, setPreviewContent] = useState(null)
    const userId = JSON.parse(localStorage.getItem('logindetails'))?.id

    const [isNew, setisNew] = useState(true)
    const blogPostTypeId = 4

    const [studentQueries, setStudentQueries] = useState([])

    const [selectedImage, setSelectedImage] = useState(null)
    const [userAnswer, setUserAnswer] = useState()


    const fileInputRef = useRef(null);


    const getData = async (selectedSubjectId, selectedClassId, pageNumber) => {
        console.log("yoru vauue ", selectedSubjectId, selectedClassId, pageNumber)
        const resp = await getFacultyDoubtList(selectedSubjectId ?? 0, selectedClassId ?? 0, 0, pageNumber, studentQueryType);
        // const resp = await getBlogPost(selectedSubjectId, selectedClassId, pageNumber, blogPostTypeId);
        setStudentQueries(resp?.data?.forums);
        setPaginationDetail(resp?.data?.pagination)
        setloaderShow(false)
    }

    useEffect(() => {

        if (selectedSubjectId != null)
            getData(selectedSubjectId, selectedClassId, paginationDetail?.current_page)
        else {
            getData(null, null, paginationDetail?.current_page)
        }


    }, [selectedSubjectId, selectedClassId, paginationDetail?.current_page])

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',

        };
        return date.toLocaleString('en-US', options);
    };

    const [showAddBlog, setShowAddBlog] = useState(false)
    const [selectedContent, setSelectedContent] = useState(false)

    const toggleAddBlogPopup = () => {
        setShowAddBlog((prev) => !prev)
    }
    const closeSelectedContent = () => {
        setSelectedContent(null)
    }



    const postBlog = async (selectedSubjectId, editorValue, title, tags) => {
        const response = await postDoubt(selectedSubjectId, editorValue, null, null, 65, blogPostTypeId, title, tags);
        toggleAddBlogPopup(false)
        if (selectedSubjectId != null)
            getData(selectedSubjectId, selectedClassId, paginationDetail?.current_page)
        else {
            getData(null, null, paginationDetail?.current_page)
        }
    }
    const handleRowClick = async (data) => {
        setSelectedContent(data)
    }


    const handleClassChange = (e) => {
        setSelectedClassId(e.target.value)
    }

    const handleSubjectChange = (e) => {
        setSelectedSubjectId(e.target.value)
    }
    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        // setPageNumber(pageNumber)
        setPaginationDetail({ ...paginationDetail, 'current_page': pageNumber })
        // this.setState({onLoad:true})
        // //this.show4(pageNumber);

        // this.setState({current_page: pageNumber});
        // this.getAssignment(this.props.match.params.id,pageNumber)
    }


    const handleToggleisNew = () => {
        setisNew(prev => !prev)
    }
  

    useEffect(() => {
        setFiteredBlogs(blogs)
    }, [blogs])


    const filterNew = (data) => {
        if(isNew){
            return !data?.is_faculty_answered
        }
        else{
            return data?.is_faculty_answered
        }
    }

    const getWordCount = (text) => {
        if (!text) return 0;
        const words = text.trim().split(/\s+/);
        if (words.length > 30) {
            return words.slice(0, 30).join(" ") + "...";
        } else {
            return text;
        }
    };


 
    return (
        
        <div className='app-body'>
            <div className={window.location.href.includes('app/faculty/student-queries') ? "blog-post-container app-div faculty" : "blog-post-container app-div"}>
                <div className='action-btn'>
                    <h1>Student Queries</h1>

                    <div className='actions' style={{ display: "flex", gap: "8px" }}>




                        <div style={{ display: "flex", gap: "8px" }}>
                            <button onClick={handleToggleisNew} style={{ height: '36px', opacity: isNew ? '1' : '0.6' }} >New</button>
                            <button onClick={handleToggleisNew} style={{ height: '36px', width: "118px", opacity: isNew ? '0.6' : '1' }}>Replied</button>

                            <select onChange={handleClassChange}>
                                <option value={null}>Select Course</option>
                                {courseList && courseList.map((course) => <option key={course?.class_id} value={course?.class_id}>{course?.class_name}</option>)}

                            </select>

                            <select value={selectedSubjectId} onChange={handleSubjectChange}>
                                <option value={null}>Select Subject</option>
                                {subjectList && subjectList.map((subject) => <option key={subject?.id} value={subject?.id}>{subject?.label}</option>)}
                            </select>
                        </div>
                    </div>
                </div>

                <div className='blog-list'>

                    {loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

                    {!loaderShow && <table>
                        <thead>
                            <tr>
                                <th>Ticket ID</th>

                                <th>Date</th>
                                <th>Student Name</th>

                                {/* <th>Subject Name</th> */}
                                <th width="20%">Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                studentQueries.filter(filterNew) && studentQueries.filter(filterNew).map((query) => (<tr key={query?.id}>
                                    <td>{query?.id}</td>
                                    <td>{formatDate(query?.added_on)}</td>
                                    <td>{query?.user?.name}</td>
                                    {/* <td>{query?.subject}</td> */}
                                    <td>
                                        {getWordCount(query?.description)}
                                       
                                    </td>
                                    <td>{query?.is_faculty_answered ? 'Replied' : 'New'}</td>
                                    <td>
                                        
                                        <span onClick={() => setPreviewContent(query)} title='Preview' className="fa fa-eye"></span>
                                        <Link style={{display: "inline"}} className="single-doubt-left" to={{
                                            pathname: `/faculty/doubt-responses/${query.id}`,
                                            state: {
                                                title: "Student Queries",

                                            }
                                        }}>

                                            <span style={{ marginLeft: '20px' }} title='Edit' className="fa fa-reply"></span>
                                        </Link>
                                    </td>
                                    
                                </tr>))
                            }



                            {studentQueries.filter(filterNew) && studentQueries.filter(filterNew).length == 0 && <tr>
                                <td colSpan={7}><div className=' marginTop60 noContentMessage'>No Queries Found</div></td>
                                </tr>}

                        </tbody>
                    </table>
                    }



                </div>



                {(paginationDetail?.total_page * paginationDetail?.page_size) && (paginationDetail?.total_page * paginationDetail?.page_size) >= paginationDetail?.page_size ? <div className="pagination-list fixed-pagination noStyle">
                    <Pagination
                        activePage={paginationDetail?.current_page}
                        itemsCountPerPage={paginationDetail?.page_size}
                        totalItemsCount={paginationDetail?.total_page * paginationDetail?.page_size}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}

                    />



                </div> : ''}



                <StudentQueryViewReply
                    previewContent={previewContent}
                    setPreviewContent={setPreviewContent}
                    formatDate={formatDate}
                    postDoubt={postDoubt}
                    getFacultyDoubtResponses={getFacultyDoubtResponses}
                />

             



            </div>
        </div>

    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.commonReducer.loading,
        logindetails: state.commonReducer.logindetails,
        saveLanguageWiseData: state.commonReducer.languageData
    }
}

const mapDispatchToProps = (dispatch) => {
    const { postDoubt, getBlogPost, deleteForum, getFacultyProfile, getFacultyDoubtList, getFacultyDoubtResponses } = actions;
    return {
        postDoubt: (subject_id, description, media, doubt_forum_id, class_id, type, title, tags) => dispatch(postDoubt(subject_id, description, media, doubt_forum_id, class_id, type, title, tags)),
        getBlogPost: (subject_id, class_id, pageNumber, type) => dispatch(getBlogPost(subject_id, class_id, pageNumber, type)),
        deleteForum: (forum_id) => dispatch(deleteForum(forum_id)),
        getFacultyProfile: () => dispatch(getFacultyProfile()),
        getFacultyDoubtList: (subject_id, batch_id, by_me, page, type) => dispatch(getFacultyDoubtList(subject_id, batch_id, by_me, page, type)),
        getFacultyDoubtResponses: (doubt_forum_id) => dispatch(getFacultyDoubtResponses(doubt_forum_id)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentQueries);