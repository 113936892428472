import config from '../config.js';

const apiType = 'api';
const apiVersion = 'v1';

const apiDomain = config.apiDomain;
const apiMediaDomain = config.apiMediaDomain;
const wpDomain = config.wpDomain;
const lmsBaseUrl = config.lmsBaseUrl;
const CourseId = 6;
const CourseId5 = 5;


console.log("lmsBaseUrl" + lmsBaseUrl)
//const apiDomain = "http://127.0.0.1:8000";
//const apiMediaDomain = 'http://127.0.0.1:8000';

const apiBase = `${apiDomain}/${apiType}/${apiVersion}`;

const userLogin = 'auth/login';
const wpUserToken = 'wp-user-token';
const userLogout = 'auth/logout';
const getProfile = 'center/center-profile/';
const userProfile = 'profile';
const updateUserProfile = 'update-profile';
const facultyProfile = 'faculty/profile';
const getCCS = 'center/csc_finder/';
const centerCreate = 'center/create/';
const centerList = 'center/list/';
const createCenter = 'center/create-center/';
const updateCenter = 'center/update';
const adminList = 'center/admin-list/';
const createAdmin = 'users/create/center-admin/';
const updateAdmin = 'users/update/center-admin/';
const classList = 'center/classs-list/';
const createClass = 'center/classs-create/';
const updateClass = 'center/classs-update/';
const createSection = 'center/batch-create/';
const sectionList = 'center/batch-list/';
const updateSection = 'center/batch-update/';
const masterSubjects = 'center/master-subjects/';
const subjectList = 'center/subject-list/';
const createSubject = 'center/subject-create/';
const updateSubject = 'center/subject-update/';
const createFaculty = 'users/create/faculty/';
const facultyList = 'faculty/list/';
const updateFaculty = 'users/update/faculty/';
const createStudent = 'users/create/student/';
const studentList = 'students/list/';
const updateStudent = 'users/update/student/';
const deleteSubject = 'center/delete/subject/';
const deleteClass = 'center/delete/classs/';
const deleteAdmin = 'users/delete/center-admin/';
const deleteBatch = 'center/delete/batch/';
const deleteCenter = 'center/delete/center/';
const deleteStudent = 'users/delete/student/';
const deleteFaculty = 'users/delete/faculty/';
const getTOC = 'center-content/toc/list/';
const getClearCache = 'content/toc-list';
const createTOC = 'center-content/toc/create/';
const updateTOC = 'center-content/toc/update/';
const deleteTOC = 'center-content/toc/delete/';
const getContentTOC = 'center-content/content-toc-mapping/list/';
const createContent = 'center-content/study-material/create/';
const contentDetails = 'center-content/study-material/detail/';
const contentDelete = 'center-content/study-material/delete/';
const contentUpdate = 'center-content/study-material/update/';
const assessmentData = 'center-content/assessment/';
const assessmentList = 'center-content/assessment/list/';
const dppList = 'center-content/dppplanner/list/';
const createdpp = 'center-content/dppplanner/create/';
const assessmentCreateDPP = 'center-content/dppplanner/create/';
const deleteDPP = 'center-content/dppplanner/delete/';
const editDPP = 'center-content/dppplanner/update/';
const assessmentEditDPP = 'center-content/dppplanner/update/';
const createAssessment = 'center-content/assessment/create/';
const updateAssessment = 'center-content/assessment/update/';
const deleteAssessment = 'center-content/assessment/delete/';
const addSection = 'center-content/assessment-section/create/';
const deleteSection = 'center-content/assessment-section/delete/';
const assessmentSectionList = 'center-content/assessment-section/list/';
const updateAssessmentSection = 'center-content/assessment-section/update/';
const createQuestion = 'center-content/question/create/';
const editQuestion = 'center-content/question/update/';
const newLecture = 'center-content/lecture/create/';
const editLecture = 'center-content/lecture/update/'
const deleteLecture = 'center-content/lecture/delete/'
const lectureList = 'center-content/lecture/list/';
const sendInvite = 'users/send-invite/';
const sendOtp = 'send-otp';
const updatePassword = 'update-password';
const studentDashboard = 'students/dashboard';
const facultyDashboard = 'faculty/dashboard';
const getExerciseList = 'content/exercises-list';
const getDPPList = '/content/dpp-list';
const getFacultyDPPList = '/faculty/dpp-summary';
const getFacultyExerciseList = 'faculty/exercises-summary';
const studentLectures = 'content/lectures';
const facultyLectures = 'faculty/lectures';
const studentTOC = 'content/toc-list';
const saveTocCompletion = 'content/save_toc_completion';
const getContentPreview = '/content/assessment-preview';
const getNotesContentDetail = '/content/notes/detail';
const getVideoDetail = '/content/video/detail';
const getAssessmentAttempt = '/content/assessment-attempt';
const saveAttemptSubmit = '/content/attempt-submit';
const saveQuestionSubmit = '/content/question-submit';
const getAssessmentReview = '/content/assessment-review';
const getAssessmentReviewAnalytics = '/content/assessment-review-analytics';
const getAssessmentFacultyReview = `faculty/assessment-review`;
const studentDoubtList = 'doubt/doubt-forum';
const facultyDoubtList = 'faculty/doubt-forum';
const postDoubt = 'doubt/doubt-forum';
const getScorecard = `content/assessment-scorecard`;
const getFacultyScorecard = `faculty/assessment-score-card`;
const invalidLogin = `invalid-login`;
const wpList = `products/categories`;
const imageUpload = `center-content/question/uploadImage/`;
const studentQuestionResponse = `faculty/student-question-response`;
const submitMarks = 'content/attempt-submit-marks';
const wpKeyDecrypt = 'wp-key-decryption';
const uploadUserFile = 'center-content/question/upload-userFile';
const getQuestionsList = '/content/question_bank';
const searchList = '/content/elastic-search';
const xpUserSignup = '/users/signup/';
const xpUserOTPVerification = '/users/xp-token-validate';
const xpStudentCreation = '/users/create/student_v2/';
const xpMyCoachListing = '/content/my-coach';
const xpCertificationBannerListing = '/banner/list'
const xpUserBannerCreation = '/userbanner/create'
const adminSearchList = '/content/elastic-search';
const learnerAssignmentList = '/content/assignment-list';
const assignmentCreate = '/center-content/assignment/create/';
const assignmentReview = '/faculty/assignment-review';
const learnerAssignmentReview = '/content/assignment-review';
const uploadUserAssigFile = '/content/assign-upload-user-file';
const facultyAssignmentList = '/faculty/assignment-list';
const assignmentPlanner = '/center-content/assignmentplanner/create/';
const facultyUploadMarks = '/faculty/assign-upload-marks';
const deleteAssignment = 'center-content/assignment/delete';
const editAssignment = 'center-content/assignment/update';
const xpGoalQuestionList = '/goal/questions';
const updateAssignmentPlanner = 'center-content/assignmentplanner/update';
const xpsendMentorship = '/mentorship/interests/submit';
const xpGetMentorshipInterest = '/mentorship/interests';
const xpSubmitGoalQuestionList = '/goal/questions/submit';
const xpSubmitTellInterest = '/users/interst/';
const xpGetTellInterest = '/users/show-interst/';
const xpGetAllChallengesAssignmentList = '/center-content/assignment/list';
const sendInvitePassword = 'users/send-invite-password';
const userContentCompletion = 'content/usercontentcompletion/create';
const decrypt = 'dcrypt';
const exportNotes = 'center-content/report/notes-access-log';
const exportVideos = 'center-content/report/video-access-log';
const userLogReport = '/center-content/report/user-access-log';
const encryption = '/encryption';
const qodCreate = '/qod/question/create';
const qodUpdate = '/qod/question/update/';
const qodDelete = '/qod/question/delete/';
const qodlist = '/qod/question/list';
const questionBankSearch = '/qod/questionBank/search';
const qodStudentList = '/qod/student-question/list';
const questionSectionMapping = '/qod/section-question-mapping';
const qodQuestionSubmit = '/qod/question-submit';
const unsubscribe = '/unsubscribe';
const assignmentPlannerList = '/faculty/global-assignment-list';
const assignmentPlannerdelete = '/center-content/assignmentplanner/delete';
const questionSearch = '/qod/question-search';
const editQuestionBank = '/content/question_bank_search';
const studentQodListing = '/qod/student-attempted-question/list';
const userSearchList = '/users/search-elastic';
const userdetail = '/students/student-detail';
const exportAssessment = 'center-content/assessment-report';
const exportAssignment = 'center-content/assignment-report';
const userLoginReport = '/center-content/report/user-login-log';
const userAccessLogTimeDuration = '/center-content/report/user-access-log-time-duration';
const myCoursesList = '/center/student-batch-list';
const userNotificationLogs = '/users/notification-logs';
const userNotificationReads = '/users/notification-read';
const classbatchmapping = '/students/class-batch-mapping';
const zipDownload = '/content/download-zip-file';
const openCourseSubjects = '/center/open-course-subjects';
const openCourseLevels = '/center/open-course-levels';
const bbbJoinLiveClass = '/link/getLCJoiningUrl';
const instituteWidgetList = '/institutes/widget-settings-list';
const getWidgetDataById = '/institutes/widget-data'
const widgetQuestionSubmit = '/institutes/widget-question-submit';
const promoteStudentSem = '/center-content/switch-student-course';
const fetchPromotionState = '/center-content/fetch-celery-state';
const programList = '/center-content/program-list';
const sessionList = '/center-content/session-list';
const programCreate = '/center-content/program-create';
const sessionCreate = '/center-content/session-create';
const sessionProgramMapping = '/center-content/session-program-mapping';
const programSessionEdit = '/center-content/session-update';
const programEdit = '/center-content/program-update';
const assessmentQuestionDelete = '/center-content/assessment-section-question/delete';
const addMoreSemesters = '/center-content/program-class-mapping';
const categoryList = '/center-content/category-list';
const widgetList = '/institutes/widget-list';
const widgetSubmit = '/institutes/widget-submit';
const widgetReorder = '/institutes/widget-reorder';
const widgetDelete = '/institutes/widget-delete';
const formList = '/institutes/form-list';
const bannerCreate = '/banner/create';
const formCreate = '/institutes/form-create';
const widgetQuestionCreate = '/institutes/widget-question-create';
const bannerUpdate = '/banner/update';
const masterSettingList = '/institutes/settings-master-list';
const settingList = '/institutes/settings-list';
const submitSetting = '/institutes/settings-submit';
const searchUser = '/users/search-user';
const wpUserLink = '/wp-user-link';
const userSignup = '/users/signup-cbse';
const resetPassword = '/users/reset-password';
const wpUserLinkTokenValidate = '/wp-user-link-token-validate';
const authTokenValidate = '/institutes/auth-token-validate';
const getAssessmentsTypesList = '/userbanner/report-list';
const getAssessmentsReportsDetails = '/userbanner/get-report-details';
const getAssessmentsNestedFilters = '/banner/nested-filters';
const sendAssessmentsResportsData = '/userbanner/get-assessment-report';
const callzoomAttendanceAPI = '/zoom/create-meeting-attender';
const callFacultyzoomAttendanceAPI = '/zoom/change-host-name';
const getLectureDatabyID = '/content/lectures-detail';
const callprofileApI = '/profile';
const createLeadUser = '/users/create/leadUser';
const switchClassTags = '/content/class-tags';
const deleteBanner = '/banner/delete';
const mobileOTPSend = '/users/mobile-otp-send';
const mobileOTPValidate = 'users/mobile-otp-validate';

const uploadUserAssigAddMoreFile = '/content/assignment-attempt-file';



const facultyUploadMutlipleFiles = '/faculty/assign-upload-files'

const getBatchHistory = '/users/get-batch-history';
const getUserPortfolio = '/users/get-user-portfolio';
const addExperience = '/users/add-professional-journey';
const deletePortfolio = 'users/delete-user-portfolio';
const addlanguageYearsOFExperience = '/users/add-preferred-language';
const facultyZoomCreate = '/content/zoom-create';
const facultyDetailsByID = '/faculty/list';
const teacherUploadDoucment = '/users/upload-doc';
const teacherUploadDoucmentList = '/users/list-doc';
const teacherUploadDoucmentDelete = 'users/delete-doc';
const createStudioProject = '/center-content/project/create/';
const getAllStudioProject = '/center-content/project/list/';
const deleteStudioProject = 'center-content/project/delete/';
const updateStudioProject = 'center-content/project/update/';
const studioProjectUserUploadFile = '/content/project-upload-user-file';
const getFacultyStudentProjectResponse = 'faculty/student-project-response';
const facultyUploadStudioProjectMarks = 'faculty/project-upload-marks';
const studioProjectUserAttemptList = '/content/user-project-attempt-list';
const studioPojectZipDownload = '/content/project-download-zip-file';
const studioProjectListing = '/content/project-list';
const facultyAssignmentReportDownload = '/faculty/assignment-report';
const curriculumCreate = '/institutes/curriculum-create';
const curriculumUpdate = '/institutes/curriculum-update';
const curriculumDelete = '/institutes/curriculum-delete';
const curriculumList = '/institutes/curriculum-list';
const getCurriculumListing = '/content/curriculum-list';
const callRegsiterAPI = 'https://onlinevgu.com/wp-json/v1/api/user_signup_through_mobile';
const bulkUserCreate = '/center-content/bulk-user-create';
const bulkUserJobID = '/center-content/fetch-celery-state'
const userResetPassword = '/users/reset-password';
const bulkPasswordUpdate = '/center-content/bulk-reset-password';
const bulkBatchUpdate = '/center-content/bulk-batch-assign';
const bulkQuestionUpdate = '/center-content/bulk-question-create';
const scheduleFBNotifications = '/center-content/schedule-fb-notifications';
const getLanguageWiseData = '/banner/get-language-file';
const getPageLanguageWiseData = '/banner/get-language-file'
const getCustomLoginPageSettings = '/institutes/center-id/'
const increaseAssessmentAttemptCount = '/center-content/assessment-attempt-count';
const increaseAssignmentAttemptCount = '/center-content/assignment-attempt-count';
const increaseAssessmentAssignmentAttemptCount = '/center-content/assessment-assignment-attempt-count';
const notificationStudentList = '/center-content/student-list'
const getLearnerAttendanceReport = '/content/attendance-report'
const getLearnerAttendanceReportFilters = '/content/attendance-report-filters'
const adminLectureWiseAttendance = '/content/attendence-batch-lecturewise';
const adminMarkAttendanceAPI = '/zoom/create-meeting-attender';
const userSurvayCompletion = '/content/attempt-survey'
const facultyAssessmentReport = '/center-content/assessment-attempt-report';
const learnerAttendanceAllReports = '/content/attendance-report-overall';
const adminAttendanceAllReports = '/content/attendance-report-overall';

// const mongoAssessApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_assess_report_migration?build_cache=1';
// const mongoQuestionApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_question_report_migration?build_cache=1';
// const mongoQuickLinkApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_qucik_link_migration?build_cache=1';
// const mongoContentCompApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_content_comp_report_migration?build_cache=1';
// const mongoLogsApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_logs_report_migration?build_cache=1';
// const mongoCheckTask1 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_assess&build_cache=1'
// const mongoCheckTask2 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_question&build_cache=1'
// const mongoCheckTask3 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_quick_link&build_cache=1'
// const mongoCheckTask4 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_content_comp&build_cache=1'
// const mongoCheckTask5 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_logs&build_cache=1'


const sendAssessmentAttemptEmail = '/content/send-assessment-attempt-email';

const mongoAssessApi = 'https://api.verandahighered.com/api/v1/center-content/mongo_assess_report_migration?build_cache=1';
const mongoQuestionApi = 'https://api.verandahighered.com/api/v1/center-content/mongo_question_report_migration?build_cache=1';
const mongoQuickLinkApi = 'https://api.verandahighered.com/api/v1/center-content/mongo_qucik_link_migration?build_cache=1';
const mongoContentCompApi = 'https://api.verandahighered.com/api/v1/center-content/mongo_content_comp_report_migration?build_cache=1';
const mongoLogsApi = 'https://api.verandahighered.com/api/v1/center-content/mongo_logs_report_migration?build_cache=1';
const mongoCheckTask1 = 'https://api.verandahighered.com/api/v1/center-content/fetch-celery-state?task_id=mongo_assess&build_cache=1'
const mongoCheckTask2 = 'https://api.verandahighered.com/api/v1/center-content/fetch-celery-state?task_id=mongo_question&build_cache=1'
const mongoCheckTask3 = 'https://api.verandahighered.com/api/v1/center-content/fetch-celery-state?task_id=mongo_quick_link&build_cache=1'
const mongoCheckTask4 = 'https://api.verandahighered.com/api/v1/center-content/fetch-celery-state?task_id=mongo_content_comp&build_cache=1'
const mongoCheckTask5 = 'https://api.verandahighered.com/api/v1/center-content/fetch-celery-state?task_id=mongo_logs&build_cache=1'
const breakoutRoomListing = '/center/group-listing';
const createBreakoutRoom = '/center/class-group';
const listUserGroups = '/center/user-groups';


const electiveStudentList = '/students/list/'
const removeElectiveStudent = '/center-content/delete-student-subject'
const deleteBreakoutRoom = '/center/class-group-delete';
const deleteForum = '/doubt/doubt-forum-delete';
const getAssessmentReport = '/userbanner/get-assessment-report';
const courseDiscussionList = '/doubt/doubt-forum-list';




// const mongoAssessApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_assess_report_migration?build_cache=1';
// const mongoQuestionApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_question_report_migration?build_cache=1';
// const mongoQuickLinkApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_qucik_link_migration?build_cache=1';
// const mongoContentCompApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_content_comp_report_migration?build_cache=1';
// const mongoLogsApi = 'https://vapi.learningoxygen.com/api/v1/center-content/mongo_logs_report_migration?build_cache=1';
// const mongoCheckTask1 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_assess&build_cache=1'
// const mongoCheckTask2 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_question&build_cache=1'
// const mongoCheckTask3 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_quick_link&build_cache=1'
// const mongoCheckTask4 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_content_comp&build_cache=1'
// const mongoCheckTask5 = 'https://vapi.learningoxygen.com/api/v1/center-content/fetch-celery-state?task_id=mongo_logs&build_cache=1'




const userAssessmentListing = '/content/user-assessment-list';
const userAssignmentListing = '/content/user-assignment-list';
// const sendAssessmentAttemptEmail = '/content/send-assessment-attempt-email';

const certificateListing = '/students/get-user-certificate';
const userCertificateBulkUpload = '/users/user-certificate-bulk-upload';
const getStudentListInSession = '/faculty/live-class-user';
const getUploadCertificateList = '/students/get-user-certificate';
const getUserProfile = '/profile_view_admin';
const adminUserLog = '/users/institute-user-log';
const studentAttendanceReport = '/users/student-attendence';
const usersAdminDashboard = '/users/admin-dashboard';


const dashboardActivityGraph = '/users/dashboard-activity-graph'; // activity_type, day or month or week
const dashboardCourseGraph = '/users/dashboard-course-graph'; //'attendance_percentage="90"' add dropdown
const dashboardActiveUserMonthly = '/users/dashboard-active-user-monthly';
const dashboardUserEnrollement = '/users/dashboard-user-enrollement';
const getCollegeMetaInfo = '/institutes/college-meta-info';
const getCollegeDetails = '/institutes/get-college-details';
const removeCollegeImage = 'institutes/remove-college-image';
const saveElectiveSubjects = 'center-content/save-student-subject';




export default {
  authTokenValidate,
  wpUserLinkTokenValidate,
  resetPassword,
  userSignup,
  wpUserLink,
  searchUser,
  submitSetting,
  settingList,
  masterSettingList,
  bannerUpdate,
  widgetQuestionCreate,
  formCreate,
  bannerCreate,
  formList,
  widgetList,
  xpCertificationBannerListing,
  widgetSubmit,
  widgetReorder,
  widgetDelete,
  categoryList,
  addMoreSemesters,
  assessmentQuestionDelete,
  programEdit,
  programSessionEdit,
  sessionProgramMapping,
  programList,
  sessionList,
  programCreate,
  sessionCreate,
  promoteStudentSem,
  fetchPromotionState,
  widgetQuestionSubmit,
  lmsBaseUrl,
  bbbJoinLiveClass,
  myCoursesList,
  CourseId5,
  CourseId,
  assignmentPlannerdelete,
  assignmentPlannerList,
  unsubscribe,
  getWidgetDataById,
  instituteWidgetList,
  xpMyCoachListing,
  myCoursesList,
  openCourseLevels,
  openCourseSubjects,
  zipDownload,
  classbatchmapping,
  userNotificationReads,
  userNotificationLogs,
  userAccessLogTimeDuration,
  userLoginReport,
  exportAssessment,
  exportAssignment,
  userdetail,
  userSearchList,
  unsubscribe,
  assignmentPlannerdelete,
  assignmentPlannerList,
  studentQodListing,
  editQuestionBank,
  questionSearch,
  adminSearchList,
  encryption,
  qodQuestionSubmit,
  questionSectionMapping,
  qodlist,
  qodCreate,
  qodUpdate,
  qodDelete,
  userLogReport,
  exportNotes,
  exportVideos,
  decrypt,
  qodStudentList,
  questionBankSearch,
  userContentCompletion,
  sendInvitePassword,
  updateAssignmentPlanner,
  editAssignment,
  deleteAssignment,
  facultyUploadMarks,
  assignmentPlanner,
  facultyAssignmentList,
  uploadUserAssigFile,
  learnerAssignmentReview,
  assignmentReview,
  assignmentCreate,
  searchList,
  uploadUserFile,
  apiBase,
  apiDomain,
  apiMediaDomain,
  wpDomain,
  userLogin,
  userLogout,
  getCCS,
  getProfile,
  userProfile,
  updateUserProfile,
  facultyProfile,
  centerCreate,
  centerList,
  createCenter,
  updateCenter,
  adminList,
  createAdmin,
  updateAdmin,
  classList,
  createClass,
  updateClass,
  masterSubjects,
  subjectList,
  createSubject,
  updateSubject,
  createFaculty,
  facultyList,
  updateFaculty,
  createSection,
  sectionList,
  updateSection,
  createStudent,
  studentList,
  updateStudent,
  deleteSubject,
  deleteClass,
  deleteBatch,
  deleteAdmin,
  deleteCenter,
  deleteStudent,
  deleteFaculty,
  getTOC,
  createTOC,
  updateTOC,
  deleteTOC,
  getContentTOC,
  createContent,
  contentDetails,
  contentDelete,
  contentUpdate,
  assessmentList,
  dppList,
  createdpp,
  assessmentCreateDPP,
  editDPP,
  assessmentEditDPP,
  deleteDPP,
  assessmentData,
  createAssessment,
  updateAssessment,
  deleteAssessment,
  addSection,
  updateAssessmentSection,
  deleteSection,
  assessmentSectionList,
  createQuestion,
  editQuestion,
  newLecture,
  editLecture,
  deleteLecture,
  lectureList,
  sendInvite,
  studentDashboard,
  facultyDashboard,
  getExerciseList,
  getDPPList,
  studentLectures,
  facultyLectures,
  studentTOC,
  getNotesContentDetail,
  getContentPreview,
  getVideoDetail,
  getAssessmentAttempt,
  saveAttemptSubmit,
  saveQuestionSubmit,
  getAssessmentReview,
  getAssessmentReviewAnalytics,
  getAssessmentFacultyReview,
  studentDoubtList,
  facultyDoubtList,
  postDoubt,
  getFacultyDPPList,
  getFacultyExerciseList,
  getScorecard,
  getFacultyScorecard,
  sendOtp,
  updatePassword,
  wpUserToken,
  wpList,
  invalidLogin,
  imageUpload,
  studentQuestionResponse,
  submitMarks,
  wpKeyDecrypt,
  getQuestionsList,
  xpUserSignup,
  xpUserOTPVerification,
  xpStudentCreation,
  xpMyCoachListing,
  xpCertificationBannerListing,
  xpUserBannerCreation,
  learnerAssignmentList,
  xpGoalQuestionList,
  xpsendMentorship,
  xpGetMentorshipInterest,
  xpSubmitGoalQuestionList,
  xpSubmitTellInterest,
  xpGetTellInterest,
  xpGetAllChallengesAssignmentList,
  getAssessmentsTypesList,
  getAssessmentsReportsDetails,
  getAssessmentsNestedFilters,
  sendAssessmentsResportsData,
  callzoomAttendanceAPI,
  callFacultyzoomAttendanceAPI,
  getLectureDatabyID,
  callprofileApI,
  createLeadUser,
  switchClassTags,
  deleteBanner,
  mobileOTPSend,
  mobileOTPValidate,
  uploadUserAssigAddMoreFile,
  facultyUploadMutlipleFiles,
  getBatchHistory,
  getUserPortfolio,
  addExperience,
  deletePortfolio,
  addlanguageYearsOFExperience,
  facultyZoomCreate,
  facultyDetailsByID,
  teacherUploadDoucment,
  teacherUploadDoucmentList,
  teacherUploadDoucmentDelete,
  createStudioProject,
  getAllStudioProject,
  deleteStudioProject,
  updateStudioProject,
  studioProjectUserUploadFile,
  getFacultyStudentProjectResponse,
  facultyUploadStudioProjectMarks,
  studioProjectUserAttemptList,
  studioPojectZipDownload,
  studioProjectListing,
  facultyAssignmentReportDownload,
  curriculumCreate,
  curriculumUpdate,
  curriculumDelete,
  curriculumList,
  getCurriculumListing,
  callRegsiterAPI,
  bulkUserCreate,
  bulkUserJobID,
  userResetPassword,
  bulkPasswordUpdate,
  bulkBatchUpdate,
  bulkQuestionUpdate,
  scheduleFBNotifications,
  getLanguageWiseData,
  getPageLanguageWiseData,
  getCustomLoginPageSettings,
  increaseAssessmentAttemptCount,
  increaseAssignmentAttemptCount,
  increaseAssessmentAssignmentAttemptCount,
  notificationStudentList,
  getLearnerAttendanceReport,
  getLearnerAttendanceReportFilters,
  adminLectureWiseAttendance,
  adminMarkAttendanceAPI,
  userSurvayCompletion,
  facultyAssessmentReport,
  learnerAttendanceAllReports,
  mongoAssessApi,
  mongoQuestionApi,
  mongoQuickLinkApi,
  mongoContentCompApi,
  mongoLogsApi,
  mongoCheckTask1,
  mongoCheckTask2,
  mongoCheckTask3,
  mongoCheckTask4,
  mongoCheckTask5,
  adminAttendanceAllReports,
  sendAssessmentAttemptEmail,
  breakoutRoomListing,
  createBreakoutRoom,
  listUserGroups,
  deleteBreakoutRoom,
  deleteForum,
  electiveStudentList,
  removeElectiveStudent,
  getAssessmentReport,
  courseDiscussionList,
  userAssessmentListing,
  userAssignmentListing,
  sendAssessmentAttemptEmail,
  certificateListing,
  userCertificateBulkUpload,
  getStudentListInSession,
  getUploadCertificateList,
  getUserProfile,
  saveTocCompletion,
  adminUserLog,
  studentAttendanceReport,
  usersAdminDashboard,
  dashboardActivityGraph,
  dashboardCourseGraph,
  dashboardActiveUserMonthly,
  dashboardUserEnrollement,
  getClearCache,
  getCollegeMetaInfo,
  getCollegeDetails,
  removeCollegeImage,
  saveElectiveSubjects

}
