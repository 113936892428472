import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import Listing from '../../public/icons/listing.svg';

class Sections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      classs: parseInt(this.props.match.params.classs),
      class_id: parseInt(this.props.match.params.classs),
      name: '',
      code: '',
      order: '',
      description: '',
      page_url:this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin:false,
      session:0,

      isBulkBatchDialogVisible:false,
      bulkBatchUpdateJobData:[],
      bulkBatchUpdateTableShow:false,
      bulkBatchUpdateJobStatus:false,
      bulkBatchUpdateJobCounter:0,
      bulkBatchUpdateJobStatusError:false,
      saveBatchId:''
    }
  }

  componentDidMount() {
    localStorage.setItem("page_url",this.state.page_url)

    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getClassList();
    this.getSectionList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL1 = getUrl.split("/");
    let modifyURL = modifyURL1[2];
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, name: '', code: '', class_id: this.state.classs });

  close = () => this.setState({ open: false });

  show2 = (val) => {
    this.setState({ open2: true, section_id: val.id, name: val.label, code: val.short_code,
                      center_id: val.center, class_id: this.state.classs,is_current:val.is_current});
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getClassList = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classes: response.data })
    }
  }

  getSectionList = async () => {
    const response = await this.props.getSectionList(this.state.center_id, this.state.class_id,1,this.state.page_url);
    if(response !== undefined) {
      this.setState({ sections: response.data,session:response.data[0]?.session });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  classsChange = (e) => {
    this.setState({ class_id: e.target.value })
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  setStatus = (e) => {
    this.setState({ is_current: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  newSection = async () => {
    const { name, code, center_id, class_id,session,is_current } = this.state;
    await this.props.createSection(name, code, center_id, class_id,session,is_current);
    //this.setState({ open: false });
  }

  editSection = async () => {
    const { section_id, name, code, center_id, class_id,session,is_current} = this.state;
    await this.props.updateSection(section_id, name, code, center_id, class_id,session,is_current);
    //this.setState({ open2: false });
  }

  deleteSection = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteSection(d_id, center_id);
    this.setState({ opend: false });
  }


  bulkBatchUpload = (id) => {
    this.setState({saveBatchId: id});
    this.setState({isBulkBatchDialogVisible: true});
  }

  bulkBatchClose = () => {
    this.setState({isBulkBatchDialogVisible: false});
  }

  bulkBatchFileClick = (event) => {
    document.getElementById("bulkBatchUpdate").click();
  }

  bulkBatchFileChange =  async (event) => {
    console.log(event.target.files[0].type.split('/')[0]);
    let getFile =  event.target.files[0];
    let getFileURL =  URL.createObjectURL(getFile);
    console.log(getFile);
    console.log(getFileURL);
    const response = await this.props.bulkBatchUpdate(getFile,this.state.saveBatchId); 
    if(response !== undefined) {
      console.log(response.data);
      this.setState({bulkBatchUpdateJobData: response.data.job_id});
      this.setState({bulkBatchUpdateTableShow:true});
      this.intervalFunction2(response.data.job_id);
    }
  }

  intervalFunction2 = async (jobid) =>{
    return await new Promise(resolve => {
      const interval =  setInterval(() => {
        console.log('Logs every minute');
        this.bulkBatchUpdateFileCheckStatus(jobid);
        if(this.state.bulkBatchUpdateJobStatus){
          console.log(this.state.bulkBatchUpdateJobStatus);
          clearInterval(interval);
        }
      }, 10000);
    })  
  }
  
  
  bulkBatchUpdateFileCheckStatus =  async (jobid) => {
      if(!this.state.bulkBatchUpdateJobStatus){
        const response  =  await this.props.bulkBatchJobID(jobid);
        if(response !== undefined) {
          console.log('response Batch Update');
          console.log(response);
          this.setState({bulkBatchUpdateJobStatus:true});
       }else{
          console.log("response?.status Batch update")
            this.setState({bulkBatchUpdateJobCounter:this.state.bulkBatchUpdateJobCounter+1});
            if(this.state.bulkBatchUpdateJobCounter == 5){
              this.setState({bulkBatchUpdateJobStatus:true});
              this.setState({bulkBatchUpdateJobStatusError:true});
              setTimeout(window.location.reload.bind(window.location), 1000);
            }
          }
       
      }
     }




  render() {

    const limitLabel = (label, maxLength) => {
      if (label.length > maxLength) {
        return label.substring(0, maxLength) + '...';
      }
      return label;
    };
    const maxLength = 80;

    const isEnabled = this.state.name.length > 0 && this.state.code.length > 0 &&
            this.state.class_id.length !== null;
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search sections"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.sections}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{`${this.state.class_label} | ${this.props.pagewiseLanguageData.sections}`}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder={this.props.pagewiseLanguageData.search_sections}/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_section}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data2">
            <div className="popup-inputs">
              <select value={this.state.class_id}>
                <option disabled value={this.state.class_id}>
                  {this.state.class_label}
                </option>
              </select>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.section_name} onChange={this.nameChange}/>
              <input placeholder={this.props.pagewiseLanguageData.section_code} onChange={this.codeChange}/>
            </div>
            <div className="popup-inputs">
              <select onChange={this.setStatus}>
                <option>--{this.props.pagewiseLanguageData.select_status}--</option>
                <option value="1" selected={this.state.is_current ? "selected" :""}>{this.props.pagewiseLanguageData.active}</option>
                <option value="0" selected={!this.state.is_current ? "selected" :""}>{this.props.pagewiseLanguageData.inactive}</option>
              </select>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newSection}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content2">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_section}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data2">
            <div className="popup-inputs">
              <select value={this.state.class_id}>
                <option disabled value={this.state.class_id}>
                  {this.state.class_label}
                </option>
              </select>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.section_name} onChange={this.nameChange}
                value={this.state.name}/>
              <input placeholder={this.props.pagewiseLanguageData.section_code} onChange={this.codeChange}
                value={this.state.code}/>
            </div>
            <div className="popup-inputs">
              <select onChange={this.setStatus}>
                <option>--{this.props.pagewiseLanguageData.select_status}--</option>
                <option value="1" selected={this.state.is_current ? "selected" :""}>{this.props.pagewiseLanguageData.active}</option>
                <option value="0" selected={!this.state.is_current ? "selected" :""}>{this.props.pagewiseLanguageData.inactive}</option>
              </select>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.editSection}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.are_you_sure_you_want_to_delete_this_section}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteSection}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>

      {/* Bulk Batch Update Dialog Start*/}
      <div className={this.state.isBulkBatchDialogVisible? 'bulkUserUploadDialog open' : 'bulkUserUploadDialog'}>
          <div className='bulkUserUploadContainer'>
              <span className="close" onClick={this.bulkBatchClose}>✕</span>
              <div className='popup-header'>{this.props.pagewiseLanguageData.bulk_batch_assign}</div>
              <div className='actions'>
                <a href={sampleBatchUpdateFile} download><img src={DownloadArrow}/><i>{this.props.pagewiseLanguageData.download_sample_file}</i></a>
                <input type="file" onChange={this.bulkBatchFileChange}  id='bulkBatchUpdate' accept=".csv" style={{ display: 'none' }} />  
                <span onClick={this.bulkBatchFileClick} ><img src={DownloadArrow}/><i>{this.props.pagewiseLanguageData.upload_file}</i></span>  
              </div>
              <div className={this.state.bulkBatchUpdateTableShow ? 'tableWrapper display' : 'tableWrapper'}>
                <table>
                <thead>
                   <tr>
                     <th>{this.props.pagewiseLanguageData.jobid}</th>
                     <th>{this.props.pagewiseLanguageData.status}</th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr>
                     <td>{this.state.bulkBatchUpdateJobData}</td>
                     <td>{this.state.bulkBatchUpdateJobStatus ? this.props.pagewiseLanguageData.success : this.props.pagewiseLanguageData.processing}</td>
                   </tr>
                  </tbody>
                </table>
              </div>
              {this.state.bulkBatchUpdateJobStatusError &&
              <div className='somthingWenWrongMsg'>{this.props.pagewiseLanguageData.invalid_csv_format}</div>
              }
          </div>
        </div>
        {/* Bulk Batch Update Dialog Ends*/}   

        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <Link id="trace-back" to={`/admin/classes`}>
                  <img src={Back} alt="back"/>
                </Link>
                <h1>{this.state.class_label} | {this.props.pagewiseLanguageData.sections}</h1>
              </div>
            </div>
            <div className="heading-part2">
              {!this.state.is_faculty_admin && <span onClick={this.show}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
              </span>}
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          {this.state.sections.length === 0 ? <Empty/> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
              {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-class">

              </div>
              <div className="list-subject">

              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.center}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.students}
              </div>
              {!this.state.is_faculty_admin && <div className="list-action">
              {this.props.pagewiseLanguageData.actions}
              </div>}
            </div> : null}
            {JSON.stringify(this.state.sectionsd)}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.sections.map((section, index) =>
                <div className="list-item" key={section.id}>
                  <div className="title-item">
                    {section.label}
                  </div>
                  <div className="section-item">
                    {section.short_code}
                  </div>
                  <div className="class-item">
                    {section.description}
                  </div>
                  <div className="subject-item">
                    {section.order}
                  </div>
                  <div className="faculty-item">
                    {section.center_details.name}
                  </div>
                  <div className="faculty-item">
                    {section.no_of_students}
                  </div>
                  <div className="action-item">
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(section)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>}
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(section.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span>}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.sections.map((section, index) =>
                <div className="grid-item" key={section.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid" title={section.label}>
                      {/* {section.label} */}
                      {limitLabel(section.label, maxLength)}
                    </div>
                    <div className="grid-actions">
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(section)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>}
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(section.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>}
                    </div>
                  </div>
                  <div className="grid-middle">
                    {this.props.pagewiseLanguageData.code}
                    {/* <span>{section.short_code}</span> */}
                    <span title={section.short_code}>{limitLabel(section.short_code, maxLength)}</span>
                  </div>
                  <div className="grid-middle spaceBetween">
                    <div>
                      {this.props.pagewiseLanguageData.total_mapped_students}
                      <span>{section.no_of_students}</span>
                    </div>
                      <div className='mapUser'onClick={() => this.bulkBatchUpload(section.id)}><img src={mapIcon}/>{this.props.pagewiseLanguageData.map_users}</div>
                      {section.no_of_students > 0 && <Link className='breakOutRoomsLink' to={{ pathname: `/admin/groups-listing/`,
                        state: {class_id:this.state.class_id, class_label:this.state.class_label, batch_id: section.id, session_id: section.session, no_of_students:section.no_of_students, section_name : section.label, section_code : section.short_code} }}
                        onClick={this.changeTab}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.list}/>
                        Breakout Rooms
                      </Link>
                      }
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, updateSection, getSectionList, getClassList,
          deleteSection,bulkBatchUpdate,bulkBatchJobID,getPageLanguageWiseData} = actions;
  return {
    createSection: (label, short_code, center, class_id,session,is_current) => dispatch(createSection(label, short_code, center, class_id,session,is_current)),
    updateSection: (section_id, label, short_code, center, class_id,session,is_current) => dispatch(updateSection(section_id, label, short_code, center, class_id,session,is_current)),
    getSectionList: (center_id, class_id,show_all,page) => dispatch(getSectionList(center_id, class_id,show_all,page)),
    getClassList: () => dispatch(getClassList()),
    deleteSection: (id, center_id) => dispatch(deleteSection(id, center_id)),
    bulkBatchUpdate : (uploadfile,batch_id) => dispatch(bulkBatchUpdate(uploadfile,batch_id)),
    bulkBatchJobID : (jobid) => dispatch(bulkBatchJobID(jobid)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sections);
