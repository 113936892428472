import React, { useEffect, useState } from 'react'
import actions from '../../../redux/actions';
import { useDispatch } from 'react-redux';

function DashboardOverview() {
    const { adminDashboard } = actions;
    const dispatch = useDispatch()
    const [resp, setResp] = useState()
    const [loading, setLoading] = useState()
   

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await dispatch(adminDashboard())
            setResp(response.data)
        }
        catch (e) {

        }
        finally {
            setLoading(false)
        }


    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="flexCard">

            <div className="card">
                <div className="left">
                    <h3 className='cardTitle'>Active Students</h3>
                    <h2 className='count'>{resp?.student?.active_students ?? 0} / <span className='graphCountName' >{resp?.student?.total_students ?? 0}</span></h2>

                </div>
                <div className="right">
                    <div className='circle'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 10H16.6668M16.6668 10L11.6668 5M16.6668 10L11.6668 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="left">
                    <h3 className='cardTitle'>Faculties</h3>
                    <h2 className='count'>{resp?.faculty?.total_faculty ?? 0}</h2>
                </div>
                <div className="right">
                    <div className='circle'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 10H16.6668M16.6668 10L11.6668 5M16.6668 10L11.6668 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="left">
                    <h3 className='cardTitle'>Staff</h3>
                    <h2 className='count'>{resp?.total_staff ?? 0}</h2>
                </div>
                <div className="right">
                    <div className='circle'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 10H16.6668M16.6668 10L11.6668 5M16.6668 10L11.6668 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="left">
                    <h3 className='cardTitle'>All Programmes</h3>
                    <h2 className='count'>{resp?.total_active_courses ?? 0}</h2>
                </div>
                <div className="right">
                    <div className='circle'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 10H16.6668M16.6668 10L11.6668 5M16.6668 10L11.6668 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default DashboardOverview