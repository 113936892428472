import React, { Component } from 'react';
import './styles.css';

const withNetworkStatus = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOnline: navigator.onLine,
        internetDialogOpen: false
      };
    }

    componentDidMount() {
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
    }

    handleOnline = () => {
      this.setState({ isOnline: true, internetDialogOpen: true });
      console.log('You are back online!');
    };

    handleOffline = () => {
      this.setState({ isOnline: false, internetDialogOpen: true });
      console.log('You have lost internet connection!');
    };

    internetHandleClose = () => {
      this.setState({ internetDialogOpen: false });
    };

    handleRetry = () => {
      this.checkOnlineStatus();
    };

    async checkOnlineStatus() {
      try {
        const onlineStatus = await fetch('https://www.google.com', {
          method: 'HEAD',
          cache: 'no-store',
        });

        if (onlineStatus.ok) {
          this.setState({ isOnline: true, internetDialogOpen: false });
        } else {
          throw new Error('Unable to connect to the internet.');
        }
      } catch (error) {
        console.error('Error checking online status:', error);
        this.setState({ isOnline: false, internetDialogOpen: true });
      }
    }

    render() {
      const { isOnline, internetDialogOpen } = this.state;
      return (
        <>
          <WrappedComponent isOnline={isOnline} {...this.props} />
          {internetDialogOpen && (
            <div className='CheckInternetDialogWrapper'>
              <div className='CheckInternetDialogContainer'>
                <h1>{isOnline ? 'Congratulations!' : 'Whoops!!'}</h1>
                {isOnline && <p>You are back online</p>}
                {!isOnline && <p>Your Internet Connection is Lost. <br/>Check your connection and try again.</p>}
                <div className='actions'>
                  {isOnline ? (
                    <button onClick={this.internetHandleClose}>Close</button>
                  ) : (
                    <button className='tryagainblink' onClick={this.handleRetry}>Try again</button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };
};

export default withNetworkStatus;
