import React, { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import { useDispatch } from 'react-redux';
import actions from '../../../redux/actions';
import reportsLoader from '../../../public/icons/loader.png';

const UserLogins = () => {


    const { dashboardActivityGraph } = actions;
    const dispatch = useDispatch()
    const [resp, setResp] = useState()
    const [loading, setLoading] = useState()
    const [currentState, setCurrentState] = useState('week') //day or month or week

    const [config, setConfig] = useState(null)

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await dispatch(dashboardActivityGraph(currentState))
            setResp(response.data)

            setConfig({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ' '
                },
                xAxis: {
                    categories: Object.keys(response.data?.graph_data)
                },
                yAxis: {
                    title: {
                        text: 'Values'
                    }
                },
                plotOptions: {
                    column: {

                        borderRadius: 5, // Set the border radius for rounded corners

                    }
                },
                series: [
                    {
                        name: 'Students',
                        data: Object.keys(response.data?.graph_data).map((key) => response.data?.graph_data[key]),
                        color: '#394CFF'
                    },

                ]
            })
        }
        catch (e) {

        }
        finally {
            setLoading(false)
        }


    }

    useEffect(() => {
        fetchData()

    }, [currentState])


    const handleTypeClick = (e) => {
        const value = e.target.innerText.toLowerCase();

        switch (value) {
            case 'daily':
                setCurrentState('day')
                break;
            case 'weekly':
                setCurrentState('week')

                break;
            case 'monthly':
                setCurrentState('month')

                break;


        }
    }

    return (
        <div className='userLogins'>
            <div className="flexCard">
                <h3 className='graphTitle'>Users logins</h3>
                <div className="buttons">
                    <h4 className={`${currentState == 'day' ? 'selected' : 'unselected'}`} onClick={handleTypeClick}>Daily</h4>
                    <h4 className={`${currentState == 'week' ? 'selected' : 'unselected'}`} onClick={handleTypeClick}>Weekly</h4>
                    <h4 className={`${currentState == 'month' ? 'selected' : 'unselected'}`} onClick={handleTypeClick}>Monthly</h4>
                </div>
            </div>

            <hr />

            <div className="graph">
                <div className="left">
                    <h2 className='graphCount'>{resp?.total_activity ?? 0}</h2>
                    <span className='graphCountName'>Activity</span>
                    <h2 className='graphCount'>{resp?.unique_users ?? 0}</h2>
                    <span className='graphCountName'>Unique Users</span>
                </div>
                <div className="right">
                    {
                        config && !loading ? <ReactHighcharts config={config} /> : <div className="loader">
                            <img style={{ width: '60px' }} src={reportsLoader} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserLogins   