import React, { useState, useRef } from 'react'

import JoditEditor from 'jodit-react';

export const CreateBlog = (props) => {
    const { toggleAddBlogPopup, defaultSelectedSubjectId, handleSubmit, editData } = props
    const [title, setTitle] = useState(editData?.title)
    const [tags, setTags] = useState('')
    const [editorValue, setEditorValue] = useState(editData?.description)
    const [selectedSubjectId, setSelectedSubjectId] = useState(editData?.description ??defaultSelectedSubjectId)
    const subjectList = JSON.parse(localStorage.getItem('logindetails'))?.subjects
    const editorRef = useRef(null);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
            ['clean']
        ]
    };

    const formats = [
        'header', 'font',
        'list', 'bullet',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align', 'color', 'background',
        'link', 'image', 'video'
    ];
    const handleTitle = (e) => {
        if (e.target.value == '') {
            setTitle(null)
        }
        else {
            setTitle(e.target.value)
        }
    }
    const handleSubjectChange = (e) => {
        setSelectedSubjectId(e.target.value)
    }
    const handleTagChange = (e) => {
        setTags(e.target.value)
    }
    const config = {
        readonly: false, // Enable editing
        toolbar: true, // Show toolbar
        buttons: [
            'bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol',
            'font', 'fontsize', 'brush', 'paragraph', 'link', 'image', 'video',
            'align', 'undo', 'redo', 'source'
        ], // Limited toolbar options including HTML preview
        showXPathInStatusbar: false, // Optionally hide the XPath status bar
        toolbarSticky: false, // Disable sticky toolbar
        removeButtons: ['fullsize'] // Remove full-screen button
    };


    return (
        <div className='studioProjectDialog open'>
            <div className='studioProjectDialogContainer '>
                <span onClick={toggleAddBlogPopup} className='close'>X</span>
                <div className='editor-form'>
                    <h3>Add Blog</h3>
                    <div className='block'>
                        <label>Select Subject</label>
                        <select value={selectedSubjectId} onChange={handleSubjectChange}>
                            <option value={null}>Select Subject</option>
                            {subjectList && subjectList.map((subject) => <option key={subject?.id} value={subject?.id}>{subject?.label}</option>)}
                        </select>
                    </div>
                    <div className='flex'>
                   
                    <div className='block'>
                        <label>Add Keyword</label>
                        <input value={tags} maxLength="250" onChange={handleTagChange} placeholder="Enter Keywords comma separated" />
                        <div className='noteText'>The max length of the field in 250 characters</div>
                    </div>
                    <div className='block'>
                        <label>Add Title</label>
                        <input value={title} onChange={handleTitle} maxLength="200" placeholder="Title" />
                        <div className='noteText'>The max length of the field in 200 characters</div>
                    </div>
                    </div>
                   
                    <div className='block'>
                        <label>Add Description</label>
                        {/* <ReactQuill theme="snow" value={editorValue} placeholder='Enter description' onChange={setEditorValue}  /> */}
                        <JoditEditor
                            ref={editorRef}
                            value={editorValue}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setEditorValue(newContent)} // preferred to use only this option to update the content for performance reasons
                        // onChange={newContent => setEditorValue(newContent)}
                        />
                    </div>
                </div>
                <div className='action-btn'>


                    <button className={`submit-btn ${(selectedSubjectId != null && title != null && editorValue != null && editorValue != '<p><br></p>') ? '' : 'isdisable'}`} onClick={() => {
                        let tagList = tags.split(',').map(tag => tag.trim());
                        handleSubmit(selectedSubjectId, editorValue, title, tagList)
                    }} type="button" >Submit</button>

                </div>

            </div>
        </div>
    )
}
