import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,Redirect,withRouter } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Helmet } from "react-helmet";
import { store } from 'react-notifications-component';
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import mycoachicon1 from '../../public/icons/mycoach-icon1.png';
import actions from '../../redux/actions';
import Profile from '../../public/icons/profile.svg';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import common from '../../common';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import PaidCourse from '../../public/icons/paid_course_csknew.png';
import Slider from "react-slick";
import AssessmentPreview from '../../components/study-material/CommonComponents';
import subscribeicon from '../../public/icons/mycoach-subscribed-icon.png';
import arrowicon from '../../public/icons/mycoach-arrow-icon.png';
import Delete from '../../public/icons/delete.svg';
import Empty from '../../components/error/Empty';
import EmptyBox from '../../components/error/EmptyBox';
import OOPS from '../../public/icons/WiredMobile.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
//import './styles.css';
import './widget-styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tickicon from '../../public/icons/iconfinder_Tick_Mark_Circle_1398913.png';
import editicon from '../../public/icons/editicon.png';
import addicon from '../../public/icons/plusicon.png';
//import referralrightbg from '../../public/images/referral-rightbg1.png';

var widgetList = {
    "data": [
     {
            "id": 2,
            "added_on": "2021-05-03T00:00:00",
            "updated_on": "2021-05-03T00:00:00",
            "object_status": 0,
            "updated_by": null,
            "type": "banners",
            "size": "1x3",
            "position": "top-left",
            "request_type": "post",
            "image": null
        },
        {
            "id": 1,
            "added_on": "2021-05-03T00:00:00",
            "updated_on": "2021-05-03T00:00:00",
            "object_status": 0,
            "updated_by": null,
            "type": "courses",
            "size": "1x1",
            "position": "top-left",
            "request_type": "post",
            "image": null
        },
       
        {
           "id": 3,
           "type": "quick_links",
           "size": "1x1",
           "position": "top-left",
           "request_type": "post",
           "api_url": null,
           "parameters": null,
           "image": null
       },
       {
           "id": 3,
           "type": "podcast",
           "size": "1x2",
           "position": "top-left",
           "request_type": "post",
           "api_url": null,
           "parameters": null,
           "image": null
       },

    ],
    "error": [],
    "status": 1,
    "currentDateTime": "2021-05-04T10:33:47+05:30"
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const MAX_LENGTH = 55;

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validMobileRegex = /^[0-9\b]+$/;

class WidgetComponent extends Component{
  constructor(props){
    super(props)

    this.state ={
      widgetdata:[],
      loading:true,
      widgetSettingsConfig:props.widgetSettingsConfig,
      username:localStorage.getItem("name"),
      list:this.props.list, 
      showModal:false,
      statetoken: localStorage.getItem("token"),
      userID: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      id:'',
      is_attendanceURL:false,
      currentLectureURL:'',
      settings : {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll:2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      }
    }
  }
  
  componentDidMount(){
    let {settings} = this.state

    

    if(!localStorage.getItem('is_cache_enabled')){
      this.instituteWidgetData();
    }
    
    if(localStorage.getItem('is_cache_enabled')){
      this.getAllCacheData();
    }

    this.props.widgetInfo.filter(w => w.type == 'courses').map(cr =>{
      if(cr.data.type == 2) {
        if(cr.size == "1x1"){
          settings.slidesToShow = 1
          this.setState({settings})
        } 
        if(cr.size == "1x2"){
          settings.slidesToShow = 2
          this.setState({settings})
        } 
        if(cr.size == "1x3"){
          settings.slidesToShow = 3
          this.setState({settings})
        } 
      }
    })


    //this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }


  getAllCacheData = async() =>{

    var data =[];
    var names = await caches.keys()

    const cacheStorage = await caches.open('widgetList'+this.props.list.id);

    const cachedResponse = await cacheStorage.match('/study.onlinevgu.com');

    
    if(cachedResponse)
      data = await cachedResponse?.json();
    
    this.setState({widgetdata:data,loading: false})
  }

  instituteWidgetData = async() =>{
    console.log("dsssssssssssssss")
    let widget_response = await this.props.widgetComponentFun(this.props.list.id);
    if(widget_response != undefined){
      this.setState({widgetdata:widget_response.data,loading: false})
      localStorage.setItem('is_cache_enabled',1);
      if ('caches' in window) {
        // Opening given cache and putting our data into it
        caches.open('widgetList'+this.props.list.id).then((cache) => {

          cache.put('/study.onlinevgu.com', new Response(JSON.stringify(widget_response.data)));

        });
      }
    }
  }

  getUrl = async (banner_type,url,ext) =>{
    if(banner_type == "partner_site"){
      const response = await this.props.getwpUserToken();
      const username = localStorage.getItem("username")

      const slug=ext?.slug || '';
      const page=ext?.page || '';

      if(slug)
        window.open(`${url}/1.php?token=${response.data?.token}&username=${username}&slug=${slug}`, "_self");

      if(page)
        window.open(`${url}/1.php?token=${response.data?.token}&username=${username}&page=${page}`, "_self");

      if(!slug && !page){
        window.open(`${url}/1.php?token=${response.data?.token}&username=${username}`, "_self");
      }
      //const slug = url.split('/').pop() ? url.split('/').pop():"";
    }else{
      if(url.indexOf('attempt') > -1){
        var content_id = url.split("/").pop();
        this.attemptAndReview('attempt',content_id);
      }else{
        window.open(`${url}`,"_blank");
      }
    }
  }

  bbbJoinLiveClass = async (etc,session) =>{

    if(!etc){
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.missing_moderator_and_attendee_details}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })

      return true
    }
    const {name,user_id} = this.state

    if(etc){
      let etcObject = JSON.parse(etc);

      const response = await this.props.bbbJoinLiveClass(session,name,etcObject.attendantPasswordBBB,user_id)
      console.log(response)
      if(response != undefined){
        window.open(response.data?.liveClassUrl, '_blank').focus();
      }
    }
    
  }


  switchCourse = async(id) =>{
    //localStorage.setItem("attendance_batch_id",id);
    await this.props.switchCourse(id);
  }

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      /*this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )*/
      window.open(`/learner/assessment-review/${contentPreview.content_id}`,"_self")
    }
    else {
      this.setState({showAttemptModal: false})
      /*this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );*/
      window.open(`/learner/attempt/${contentPreview.content_id}`,"_self")
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review') {
      /*this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )*/
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })

      document.getElementsByClassName("new-dashboard-navigation")[0].style.opacity =0.1
      document.getElementsByClassName("app-header")[0].style.opacity= 0.1
      
    }
  }

  closeAttemptModal = () => {
    this.setState({ showModal: false })
    document.getElementsByClassName("new-dashboard-navigation")[0].style.opacity =1
    document.getElementsByClassName("app-header")[0].style.opacity= 1
  }

  setLink = (lecture) => {
    if(lecture.liveClass_server == 2){
       return(
        <>
          {lecture.action == "Scheduled" ? <span>{lecture.action}</span> :<a href="#" title="server 2"  onClick={() => this.bbbJoinLiveClass(lecture.etc,lecture.live_class_Session)} target="_blank">{lecture.action}</a>}
        </>
      )
    }

    if(lecture.liveClass_server == 3){
      return(
       <>
         {lecture.action == "Scheduled" ? <span>{lecture.action}</span> :<a  title="server 3444444"  onClick={() => this.props.zoomAttendance(lecture.open_url,lecture.id)} href={lecture.open_url} target="_blank">{lecture.action}</a>}
       </>
     )
   }

    else{
       return(
        <>
          {lecture.action == "Scheduled" ? <span>{lecture.action}</span> :<a  onClick={() => this.props.zoomAttendance(lecture.open_url,lecture.id)} index={lecture.id.split('_')[0]} title="Join  Classsssss" href={lecture.liveClass_server == 3 ? lecture.open_url : lecture.url} target="_blank">{lecture.action}</a>}
        </>
      )
    }
  }


  
 

  render() {

    const {widgetdata,widgetSettingsConfig,username,list} = this.state

    return (
     <div>
     {!isEmpty(this.props.contentPreview) 
              && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
              onClose={this.closeAttemptModal}>
              <AssessmentPreview 
                contentPreview={this.props.contentPreview}
                attempt={this.attemptPaper}
                showAttemptButton={true}
                saveLanguageWiseData = {this.props.saveLanguageWiseData}
              />
            </Popup>}
      {this.props.list.type === "courses" && <><section>
        <div className="heading-title">{this.props.list.title && this.props.list.title}</div>
        {this.state.loading && <div class="facebookLoader">
          <div class="inter-crop"></div>
          <div class="inter-right--top"></div>
          <div class="inter-right--bottom"></div>
        </div>}

        {!this.state.loading && this.props.list.data.type == 2 ? widgetdata?.length > 1 ? <Slider ref={c => (this.slider = c)} {...this.state.settings}> {widgetdata.map(item =>
                            <div onClick={() => this.switchCourse(item?.batch_id)} className="paid-course-item"> 
                              <div className="course-thumb"><img src={PaidCourse} alt="subscribeicon"/></div>
                              <div className="arrow"><a href="#" onClick={() => this.switchCourse(item?.batch_id)}><img src={arrowicon} /></a></div>
                              <div className="course-content">
                              <div className="course-title"><a href="/learner">{item.label}</a></div>
                                <div className="course-description hello2">
                                  {item.description.length > 125 ?
                                      `${item.description.substring(0, 125)}...` : item.description
                                    }
                                  </div>
                               </div>
                            </div>
                          )}</Slider> : <>{widgetdata?.map(item =>
                            <div onClick={() => this.switchCourse(item?.batch_id)} className="paid-course-item paid-course-single-item"> 
                              <div className="course-thumb"><img src={PaidCourse} alt="subscribeicon"/></div>
                              <div className="arrow"><a href="#" onClick={() => this.switchCourse(item?.batch_id)}><img src={arrowicon} /></a></div>
                              <div className="course-content">
                              <div className="course-title"><a href="/learner">{item.label}</a></div>
                              <div className="course-description hello1">
                                {item.description.length > 250 ?
                                    `${item.description.substring(0, 250)}...` : item.description
                                  }
                                </div>
                              </div>
                           </div>
                          )}</> : <>{this.props.list.data.type == "tags" && this.props.list.data.tags == "Box_2" ? <ol> <>{!this.state.loading && <li>
                          <a href="https://www.sastratbi.in/" target="_blank">
                            <div className="arrow"><img src={arrowicon} /></div>
                            <div className="content" Style="opacity:0; visibility:hidden">
                              <h3>"Scavenger Hunt"</h3>
                              <p>Gaming Masters</p>
                              <p>are you ready for the Quest?</p>
                            </div>
                          </a>
                        </li>}</>{!this.state.loading && widgetdata?.open_subjects?.length>0 && widgetdata?.open_subjects.map(p =>
                            <li> 
                              <a href={"/learner/study-material/"+p.id}>
                                <div className="course-thumb"><img src={common.addFileUrl(p?.image_url)} alt="images" height="100px"/></div>
                                <div className="course-title">{p.label}</div>
                              </a>
                            </li>
                        )}</ol>:<><ol>{!this.state.loading && widgetdata?.open_subjects?.length>0 && widgetdata?.open_subjects.map(p =>
                            <li>
                              <a href={"/learner/study-material/"+p.id}> 
                                <div className="course-thumb"><img src={common.addFileUrl(p?.image_url)} alt="images" height="100px"/></div>
                                <div className="course-title">{p.label}</div>
                              </a>
                            </li>
                        )}</ol></>}</>
                      }             
      </section></>
      }

      {this.props.list.type === "banners" && <>
        <section>

          <div className="heading-title">{this.props.list.title && this.props.list.title}</div>
          {this.state.loading && <div class="facebookLoader">
          <div class="inter-crop"></div>
          <div class="inter-right--top"></div>
          <div class="inter-right--bottom"></div>
          </div>}
          {!this.state.loading && widgetdata?.length ?<>
          {!this.state.loading && widgetdata?.length ==1 ? widgetdata?.length && widgetdata.map(item =>
                            <>{widgetSettingsConfig[this.props.list.id] && 'clickable_area' in widgetSettingsConfig[this.props.list.id] && widgetSettingsConfig[this.props.list.id]['clickable_area'] ? <a onClick={() => this.getUrl(item.banner_type,item.url,item.ext)} target="_blank"><div className="userinfo-widget">
                              {item.web_image ? <div><img src={item?.web_image} /></div> :""}

                              <div Style={!item.content?"display:none":""}>
                                <div><b>{widgetSettingsConfig[this.props.list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title : item.title}</b><b>{widgetSettingsConfig[this.props.list.id]?.placeholders?.includes('batch') ? item.title.indexOf('{batch}')!= -1 ?`Hi ${item.batch}`:'': ''}</b></div>
                                <p dangerouslySetInnerHTML={{__html: item.content}}></p> 
                                {item.url ? <div className="explorebtn">{widgetSettingsConfig[this.props.list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url,item.ext)}>{widgetSettingsConfig[this.props.list.id].anchor_text ? widgetSettingsConfig[this.props.list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                              </div> 
                            </div></a> :<div className="userinfo-widget">
                              {item.web_image ? <div><img src={item?.web_image} /></div> :""}
                              <div Style={!item.content?"display:none":""}>
                                <div><b>{widgetSettingsConfig[this.props.list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title  : item.title}</b></div>
                                <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                                {item.url ? <div className="explorebtn">{widgetSettingsConfig[this.props.list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url,item.ext)}>{widgetSettingsConfig[this.props.list.id].anchor_text ? widgetSettingsConfig[this.props.list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                              </div>
                            </div>}</> ):<Carousel showArrows={false} showThumbs={false} emulateTouch={true} useKeyboardArrows={true}>
                            {widgetdata && widgetdata[this.props.list.id]?.length && widgetdata[this.props.list.id].map(item =>
                              <div className="userinfo-widget">
                                {item.web_image ? <div><img src={item?.web_image} /></div> :""}
                                <div>
                                  <div><b>{widgetSettingsConfig[this.props.list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title  : item.title}</b></div>
                                  <p dangerouslySetInnerHTML={{__html: item.content}}></p>  
                                  {item.url ? <div className="explorebtn">{widgetSettingsConfig[this.props.list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url,item.ext)}>{widgetSettingsConfig[this.props.list.id].anchor_text ? widgetSettingsConfig[this.props.list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                                </div>
                              </div>
                            )}
                          </Carousel>
                          }</>
                        :<EmptyBox/>}
                        
        </section></>
      }
      {this.props.list.type === 'quick_links' && <><section>
        {this.state.loading && <div class="facebookLoader">
          <div class="inter-crop"></div>
          <div class="inter-right--top"></div>
          <div class="inter-right--bottom"></div>
        </div>}
        <div className="quick-links-widget widget">
          {!this.state.loading && <div className="heading-title">{this.props.list.title && this.props.list.title}</div>}
          {!this.state.loading && widgetdata?.length && widgetdata? <ol>
                            <>{widgetdata?.length ? widgetdata.map(item =>
                             <>{item.type=="live_class" && <li Style={item.action && (item.action === "Join Class" || item.action === "Scheduled") ? "display:flex":"display:none"}>
                                <div>{item.title}</div>
                                <div className="Liveclass">
                                  {this.setLink(item)}
                                </div>
                              </li>}
                              
                              {item.type === "assessment" &&
                                <li>
                                <div>{item.title}</div>
                                
                                {item.action.map( action =>
                                    <>
                                      {
                                        action.label =="Attempt" || action.label =="Re-Attempt" || action.label =="Resume" ? <a href="#" onClick={() => this.attemptAndReview(action.label,item.content_id)}><button>{action.label}</button></a> : <Link to={{pathname: `/learner/assessment-review/${item.content_id}`,
                                        state: {modal:true}}}>
                                        <button>
                                        {action.label}
                                        </button>
                                        </Link>
                                      }
                                    </>
                                  
                                )}
                              </li>}

                             { item.type === "assignment" &&
                             <li>
                             <div>{item.title}</div>
                             
                             {item.action.map( action =>
                               <div>
                                 {action.label != "Scheduled" ? <Link to={{pathname: `/learner/assignment-review/${item.content_id}` , state:{action:action.label,subject:item?.subject_name,id:item?.subject_id,fromDashboard: true }}}>
                                    {action.label}
                                 </Link> : <span className="action-class">{action.label}</span>}
                               </div>
                             )}
                           </li>}

                           { item.type === "project" &&
                             <li>
                             <div>{item.title}</div>
                             <div>
                                 <Link to={{pathname: `/learner/studio-project/${item.id.split("_")[0]}` , state:{ProjectID:item.id.split("_")[0], projectTitle:item.title, projectScheduledDate:item?.scheduled_date, projectDueDate:item?.due_date,fromDashboard: true }}}>
                                    Studio Project
                                 </Link>
                               </div>
                             
                           </li>}

                           { item.type === "notes" &&
                             <li>
                            <div className='notesTitle'>{item.title}</div>
                            
                            {item.action.map( action =>
                            <>
                              {action.label != '' &&
                                <Link to={{pathname: `/learner/classroom/${item.subject_id}` ,  state:{content_subtype_name:4,content_id:item?.content_id,fromDashboard: true }}}>
                                  <span className="action-button">{action.label}</span>
                                </Link>
                              }
                              </>
                              )}
                              
                             </li>}


                           { item.type === "video" &&
                             <li>
                             <div className='videoTitle'>{item.title}</div>
                            
                             {item.action.map( action =>
                              <>
                                {action.label != '' &&
                                <Link  to={{pathname: `/learner/classroom/${item.subject_id}` ,  state:{content_subtype_name:5,content_id:item?.content_id,fromDashboard: true }}}>
                                  <span className="action-button">{action.label}</span> 
                                </Link>}
                                </>
                              )}
                              
                              </li>}

                             </>
                              
                            ):""}</>
                            {/*<li>
                              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo ante velit.</div>
                              <div><a href="#">Join</a></div>
                            </li>
                            <li>
                              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo ante velit</div>
                              <diev>Scheduled for 21 may (Tuesday)<a onClick={() => this.on_refer_click(list.id)} > click </a></div>
                            </li>*/}
                          </ol> : <EmptyBox/>}
        </div></section></>
      }
      {this.props.list.type === 'recommendation_engine' && <><section>
        {this.state.loading && <div class="facebookLoader">
          <div class="inter-crop"></div>
          <div class="inter-right--top"></div>
          <div class="inter-right--bottom"></div>
        </div>}
      {!this.state.loading && <div className="quick-recommendations-widget">
            <div className="heading-title">{this.props.list.title && this.props.list.title}</div>
        <ol>
                          {!this.state.loading && widgetdata?.length && widgetdata.map(item =>
                            <li>
                                <a href={item.link} target="_blank">
                                <div className="course-thumb"><img src={item?.image ? item?.image:""} /></div>
                                <div className="course-content">
                                    <div className="title">{item?.course}</div>
                                    <div className="details"><span>{item?.university}</span><span>{item?.location}</span></div>
                                </div>
                                </a>
                            </li>
                          )}
                        </ol>
      </div>}
      </section></>
      
      }

      {this.props.list.type === 'forms' && <><section>
      {this.state.loading && <div class="facebookLoader">
          <div class="inter-crop"></div>
          <div class="inter-right--top"></div>
          <div class="inter-right--bottom"></div>
        </div>}
        <div className="heading-title">{this.props.list.title && this.props.list.title}</div>
        {!this.state.loading && <a href={`/learner/mygoal/${list.id}`}>
                          <ol className="referal-widget">
                            <li>
                              <div className="course-thumb"><img src={subscribeicon} alt="subscribeicon"/></div>
                              <div className="arrow"><img src={arrowicon}/></div>
                              <div className="course-title">{widgetdata?.title}</div>
                              <div className="course-description">{widgetdata?.description}</div>
                            </li>
                          </ol>
                        </a>}
        </section></>
      }
     </div>
    )
  }
}

class WidgetDashboard extends Component {
  constructor(props) {
    super(props);
    this.state ={
      widgetList:[],
      widgetRows:[],
      widgetIds :[],
      widgetdata:{},
      widgetSettings:[],
      widgetSettingsCss:[],
      widgetSettingsConfig:[],
      questions:[],
      loading:true,
      open_refer:false,
      isEnabled:false,
      selected_list_id:null,
      widget_form_data:[],
      widgetInfo:[],
      username:localStorage.getItem("name"),
      showModal: false,
      is_checked:false,
      onsave:false,
      errors:{},
      fields:{},
    }
    //this.getWidgetData = this.getWidgetData.bind(this)
  }
  
  componentDidMount() {

    //New Code For Change Theme Setting Based Onb Login start
    console.log("props.learnerprofile")
    console.log(this.props.learnerprofile);
    let getCurrentCenterID = localStorage.getItem('center_id');
    let saveBeforeLoginSetting = localStorage.getItem('loginPageSettings');
    let getBeforeCenterID =  JSON.parse(saveBeforeLoginSetting).length == 0 ? getCurrentCenterID : JSON.parse(saveBeforeLoginSetting)[0]['center_id'];
    console.log("getCurrentCenterID", getCurrentCenterID)
   console.log("saveBeforeLoginSetting", saveBeforeLoginSetting)
   console.log("getBeforeCenterID", getBeforeCenterID)

   if(getCurrentCenterID != getBeforeCenterID){
    console.log("getBeforeCenterID =  nahi hai");
    let getNewResponse = JSON.parse(localStorage.getItem('logindetails'));
    let fetchPrimaryColor = getNewResponse.institute_settings.primary_color;
    let fetchPrimaryColorLight = getNewResponse.institute_settings.primary_color_light;
    let fetchSecondaryColor = getNewResponse.institute_settings.secondary_color;
    let fetchLogoUrl = getNewResponse.institute_settings.logo_url;
    let fetchLoginBannerUrl = getNewResponse.institute_settings.login_banner;
    
    if(fetchPrimaryColor !== 'undefined'){
      localStorage.setItem('primary_color', fetchPrimaryColor);
      document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color'));
    }
    if(fetchPrimaryColorLight !== 'undefined'){
      localStorage.setItem('primary_color_light', fetchPrimaryColorLight);
      document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light'));
   
    }
    if(fetchSecondaryColor !== 'undefined'){
      localStorage.setItem('secondary_color', fetchSecondaryColor);
      document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color'));
   
    }

    if(fetchLogoUrl !== 'undefined'){
      localStorage.setItem('logo_url', fetchLogoUrl);
    }

    if(fetchLoginBannerUrl !== 'undefined'){
      localStorage.setItem('login_banner', JSON.stringify(fetchLoginBannerUrl));
    }

  }
    //New Code For Change Theme Setting Based Onb Login ends





    this.instituteWidgetList();
  }

  onResourceLoad = () =>{
    window.location.reload();
  }

  instituteWidgetList = async() =>{
    
    let {widgetRows,widgetInfo,widgetList,settings,widgetdata,widgetIds,widgetSettings,widgetSettingsCss,widgetSettingsConfig} = this.state

    const response = await this.props.instituteWidgetList()

    if(response.message == 'Network Error'){
      localStorage.setItem("is_error_found",1)
      this.setState({isErrorFound:true})
    }
    else{
      if(response && response != undefined){

        let widgetData = response.data?.filter(w => w.type !='popup' && w.type !='row').sort((a,b) => parseInt(a.column) - parseInt(b.column))

        widgetRows = response.data?.filter(wr => wr.type =='row').sort((a,b) => parseInt(a.row) - parseInt(b.row))

        widgetIds = response.data?.map(list => list.id)

        if(widgetRows){
          for(let wd = 1;wd <= widgetRows.length;wd++){

            widgetList[wd] = widgetData?.filter(w => parseInt(w.row) === parseInt(wd))                   

          }

          response.data.forEach((data, index, array) => {
              widgetSettingsCss[data.id] = data.settings.settings?.css
          });
          //widgetSettingsConfig = response.data.map(list1 => list1.settings.settings?.config)
          //var widgetSettingsConfig = [];
          response.data.forEach((data, index, array) => {
              widgetSettingsConfig[data.id] = data.settings.settings?.config
          });

          response.data.forEach((data, index, array) => {
              let wdgtsetting = data.settings.settings?.class

              let sett = [];
              if(wdgtsetting){
                for(const [key,value] of Object.entries(wdgtsetting)){
                  sett.push(key+"-"+value)
                }
                widgetSettings[data.id] = sett.join(" ")
              }
          });
        }
      }
    }

    this.setState({widgetInfo:response.data,widgetRows,widgetdata,widgetList,widgetSettings,widgetSettingsConfig,widgetSettingsCss,loading:false})
  }

  zoomAttendance = (lectureURL,ids) =>{
    let {id,userID,currentLectureURL} = this.state;
    this.setState({currentLectureURL:lectureURL});
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    console.log("lectureURL", lectureURL)
    console.log("id", ids.split("_")[0])
    console.log("userID", localStorage.getItem("user_id"))
    console.log("dateTime", dateTime)
    console.log("currentLectureURL", lectureURL)
    if(id,userID,dateTime){
     this.zoomAttendanceAPI(ids.split("_")[0],localStorage.getItem("user_id"),dateTime);
    }
    
  }

  
  // zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
  //   const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time);
   
  //     this.setState({is_attendanceURL:false});
  //     console.log("attendanceURL", this.state.is_attendanceURL);
  //     console.log("currentLectureURL", this.state.currentLectureURL);
  //     window.open(this.state.currentLectureURL,"_blank");
      
   

  //  }

   zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
    const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time,"attendance");
      this.setState({is_attendanceURL:false});
      console.log("attendanceURL", this.state.is_attendanceURL);
      console.log("currentLectureURL", this.state.currentLectureURL);
      if(response !== undefined || response?.status == 1){
        window.open(response.data.url,"_blank");
        //window.open(this.state.currentLectureURL,"_blank");
      }
      
      
   

   }
  
  
  

  render() {


    const {widgetRows,widgetdata,widgetSettings,widgetSettingsCss,widgetSettingsConfig,loading,username,fields,errors} = this.state


    if(loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }

    return (
        <div className="">
          <ReactNotifications/>
          <div className="">
            <Helmet>
              <title>Dashboard - Learner</title>
            </Helmet>
            {/*Code start from here*/}
              <div className="IADashboardWrapper1">
                <div className="IADashboardContainer1">
                 
                  {this.state.widgetList.length > 0 && this.state.widgetList.map((widget,key) => 
                    <>{key && <section className={this.state.selected_widget_id == widgetRows[key-1]?.id ? "rowWrapper rowWrapperBorder":"rowWrapper"} style={this.state.selected_widget_id == widgetRows[key-1]?.id ? widgetSettingsCss[this.state.selected_widget_id] : widgetRows[key-1]?.settings.settings?.css}>
                      <div className="rowWidget rowWidgetPreview">
                        {widgetRows[key-1]?.settings?.settings?.config.heading ? widgetRows[key-1]?.settings?.settings?.config.navigation_url ? <a href={widgetRows[key-1]?.settings?.settings?.config.navigation_url } target="_blank"><div className="courses-widget widget className-widget-heading"><div className="userinfo-widget"><div>{widgetRows[key-1]?.settings?.settings?.config.image && <img src={widgetRows[key-1]?.settings?.settings?.config.image} alt="heading"/>}</div><div><h3>{widgetRows[key-1]?.settings?.settings?.config.heading}</h3><p>{widgetRows[key-1]?.settings?.settings?.config.description}</p></div></div></div></a>:<div className="courses-widget widget className-widget-heading"><div className="userinfo-widget"><div>{widgetRows[key-1]?.settings?.settings?.config.image && <img src={widgetRows[key-1]?.settings?.settings?.config.image} alt="heading"/>}</div><div><h3>{widgetRows[key-1]?.settings?.settings?.config.heading}</h3><p>{widgetRows[key-1]?.settings?.settings?.config.description}</p></div></div></div> :""} 
                      </div>    
                    <div className={"rowInner"}>
                      {widget.length ? widget.map((list,index) =>
                      <>{list.is_active &&
                        <div className={(this.state.selected_widget_id == list.id ?"rowInnerBorder":"")+" column-widget column"+(list.size)} style={widgetSettingsCss[list.id]}>
                          <div className={list.type === "courses" ? list.data.type == 2 ? `paid-course courses-widget widget ${widgetSettings[list.id]}` :list.data.type == "tags" && list.data.tags == "Box_2" ? `tag-course courses-widget widget ${widgetSettings[list.id]}`:`free-course courses-widget widget ${widgetSettings[list.id]}` : `courses-widget widget ${widgetSettings[list.id]}`}>
                            <WidgetComponent widgetInfo={this.state.widgetInfo} contentPreview={this.props.contentPreview} getAttemptPreview ={this.props.getAttemptPreview} list={list} widgetComponentFun={this.props.getWidgetDataById} widgetSettingsCss={widgetSettingsCss} widgetSettingsConfig={widgetSettingsConfig} getwpUserToken={this.props.getwpUserToken} switchCourse={this.props.switchCourse} zoomAttendance={this.zoomAttendance} saveLanguageWiseData = {this.props.saveLanguageWiseData} />
                          </div>
                        </div>
                        }</>
                      ):<EmptyBox/>}
                    </div>
                    </section>}</>
                  )}
                </div>
              </div>

              <Popup className="iserrorfound" open={false}
                onClose={this.closeAttemptModal}>
                <div><img src={OOPS} alt="oops" width="200px" height="124px"/></div>
                <div className="ohno">Oh no</div>
                <div className="connection_failed">Poor network connection!!</div>
                <div style={{marginTop:"20px"}}>
                  <button onClick={this.onResourceLoad}>Try Again</button>
                </div>
              </Popup>

            {/*Code start from here*/}
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    learnerprofile : state.commonReducer.logindetails,
    widgets:state.getReducer.widgets,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const {callzoomAttendanceAPI,instituteWidgetList,getwpUserToken,switchCourse,bbbJoinLiveClass,getWidgetDataById,getAttemptPreview,widgetQuestionSubmit} = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    instituteWidgetList: () =>dispatch(instituteWidgetList()),
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    widgetQuestionSubmit : (questions) => dispatch(widgetQuestionSubmit(questions)),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),
    bbbJoinLiveClass:(session,name,meetingPassword,userId) => dispatch(bbbJoinLiveClass(session,name,meetingPassword,userId)),
    callzoomAttendanceAPI: (lecture_id,user_id,join_time,join_type) => dispatch(callzoomAttendanceAPI(lecture_id,user_id,join_time,join_type))
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetDashboard);