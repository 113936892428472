import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import Empty from '../../components/error/Empty';
import queryString from 'query-string'
import 'react-notifications-component/dist/theme.css';
import './styles.css';
// import InfiniteScroll from "react-infinite-scroll-component";
import reportsLoader from '../../public/icons/loader.png';

class UserAssignmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tests: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      content_id:0,
      is_recent:0,
      showModal: false,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      labelFunction:false,
      getis_archive:'',
      is_archive:'',
      loaderShow:true,
      userAssignmentList:[]
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {

    localStorage.setItem("page","dpp")
    
    const searchId=queryString.parse(this.props.location.search);

    let getis_archive = this.props.location?.state?.archiveBatchID; 
    if(getis_archive){
      this.setState({getis_archive: getis_archive});
      this.setState({is_archive:getis_archive});
    }

    
    let getCurrentClassId = this.props.location?.state?.currentClassid; 
    console.log("getCurrentClassId",getCurrentClassId);
    this.setState({getCurrentClassId:getCurrentClassId});
      

    if(searchId.id){
      var elmnt = document.getElementById(searchId.id);
      if(elmnt) document.getElementById(searchId.id).lastChild.style.border="";
    }
  

   this.getUserAssessmentsList();


  }



  getUserAssessmentsList = async () => {
    try {
      this.setState({ loaderShow: true });
      
      const response = await this.props.getUserAssignmentsList();
  
      if (response !== undefined) {
        console.log("response ayya", response?.data?.contents);
        this.setState({
          loaderShow: false,
          userAssignmentList: response?.data?.contents
        });
      } else {
        this.setState({ loaderShow: false });
        // Handle the case where response is not as expected
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      this.setState({ loaderShow: false });
      // Handle the error appropriately
      console.error("Error fetching user assessments:", error);
    }
  };
  
  formatDate = (getdate) => {
    const timestamp = getdate;
    const dateObj = new Date(timestamp);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });

    const now = new Date();
    const diffInSeconds = Math.floor((now - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
        return "(Just now)";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr ago`;
    } else if (diffInDays === 1) {
        return "Yesterday";
    } else if (diffInDays < 30) {
        return `${formattedDate} ${formattedTime}`;
    } else if (diffInMonths < 12) {
        return `${formattedDate} ${formattedTime}`;
    } else {
        return `${formattedDate} ${formattedTime}`;
    }
};


attemptAndReview = async (content_id) => {
  this.setState({ content_id })
    this.props.history.push(
      `/learner/assessment-review/${content_id}`,
      {modal: true}
    )
    await this.props.getAttemptPreview(content_id)
    this.setState({ showModal: !this.state.showModal })
  
}


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

	render() {
    const { value } = this.state.start_date;
    
		return (
      <>
        {!isEmpty(this.props.contentPreview) 
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview 
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
            saveLanguageWiseData = {this.props.saveLanguageWiseData}
          />
        </Popup>}
        <div className="app-body" id="appBody" >
          <ReactNotifications/>
          <div className={this.props.dashboard_type == 'dynamic' ? "app-div widget-app-div" : "app-div"}>
            <Helmet>
              <title>Assignments - Learner</title>
            </Helmet>


            {/* New Design */}
              <div className='userAssessmentsListing'>

              <div className='page-header styleChange'>
                <div className='page-header-left'>
                  <Link id="trace-back" 
                    to={{ pathname: `/learner`,
                    state: {currentClassId: this.state.getCurrentClassId}}}>
                      <img src={BackArrow} alt="back with class id"/>
                  </Link>
                  <h1>Assignments</h1>
                  </div>
                </div>
                {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

                {!this.state.loaderShow && 
                  <div className='tableWrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width:'15%'}}>Date</th>
                                    <th style={{width:'10%'}}>Contact Type</th>
                                    <th style={{width:'20%'}}>Name</th>
                                    <th style={{width:'20%'}}>Attempted Status</th>
                                    <th style={{width:'20%'}}>Subject</th>
                                    <th style={{width:'20%'}}>Course / Semester</th>
                                    <th style={{width:'5%'}}>Ref. ID</th>
                                    <th style={{width:'10%'}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.userAssignmentList && this.state.userAssignmentList.map((data, index) => (
                                    <tr key={data?.id}>
                                        <td>{this.formatDate(data.added_on)}</td>
                                        <td>{data?.content_type}</td>
                                        <td>{data.title}</td>
                                        <td>{data?.attempt_id && <span className='attempted'>Attempted</span>}</td>
                                        <td>{data?.subject_label}</td>
                                        <td>{data?.program_label}</td>
                                        <td>{data?.attempt_id ? data?.attempt_id : ''}</td>
                                        <td className='actions'><div className='actions'>
                                          {data?.attempt_id && 
                                          <Link to={{pathname: `/learner/assignment-review/${data.assignment_id}` , state:{subject:data?.subject_label, id:data?.subject_id}}}>
                                            <span><i className='fa fa-eye'></i>Preview</span></Link>}
                                          </div>
                                          </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                  </div>
                    }
              
              


              </div>
            {/* New Design */}

            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
            
          </div>
        </div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    dashboard_type : state.commonReducer.dashboard_type,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentDPPList, getAttemptPreview,getUserAssignmentsList } = actions;
  return {
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getStudentDPPList: (date,days,direction,if_loading,batch_id) => dispatch(getStudentDPPList(date,days,direction,if_loading,batch_id)),
    getUserAssignmentsList: () => dispatch(getUserAssignmentsList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAssignmentsList);