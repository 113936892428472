import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import create_group_file from '../../public/images/create_group_student.csv';
import reportsLoader from '../../public/icons/loader.png';

class OpenGroupsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      batch_id:this.props.location.state?.batch_id,
      classs: parseInt(this.props.match.params.classs),
      class_id: this.props.location.state?.class_id,
      name: '',
      code: '',
      order: '',
      description: '',
      page_url:this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin:false,
      session:0,
      getlistingData:[],
      loaderShow:true,

      openDialog: false,
      openThanksDialog: false,
      groupTitle: '',
      numberOfGroups: 0,
      noOfStudents: this.props.location.state?.no_of_students,
      session_id: this.props.location.state?.session_id,
      username: localStorage.getItem('name'),
      center_id: localStorage.getItem('center_id'),
      groupPurpose:'',
      breakOutRoomName:'',
      csvFileName:'',
      csvFileOnly:'',
      groupFileError:false,
      openConfirmationGrpupDialog:false
    }
  }

  componentDidMount() {
    localStorage.setItem("page_url",this.state.page_url)

   this.getListingData()
  }

 
 


  getListingData = async () => {
    this.setState({loaderShow:true});
    const response = await this.props.breakoutRoomListing(this.state.class_id);
    if(response?.status == 1){
      this.setState({loaderShow:false});
      this.setState({getlistingData:response?.data})
  }
  }
  


  OpenThanksDialog = () => {
    this.setState({ openThanksDialog: true })
  }


  ThanksCloseDialog = () => {
    this.setState({ openThanksDialog: false })

  }


  OpenDialog = (section) => {
    this.setState({ openDialog: true })
  }

  CloseDialog = () => {
    this.setState({ openDialog: false });
  }



  handlePurposeChange= (e) => {
      console.log(e.target.value);
      this.setState({ groupPurpose: e.target.value })
  }



  handleBreakOutNameChange= (e) => {
    console.log(e.target.value);
    this.setState({ breakOutRoomName: e.target.value })
}

  handleSubmit =  (event) => {
    console.log("groupPurpose" , this.state.groupPurpose);
    console.log("breakOutRoomName" , this.state.breakOutRoomName);
    console.log("File" , this.state.csvFileOnly);
    console.log("File Name" , this.state.csvFileName);


    // const response = await this.props.createBreakoutRoom(this.state.formBlocks);
    // if(response?.status == 1){
    //  console.log("response", response);
    // }
  };
  
  formatDate = (getdate) => {
    const timestamp = getdate;
    const dateObj = new Date(timestamp);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });

    const now = new Date();
    const diffInSeconds = Math.floor((now - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
        return "(Just now)";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr ago`;
    } else if (diffInDays === 1) {
        return "Yesterday";
    } else if (diffInDays < 30) {
        return `${formattedDate} ${formattedTime}`;
    } else if (diffInMonths < 12) {
        return `${formattedDate} ${formattedTime}`;
    } else {
        return `${formattedDate} ${formattedTime}`;
    }
};


connectStudentsFileClick = () => {
  document.getElementById("connectStudentsFile").click();
}


handleConnectStudent(event) {
  const file = event.target.files[0];
  if (file) {
      // Check the file type
      const fileType = file.type.split('/')[1];
      if (fileType === 'csv' || fileType === 'vnd.ms-excel') {
          const fileURL = URL.createObjectURL(file);
          console.log(file);
          console.log(file.name);
          console.log(fileURL);
          this.setState({ csvFileName: file.name});
          this.setState({ csvFileOnly: file});
          this.setState({ groupFileError: false });
      } else {
          //alert('Please upload a valid CSV file.');
          this.setState({ groupFileError: true });
          
      }
  }
}


confirmationCloseDialog= () => {
  this.setState({ openConfirmationGrpupDialog: false})
}
confirmationOpenDialog= () => {
  this.setState({ openConfirmationGrpupDialog: true})
}

  render() {
    const isEnabled = this.state.groupPurpose && this.state.breakOutRoomName && this.state.csvFileName && this.state.groupFileError == '';
	
    return (
      <div className="admin-div">
        <Helmet>
          <title>{`Open Grpup Listing`}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder={this.props.pagewiseLanguageData.search_sections}/>
       
        <div className={this.state.openDialog ? "createGroupDialog open" : "createGroupDialog"} >
          <div className='createGroupDialogContainer'>
            <h3 className='marginBottom20'>Create Group<span onClick={this.CloseDialog} className='fa fa-times'></span></h3>
            <div className='formWrapper'>
              <div className='formGroup'>
              <div className='formBlock noStyle'>
                <label>
                  <div>Group Name <span className='required'>*</span></div>
                  <input type="text" onChange={(e) => this.handleBreakOutNameChange(e)}  placeholder='Enter breakout room name'/></label>
              </div>
              <div className='formBlock noStyle'>
                <label>
                  <div>
                    Purpose
                    <span className='required'>*</span>
                  </div>
                  <textarea onChange={(e) => this.handlePurposeChange(e)}  placeholder='Enter your purpose'></textarea></label>
              </div>
              <div className='formBlock noStyle'>
                <label><div>Connect Students<span className='required'>*</span></div>
                <input type="file" onChange= {(e)=>this.handleConnectStudent(e)}  id='connectStudentsFile' accept=".csv" style={{ display: 'none' }} />  
                  <div>
                  <a href="javascript:void(0);" className='connectStudentsFileClick' onClick={this.connectStudentsFileClick} ><i className='fa fa-upload'></i>Upload CSV</a>  
                  <a href={create_group_file} className='connectStudentsDownloadSampleFileLink' download><img src={DownloadArrow}/><i>Sample File</i></a>
                  </div>
                </label>
               
              </div>
              {this.state.csvFileName  &&<div className='uploadFileName'>{this.state.csvFileName}</div> }
              {this.state.groupFileError  &&<div className='uploadErrorMessage'>Please upload only .csv format</div> }

                
              </div>

            

              
              
              <div className='actions'><button onClick={this.handleSubmit} disabled={!isEnabled ? "disabled":""} >Submit</button></div>
            </div>
          </div>
        </div>



        <div className={this.state.openConfirmationGrpupDialog ? "confirmationGroupDialog open" : "confirmationGroupDialog "} >
          <div className='confirmationGroupContainer'>
            <h6>Confirmation</h6>
            <div className='formWrapper'>
              <p>Are you sure you wants to delete this<br/>Breakout room</p>

              <div className='actions'>
                  <a href='javascript:void(0);' onClick={this.confirmationCloseDialog}>Cancel</a>
                  <button>Ok</button>
              </div>
            </div>
          </div>
        </div>


        <div className={this.state.openThanksDialog ? "thanksGroupDialog open" : "thanksGroupDialog "} >
          <div className='thanksGroupContainer'>
            <span onClick={this.ThanksCloseDialog} className='fa fa-times'></span>
            <i className='icon fa fa-thumbs-up'></i>
            <h6>ThankYou</h6>
            <div className='formWrapper'>
              <p>Group are Created</p>
            </div>
          </div>
        </div>


        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading marginBottomZero">
                <Link id="trace-back" to={`/admin/classes`}>
                  <img src={Back} alt="back"/>
                </Link>
                <h1>Group</h1>
              </div>
            </div>
           <div className='createBreakoutRooms'><span onClick={(e) => this.OpenDialog()}>Create Group</span></div>
          </div>
          <div className='blog-list-table'>

          {       

                  <table>
                            <thead>
                                <tr>
                                    <th>Group Name</th>
                                    <th>Purpose Add Limit also</th>
                                    <th>Added On</th>
                                    <th>Created By</th>
                                    <th>#Users</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {this.state.getlistingData && this.state.getlistingData.map((data, index) => (
                                    <tr key={data?.id}>
                                        <td>{data?.title ?? "Untitled"}</td>
                                        <td>{data?.topic ?? "Untitled"}</td>
                                        <td>{this.formatDate(data?.added_on)}</td>
                                        <td>Created By</td>
                                        <td>#Users</td>
                                        <td>Actions</td>
                                    </tr>
                                ))}
                                 {this.state.getlistingData.length == 0  && this.state.loaderShow == false &&
                                    <tr>
                                      <td colSpan="6" className='noContentMessage'>No Records found</td>
                                  </tr>
                                  }

                                  {this.state.loaderShow &&
                                    <tr>
                                      <td colSpan="6" className=''><div className='LoadingMessage'><img src={reportsLoader}/></div></td>
                                  </tr>
                                  }
                                  

                            
                   
                            </tbody>

                           
                        </table>
                    }

                </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, updateSection, getSectionList, getClassList,getPageLanguageWiseData,breakoutRoomListing,createBreakoutRoom} = actions;
  return {
    getSectionList: (center_id, class_id,show_all,page) => dispatch(getSectionList(center_id, class_id,show_all,page)),
    getClassList: () => dispatch(getClassList()),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    breakoutRoomListing: (class_id) => dispatch(breakoutRoomListing(class_id)),
    createBreakoutRoom: (title,description,center_id,class_id,session_id,no_of_groups,topic) => dispatch(createBreakoutRoom(title,description,center_id,class_id,session_id,no_of_groups,topic)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenGroupsListing);
