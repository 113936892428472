import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
// import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';

class FacultyTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tests: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      content_id:0,
      classs: '',
      batch: 0,
      classes: [],
      batches: [],
      batchesFilter: [],
      subjects: [],
      subject:0,
      subjectsFilter: [],
      is_recent:0,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('mouseover', this.changeDateOnScroll);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date});
   this.getCurrentDate();
   this.getFacultyProfile();
   this.getFacultyDPPList(0,0,date,15,'forward',true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('mouseover',this.changeDateOnScroll);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  classs: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0]?.class_id,
                  subjectsFilter: response.data.subjects.filter(subject => subject.class_id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batchesFilter: response.data.batches.filter(batch => batch.classs?.id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batches: response.data.batches });
  }

  getFacultyDPPList = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response = await this.props.getFacultyDPPList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({tests:response.data.data?response.data.data:"", loading: false,countBottom:1,content_id:response.data.data?response.data?.data[0]?.content_id:"NA"})
      this.ifRecordsExists();
      if(!response.data.data.length){

        this.setState({content_id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getFacultyDPPListOnBackward(this.state.subject,this.state.batch,currentDate,15,'backward',false);
      }
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getFacultyDPPListOnForward = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response= await this.props.getFacultyDPPList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({
        tests: this.state.tests?this.state.tests.concat(response.data.data):response.data.data,
        loading: false,countBottom:1
      });
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getFacultyDPPListOnBackward = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response= await this.props.getFacultyDPPList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({
        tests: response.data.data?response.data.data.concat(this.state.tests):this.state.tests,
        loading: false,countTop:1
      });

      this.ifRecordsExists();
      if(this.state.content_id)
        this.handleScrollPos();
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  onChange = value => {
     var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,tests:[]});
    this.setState({open:false})
    
    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });

    this.setState({start_date:date,loading:true,is_recent:1})
    this.setState({ hasMore: false,countTop:0,current_date:calendarDate});
    this.getFacultyDPPList(this.state.subject,this.state.batch,date,15,'forward',true);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({current_date:date,date:currentDate});
  }


  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  }

  fetchMoreData = () => {
    if(this.state.countBottom){
      this.setState({hasMore:false,loading:false})
      return;
    }
    if(this.state.countBottom===0)
      this.getFacultyDPPListOnForward(this.state.start_date,15,'forward',false);
  };  

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,subject:0,batch:0})
   this.getCurrentDate();
   this.getFacultyDPPList(0,0,date,15,'forward',true);
  }

  handleScroll = (e) => {

    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.tests && this.state.tests.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.due_date)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;

    if(top){
      //this.setState({"loading":true})
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getFacultyDPPListOnBackward(this.state.subject,this.state.batch,currentDate,15,'backward',false);
      }
    }
  }
  
  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: this.state.subjects, subject: 0,
                        batchesFilter: this.state.batches, batch: 0 });
    }

    this.setState({is_recent:1});
  }

  onSubjectChange = (e) => {
    this.setState({ subject:parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyDPPList(parseInt(e.target.value),this.state.batch,this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  onSectionChange = (e) => {
    this.setState({ batch: parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyDPPList(this.state.subject,parseInt(e.target.value),this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.tests && this.state.tests.some(function(test,index) {
        
        if(i <= new Date(test.due_date).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.due_date).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.due_date).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }

  render() {
    const { value } = this.state.start_date;
    
    return (
      <div className="app-body" id="appBody">
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.tests} - ${this.props.saveLanguageWiseData.faculty}`}</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="page-header-faculty">
            <h1 className='headingTitle paddingBottom10'>Test</h1>
          <div className="page-header">
              <div className="react-calendar-date" onClick={this.openCalendar}
                ref={this.wrapperRef}>
                {this.state.current_date}<br/>
                 
                <img src={Calendar2} alt="cal"/>
              </div>
              <div className="page-header-right">
                <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                  {this.state.classes.map(classs => 
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} value={parseInt(this.state.batch)}>
                  <option value={parseInt(0)}>
                    {this.props.saveLanguageWiseData.select_section}
                  </option>
                  {this.state.batchesFilter.map(batch => 
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                <select onChange={this.onSubjectChange} value={parseInt(this.state.subject)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_subject}
                  </option>
                  {this.state.subjectsFilter.map(subject =>
                    <option value={parseInt(subject.id)} key={subject.id}>
                      {subject.label}
                    </option>
                  )}
                </select>
              </div>
              </div>
              {this.state.is_recent?
                <div className="back-to-recent-faculty" onClick={this.switchToRecent}>
                    {this.props.saveLanguageWiseData.today}
                </div>:""
              }
            </div>
            
            <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
              ref={this.wrapperRef}>
              <Calendar
                onChange={this.onChange}
                value={value}
              />
            </div>
            
            {!this.state.loading && <div className="list-sticky notwidgetheader" onScroll={this.handleScroll}>
            { this.state.tests && this.state.tests.length <= 2 ?<div id={this.state.content_id=="NA"?"conter":""}></div> :""}
               
              {this.state.tests && !this.state.tests.length && <Empty/>}
                <div className="list-content padding20">
                {this.state.current_date_records.map((data,index)=>
                  <div>
                    { data.status==0 ? 
                      <div><div className="dash-test-first">{data.month} {data.year}</div><div className="dash-test-no-found">{this.props.saveLanguageWiseData.no_test_planned}</div></div>:""}
                  </div>
                )}
                
                
                {this.state.tests && this.state.tests.map((test,index) =>
                  <div className="list-div-x2" id={test.content_id} ref={this.myRef}>
                    <div className="dash-test-first">
                       {index === 0 ? <>
                        <span>{this.getTime(test.due_date)[0]} {this.getTime(test.due_date)[5]}</span>
                        <span>{this.getTime(test.due_date)[1]}</span>
                      </> : this.checkDateTime(test.due_date, 
                        this.state.tests[index - 1].due_date) ? null : <>
                        <span>{this.getTime(test.due_date)[0]} {this.getTime(test.due_date)[5]}</span>
                        <span>{this.getTime(test.due_date)[1]}</span>
                      </>}
                    </div>
                    
                    <div className="dash-test" id={test.content_id==this.state.content_id?"conter":""} key={index} Style={this.state.date==this.getTime(test.due_date)[2] && this.getTime(test.due_date)[3]?"background-color:#f1e6ef":""}>
                      <div>
                        <div Style="display: inline-block;">
                          <span Style="font-size:17px">{test.title}</span><br/><br/>
                          <span Style="color:#a9a0a0">{this.props.saveLanguageWiseData.average_score}</span> : <span Style="color:var(--primary)">{test.summary.score}</span>
                        </div>
                        <span className="dash-flex2-img"><img id="Idea" src={idea} alt="Idea"/></span>
                        
                      </div>
                      <div className="dash-test-bottom">
                        <div>
                          <span>{test.class_name} | {test.batch_name} | {test.subject_name}</span>
                        </div>
                        <div className="faculty-dash-actions">
                          {test.actions && test.actions.map(action =>
                            <Link key={action.id} to={{pathname: `/faculty/assessment-review/${test.content_id}`,
                              state: { batch: test?.batch_id, modal: true }}}>
                              <button>
                                {action.label}
                              </button>
                            </Link>
                          )}
                          </div>
                        </div>
                    </div>
                  </div>
                )}
                { this.state.tests && this.state.tests.length>2 ?<div id={this.state.content_id=="NA"?"conter":""}></div> :""}
                
                </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyDPPList } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyDPPList: (subject_id,batch_id,date,days,direction,if_loading) => dispatch(getFacultyDPPList(subject_id,batch_id,date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyTests);