import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Error from '../../public/images/error.svg';
import NotFoundImage from '../../public/images/404.jpeg';
import './styles.css';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
			mock: false
    }
	}

	render() {
		return (
			<div className="error-div">
        <img src={NotFoundImage} alt="404"/>
        <div>
          <Link to={'/'}>
            Go to Home
          </Link>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);