import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import actions from '../../redux/actions';
import askicon from '../../public/icons/mycoach-ask-icon.png';
import './mycoach.css';
import ImgHolder from '../../public/icons/doubt-image.svg';
import ReactNotifications, { store } from 'react-notifications-component';
import AskDoubts from '../../components/doubts/AskDoubts'


class HeaderMyCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      image: null,
      imageURL: null,
      isvisible:false,
      ideaspace:false,
      pathname:'',
      center_id:parseInt(localStorage.getItem('center_id')),
      descriptionContent:"",
      is_webview:localStorage.getItem("is_webview"),
    }
  }

  componentDidMount() {
    var element = document.getElementById("bodyParam");
    element.classList.add('mycoachpageadded');
    const pathname = window.location.pathname;
     this.pathchange(pathname);  

     const key="generic";
     this.getCertificationListCall(key);
    
  }

  componentWillUnmount() {
    
  }

  getCertificationListCall = async(type) =>{
    const {center_id} = this.state

    const response = await this.props.getCertificationBannerList(type);
    //this.setState({mycoachdata : response?.data})
    if(response != undefined){
      this.setState({descriptionContent:response?.data[0]?.description})
    }
  }


  pathchange(pathname){
    let newpathname = pathname;
    //console.log("newpathname" + newpathname)
    if(pathname == '/app/learner/doubts'){
      console.log("pathname")
      this.setState({ideaspace : true,pathName: pathname.split('/').pop()});
    }
  }


  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    this.setState({ profile: response.data, loading: false,
        no_data: response.data.message == "You are not assigned to any class and section, Please contact Admin for assigning into class"
          ? true : false })
    this.setState({userid:response.data.email,uname:response.data.username,username:response.data.name,email:response.data.email,mobile:response.data.mobile,profile_picture:response.data.profile_picture});
  }
  

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  }

  handleChange = (event) => {
		if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('doubt_img').value = "";
      store.addNotification({
        title: 'Error',
        message: this.props.saveLanguageWiseData.images_uploaded,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  postDoubt = async () => {
    // const { subject, description, image } = this.state;
    // const response = await this.props.postDoubt(subject, description, image, null);
    // if(response !== undefined) {
    //   this.setState({ posted: true, question: response.data, open: false });
    // }
  }


  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('doubt_img').value = "";
  }

  
  openDialog(){
    this.setState({isvisible : true});
  }

  closeDialog(){
    this.setState({isvisible : false});
  }

render() {
  const { descriptionContent ,pathName} = this.state;

  const isEnabled = this.state.description.length > 0;
    return (
      <>
      <div className={this.state.is_webview == "1" ? "webview-header mycoach-header":"mycoach-header widthChange"}>
         
          <div className="intro-block">
              <div className="user-block">
                {/* <h3>{this.props.saveLanguageWiseData.hello} <span>{this.state?.profile?.name}</span></h3> */}
                <h3>{this.props.saveLanguageWiseData.hello} <span>{this.props?.learnerprofile?.name}</span></h3>
                <p>{pathName == "doubts" && this.props.learnerprofile?.institute_settings?.is_idea_space_description ? this.props.learnerprofile?.institute_settings?.is_idea_space_description : pathName == 'queries' && this.props.learnerprofile?.institute_settings?.is_query_description ?this.props.learnerprofile?.institute_settings?.is_query_description: descriptionContent}</p>
                
              </div>
              <div className="askanything">
                <AskDoubts title={this.state.ideaspace == true ? "Share Ideas" : "Ask Coach Anything"}/>
                {/* <i><img src={askicon}/></i> */}
                {this.state.ideaspace == true ? "Share Ideas" : "Ask Coach Anything"}
              </div>
          </div>
        </div>
        <div className= {this.state.isvisible ? 'isvisible askDialog':'askDialog'}>
            <div className="askDialog-inner">
                <div className="popup-header">{this.props.saveLanguageWiseData.ask_coach_anything_else}</div>
                <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span> 
                <div className="askdialog-content">
                <input style={{display: 'none'}} accept="image/*" type="file"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}
                  id="doubt_img"/>
                <div className="image-upload designChanges">
                  <span>{this.props.saveLanguageWiseData.add_an_image}</span>
                  {this.state.imageURL === null
                    ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()}/>
                    : <div className="post-doubt-img"
                      style={{backgroundImage: "url("+this.state.imageURL+")"}}>
                      <span className="close" onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                </div>
                <textarea placeholder={this.props.saveLanguageWiseData.start_typing} 
                  onChange={this.onDescriptionChange}/>
              </div>
              <div className="askdialog-action">
                <button disabled={!isEnabled} onClick={this.postDoubt}>
                  {this.props.saveLanguageWiseData.post_doubt}
                </button>
              </div>
            </div>
          </div>
      

      </> 

    )
	}
}
const mapStateToProps = (state) => {
	return {
    currentPage: state.commonReducer.currentPage,
    loading: state.commonReducer.loading,
    learnerprofile : state.commonReducer.logindetails,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}
  
const mapDispatchToProps = (dispatch) => {
	const { setCurrentPage, getLearnerProfile,getCertificationBannerList} = actions;
	return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getCertificationBannerList: (type,center_id) => dispatch(getCertificationBannerList(type,center_id)),
    
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderMyCoach);