import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import axios from 'axios';
import actions from '../../redux/actions';
import common from '../../common';
import LoaderImage from '../../public/icons/loader.png';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class BuildCache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center_id: localStorage.getItem('center_id'),
      image: null,
      taskStatus1 : '',
      taskStatus2 : '',
      taskStatus3 : '',
      taskStatus4 : '',
      taskStatus5 : '',
      isClicked1:false,
      isClicked2:false,
      isClicked3:false,
      isClicked4:false,
      isClicked5:false
    }
  }

  componentDidMount() {
    console.log("landing testing");

    this.mongoCheckTask1();
    this.mongoCheckTask2();
    this.mongoCheckTask3();
    this.mongoCheckTask4();
    this.mongoCheckTask5();

  }


   formatDate = (getdate) => {
    const timestamp = getdate;
    const dateObj = new Date(timestamp);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });

    const now = new Date();
    const diffInSeconds = Math.floor((now - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
        return "(Just now)";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr ago`;
    } else if (diffInDays === 1) {
        return "Yesterday";
    } else if (diffInDays < 30) {
        return `${formattedDate} ${formattedTime}`;
    } else if (diffInMonths < 12) {
        return `${formattedDate} ${formattedTime}`;
    } else {
        return `${formattedDate} ${formattedTime}`;
    }
};



  mongoCheckTask1 = () => {
    axios.get(`${common.mongoCheckTask1}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya1" , res?.data?.data?.updated_on);
      if(res?.data?.data?.updated_on){
        this.setState({taskStatus1 : this.formatDate(res?.data?.data?.updated_on)})
      }else{
        this.setState({taskStatus1 : ''})
      }
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }


  mongoCheckTask2 = () => {
    axios.get(`${common.mongoCheckTask2}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya2" , res?.data?.data?.updated_on);
      if(res?.data?.data?.updated_on){
        this.setState({taskStatus2 : this.formatDate(res?.data?.data?.updated_on)})
      }else{
        this.setState({taskStatus2 : ''})
      }
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoCheckTask3 = () => {
    axios.get(`${common.mongoCheckTask3}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya3" , res?.data?.data?.updated_on);
      if(res?.data?.data?.updated_on){
        this.setState({taskStatus3 : this.formatDate(res?.data?.data?.updated_on)})
      }else{
        this.setState({taskStatus3 : ''})
      }
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoCheckTask4 = () => {
    axios.get(`${common.mongoCheckTask4}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya4" , res?.data?.data?.updated_on);
      if(res?.data?.data?.updated_on){
        this.setState({taskStatus4 : this.formatDate(res?.data?.data?.updated_on)})
      }else{
        this.setState({taskStatus4 : ''})
      }
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoCheckTask5 = () => {
    axios.get(`${common.mongoCheckTask5}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya5" ,res?.data?.data?.updated_on);
      if(res?.data?.data?.updated_on){
        this.setState({taskStatus5 : this.formatDate(res?.data?.data?.updated_on)})
      }else{
        this.setState({taskStatus5 : ''})
      }
      
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }
 

  mongoAssessClick = () => {
    this.setState({isClicked1:true});
      axios.get(`${common.mongoAssessApi}`,
      {headers: {
        'Authorization': 'token ' + localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
      }).then(res => {
        console.log("response Ayya" , res);
        this.setState({isClicked1:false})
      }).catch(err => {
        console.log('------Error', JSON.stringify(err));
        console.log('error', err.status)
       
      })
    }
  
  

  mongoQuestionClick = () => {
    this.setState({isClicked2:true});
    axios.get(`${common.mongoQuestionApi}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya" , res);
      this.setState({isClicked2:false})
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoQuickLinkClick = () => {
    this.setState({isClicked3:true});
    axios.get(`${common.mongoQuickLinkApi}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya" , res);
      this.setState({isClicked3:false})
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoContentCompClick = () => {
    this.setState({isClicked4:true});
    axios.get(`${common.mongoContentCompApi}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
      console.log("response Ayya" , res);
      this.setState({isClicked4:false})
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }

  mongoLogsClick = () => {
    this.setState({isClicked5:true});
    axios.get(`${common.mongoLogsApi}`,
    {headers: {
      'Authorization': 'token ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    }
    }).then(res => {
        console.log("response Ayya" , res);
        this.setState({isClicked5:false});
    }).catch(err => {
      console.log('------Error', JSON.stringify(err));
      console.log('error', err.status)
     
    })
  }


	render() {
    if(this.props.loading) {
      return (
        <div className="admin-div">
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>Build Cache</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search Build Cache"/>
        <div className="buildCacheWrapper">
            <h2>Build Cache</h2>
            <ol>
                <li className={`${this.state.isClicked1 ? 'clicked-class' : ''}`}>
                  <div>Update Assessment Report</div>
                  <div>
                    {this.state.taskStatus1 == "" && 
                      "never Initiated"
                    } 
                    {this.state.taskStatus1 != "undefined" && this.state.taskStatus1}
                     <button className={`${this.state.isClicked1  ? 'is_disabled' : ''}`} onClick={() => this.mongoAssessClick("mongo_assess")}>Migrate</button><i className='LoaderImage'><img src={LoaderImage}/></i></div>
                </li>
                <li className={`${this.state.isClicked2  ? 'clicked-class' : ''}`}>
                  <div>Update Assessment detailed Report</div>
                  <div>
                  {this.state.taskStatus2 == "" && 
                      "never Initiated"
                    } 
                    {this.state.taskStatus2 != "undefined" && this.state.taskStatus2}
                    <button className={`${this.state.isClicked2  ? 'is_disabled' : ''}`} onClick={() => this.mongoQuestionClick("mongo_question")}>Migrate</button><i className='LoaderImage'><img src={LoaderImage}/></i></div>
                </li>
                <li className={`${this.state.isClicked3  ? 'clicked-class' : ''}`}>
                  <div>Update Quick Links</div>
                  <div>
                  {this.state.taskStatus3 == "" && 
                      "never Initiated"
                    } 
                    {this.state.taskStatus3 != "undefined" && this.state.taskStatus3}
                    <button className={`${this.state.isClicked3  ? 'is_disabled' : ''}`} onClick={() => this.mongoQuickLinkClick("mongo_quick_link")}>Migrate</button><i className='LoaderImage'><img src={LoaderImage}/></i></div>
                </li>
                <li className={`${this.state.isClicked4  ? 'clicked-class' : ''}`}>
                  <div>Study Material Reading Report</div>
                  <div>
                    {this.state.taskStatus4 == "" && 
                      "never Initiated"
                    } 
                    {this.state.taskStatus4 != "undefined" && this.state.taskStatus4}
                    
                    <button className={`${this.state.isClicked4  ? 'is_disabled' : ''}`} onClick={() => this.mongoContentCompClick("mongo_content_comp")}>Migrate</button><i className='LoaderImage'><img src={LoaderImage}/></i></div>
                </li>
                <li className={`${this.state.isClicked5  ? 'clicked-class' : ''}`}>
                  <div>User Access Log Report</div>
                  <div>
                    
                     {this.state.taskStatus5 == "" && 
                      "never Initiated"
                    } 
                    {this.state.taskStatus5 != "undefined" && this.state.taskStatus5}
                    
                    <button  className={`${this.state.isClicked5  ? 'is_disabled' : ''}`} onClick={() => this.mongoLogsClick("mongo_logs")}>Migrate</button> <i className='LoaderImage'><img src={LoaderImage}/></i></div>
                </li>
            </ol>
        
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage:state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { updateCenter, getProfile,getPageLanguageWiseData} = actions;
  return {
    updateCenter: (center_id, name, code, country, state, city, logo, admin) => dispatch(updateCenter(center_id, name, code, country, state, city, logo, admin)),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildCache);