import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
// import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';

const lec_modes = {
  2: 'Live',
  0: 'Classroom',
  1: 'Online'
}

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count
    }
  }
  
  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance =  this.startTime - end.getTime();

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
       
      </span>
    )
  }
}

class FacultyAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lectures: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      center_id: localStorage.getItem('center_id'),
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      id:0,
      classs: '',
      batch: 0,
      classes: [],
      batches: [],
      batchesFilter: [],
      subjects: [],
      subject:0,
      subjectsFilter: [],
      is_recent:0,
      activeDate:null,
      record_last_date:null,
      is_on_change:false,
      id:0,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let year = parseInt(d.getFullYear())
    let month = parseInt(d.getMonth())
    let day = parseInt(d.getDate())

   this.setState({start_date:date, activeDate: new Date(year,month,day)});
   this.getCurrentDate();
   this.getFacultyProfile();
   this.getCurrentDate();
   this.getAssignmentList(this.state.center_id,this.state.subject,this.state.classs,false,this.state.batch,date,'forward');
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  batches: response.data.batches });
  }

   getAssignmentList = async (center_id,subject_id,class_id,is_loading,section_id,start_date,direction) => {
    const response = await this.props.facultyAssignmentList(center_id,subject_id,class_id,is_loading,section_id,start_date,direction);
    if(response){
      this.setState({lectures:response.data ? response.data:"", loading: false,countBottom:1,id:response.data?response.data[0]?.planner_id:"NA",record_last_date:response.data?response.data[0]?.scheduled_date :""})
      this.ifRecordsExists();

      if(!response.data.length){

        this.setState({id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getAssignmentListOnBackward(this.state.center_id,this.state.subject,this.state.classs,false,this.state.batch,currentDate,'backward');
        //this.getFacultyLectureListOnBackward(this.state.subject,this.state.batch,currentDate,15,'backward',false);
      }
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getAssignmentListOnBackward = async (center_id,subject_id,class_id,is_loading,section_id,start_date,direction) => {
    const response = await this.props.facultyAssignmentList(center_id,subject_id,class_id,is_loading,section_id,start_date,direction);
    if(response) {
      this.setState({
        lectures: response.data?response.data.concat(this.state.lectures):this.state.lectures,
        loading: false,countTop:1,is_on_change:false
      });
      //this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  onChange = value => {

    console.log(value)

    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');

    localStorage.setItem("record_date","");
    this.setState({ hasMore: true,countTop:0,current_date:calendarDate,lectures:[],is_on_change:true});
    this.setState({open:false})
    
    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });

    this.setState({start_date:date,loading:false,is_recent:1})
    this.setState({ hasMore: false,countTop:0,current_date:calendarDate});

    this.getAssignmentList(this.state.center_id,this.state.subject,this.state.classs,true,this.state.batch,date,'forward');
    //this.getFacultyLectureList(this.state.subject,this.state.batch,date,15,'forward',true);
  }

  switchToRecent= () => {
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let year = parseInt(d.getFullYear())
    let month = parseInt(d.getMonth())
    let day = parseInt(d.getDate())
    localStorage.setItem("record_date","");
   this.setState({is_on_change:false,start_date:date,value:new Date(date),activeDate: new Date(year,month,day),is_recent:0,loading:true,countTop:0,lectures:[],subject:0,batch:0,classs:0})
   this.setState({ subjectsFilter: [],batchesFilter: []});
   this.getCurrentDate();
   this.getAssignmentList(this.state.center_id,0,0,false,0,date,'forward');
   //this.getFacultyLectureList(0,0,date,15,'forward',true);
  }

  getCurrentDate =() => {
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({current_date:date,date:currentDate});
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    console.log(textContent);
    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();
    
    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  } 

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  handleScroll = (e) => {

    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.lectures && this.state.lectures.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.scheduled_date)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      //this.setState({"loading":true})
      if(this.state.countTop===0){

        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getAssignmentListOnBackward(this.state.center_id,this.state.subject,this.state.classs,false,this.state.batch,currentDate,'backward');
      }
    }
  }

  ifRecordsExists = () =>{

      var val=this.state.start_date;
      var d = new Date(val);
      var dval = new Date(this.state.record_last_date);

      let arr = [];
      const month=this.state.mlist;


      var d1 = new Date(month[d.getMonth()] +"-"+d.getFullYear());
      var d2 = new Date(month[dval.getMonth()] +"-"+dval.getFullYear());

      var months;
      var html;
      var temparray =[];

      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      console.log(months <= 0 ? 0 : months);

      for(let i=0; i<months;i++){
        
        html = month[d.getMonth()+i]+' '+d.getFullYear();
         //html += '<div><div class="dash-test-first">'+month[d.getMonth()+i]+' '+d.getFullYear()+' </div><div class="dash-test-no-found">No Assignment Planned</div></div>';
         //localStorage.setItem("record_date",scheduled_date)

         temparray.push(html)
      }

      this.setState({current_date_records:temparray})
  }

  saveQuestionTime = (time_taken,difference) => {
   if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) {
    setTimeout(function(){ window.location.reload(true) },60000);
   }
  }
  
  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: [], subject: 0,
                        batchesFilter: [], batch: 0 });
    }

    this.setState({ hasMore: false,countTop:0});
    this.getAssignmentList(this.state.center_id,this.state.subject,parseInt(e.target.value),false,this.state.batch,this.state.start_date,'forward');
    this.setState({is_recent:1});
  }

  onSubjectChange = (e) => {
    this.setState({ subject:parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getAssignmentList(this.state.center_id,parseInt(e.target.value),this.state.classs,false,this.state.batch,this.state.start_date,'forward');
    //this.getFacultyLectureList(parseInt(e.target.value),this.state.batch,this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  onSectionChange = (e) => {
    this.setState({ batch: parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getAssignmentList(this.state.center_id,this.state.subject,this.state.classs,false,parseInt(e.target.value),this.state.start_date,'forward');
    //this.getFacultyLectureList(this.state.subject,parseInt(e.target.value),this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  //onActiveStartDateChange = ({ activeStartDate, value, view }) => alert('Changed view to: ', value, view)

  render() {
    const {activeDate,value} = this.state;

    
    return (
      <div className="app-body" id="appBody" onScroll={this.handleScroll}>
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>Lectures - Faculty</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="page-header-faculty">
            <h1 className='headingTitle paddingBottom10'>Assignments</h1>
          <div className="page-header">
              <div className="react-calendar-date" onClick={this.openCalendar}
                ref={this.wrapperRef}>
                {this.state.current_date}<br/>
                <img src={Calendar2} alt="cal"/>
              </div>
              <div className="page-header-right">
                <select onChange={this.onClassChange} value={parseInt(this.state.classs)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_class}
                  </option>
                  {this.state.classes.map(classs => 
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} value={parseInt(this.state.batch)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_section}
                  </option>
                  {this.state.batchesFilter.map(batch => 
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                <select onChange={this.onSubjectChange} value={parseInt(this.state.subject)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_subject}
                  </option>
                  {this.state.subjectsFilter.map(subject =>
                    <option value={parseInt(subject.id)} key={subject.id}>
                      {subject.label}
                    </option>
                  )}
                </select>
              </div>
              </div>
              {this.state.is_recent?
                <div className="back-to-recent-faculty" onClick={this.switchToRecent}>
                  Today
                </div>:""
              }
            </div>
             {!this.state.loading && <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
              ref={this.wrapperRef}>
              <Calendar
                onChange={this.onChange}
                defaultValue={value}
                defaultActiveStartDate = {activeDate}
              />
            </div>}
            {!this.state.loading && <div className="list-sticky notwidgetheader">
           { this.state.lectures && this.state.lectures.length <=2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}

              {this.state.lectures && !this.state.lectures.length && <Empty/>}
              <div className="list-content padding20">
                {this.state.is_on_change && this.state.current_date_records.map((data,index)=>
                  <div> 
                      <div><div className="dash-test-first">{data}</div><div className="dash-test-no-found">No Assignments Planned</div></div>
                  </div>
                )}

                
                {this.state.lectures && this.state.lectures.map((lecture,index) =>
                  <div className="list-div-x2" id={lecture.planner_id}>
                    {lecture.scheduled_date && <StartTimer startTime={lecture.scheduled_date} onChange={this.saveQuestionTime} 
                     count={0}/>}

                    <div className="dash-test-first">
                      {index === 0 ? <>
                        <span>{this.getTime(lecture.scheduled_date)[0]} {this.getTime(lecture.scheduled_date)[5]} </span>
                        <span>{this.getTime(lecture.scheduled_date)[1]}</span>
                      </> : this.checkDateTime(lecture.scheduled_date, 
                        this.state.lectures[index - 1].scheduled_date) ? null : <>
                        <span>{this.getTime(lecture.scheduled_date)[0]} {this.getTime(lecture.scheduled_date)[5]}</span>
                        <span>{this.getTime(lecture.scheduled_date)[1]}</span>
                      </>}
                    </div>
                    <div className="dash-test" id={lecture.planner_id==this.state.id?"conter":""} key={index} 
                      Style={this.state.date==this.getTime(lecture.scheduled_date)[2] && this.getTime(lecture.scheduled_date)[3]?"background-color:#fff6e4":""}>
                      <div>
                        <div Style="display: inline-block;">
                          <span Style="font-size:17px">{lecture.title}</span><br/><br/>
                          <span style={{color: '#777'}}>
                            {this.getTimeAMPMFormat(new Date(lecture.scheduled_date))} | {lecture?.duration_hrs} Hrs
                          </span>
                        </div>
                        
                      </div>
                      <div className="dash-test-bottom">
                        <div>
                          <span>{lecture.classs} | {lecture.batch} | {lecture.subject}</span>
                        </div>
                        <div className="faculty-dash-actions">
                          {lecture.action && lecture.action.map(actions =>
                            <Link key={lecture.id} to={{pathname: `/faculty/assignment-review/${lecture.id}`,
                              state:{action:actions.label,subject:lecture.subject,id:lecture.subject_id}}}>
                              <button>
                                {actions.label}
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> 
                 
                )}
                  { this.state.lectures && this.state.lectures.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                
                 </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyLectureList ,facultyAssignmentList} = actions;
  return {
    facultyAssignmentList : (center_id,subject_id,class_id,is_loading,section_id,start_date,direction) => dispatch(facultyAssignmentList(center_id,subject_id,class_id,is_loading,section_id,start_date,direction)),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyLectureList: (subject_id,batch_id,date,days,direction,if_loading) => dispatch(getFacultyLectureList(subject_id,batch_id,date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyAssignment);