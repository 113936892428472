import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MonthTable from '../../components/temp';
import Profile from '../../public/icons/profile.svg';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Right from '../../public/icons/right-arrow-black.svg';
import Target from '../../public/icons/target.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';


class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count,

    }
  }
  
  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance =  this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
       
      </span>
    )
  }
}

class DashboardFaculty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      profile: {},
      subjects: [],
      batches: [],
      practices: [],
      lecture_dpps: [],
      batchArrayList :[],
      open:false,
      batchDialogOpen:false,
      batchListArray :[],
      mode:['CLASSROOM','WEBLINK','LIVE'],
    }
    this.questionTimer = React.createRef();
  }
  
  componentDidMount() {
    //localStorage.setItem('is_faculty_admin',"false");
    this.getFacultyProfile();
    this.show2();
  }

  getBatches = (classId) => {
    console.log(classId);
    this.state.batchListArray = [];
    if(this.state.batches && this.state.batches.length){
      for(let i = 0; i < this.state.batches.length; i++) {
        if(parseInt(classId) === parseInt(this.state.batches[i].classs.id)) {
          this.state.batchListArray.push(this.state.batches[i]);
          this.setState({batchDialogOpen:true});
          document.body.classList.add('batches-dialog-open');
          //return this.state.batches[i].label;
        }
      }
    
    }
  }

  batchDialogClose = ()=>{
    this.setState({batchDialogOpen:false});
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ profile: response?.data, subjects: response?.data.subjects,batches: response?.data.batches })
    this.getDashboard();
  }

  /*getDashboard = async () => {
    const response = await this.props.getFacultyDashboard();
    this.setState({ lecture_dpps: response.data.last_activity, loading: false });
  }*/
  getDashboard = async () => {
    const response = await this.props.getFacultyDashboard();
    if(response)
      this.setState({ lecture_dpps: response.data.lecture_dpps,practices:response.data.practice_analysis,loading: false });
  }

  getMode = (val) => {
     return this.state.mode[val];
  }

  show2 = () => this.setState({ open: true });

  getTime = (val) => {
    var date = new Date(val);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return timewithampm.toUpperCase();
  }

  /*convertedHour = (minutes) =>{    
    return Math.floor(minutes / 60);
  }*/

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +this.props.saveLanguageWiseData.hrs:minutes + this.props.saveLanguageWiseData.mins;
  }

  sectionName = (classId,type=null) => {
    let  batchArrayList  = [];
    if(this.state.batches && this.state.batches.length){

      if(type == 'hover' && this.state.batches.length > 2){
        for(let i = 2; i < this.state.batches.length; i++) {
          if(parseInt(classId) === parseInt(this.state.batches[i].classs?.id)) {
            batchArrayList.push(this.state.batches[i].label)
            //return this.state.batches[i].label;
          }
        }
      }else{
        for(let i = 0; i < 3; i++) {
          if(this.state.batches[i]?.classs){
            if(parseInt(classId) === parseInt(this.state.batches[i].classs?.id)) {
              batchArrayList.push(this.state.batches[i].label)
              //return this.state.batches[i].label;
            }
          }
        }
      }
      return batchArrayList;
    }
  }

  saveQuestionTime = (time_taken,difference) => {
    console.log(Math.floor(time_taken/(1000*60)))
    /*setInterval(function() {
      if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) window.location.reload();
    }, 60000);  */
     if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) {
      setTimeout(function(){ window.location.reload(true) },60000);
     }
    //
  }

  // zoomAttendance = (faculty_id,room,lectureURL) =>{
  //   let facultyID = faculty_id
  //   let roomID = room
  //   if(facultyID,roomID,lectureURL){
  //     this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
  //   }
    
  // }

  zoomAttendance = async(faculty_id,room,lectureURL,lecture_id) =>{
    let facultyID = faculty_id
    //let roomID = room
    let lectureID = lecture_id
    console.log("lectureID",lectureID);
    
    if(lectureID){
      const response = await this.props.facultyZoomCreate(lectureID);
      console.log("response",response)
      if(response !== undefined || response?.status == 1){
        let lectureURL = response.data.url;
        let roomID = response.data.meeting_id;
        console.log("lectureURLdddd",lectureURL)
        console.log("roomID",roomID)
        if(facultyID,roomID,lectureURL){
          this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
        }
      }
    }


    
    
  }
 

  // zoomAttendanceAPI = async (faculty_id,room,lectureURL) =>{
  //   this.setState({currentLectureURL:lectureURL});
  //   const response = await this.props.callFacultyzoomAttendanceAPI(faculty_id,room);
  
  //     this.setState({is_attendanceURL:false});
  //     console.log("faculty_id", faculty_id);
  //     console.log("room", room);
  //     console.log("currentLectureURLlllll", this.state.currentLectureURL);
  //     window.open(this.state.currentLectureURL,"_blank");
  //  }

  zoomAttendanceAPI = async (faculty_id,room,lectureURL) =>{
    this.setState({currentLectureURL:lectureURL});
    const response = await this.props.callFacultyzoomAttendanceAPI(faculty_id,room);
      this.setState({is_attendanceURL:false});
      console.log("faculty_id", faculty_id);
      console.log("room", room);
      console.log("currentLectureURLlllll", this.state.currentLectureURL);
      //window.open(this.state.currentLectureURL,"_blank");
      window.open(lectureURL,"_blank");
   }

	render() {

    const { batchArrayList,batches } = this.state

    if(this.props.loading || this.state.loading) {
      return (
        <div className="app-body">
          <div className="app-div">
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
          </div>
        </div>
      )
    }
		return (
			<div className="app-body">
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>{this.props.saveLanguageWiseData.dashboard} - {this.props.saveLanguageWiseData.faculty}</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="dashboard-main">
          <div className={this.state.batchDialogOpen ? 'batchesDialogWrapper open' : 'batchesDialogWrapper'}>
                  <div className='batchesDialogContainer'>
                      <h3>{this.props.saveLanguageWiseData.batches_in} {this.state.batchListArray.length && this.state.batchListArray[0].classs.label } <span  onClick={this.batchDialogClose}>X</span></h3>
                      <ol>
                        {this.state.batchListArray.length && this.state.batchListArray.map(batches =>
                            <li>{batches.label}</li>
                            )}  
                        </ol>
                  </div>
            </div>  
            <div className="dashboard-left">
              <h1>{this.state.profile?.classs?.label} {this.props.saveLanguageWiseData.subjects}</h1>
              <div className="dashboard-subjects">
                {this.state.subjects.map(subject =>
                 <div className='subjectsWrapper'  style={{backgroundColor: subject.background_code}}>
                   <div className='dash-subject-fac'>
                    <Link  key={subject.id} 
                      to={{ pathname: `/faculty/study-material/${subject.id}`,
                      state: {subject_label: subject.label}}}
                      >
                      <div>
                        <img src={common.addFileUrl(subject.url)} alt="sub"/>
                      </div>
                      <span>{subject.label}</span>
                    </Link>
                  </div>
                  
                  <div className='subjectBatchesLink'><span  onClick={()=>{this.getBatches(subject.class_id)}}>{this.props.saveLanguageWiseData.view_batches}</span></div>
                  </div>
                )}
              </div>
              {this.state.practices.length > 0 && <div className="dashboard-exercise">
                  <Link to={`/faculty/practice`}>
                    <h1>{this.props.saveLanguageWiseData.practice}</h1>
                    <img src={Right} alt="img"/>
                  </Link>
                  <div className="dashboard-practices">
                    {this.state.practices.map((practice, index) =>
                      <div className="dash-practice" key={index}
                        id={practice.label === 'Attempt' ? "dash-practice1" : ""} Style={practice.is_attempted?`background-color:var(--primary-rgba)`:`background-color: var(--primary-rgba);`}>
                        <div>
                          <div Style="background-image:url(/static/media/target.556d756c.svg);width: 50px;height: 50px;background-repeat: no-repeat;background-size: contain;order: 2;opacity:0.4"></div>
                          <div Style="flex: 1;display: flex;flex-direction: column;order: 1;">
                            <div Style="display: inline-block;">
                              <span className="dash-flex2-title-1">{practice.title}</span>
                              <span Style="font-size: 13px;">
                                <span Style="color:var(--black)">{this.props.saveLanguageWiseData.average_score} :</span><span Style="color:var(--primary)">{practice.average_score}</span>
                              </span>
                              <span class="dash-flex2-title-label">{practice.class_name} | {practice.section_name}</span>
                            </div>
                          </div>
                        </div>

                        <div className="dash-fac-detail">
                          <div>
                             <Link key={practice.id} to={{pathname: `/faculty/assessment-review/${practice.id}`,
                              state: { batch: practice?.batch_id, modal: true }}}>
                              <button Style={practice.is_attempted?`background-color:var(--primary)`:`background-color:var(--primary)`}>
                                {practice.action_label}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>}
            </div>
            <div className="dashboard-right">
              <div className="dashboard-profile">
                <div className="dash-prof-img">
                  {this.state.profile?.profile_picture === '' 
                    || this.state.profile?.profile_picture === null
                    ? <img src={Profile} alt="img"/>
                    : <div className="dash-prof-img-in" style={{backgroundImage: 
                    `url(${common.addFileUrl(this.state.profile?.profile_picture)})`}}>
                  </div>}
                </div>
                <div className="dash-prof-name faculty-prof-name">{this.state.profile?.name}</div>
                <div className="drop-profile-creds-faculty">
                  <div className="">{this.props.saveLanguageWiseData.teacher}</div>
                </div>
                <div>{this.state.profile?.mobile}</div>
                <hr Style="width: 34px;"/>
                <div>{this.state.profile?.email}</div>
              </div>

              {this.props.logindetails.institute_settings.dashboard_setting && JSON.parse(this.props.logindetails.institute_settings.dashboard_setting).course_block ? <div className="dashboard-schedule newDesign">
               <div className="dash-sch-head">
                  <div>
                    <span>{this.props.saveLanguageWiseData.scheduled}</span>
                    {this.props.saveLanguageWiseData.recent_activities}
                  </div>
                </div>
                {this.state.lecture_dpps.map(lecture => {
                    if(lecture.id !== undefined) {
                      return <div className="dash-lecture newDesign" key={lecture.id}>
                      {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime} 
                       count={0} ref={this.questionTimer}/>}
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.start_date_time)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.start_date_time)).getMonth() + 1]}</span>
                          {this.getTime(lecture.start_date_time)}
                        </div>
                        <div className="newDesign">
                          <div className="actions">
                            <div className="testType"> 
                              {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                            </div>
                          </div>
                        <div className="dash-lecture-date-title">
                          <div className="dash-lec-title">{lecture.title}</div>
                          <div className="newDesign">
                          {/* <div className="dash-lec-title-hrs">{lecture.classs} | {lecture.batch}| {lecture.subject}</div> */}
                          <div className="dash-lec-title-hrs">{this.props.saveLanguageWiseData.batch} - {lecture.batch}</div>
                          <div className="dash-lec-title-hrs">{lecture.duration_hrs} {this.props.saveLanguageWiseData.hrs}</div>
                          {lecture.mode == 0 &&<div className="dash-lec-title-hrs">{lecture.url}</div>}
                          </div>
                        </div>
                        <div className="dash-lecture-date-third">
                        {lecture.action_label != "Join Class" &&<span><a href={lecture.record_url} target="_blank">{lecture.action_label != "Join Class" && lecture?.action_label}</a></span>}
                          {/* <div> 
                            {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                          </div> */}
                          <div Style={lecture.action_label && lecture.action_label === "Join Class"  ? "display:flex":"display:none"}>
                            {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label 
                              !== undefined && lecture.action_label === "Join Class" &&  lecture.liveClass_server == 3 
                              ? <a href="#" onClick={() => this.zoomAttendance(lecture.faculty_user_id, lecture.room, lecture.url, lecture.id)}>
                              {lecture.action_label}
                            </a> : <a href={lecture.url} target="_blank">
                              {lecture.action_label}
                            </a>}
                          </div>
                        </div>
                      </div>
                      </div>
                    }
                    else {
                      return <div className="dash-lecture newDesign" key={lecture.content_id}>
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.due_date)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.due_date)).getMonth() + 1]}</span>
                          {this.getTime(lecture.due_date)}
                        </div>
                        <div className="newDesign">
                        <div className="actions">
                            <div className="testType"> 
                              {lecture.type === 'DPPPlanner' ? <span Style="">{this.props.saveLanguageWiseData.real_test}</span> : null}
                            </div>
                          </div>
                        <div className="dash-lecture-date-title">
                       
                          <div className="dash-lec-title">{lecture.title}</div>
                          <div className="newDesign">
                          <div className="dash-lec-title-hrs">{this.props.saveLanguageWiseData.batch} - {lecture.batch_name}</div>
                          {/* <div className="dash-lec-title-hrs">{lecture.subject_name}</div> */}
                          <div className="dash-lec-title-hrs">{this.convertedHour(lecture.duration_mins)}</div>
                          {/* <span className="dash-lec-hrs">
                            {lecture.class_name} | {lecture.batch_name}| {lecture.subject_name}
                          </span> */}
                          
                          
                          {/* {lecture.actions.map(action =>
                            <Link index={action.id} to={action.label.toLowerCase() == 'review' 
                              || action.label.toLowerCase() == 'result' ? {pathname :`/learner/assessment-review/${lecture.content_id}`
                              ,state: {modal: true}} : `/learner/attempt/${lecture.content_id}`}>
                              {action.label}
                            </Link> 
                          )} */}
                          </div>
                        </div>
                        <div className="dash-lecture-date-third">
                          {/* <div Style="background-color:#fff6e4;font-family: lato;"> 
                            {lecture.type === 'DPPPlanner' ? <span Style="">REAL TEST</span> : null}
                          </div> */}
                          <div Style={lecture.action_label ? "display:flex":"display:none"}>
                            <Link key={lecture.content_id} to={{pathname: `/faculty/assessment-review/${lecture.content_id}`,
                              state: { batch: lecture?.batch_id, modal: true }}}>
                                {lecture.action_label}
                            </Link>
                          </div>
                        </div>
                      </div>
                      </div>
                    }
                  })}
              </div>:""}
            </div>
          </div>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    logindetails:state.commonReducer.logindetails,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyDashboard, getFacultyProfile,callFacultyzoomAttendanceAPI,facultyZoomCreate } = actions;
  return {
    getFacultyDashboard: () => dispatch(getFacultyDashboard()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    callFacultyzoomAttendanceAPI : (faculty_id,room) => dispatch(callFacultyzoomAttendanceAPI(faculty_id,room)),
    facultyZoomCreate : (lecture_id) => dispatch(facultyZoomCreate(lecture_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFaculty);