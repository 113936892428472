import React, { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import { useDispatch } from 'react-redux';
import actions from '../../../redux/actions';
import reportsLoader from '../../../public/icons/loader.png';


export const CandidateEnrolment = () => {
    const { dashboardUserEnrollement } = actions;
    const dispatch = useDispatch()
    const [resp, setResp] = useState()
    const [loading, setLoading] = useState()
    const [config, setConfig] = useState(null)


    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await dispatch(dashboardUserEnrollement())

            setConfig({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ' '
                },
                xAxis: {
                    categories: Object.keys(response.data?.graph_data)
                },
                yAxis: {
                    title: {
                        text: 'Values'
                    }
                },
                plotOptions: {
                    column: {

                        borderRadius: 5, // Set the border radius for rounded corners

                    }
                },
                series: [
                    {
                        name: 'Students',
                        data: Object.keys(response.data?.graph_data).map((key) => response.data?.graph_data[key]),
                        color: '#394CFF'
                    },

                ]
            })
        }
        catch (e) {

        }
        finally {
            setLoading(false)
        }


    }

    useEffect(() => {
        fetchData()
    }, [])
    return (


        <div style={{ width: '100%' }}>
            <h3 className='graphTitle'>Candidate Enrolment</h3>
            {
                config ? <ReactHighcharts config={config} /> : <div className="loader">
                    <img style={{ width: '60px' }} src={reportsLoader} />
                </div>
            }
        </div>
    )
}
