import React from 'react';
import './UniversityList.css';

const universities = [
  {
    id: 1,
    name: "Sir MV School of Architecture",
    location: "Bangalore",
    applicationFees: "N/A",
    logo: "https://c0.piktochart.com/v2/themes/3383-creative-new-employee-onboarding/snapshot/large.jpg",
    programs: [
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Class 3",
        description: "A comprehensive course on architecture",
        price: "1000$",
        skills: "Python, Pandas, Numpy",
        image: null,
      },
      {
        id: 2,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Class 4",
        description: "Introduction to modern architecture",
        price: "1200$",
        skills: "AutoCAD, SketchUp",
        image: null,
      },
      {
        id: 2,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Class 4",
        description: "Introduction to modern architecture",
        price: "1200$",
        skills: "AutoCAD, SketchUp",
        image: null,
      },
    ],
  },
  {
    id: 2,
    name: "Unitedworld Institute of Design",
    location: "Ahmedabad",
    applicationFees: "Rs. 2000",
    logo: "https://c0.piktochart.com/v2/themes/3383-creative-new-employee-onboarding/snapshot/large.jpg",
    programs: [
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
    ],
  },

  {
    id: 2,
    name: "Unitedworld Institute of Design",
    location: "Ahmedabad",
    applicationFees: "Rs. 2000",
    logo: "https://c0.piktochart.com/v2/themes/3383-creative-new-employee-onboarding/snapshot/large.jpg",
    programs: [
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
      {
        id: 1,
        logo:'https://cdn.pixabay.com/photo/2019/01/23/21/16/pixabay-3951079_1280.png',
        label: "Design Basics",
        description: "A foundation course on design",
        price: "1500$",
        skills: "Adobe Photoshop, Illustrator",
        image: null,
      },
    ],
  },
  // Add more university objects here...
];

const UniversityList = () => {
  return (
    <div className="university-list-container">
      <h2>Available Universities</h2>
      <div className="university-list">
        {universities.map((uni) => (
          <div key={uni.id} className="university-card">
            <img src={uni.logo} alt={uni.name} className="university-logo" />
            <div className="university-details">
              <h3>{uni.name}</h3>
              {/* <p>{uni.location}</p>
              <p>Application Fees: {uni.applicationFees}</p> */}
              <div className="programs-container">
                {uni.programs.length > 0 ? (
                  <div className="programs-list">
                    {uni.programs.map((program) => (
                      <div key={program.id} className="program-card">
                        <img src={program.logo} className="program-logo" />
                        <div className='program-info'>
                        <h4>{program.label}</h4>
                        <p>{program.price}</p>
                        <p>{program.skills}</p>
                        </div>
                        
                       
                      </div>
                      
                      
                    ))}
                  </div>
                ) : (
                  <p>No programs available</p>
                )}
              </div>
              {/* <button className="details-button">View Details</button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniversityList;
