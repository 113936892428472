import './AttendanceReport.css';
import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { connect } from 'react-redux';

const UserAttendanceReport = (props) => {
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectEndDate, setSelectEndDate] = useState('');
    const [classList, setClassList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);

    const { studentAttendanceReport, getClassList, getSectionList2, getSubjectList2 } = props;

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getClassList();
                setClassList(response.data);
            } catch (error) {
                console.error("Failed to fetch class list:", error);
            }
        };
        fetchClassList();
    }, [getClassList]);

    const fetchAttendanceReport = async () => {
        try {
            const attendanceResp = await studentAttendanceReport(selectedSubject, selectedClass, selectedBatch, selectedDate,selectEndDate);
            if (attendanceResp && attendanceResp.data && attendanceResp.data.attendance_data) {
                setAttendanceData(attendanceResp.data.attendance_data);
            } else {
                setAttendanceData([]);
            }
        } catch (error) {
            console.error("Failed to fetch attendance report:", error);
            setAttendanceData([]);
        }
    };

    const onClassChange = async (e) => {
        const selectedClassId = e.target.value;
        setSelectedClass(selectedClassId);

        try {
            const response = await getSectionList2(3, selectedClassId);
            setBatchList(response.data);
        } catch (error) {
            console.error("Failed to fetch batch list:", error);
        }
    };

    const onBatchChange = async (e) => {
        const selectedBatchId = e.target.value;
        setSelectedBatch(selectedBatchId);

        try {
            const response = await getSubjectList2(3, selectedClass);
            setSubjectList(response.data);
        } catch (error) {
            console.error("Failed to fetch subject list:", error);
        }
    };

    const onSubjectChange = async (e) => {
        const selectedSubjectId = e.target.value;
        setSelectedSubject(selectedSubjectId);
    };

    const onDateChange = (e) => {
        const selectedDate = e.target.value;
        setSelectedDate(selectedDate);

        try {
            fetchAttendanceReport()
        } catch (error) {
            console.error("Failed to fetch attendance report after subject selection:", error);
        }
    };
    const onEndDateChange = (e) => {
        const selectEndDate = e.target.value;
        setSelectEndDate(selectEndDate);

        try {
            fetchAttendanceReport()
        } catch (error) {
            console.error("Failed to fetch attendance report after subject selection:", error);
        }
    };



    const downloadCSV = async () => {
        try {
            const csvContent = convertToCSV(attendanceData);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'attendance_report.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Failed to download CSV:", error);
        }
    };

    const convertToCSV = (data) => {
        const uniqueDates = getUniqueDates();
        const headers = ['S.No', 'Roll No', 'Student Name', 'Email', ...uniqueDates, 'Expected Attendance in Days', 'No of Days Present', 'Present %age'];
        const rows = data.map((student, index) => [
            index + 1,
            student.roll_no || 'N/A',
            student.name,
            student.email,
            ...uniqueDates.map(date => student.attendance[date] ? student.attendance[date].join(', ') : 'Absent'),
            student.total_expected_attendance,
            student.total_class_attended,
            student.attendance_percentage
        ]);

        const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
        return csvContent;
    };

    const getUniqueDates = () => {
        const uniqueDates = new Set();
        attendanceData.forEach(student => {
            Object.keys(student.attendance).forEach(date => {
                uniqueDates.add(date);
            });
        });
        return [...uniqueDates].sort();
    };

    return (
        <div className="admin-div">
            <div className="main-part">
                <div className="sub-head">
                    <div className="attendance-report">
                        <div className='attendance-report-header'>
                            <h1>Attendance Report</h1>   <div className="export-button-container">
                                <button onClick={downloadCSV}>Export</button>
                            </div>
                        </div>
                        <hr />
                        <div className="dropdown-container">
                            <select onChange={onClassChange} value={selectedClass}>
                                <option value="">Select Class</option>
                                {classList.map(classs => (
                                    <option value={classs.id} key={classs.id}>
                                        {classs.label}
                                    </option>
                                ))}
                            </select>

                            <select onChange={onBatchChange} value={selectedBatch}>
                                <option value="">Select Batch</option>
                                {batchList && batchList.map(batchItem => (
                                    <option value={batchItem.id} key={batchItem.id}>
                                        {batchItem.label}
                                    </option>
                                ))}
                            </select>

                            <select onChange={onSubjectChange} value={selectedSubject}>
                                <option value="">Select Subject</option>
                                {subjectList.map(subjectItem => (
                                    <option value={subjectItem.id} key={subjectItem.id}>
                                        {subjectItem.label}
                                    </option>
                                ))}
                            </select>
                            

                            <input type="date" onChange={onDateChange} value={selectedDate} />
                            <input type="date" onChange={onEndDateChange} value={selectEndDate} />


                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Roll No</th>
                                    <th>Student Name</th>
                                    <th>Email</th>
                                    {getUniqueDates().map((date, index) => (
                                        <th key={index}>{date}</th>
                                    ))}
                                    <th>Expected Attendance in Days</th>
                                    <th>No of Days Present</th>
                                    <th>Present %age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendanceData.length === 0 ? (
                                    <tr>
                                        <td colSpan={getUniqueDates().length + 8}>No data available</td>
                                    </tr>
                                ) : (
                                    attendanceData.map((student, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{student.roll_no || 'N/A'}</td>
                                            <td>{student.name}</td>
                                            <td>{student.email}</td>
                                            {getUniqueDates().map(date => (
                                                <td key={date}>
                                                    {student.attendance[date] ? student.attendance[date].join(', ') : 'Absent'}
                                                </td>
                                            ))}
                                            <td>{student.total_expected_attendance}</td>
                                            <td>{student.total_class_attended}</td>
                                            <td>{student.attendance_percentage}%</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { studentAttendanceReport, getClassList, getSectionList2, getSubjectList2 } = actions;
    return {
        studentAttendanceReport: (subject_id, classs_id, batch_id, date,end_date, isDownload) => dispatch(studentAttendanceReport(subject_id, classs_id, batch_id, date,end_date, isDownload)),
        getClassList: () => dispatch(getClassList()),
        getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
        getSubjectList2: (center_id, class_id) => dispatch(getSubjectList2(center_id, class_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAttendanceReport);
