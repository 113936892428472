import React from 'react'
import Header from '../../../components/navigation/Header';
import DashboardOverview from './DashboardOverview.jsx';
import './style.css'
import UserLogins from './UserLogins.jsx';
import LeadingPrograms from './LeadingPrograms.jsx';
import MonthlyUniqueUsers from './MonthlyUniqueUsers.jsx';
import { CandidateEnrolment } from './CandidateEnrolment.jsx';
export const AdminDashbaord = () => {


    return (
        <div className="admin-div">
            <Header placeholder="Search assessments" />
            <div className="main-part bg-color">
                <div className="sub-head">
                    <div className="heading-part1">
                        <div className="heading"><h1>Dashboard</h1></div>
                    </div>
                </div>


                <div className="dashboard-container">
                    <DashboardOverview />

                    <div className="flexCard ">
                        <div className="card">
                            <UserLogins />
                        </div>
                        <div className="card">
                            <LeadingPrograms />
                        </div>
                    </div>

                    <div className="flexCard ">
                        <div className="card">
                            <MonthlyUniqueUsers />
                        </div>
                        <div className="card">
                            <CandidateEnrolment />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


