import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import { parse } from 'date-fns';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      classs: parseInt(this.props.match.params.classs),
      class_id: parseInt(this.props.match.params.classs),
      name: '',
      code: '',
      order: '',
      description: '',
      page_url: this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin: false,
      session: 0,

      isBulkBatchDialogVisible: false,
      bulkBatchUpdateJobData: [],
      bulkBatchUpdateTableShow: false,
      bulkBatchUpdateJobStatus: false,
      bulkBatchUpdateJobCounter: 0,
      bulkBatchUpdateJobStatusError: false,
      saveBatchId: '',

      openDialog: false,
      openThanksDialog: false,
      formBlocks: [{ id: this.generateUID(), title: '', groups: '' }],
      groupTitle: '',
      numberOfGroups: 0,
      noOfStudents: '',
      username: localStorage.getItem('name')
    }
  }

  componentDidMount() {
    localStorage.setItem("page_url", this.state.page_url)

    this.setState({ is_faculty_admin: localStorage.getItem("is_faculty_admin") })
    this.getClassList();
    this.getSectionList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = () => {
    let getUrl = this.props.location.pathname;
    let modifyURL1 = getUrl.split("/");
    let modifyURL = modifyURL1[2];
    let language = localStorage.getItem('lang');
    if (modifyURL) {
      console.log("modifyURL", modifyURL);
      console.log("languagessss", language);
      this.fetchPageWiseData(language, modifyURL);
    }

  }

  generateUID = () => {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
    const randomStr = Math.random().toString(36).substring(2, 8); // Generate random string
    return `${timestamp}-${randomStr}`;
  }


  fetchPageWiseData = async (language, modifyURL) => {
    const response = await this.props.getPageLanguageWiseData(language, modifyURL);
    if (response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData', JSON.stringify(response?.data))


    }
  }


  getClassList = async () => {
    const response = await this.props.getClassList();
    if (response !== undefined) {
      this.setState({ classes: response.data })
    }
  }

  getSectionList = async () => {
    const response = await this.props.getSectionList(this.state.center_id, this.state.class_id, 1, this.state.page_url);
    if (response !== undefined) {
      this.setState({ sections: response.data, session: response.data[0]?.session });
    }
  }

  OpenThanksDialog = (section) => {
    this.setState({ openThanksDialog: true })

  }


  ThanksCloseDialog = () => {
    this.setState({ openThanksDialog: false })

  }




  OpenDialog = (section) => {
    this.setState({ openDialog: true })
    this.setState({ noOfStudents: section });
    this.setState({ formBlocks: [{ id: this.generateUID() }] });

  }

  CloseDialog = () => {
    this.setState({ openDialog: false });
    this.setState({ formBlocks: [{ id: this.generateUID(), title: '', groups: '' }] });
  }


  addDiv = () => {
    // const newId = this.state.formBlocks.length + 1;
    // this.setState({
    //   formBlocks: [...this.state.formBlocks, { id: newId }]
    // });

    this.setState(prevState => ({
      formBlocks: [...prevState.formBlocks, { id: this.generateUID(), title: '', groups: '' }]
    }), () => {
      console.log('Previous state of formBlocks:', this.state.formBlocks);
    });
  }


  handleChange = (id, event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formBlocks: prevState.formBlocks.map(block => block.id === id ? { ...block, [name]: value } : block)
    }));
    let sum = 0;
    const newState = this.state.formBlocks.map(block => block.id === id ? { ...block, [name]: value } : block)
    console.log("newState ", newState)
    newState.forEach((data) => {
      try {
        sum = (parseInt(data.groups) || 0) + sum;
      }
      catch (e) {
        console.error("is not a number ", e);
      }
    })
    this.setState({ numberOfGroups: sum })

  };


  deleteChange = (id, removeValue) => {

    this.setState(prevState => ({
      formBlocks: prevState.formBlocks.filter(block => {
        return block.id != id;
      })
    }));


    

    this.setState(prevState => ({
      numberOfGroups: (parseInt(prevState.numberOfGroups) || 0 )- (parseInt(removeValue) || 0)
    }));


  }

  handleSubmit = (event) => {
    event.preventDefault();
    // Access values from state and perform your submit logic here

    let sum = 0;

    this.state.formBlocks.forEach((data) => {
      try {
        sum = (parseInt(data.groups) || 0) + sum;
      }
      catch (e) {
        console.error("is not a number ", e);
      }
    })



    this.setState({ numberOfGroups: sum })
    console.log('Form values:', this.state.formBlocks);
    console.log('Form values:', sum);

    if (sum <= this.state.noOfStudents) {
      this.setState({ openDialog: false })
      this.setState({ openThanksDialog: true });
      console.log('this.state.sumofGropStudent', this.state.numberOfGroups);
      console.log('this.state.noOfStudents:', this.state.noOfStudents);
    }
    // Example: You might want to send the data to an API or perform further processing
    // Reset form state if needed
    // this.setState({
    //   formBlocks: [{ id: 1, title: '', groups: 0 }],
    //   groupTitle: '',
    //   numberOfGroups: 0
    // });
  };



  render() {
    const isEnabled = this.state.name.length > 0 && this.state.code.length > 0 &&
      this.state.class_id.length !== null;
    if (this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search sections" />
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading marginBottomZero">
                  <h1>{this.props.pagewiseLanguageData.sections}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"} />
              <Shimmer className={"list-item"} />
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"} />
              <Shimmer className={"grid-item"} />
            </div>}
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{`${this.state.class_label} | ${this.props.pagewiseLanguageData.sections}`}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_sections} />


        <div className={this.state.openDialog ? "createGroupDialog open" : "createGroupDialog"} >
          <div className='createGroupDialogContainer'>
            <h3>{this.state.class_label}<span onClick={this.CloseDialog} className='fa fa-times'></span></h3>
            <h6>Faculty - <span>{this.state.username}</span></h6>
            <h5>Breakout rooms / Groups <span>Number of students - {this.state.noOfStudents}</span></h5>
            <div className='formWrapper'>
              <div className='formGroup'>
                {this.state.formBlocks.map((block,index) => (
                  <div className='formBlock'  key={block.id}>
                    <label>Group / Topic Title<input type="text" name="title" value={block.title} onChange={(e) => this.handleChange(block.id, e)} /></label>
                    <label>Number of Groups<input type="number" name="groups" onChange={(e) => this.handleChange(block.id, e)} value={block.groups} /></label>
                    {this.state.formBlocks.length > 1 && index !== 0 && (<i onClick={() => this.deleteChange(block.id,block.groups)} className='fa fa-minus'></i> )}
                  </div> 
                ))}
              </div>

              <div className='addMoreLink'>
                <span>Number of Groups - {this.state.numberOfGroups}</span>
                {this.state.numberOfGroups < this.state.noOfStudents && <span onClick={this.addDiv}><i className='fa fa-plus'></i>Add more</span>}
              </div>

              {this.state.numberOfGroups > this.state.noOfStudents ? <div className='errorMessage'>Number of group is more than number of students.<br />Kindly check</div> : <div></div>}


              <div className='actions'><button className={`${(parseInt(`${this.state.numberOfGroups}`) || 0) <= 0 || this.state.numberOfGroups > this.state.noOfStudents ? 'is_disabled' : ''}`} onClick={this.handleSubmit}>Submit</button></div>
            </div>
          </div>
        </div>


        <div className={this.state.openThanksDialog ? "thanksGroupDialog open" : "thanksGroupDialog "} >
          <div className='thanksGroupContainer'>
            <span onClick={this.ThanksCloseDialog} className='fa fa-times'></span>
            <i className='icon fa fa-thumbs-up'></i>
            <h6>ThankYou</h6>
            <div className='formWrapper'>
              <p>Group are Created</p>
            </div>
          </div>
        </div>



        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading marginBottomZero">
                <Link id="trace-back" to={`/admin/classes`}>
                  <img src={Back} alt="back" />
                </Link>
                <h1>{this.state.class_label} | {this.props.pagewiseLanguageData.sections}</h1>
              </div>
            </div>
          </div>

          {/* {Create Group start}   */}
          <div className='createGroupWrapper'>
            <div className='createGroupContainer'>

              <ol>
                {/* <p>{JSON.stringify(this.state.sections)}</p> */}
                {this.state.sections.map((section, index) =>
                  <li className='list-item' key={index} id={section.id}>
                    <div className="cardInfo"><label>Name of class - </label>{this.state.class_label}</div>
                    <div className="cardInfo"><label>No. of students - </label>{section.no_of_students}</div>
                    {section.no_of_students > 0 && <div className='actions'><button onClick={(e) => this.OpenDialog(section.no_of_students)}>Breakout room /  Group</button>
                      <Link to={{
                        pathname: `/admin/groups-listing/`,
                        state: { class_label: this.state.class_label,class_id: this.state.class_id, no_of_students:section.no_of_students }
                      }}>
                        View Listing
                      </Link>
                    </div>}
                  </li>
                )}
              </ol>

            </div>
          </div>
          {/* //{Create Group start}   */}

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, updateSection, getSectionList, getClassList,
    deleteSection, bulkBatchUpdate, bulkBatchJobID, getPageLanguageWiseData } = actions;
  return {
    createSection: (label, short_code, center, class_id, session, is_current) => dispatch(createSection(label, short_code, center, class_id, session, is_current)),
    updateSection: (section_id, label, short_code, center, class_id, session, is_current) => dispatch(updateSection(section_id, label, short_code, center, class_id, session, is_current)),
    getSectionList: (center_id, class_id, show_all, page) => dispatch(getSectionList(center_id, class_id, show_all, page)),
    getClassList: () => dispatch(getClassList()),
    deleteSection: (id, center_id) => dispatch(deleteSection(id, center_id)),
    bulkBatchUpdate: (uploadfile, batch_id) => dispatch(bulkBatchUpdate(uploadfile, batch_id)),
    bulkBatchJobID: (jobid) => dispatch(bulkBatchJobID(jobid)),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
