import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Profile from '../../public/icons/profile.svg';
import doubtPlaceholder from '../../public/icons/doubt-image.svg';

import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import reportsLoader from '../../public/icons/loader.png';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Pagination from "react-js-pagination";

class FacultyDoubts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opens: false,
      subjects: [],
      subjectsFilter: [],
      colors: {},
      doubts: [],
      classs: '',
      batch: 0,
      classes: [],
      batches: [],
      batchesFilter: [],
      imgShow: '',
      descriptionShow: '',
      subject: this.props.location.state?.subject_id !== undefined
        ? parseInt(this.props.location.state?.subject_id) : 0,
      list: this.props.location.state?.by_me !== undefined ? 1 : 0,
      page: 1,
      total_page: 1,
      current_page: 1,
      doubtsDialogOpen: false,
      doubtsUploadFileName: '',
      doubtsUploadFileOnly: '',
      doubtsCourseValue: '',
      doubtsSectionValue: '',
      doubtsGroupValue: '',
      doubtsSubjectValue: '',
      doubtsDescValue: '',
      doubtsFileError: false,
      doubtsBatchesFilter: [],
      doubtsSubjectsFilter: [],
      loaderShow: false,
      openThanksDialog: false,
      listUserGroups: [],
      openConfirmationGrpupDialog: false,
      deleteDoubtId: null,
      deletingDoubt: false,
      page_size: 0,
      byMe: false,
      userId: JSON.parse(localStorage.getItem('logindetails'))?.id,
      faculyDiscussionType : 1




    }
  }

  componentDidMount() {
    this.getFacultyProfile();
    this.getDoubts(this.state.subject, this.state.batch, this.state.list, this.state.page);

  }


  getlistUserGroups = async (batch_id) => {
    const response = await this.props.listUserGroups(batch_id);
    if (response !== undefined) {
      this.setState({ listUserGroups: response?.data });
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({
      subjects: response?.data?.subjects, color: Object.fromEntries(
        response?.data?.subjects.map(e => [e.id, e.background_code])
      ), classes: Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {})),
      classs: Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {}))[0]?.class_id,
      subjectsFilter: response?.data?.subjects.filter(subject => subject.class_id === Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {}))[0].class_id),
      doubtsSubjectsFilter: response?.data?.subjects.filter(subject => subject.class_id === Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {}))[0].class_id),
      batchesFilter: response?.data?.batches.filter(batch => batch.classs?.id === Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {}))[0].class_id),
      doubtsBatchesFilter: response?.data?.batches.filter(batch => batch.classs?.id === Object.values(response?.data?.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {}))[0].class_id),
      batches: response?.data?.batches

    });
  }

  getDoubts = async (subject_id, batch_id, by_me, page) => {
    const response = await this.props.getFacultyDoubtList(subject_id, batch_id, by_me, page);
    // const response = await this.props.getBlogPost(subject_id);

    if (response !== undefined) {
      this.setState({
        doubts: response.data.forums,
        // doubts: this.state.doubts.concat(response.data.forums),
        current_page: response.data.pagination.current_page,
        total_page: response.data.pagination.total_page,
        page_size: response.data.pagination.page_size
      });
    }
  }

  seeMore = (subject_id, batch_id, by_me, page) => {
    var pageNumber = page + 1;
    this.setState({ page: pageNumber });
    this.getDoubts(subject_id, batch_id, by_me, page + 1);
  }

  show = (val) => this.setState({ open: true, imgShow: val });

  close = () => this.setState({ open: false });

  shows = (val) => this.setState({ opens: true, descriptionShow: val });

  closes = () => this.setState({ opens: false });

  onClassChange = (e) => {
    if (e.target.value !== '') {
      this.setState({
        subjectsFilter: this.state.subjects.filter(subject =>
          subject.class_id === parseInt(e.target.value)),
        classs: parseInt(e.target.value), batch: 0, subject: 0,
        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value))
      })
    }
    else {
      this.setState({
        subjectsFilter: this.state.subjects, subject: 0,
        batchesFilter: this.state.batches, batch: 0
      });
    }
    this.getDoubts(0, 0, this.state.list, 1);
  }

  onSubjectChange = (val) => {
    this.setState({ subject: val, loading: true, page: 1, doubts: [] });
    this.getDoubts(val, this.state.batch, this.state.list, 1);

  }

  onSectionChange = (e) => {
    this.setState({ batch: e.target.value, loading: true, page: 1, doubts: [] });
    this.getDoubts(this.state.subject, e.target.value, this.state.list, 1)
  }

  onListChange = (e) => {
    this.setState({ list: e.target.value, page: 1, doubts: [] });
    this.getDoubts(this.state.subject, this.state.batch, e.target.value, 1);
  }

  getDateTime = (val) => {
    var startDate = new Date(val);
    var currentDate = new Date(Date.now());
    var seconds = (currentDate.getTime() - startDate.getTime()) / 1000;
    var minut = seconds / 60;
    var hours = minut / 60;
    var minut2 = startDate.getMinutes();
    var hours2 = startDate.getHours();
    seconds = Math.floor(seconds);
    minut = Math.floor(minut);
    hours = Math.floor(hours);

    var date = startDate.getDate();
    var month = startDate.getMonth() + 1;
    var year = startDate.getFullYear();
    if (seconds < 60) {
      return `just now`;
    }
    else if (minut < 60) {
      if (minut <= 1) {
        return `${minut} min ago`;
      }
      else {
        return `${minut} mins ago`;
      }
    }
    else if (hours < 24) {
      if (hours <= 1) {
        return `${hours} hr ago`;
      }
      else {
        return `${hours} hrs ago`;
      }
    }
    else {
      if (minut2 < 10) {
        minut2 = '0' + minut2;
      }
      if (hours2 < 10) {
        hours2 = '0' + hours2;
      }
      return `${MonthTable[month]} ${date}, ${year}, ${hours2}:${minut2}`;
    }
  }


  CloseDialog = () => {
    this.setState({ doubtsDialogOpen: false })
  }

  OpenDialog = () => {


    this.setState({ doubtsDialogOpen: true })
    // this.setState({ doubtsCourseValue: this.state.classes[0].id });


    const selectBox = document.getElementById('doubtcourseSelect');
    if (selectBox) {
      var selectedValue = selectBox?.options[selectBox.selectedIndex]?.value;
      this.setState({ doubtsCourseValue: selectedValue });
      // var selectedText = selectBox.options[selectBox.selectedIndex].text;
      // alert('Selected value: ' + selectedValue + '\nSelected text: ' + selectedText);
    }
  }

  OpenThanksDialog = (section) => {
    this.setState({ openThanksDialog: true })



  }


  ThanksCloseDialog = () => {
    this.setState({ openThanksDialog: false })
    this.setState({ doubtsUploadFileName: "" })
    this.setState({ doubtsCourseValue: "" })
    this.setState({ doubtsDescValue: "" })
    this.setState({ doubtsUploadFileOnly: "" })

  }




  clickFacultyUploadDisscusstionImage = () => {
    document.getElementById("facultyUploadDisscusstionImage").click();
  }


  handleFacultyUploadDisscusstionImage(event) {
    const file = event.target.files[0];
    if (file) {
      // Check the file type
      const fileType = file.type.split('/')[1];
      if (fileType === 'jpg' || fileType === 'png' || fileType === 'JPEG' || fileType === 'PNG') {
        const fileURL = URL.createObjectURL(file);
        console.log(file);
        console.log(fileURL);
        this.setState({ doubtsUploadFileName: fileURL });
        this.setState({ doubtsUploadFileOnly: file });
        this.setState({ doubtsFileError: false });
      } else {
        alert('Please upload a valid file.');
        this.setState({ doubtsFileError: true });
      }
    }
  }


  handleFacultyDoubtsCourseChange = (e) => {
    const { doubtsCourseValue } = this.state;
    console.log("doubtsCourseValue", e.target.value)
    this.setState({ doubtsCourseValue: e.target.value });

    this.setState({ doubtsSectionValue: '' });
    this.setState({ doubtsSubjectValue: '' });
    this.setState({ doubtsGroupValue: '' });

    if (e.target.value !== '') {
      this.setState({
        doubtsSubjectsFilter: this.state.subjects.filter(subject =>
          subject.class_id === parseInt(e.target.value)),
        classs: parseInt(e.target.value), batch: 0, subject: 0,
        doubtsBatchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value))
      })
    }
    else {
      this.setState({
        doubtsSubjectsFilter: this.state.subjects, subject: 0,
        doubtsBatchesFilter: this.state.batches, batch: 0
      });
    }
    //this.getDoubts(0, 0, this.state.list, 1);
  }

  handleFacultyDoubtsSectionChange = async (e) => {
    if (e.target.value) {
      this.setState({ doubtsSectionValue: e.target.value });
      console.log("doubtsSectionValue", e.target.value)
      this.getlistUserGroups(e.target.value)
    }

  }


  handleFacultyDoubtsSubjectChange = (e) => {
    console.log("doubtsSubjectValue", e)
    this.setState({ doubtsSubjectValue: e.target.value });


  }

  handleFacultyDoubtsGroupChange = (id) => {
    console.log("doubtsGroupValue", id)
    this.setState({ doubtsGroupValue: id });

  }

  handleFacultyDoubtsDescChange = (e) => {
    console.log("doubtsDescValue", e.target.value)
    this.setState({ doubtsDescValue: e.target.value });
  }



  handleFacultyDoubtsSubmit = async () => {
    this.setState({ loaderShow: true });
    const { doubtsSubjectValue, doubtsSectionValue, doubtsGroupValue, doubtsUploadFileName, doubtsCourseValue, doubtsDescValue, doubtsUploadFileOnly } = this.state;
    console.log("doubtsSubjectValue", doubtsSubjectValue)
    console.log("doubtsSectionValue", doubtsSectionValue)
    console.log("doubtsGroupValue", doubtsGroupValue)
    console.log("doubtsUploadFileName", doubtsUploadFileName)
    console.log("doubtsCourseValue", doubtsCourseValue)
    console.log("doubtsDescValue", doubtsDescValue)
    console.log("doubtsUploadFileOnly", doubtsUploadFileOnly);

    const response = await this.props.facultyPostDoubt(doubtsSubjectValue, doubtsDescValue, doubtsUploadFileOnly, '', doubtsCourseValue, this.state.faculyDiscussionType, '');
    if (response?.status == 1) {
      this.setState({ loaderShow: false });
      this.setState({ openThanksDialog: true })
      this.setState({ openDialog: false })
      this.setState({ doubtsDialogOpen: false })
      console.log("response", response)
      // this.getDoubts()
      this.getDoubts(this.state.subject);
    }

  }


  confirmationCloseDialog = () => {
    this.setState({ openConfirmationGrpupDialog: false })
    this.setState({ deleteDoubtId: null })
    this.setState({ deletingDoubt: false })

  }
  confirmationOpenDialog = (doubt_id) => {
    this.setState({ openConfirmationGrpupDialog: true })
    this.setState({ deleteDoubtId: doubt_id })

  }

  handleDeleteGroup = async () => {
    this.setState({ deletingDoubt: true })
    try {
      const response = await this.props.deleteForum(this.state.deleteDoubtId)
      if (response?.status == 1) {

        this.setState({ doubts: this.state.doubts.filter((data) => data.id != this.state.deleteDoubtId) });
        this.confirmationCloseDialog()
      }
    }
    catch (e) {
      console.error('Error while deleting group ', e)
    }
    this.setState({ deletingDoubt: false })

  }

  handleToggleByMe = () => {
    this.setState({ byMe: !this.state.byMe })
  }



  handlePageChange = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);

    // this.setState({onLoad:true})
    // //this.show4(pageNumber);

    this.setState({current_page: pageNumber});
    this.getDoubts(this.state.subject, this.state.batch, this.state.list, pageNumber);

    // this.getAssignment(this.props.match.params.id,pageNumber)
  }


  render() {
    const isEnabled = this.state.doubtsCourseValue && this.state.doubtsSubjectValue && this.state.doubtsUploadFileName && this.state.doubtsFileError == '' && this.state.doubtsDescValue;

    return (
      <>



        <div className={this.state.openConfirmationGrpupDialog ? "confirmationGroupDialog open" : "confirmationGroupDialog "} >
          <div className='confirmationGroupContainer'>
            <h6>Confirmation</h6>
            <div className='formWrapper'>
              <p>Are you sure you wants to <br />delete this post</p>

              <div className='actions'>
                <a href='javascript:void(0);' onClick={this.confirmationCloseDialog}>Cancel</a>
                {
                  this.state.deletingDoubt ? <div style={{ width: 'auto' }} className='LoadingMessage'><img style={{ maxWidth: '40px' }} src={reportsLoader} /></div> : <button onClick={this.handleDeleteGroup}>Ok</button>
                }
              </div>
            </div>
          </div>
        </div>




        <div className={this.state.openThanksDialog ? "thanksGroupDialog open" : "thanksGroupDialog "} >
          <div className='thanksGroupContainer'>
            <span onClick={this.ThanksCloseDialog} className='fa fa-times'></span>
            <i className='icon fa fa-thumbs-up'></i>
            <h6>ThankYou</h6>
            <div className='formWrapper'>
              <p>Group are Created</p>
            </div>
          </div>
        </div>


        <div className={this.state.doubtsDialogOpen ? "facultyCreateDisscusstionDialog open" : "facultyCreateDisscusstionDialog"}>
          <div className='facultyCreateDisscusstionContainer'>
            <h3>Share Your Thought <span onClick={this.CloseDialog} className='fa fa-times'></span></h3>
            <div className='filterBlock'>
              <div className='block'>
                <label>Select Course <span className='required'>*</span></label>
                <select onChange={(e) => this.handleFacultyDoubtsCourseChange(e)} id='doubtcourseSelect' defaultValue={parseInt(this.state.classs)}>
                  {/* <option value={0}>Select course</option> */}
                  {this.state.classes.map(classs =>
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
              </div>
              <div className='block'>
                <label>Select Section</label>
                <select onChange={(e) => this.handleFacultyDoubtsSectionChange(e)} defaultValue={parseInt(this.state.batch)}>
                  <option value={0}>
                    Select section
                  </option>
                  {this.state.doubtsBatchesFilter.map(batch =>
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
              </div>




              <div className='block'>
                <label>Select Subject <span className='required'>*</span></label>
                <select onChange={(e) => this.handleFacultyDoubtsSubjectChange(e)}>
                  <option value={0}>
                    Select Subject
                  </option>
                  {this.state.doubtsSubjectsFilter.map(subject =>
                    <option value={parseInt(subject.id)} key={subject.id}>
                      {subject.label}
                    </option>
                  )}
                </select>
              </div>

              <div className='block'>
                <label>Select Group</label>
                <select onChange={(e) => this.handleFacultyDoubtsGroupChange(e)}>
                  <option value={0}>
                    Select Group
                  </option>
                  {this.state.listUserGroups.map(groups =>
                    <option value={parseInt(groups.id)} key={groups.id}>
                      {groups.title}
                    </option>
                  )}
                </select>
              </div>

            </div>
            <div className='doubtSubjectsBlock'>

              {/* <p>{console.log(JSON.stringify(this.state.doubtsSubjectsFilter))}</p>         */}




              {/* {this.state.doubtsSubjectsFilter.map(subject =>
                    <div key={subject.id} onClick={() => this.handleFacultyDoubtsSubjectChange(subject.id)}
                    id={this.state.doubtsSubjectValue === subject.id ? 'selected-subject' : ''} value={subject.id} >
                      {subject.label}
                    </div>
                  )} */}
            </div>
            <div className='formWrapper'>
              <div className='DescriptionBlock'>
                <label>Description  <span className='required'>*</span></label>
                <textarea placeholder='Start Typing...' value={this.state.doubtsDescValue} onChange={(e) => this.handleFacultyDoubtsDescChange(e)}></textarea>
              </div>
              <div className='imagePreviewBlock'>
                <label>Add an Image  <span className='required'>*</span></label>
                <input type="file" id="facultyUploadDisscusstionImage" onChange={(e) => this.handleFacultyUploadDisscusstionImage(e)} style={{ display: 'none' }} />
                <div className='mainBlock'>
                  {this.state.doubtsUploadFileName !== '' && <div className='previewBlock'><img src={this.state.doubtsUploadFileName} /></div>}
                  <div className='uploadBlock' onClick={(e) => this.clickFacultyUploadDisscusstionImage(e)}><i className='fa fa-upload'></i>Upload Image</div>
                  {this.state.doubtsFileError && <div className='uploadErrorMessage'>Please upload only .jpg, .png format</div>}
                </div>
              </div>

            </div>
            <div className='actions'><button disabled={!isEnabled ? "disabled" : ""} onClick={() => this.handleFacultyDoubtsSubmit()}>Submit</button>{this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}</div>
          </div>
        </div>


        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="image">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="image-popup">
            <img src={common.addFileUrl(this.state.imgShow)} alt="zoom" />
          </div>
        </Popup>
        <Popup open={this.state.opens} closeOnDocumentClick onClose={this.closes}
          className="image">
          <span className="close" onClick={this.closes}>&#x2715;</span>
          <div className="description-popup">
            <div className='description-popup-container'>
              {this.state.descriptionShow}
            </div>
          </div>
        </Popup>
        <div className="app-body">
          <div className="app-div ">
            <Helmet>
              <title>Discussion Forum - Faculty</title>
            </Helmet>
            <ReactNotifications />
            <img id="path-b" src={PathB} alt="pathb" />
            <img id="path-d" src={PathD} alt="pathd" />
            <div className="page-header withStyle">
              <div className="page-header-left">
                <h1>Discussion Forum</h1>
              </div>
              <div className="page-header-right">
                <button onClick={this.handleToggleByMe} style={{ height: '36px', opacity: this.state.byMe ? '0.6' : '1' }} >All</button>
                <button onClick={this.handleToggleByMe} style={{ height: '36px', width: "auto", marginLeft: "10px", opacity: this.state.byMe ? '1' : '0.6' }}>By Me</button>


                <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                  {this.state.classes.map(classs =>
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} defaultValue={parseInt(this.state.batch)}>
                  <option value={0}>
                    Select section
                  </option>
                  {this.state.batchesFilter.map(batch =>
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                {/* <select onChange={this.onListChange} defaultValue={this.state.list}>
                  <option value={0}>
                    All questions
                  </option>
                  <option value={1}>
                    Answered by me
                  </option>
                </select> */}
                <div className='CreateDiscussionLink'><button onClick={this.OpenDialog}>Create Discussion</button></div>
              </div>
            </div>
            <div className="page-doubt-subjects marginBottom20">
              <div onClick={() => this.onSubjectChange(0)}
                id={this.state.subject === 0 ? 'selected-dsubject' : ''}>
                All
              </div>
              {this.state.subjectsFilter.map(subject =>
                <div key={subject.id} onClick={() => this.onSubjectChange(subject.id)}
                  id={this.state.subject === subject.id ? 'selected-dsubject' : ''}>
                  {subject.label}
                </div>
              )}
            </div>
           
            <div className="page-data facultyNewDesign">
              {[...this.state.doubts.filter((data)=> {return this.state.byMe ? data?.user?.id == this.state.userId : true})].length === 0 && !this.props.loading &&
                <div className='noContentMessage'>No Data Found</div>
              }
              {[...this.state.doubts.filter((data)=> {return this.state.byMe ? data?.user?.id == this.state.userId : true})].map(doubt =>
                <div className="single-doubt" key={doubt.id}>
                  {doubt?.user?.id == this.state.userId &&<div className='deletePostLink' title='delete' onClick={() => {
                    this.confirmationOpenDialog(doubt.id)
                  }}><i className='fa fa-trash'></i>Delete</div>}
                  <Link className="single-doubt-left" to={{
                    pathname: `/faculty/doubt-responses/${doubt.id}`,
                    state: {
                      question: doubt
                    }
                  }}>
                    <div className="doubt-by">
                      <div className="doubt-by-div">
                        {doubt?.user?.profile_picture.length === 0
                          ? <div className="doubt-by-profile">
                            <img src={Profile} alt="user" />
                          </div> : <div className="doubt-by-profile" style={{
                            backgroundImage:
                              `url(${common.addFileUrl(doubt?.user?.profile_picture)})`
                          }}>
                          </div>}
                        <div>
                          <span className="doubt-by-name">
                            {doubt.user.name}
                          </span>
                          <span className="doubt-by-time">
                            {this.getDateTime(doubt.added_on)}
                          </span>
                        </div>
                      </div>
                      <div className="single-doubt-info">
                        <span className="single-doubt-subject">{doubt.subject}</span>
                        {/* <div className="responses-count">
                          <img src={Comment} alt="responses"/> 3
                        </div> */}
                      </div>
                    </div>
                    <span className="single-doubt-description" dangerouslySetInnerHTML={{ __html: doubt.description }}></span>
                  </Link>
                  {doubt.description.length > 1685 ? <span id="view_more"
                    onClick={() => this.shows(doubt.description)}>View more</span>
                    : null}
                  {doubt.media === null ? null
                    : <div title="Zoom" className="single-doubt-right"
                      onClick={() => this.show(doubt.media)}>
                      <img src={common.addFileUrl(doubt.media)} />
                    </div>}

                  <div class="BottomLink"><Link className="single-doubt-left" to={{
                    pathname: `/faculty/doubt-responses/${doubt.id}`,
                    state: {
                      question: doubt,
                      title: "Discussion Forum",
                    }
                  }}><button>Reply</button></Link></div>
                </div>
              )}
              {this.state.current_page === this.state.total_page ? <div></div>
                : <div className="more_doubts">
                  {!this.props.loading ? <button onClick={() => this.seeMore(this.state.subject, this.state.batch, this.state.list, this.state.page)}>
                    See More
                  </button> : <div>
                    <div className="list-loader"></div>
                  </div>}
                </div>}
            </div>

            {(this.state?.total_page * this.state?.page_size) && (this.state?.total_page * this.state?.page_size) >= this.state?.page_size ? <div className="pagination-list fixed-pagination noStyle">
              <Pagination
                activePage={this.state?.current_page}
                itemsCountPerPage={this.state?.page_size}
                totalItemsCount={this.state?.total_page * this.state?.page_size}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </div> : ''}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile, getFacultyDoubtList, facultyPostDoubt, listUserGroups, getBlogPost, deleteForum } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyDoubtList: (subject_id, batch_id, by_me, page, type) => dispatch(getFacultyDoubtList(subject_id, batch_id, by_me, page, type)),
    facultyPostDoubt: (subject_id, description, media, doubt_forum_id, class_id, type, title) => dispatch(facultyPostDoubt(subject_id, description, media, doubt_forum_id, class_id, type, title)),
    listUserGroups: (batch_id) => dispatch(listUserGroups(batch_id)),
    getBlogPost: (subject_id) => dispatch(getBlogPost(subject_id)),
    deleteForum: (forum_id) => dispatch(deleteForum(forum_id)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyDoubts);