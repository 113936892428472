import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import common from '../../common';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Add from '../../public/icons/add.svg';
import Popup from 'reactjs-popup';
import CollegeMetaInfoList from '../admin/CollegeMetaInfoList';



class Institute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center_id: localStorage.getItem('center_id'),
      image: null,
      profile: {},
      title: '',
      description: '',
      loading: false,
      selectedImages: [],
      center_id: localStorage.getItem('center_id'),
      about_college: '',
      why_to_study_here: '',
      logo: null,
      banner_url: '',
      image_gallery: [],
      ownership: '',
      address: '',
      year_of_establishment: '',
      is_ugc_approved:false,
      is_aiu_membership: false,
      is_national: false,
      website_url: '',
      courses_offers: [],
      number_of_seat: 0,
      fee_range: 0,
      is_library: false,
      is_cafeteria: false,
      hostel: '',
      is_bus_service: false,
      is_lab: false,
      is_sports_complex: false,
      is_auditorium: false,
      world_ranking: '',
      testimonials: [''],
      brochure_req_url: '',
      showMetaInfo: false,
      image_url: ''



    };

  }


  handleViewInfo = () => {
    this.setState({ showMetaInfo: true });
  }

  handleCloseMetaInfo = () => {
    this.setState({ showMetaInfo: false });
  }

  componentDidMount() {
    console.log("landing testing");
    this.getProfile();
    this.getUrlfromCurrentPage();


    let getCurrentCenterID = localStorage.getItem('center_id');
    let saveBeforeLoginSetting = localStorage.getItem('loginPageSettings');
    let getBeforeCenterID = JSON.parse(saveBeforeLoginSetting).length === 0 ? getCurrentCenterID : JSON.parse(saveBeforeLoginSetting)[0]['center_id'];
    console.log("getCurrentCenterID", getCurrentCenterID);
    console.log("saveBeforeLoginSetting", saveBeforeLoginSetting);
    console.log("getBeforeCenterID", getBeforeCenterID);

    if (getCurrentCenterID !== getBeforeCenterID) {
      console.log("getBeforeCenterID = nahi hai");
      let getNewResponse = JSON.parse(localStorage.getItem('logindetails'));
      let fetchPrimaryColor = getNewResponse.institute_settings.primary_color;
      let fetchPrimaryColorLight = getNewResponse.institute_settings.primary_color_light;
      let fetchSecondaryColor = getNewResponse.institute_settings.secondary_color;
      let fetchLogoUrl = getNewResponse.institute_settings.logo_url;
      let fetchLoginBannerUrl = getNewResponse.institute_settings.login_banner;

      if (fetchPrimaryColor !== 'undefined') {
        localStorage.setItem('primary_color', fetchPrimaryColor);
        document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color'));
      }
      if (fetchPrimaryColorLight !== 'undefined') {
        localStorage.setItem('primary_color_light', fetchPrimaryColorLight);
        document.documentElement.style.setProperty('--primary-rgba', localStorage.getItem('primary_color_light'));
      }
      if (fetchSecondaryColor !== 'undefined') {
        localStorage.setItem('secondary_color', fetchSecondaryColor);
        document.documentElement.style.setProperty('--secondary', localStorage.getItem('secondary_color'));
      }
      if (fetchLogoUrl !== 'undefined') {
        localStorage.setItem('logo_url', fetchLogoUrl);
      }
      if (fetchLoginBannerUrl !== 'undefined') {
        localStorage.setItem('login_banner', JSON.stringify(fetchLoginBannerUrl));
      }
    }
  }

  getUrlfromCurrentPage = () => {
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if (modifyURL) {
      console.log("modifyURL", modifyURL);
      console.log("languagessss", language);
      this.fetchPageWiseData(language, modifyURL);
    }
  }

  getProfile = async () => {
    const response = await this.props.getProfile(this.state.center_id);
    if (response !== undefined) {
      this.setState({ image: response.data.logo, profile: response.data });
    }
  }

  getCollegeMetaInfo = async () => {
  
    const response = await this.props.getCollegeMetaInfo(
      this.state.center_id,
      this.state.about_college,
      this.state.why_to_study_here,
      this.state.logo ? this.state.logo.file : null,
      this.state.banner_url,
      this.state.selectedImages.map(image => image.file),
      this.state.ownership,
      this.state.address,
      this.state.year_of_establishment,
     this.state.is_ugc_approved, // 

      this.state.is_aiu_membership, // Convert to Boolean
      this.state.is_national, // Convert
      this.state.website_url,
      this.state.courses_offers,
      this.state.number_of_seat,
      this.state.fee_range,
      this.state.is_library, // Convert to Boolean
      this.state.is_cafeteria,
      this.state.hostel,
      this.state.is_bus_service, // Convert to Boolean
      this.state.is_lab, // Convert to Boolean
      this.state.is_sports_complex, // Convert to Boolean
      this.state.is_auditorium, // Conver
      this.state.world_ranking,
      this.state.testimonials,
      this.state.brochure_req_url,)
    
    if (response !== undefined) {
      this.setState({ image: response.data.logo, profile: response.data });
    }
  }


  handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Convert file list to an array
    const newImages = files.map(file => ({ file, preview: URL.createObjectURL(file) }));  
   

    this.setState((prevState) => ({
      selectedImages: [...prevState.selectedImages, ...newImages], // Append new images to the existing ones
    }));
  }



  handleLogoUpload = (event) => {
    const file = event.target.files[0]; // Only take the first file
    console.log("the file is ", file)
    if (file) {
      const preview = URL.createObjectURL(file);
      this.setState({ logo: { file, preview } }); // Update state with the selected logo file
    }
  }


  handleRemoveImage(index) {
    this.setState(prevState => ({
      selectedImages: prevState.selectedImages.filter((_, i) => i !== index),
    }));
  }


  removeLogo() {
    this.setState({ logo: null });
  }




  handleSubmit = (close) => {
    this.setState({ loading: true });
    setTimeout(() => {
      // Simulate a delay for image upload
      this.setState({ loading: false });
      alert('Images uploaded successfully!');
      // this.setState({ selectedImages: [] });
      close();
    }, 2000);
    this.getCollegeMetaInfo();
  };

  fetchPageWiseData = async (language, modifyURL) => {
    const response = await this.props.getPageLanguageWiseData(language, modifyURL);
    if (response !== undefined && response.status === 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData', JSON.stringify(response?.data))
    }
  }

  handleChange = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      this.editCenter(event.target.files[0]);
    } else {
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      });
    }
  }

  editCenter = async (image) => {
    const { center_id, profile } = this.state;
    await this.props.updateCenter(center_id, profile.name, profile.code, profile.country,
      profile.state, profile.city, image, null);
  }

  handleInputChange = (event) => {
    const { name, type, value, checked } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  }

  componentWillUnmount() {
    this.state.selectedImages.forEach(image => URL.revokeObjectURL(image.preview));
  }


  handleCenterId = (e) => {
    this.setState({ center_id: e.target.value });
  }

  handleAboutCollege = (e) => {
    this.setState({ about_college: e.target.value });
  }

  handleWhyToStudyHere = (e) => {
    this.setState({ why_to_study_here: e.target.value });
  }

  handleBannerUrl = (e) => {
    this.setState({ banner_url: e.target.value });
  }

  handleOwnership = (e) => {
    this.setState({ ownership: e.target.value });
  }

  handleAddress = (e) => {
    this.setState({ address: e.target.value });
  }

  handleYearOfEstablishment = (e) => {
    this.setState({ year_of_establishment: e.target.value });
  }

  handleIsUgcApproved = (e) => {
    this.setState({ is_ugc_approved: e.target.checked });
  }

  handleIsAiuMembership = (e) => {
    this.setState({ is_aiu_membership: e.target.checked });
  }

  handleIsNational = (e) => {
    this.setState({ is_national: e.target.checked });
  }

  handleWebsiteUrl = (e) => {
    this.setState({ website_url: e.target.value });
  }

  handleCoursesOffers = (e) => {
    const courses = e.target.value.split(','); // Assuming the courses are comma-separated
    this.setState({ courses_offers: courses });
  }

  handleNumberOfSeats = (e) => {
    this.setState({ number_of_seat: e.target.value });
  }

  handleFeeRange = (e) => {
    this.setState({ fee_range: e.target.value });
  }

  handleIsLibrary = (e) => {
    this.setState({ is_library: e.target.checked });
  }

  handleIsCafeteria = (e) => {
    this.setState({ is_cafeteria: e.target.checked });
  }

  handleHostel = (e) => {
    this.setState({ hostel: e.target.value });
  }

  handleIsBusService = (e) => {
    this.setState({ is_bus_service: e.target.checked });
  }

  handleIsLab = (e) => {
    this.setState({ is_lab: e.target.checked });
  }

  handleIsSportsComplex = (e) => {
    this.setState({ is_sports_complex: e.target.checked });
  }

  handleIsAuditorium = (e) => {
    this.setState({ is_auditorium: e.target.checked });
  }

  handleWorldRanking = (e) => {
    this.setState({ world_ranking: e.target.value });
  }

  handleTestimonials = (e) => {
    const testimonials = e.target.value.split(','); // Assuming the testimonials are comma-separated
    this.setState({ testimonials: testimonials });
  }

  handleBrochureReqUrl = (e) => {
    this.setState({ brochure_req_url: e.target.value });
  }

  handleTestimonialsChange = (index, event) => {
    const newTestimonials = [...this.state.testimonials];
    newTestimonials[index] = event.target.value;
    this.setState({ testimonials: newTestimonials });
  };

  addTestimonialField = () => {
    this.setState(prevState => ({
      testimonials: [...prevState.testimonials, ''] // Adds a new empty input field
    }));
  };

  removeTestimonialField = (index) => {
    this.setState(prevState => {
      const newTestimonials = prevState.testimonials.filter((_, i) => i !== index);
      return { testimonials: newTestimonials };
    });
  };


  render() {
    if (this.props.loading) {
      return (
        <div className="admin-div">
          {/* Loading spinner or message */}
        </div>
      );
    }
    return (
      <div className="admin-div">
        <div className="heading-part2">


          {/* <Popup trigger={<div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <button style={{ position: 'absolute', top: 50, right: 0, margin: '10px' }}>
              <span>
              
                Add on Info
              </span>
            </button>
          </div>
          } modal className="custom-popup">
            {(close) => (
              <div className="popup-content">
                <span className="close" style={{ color: 'black', background: 'none' }} onClick={close}>&times;</span>
                <center><h2>Please fill the above information</h2></center>
                <div className="input-container">

                  <div className="column">
                    <h4>Institution*</h4>
                    <input
                      type="text"
                      name="about_college"
                      placeholder="Institution"
                      value={this.state.about_college}
                      onChange={this.handleAboutCollege}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Why to Study Here</h4>
                    <input
                      type="text"
                      name="why_to_study_here"
                      placeholder="Why to Study Here"
                      value={this.state.why_to_study_here}
                      onChange={this.handleWhyToStudyHere}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Banner URL</h4>
                    <input
                      type="text"
                      name="banner_url"
                      placeholder="Banner URL"
                      value={this.state.banner_url}
                      onChange={this.handleBannerUrl}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Ownership</h4>
                    <input
                      type="text"
                      name="ownership"
                      placeholder="Ownership"
                      value={this.state.ownership}
                      onChange={this.handleOwnership}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Address</h4>
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={this.state.address}
                      onChange={this.handleAddress}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Year of Establishment</h4>
                    <input
                      type="text"
                      name="year_of_establishment"
                      placeholder="Year of Establishment"
                      value={this.state.year_of_establishment}
                      onChange={this.handleYearOfEstablishment}
                      className="input-field"
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>UGC Approved</h4>
                    <input
                      type="checkbox"
                      name="is_ugc_approved"
                      checked={this.state.is_ugc_approved}
                      onChange={this.handleIsUgcApproved}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>AIU Membership</h4>
                    <input
                      type="checkbox"
                      name="is_aiu_membership"
                      checked={this.state.is_aiu_membership}
                      onChange={this.handleIsAiuMembership}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Is National</h4>
                    <input
                      type="checkbox"
                      name="is_national"
                      checked={this.state.is_national}
                      onChange={this.handleIsNational}
                    />
                  </div>

                  <div className="column">
                    <h4>Website URL</h4>
                    <input
                      type="text"
                      name="website_url"
                      placeholder="Website URL"
                      value={this.state.website_url}
                      onChange={this.handleWebsiteUrl}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Courses Offered</h4>
                    <input
                      type="text"
                      name="courses_offers"
                      placeholder="Courses Offered (comma-separated)"
                      value={this.state.courses_offers.join(',')}
                      onChange={this.handleCoursesOffers}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Number of Seats</h4>
                    <input
                      type="text"
                      name="number_of_seat"
                      placeholder="Number of Seats"
                      value={this.state.number_of_seat}
                      onChange={this.handleNumberOfSeats}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Fee Range</h4>
                    <input
                      type="text"
                      name="fee_range"
                      placeholder="Fee Range"
                      value={this.state.fee_range}
                      onChange={this.handleFeeRange}
                      className="input-field"
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Library Available</h4>
                    <input
                      type="checkbox"
                      name="is_library"
                      checked={this.state.is_library}
                      onChange={this.handleIsLibrary}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Cafeteria Available</h4>
                    <input
                      type="checkbox"
                      name="is_cafeteria"
                      checked={this.state.is_cafeteria}
                      onChange={this.handleIsCafeteria}
                    />
                  </div>

                  <div className="column">
                    <h4>Hostel Availability</h4>
                    <input
                      type="text"
                      name="hostel"
                      placeholder="Hostel Availability"
                      value={this.state.hostel}
                      onChange={this.handleHostel}
                      className="input-field"
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Bus Service</h4>
                    <input
                      type="checkbox"
                      name="is_bus_service"
                      checked={this.state.is_bus_service}
                      onChange={this.handleIsBusService}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Lab Available</h4>
                    <input
                      type="checkbox"
                      name="is_lab"
                      checked={this.state.is_lab}
                      onChange={this.handleIsLab}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Sports Complex</h4>
                    <input
                      type="checkbox"
                      name="is_sports_complex"
                      checked={this.state.is_sports_complex}
                      onChange={this.handleIsSportsComplex}
                    />
                  </div>

                  <div className="checkbox-row">
                    <h4>Auditorium</h4>
                    <input
                      type="checkbox"
                      name="is_auditorium"
                      checked={this.state.is_auditorium}
                      onChange={this.handleIsAuditorium}
                    />
                  </div>

                  <div className="column">
                    <h4>World Ranking</h4>
                    <input
                      type="text"
                      name="world_ranking"
                      placeholder="World Ranking"
                      value={this.state.world_ranking}
                      onChange={this.handleWorldRanking}
                      className="input-field"
                    />
                  </div>

                  <div className="column">
                    <h4>Testimonials</h4>
                    {this.state.testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-input">
                        <input
                          type="text"
                          name={`testimonial-${index}`}
                          placeholder="Testimonial"
                          value={testimonial}
                          onChange={(event) => this.handleTestimonialsChange(index, event)}
                          className="input-field"
                        />
                        {this.state.testimonials.length > 1 && (
                          <button onClick={() => this.removeTestimonialField(index)} className="remove-button">
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button onClick={this.addTestimonialField} className="add-button">
                      Add
                    </button>
                  </div>

                  <div className="column">
                    <h4>Brochure Request URL</h4>
                    <input
                      type="text"
                      name="brochure_req_url"
                      placeholder="Brochure Request URL"
                      value={this.state.brochure_req_url}
                      onChange={this.handleBrochureReqUrl}
                      className="input-field"
                    />



                  </div>
                </div>

                <h4>Upload Logo*</h4>
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.handleLogoUpload}
                />
                {this.state.logo && (
                  <div className="image-preview">
                    <img src={this.state.logo.preview} alt="Logo Preview" style={{ width: 100, height: 100 }} />
                    <button onClick={() => this.removeLogo()}>X</button>
                  </div>
                )}



                <h4>Upload and Preview Images</h4>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={this.handleFileUpload}
                />
                <div className="image-preview-container">
                  {this.state.selectedImages.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img src={image.preview} alt={`Preview ${index}`} style={{ width: 100, height: 100 }} />
                      <span className="close" style={{ color: 'white', background: 'none' }} onClick={() => this.handleRemoveImage(index)}>&times;</span>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => this.handleSubmit(close)}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? 'Uploading...' : 'Submit'}
                </button>
              </div>
            )}
          </Popup> */}
          {/* <button style={{ position: 'absolute', top: 50, right: '150px', margin: '10px' }} onClick={this.handleViewInfo}>
            <span>
            
              View Info
            </span>
          </button> */}

        </div>

        <Helmet>
          <title>Institute</title>
        </Helmet>
        <ReactNotifications />

        <Header placeholder="Search institutes" />

        {
          this.state.showMetaInfo == true ? <CollegeMetaInfoList onClose={this.handleCloseMetaInfo} /> :


            <div className="institute-div">
              <div id="congo-span">
                {this.props.pagewiseLanguageData.congratulations}! {this.state.profile.name}
              </div>
              <span>{this.props.pagewiseLanguageData.you_have_your_own_lms_now}</span>
              <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput} />
              {this.state.image === '' || this.state.image === null
                ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()} />
                : <div className="institute-img" onClick={() => this.fileInput.click()}
                  style={{ backgroundImage: "url(" + common.addFileUrl(this.state.image) + ")" }}></div>}
              <p>{this.props.pagewiseLanguageData.have_your_personalised_application}, <br />{this.props.pagewiseLanguageData.click_on_imageand_Upload_logo_as_it_will_be_visible_as_brand_identity}</p>
            </div>

        }

      </div>



    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { updateCenter, getProfile, getPageLanguageWiseData, getCollegeMetaInfo, removeCollegeImage } = actions;
  return {
    updateCenter: (center_id, name, code, country, state, city, logo, admin) => dispatch(updateCenter(center_id, name, code, country, state, city, logo, admin)),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    removeCollegeImage: (center_id, image_url) => dispatch(removeCollegeImage(center_id, image_url)),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
    getCollegeMetaInfo: (
      center_id,
      about_college,
      why_to_study_here,
      logo,
      banner_url,
      image_gallery,
      ownership,
      address,
      year_of_establishment,
      is_ugc_approved,
      is_aiu_membership,
      is_national,
      website_url,
      courses_offers,
      number_of_seat,
      fee_range,
      is_library,
      is_cafeteria,
      hostel,
      is_bus_service,
      is_lab,
      is_sports_complex,
      is_auditorium,
      world_ranking,
      testimonials,
      brochure_req_url
    ) => dispatch(
      getCollegeMetaInfo(
        center_id,
        about_college,
        why_to_study_here,
        logo,
        banner_url,
        image_gallery,
        ownership,
        address,
        year_of_establishment,
        is_ugc_approved,
        is_aiu_membership,
        is_national,
        website_url,
        courses_offers,
        number_of_seat,
        fee_range,
        is_library,
        is_cafeteria,
        hostel,
        is_bus_service,
        is_lab,
        is_sports_complex,
        is_auditorium,
        world_ranking,
        testimonials,
        brochure_req_url
      )
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Institute);

