import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import Carousel from 'react-elastic-carousel';
import { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import Logo from '../../public/images/imaginxp-logo.png';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import LoginImage1 from '../../public/images/sastra-login-banner1.jpg';
import LoginImage2 from '../../public/images/sastra-login-banner2.jpg';
import LoginImage3 from '../../public/images/sastra-login-banner3.jpg';
import LoginImage4 from '../../public/images/sastra-login-banner4.jpg';
import LoginImage5 from '../../public/images/imaginxp-loginbanner-5.jpg';
import GPS from '../../public/icons/google-play.png';
import APS from '../../public/icons/apple-store-icon.png';
import config from '../../config';
//import LoginImage6 from '../../public/images/imaginxp-loginbanner-5.jpg';
//import 'react-notifications-component/dist/theme.css';
import './styles.css';
import countries from './countries.json';
import base64 from 'base-64';
import axios from 'axios';
import Service from '../../utils/Service';
import { Redirect } from 'react-router';

//import { useTransition,withTranslation } from 'react-i18next';
import common from '../../common';
const themePrimaryColor = config.themePrimaryColor;
const themeSecondaryColor = config.themeSecondaryColor;
const themePrimaryLightColor = config.themePrimaryLightColor;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      mobileno:'',
      hide: true,
      tab1:true,
      tab2:false,
      tab1active:true,
      tab2active:false,
      steponeisvisible:true,
      steptwoisvisible:false,
      formErrors:{},
      hide: true,
      mobileNumber:'',
      errorMessage:'',
      succMsg:'',
      forOTPVerify:false,
      OTPvalues:[],
      OTPinput1: '',
      OTPinput2: '',
      OTPinput3: '',
      OTPinput4: '',
      loginWithOTPTab:false,
      saveCountryCodeDefault:'91',
      saveCountryCode:'',
      registerNow:false,
      registerfName:'',
      registerEmail:'',
      registerCourse:'',
      registerMobile:'',
      registerSaveCountryCode:'',
      registerEmailError:'',
      registerMobileError:'',
      registerCourseError:'',
      registerFullNameError:'',
      saveRegisterCourses:[],

      saveLanguageWiseData:[],
      mistakeCount: 0,
      loginDisabled:false,
      currentDateTime : Date.now(),

      saveSettingsData:[],

      saveInstituteLanguages:localStorage.getItem('institute_languages'),
      // logourl:'',
      // defaultLogo:require(`../../public/images/${config.brandLogo}`)
      
  
      
      

    }
  }

  componentDidMount(){
    
    let getHostname = window.location.host;
    console.log("getHostname",window.location.host);

    if(getHostname == 'localhost:3000'){
      //window.location.href = 'https://sastra.verandahighered.com/app/login';
    }else if(getHostname == 'sastra.schoolsonweb.com'){
      window.location.href = 'https://sastra.verandahighered.com/app/login';
    }else if(getHostname == 'iim.schoolsonweb.com'){
      window.location.href = 'https://lms.verandahighered.com/app/login';
    }else if(getHostname == 'veranda.schoolsonweb.com'){
      window.location.href = 'https://lms.verandahighered.com/app/login';
    }



    console.log(this.state.currentDateTime);

    console.log("this.props.location.pathname");
    console.log(window.location.host);
    if(window.location.host){
      this.fetchLoginPageSetting(window.location.host)
      //this.fetchLoginPageSetting("sastra.verandahighered.com")
    }
    const leftTime = this.getTimeDifferenceInSeconds(this.state.currentDateTime , localStorage.getItem('mistakeTimerStartTime') ?  JSON.parse(localStorage.getItem('mistakeTimerStartTime')) : null);
    console.log("leftTime", leftTime);

    if(leftTime !== null){
      this.startTimer(parseInt(leftTime))
    }

    if(localStorage.getItem("mistakeCount") === null){
      localStorage.setItem('mistakeCount', 0);
      this.setState({mistakeCount:0})
    }else{
      this.setState({mistakeCount: JSON.parse(localStorage.getItem('mistakeCount'))})
    }

    if(localStorage.getItem("lang") === null){
      localStorage.setItem("lang",'en');
    }
     
    this.setState({saveCountryCode: "91"});
    this.setState({registerSaveCountryCode:"91"})
    if(localStorage.getItem('username')){
      this.setState({ username:localStorage.getItem('username')});
      localStorage.removeItem('username')
    }
    if(localStorage.getItem('password')){
      this.setState({ password:localStorage.getItem('password')})
      localStorage.removeItem('password')
    }
    //this.getWordpressCourses();
    
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
   
  }

  fetchLoginPageSetting = async (path) =>{
    console.log("path");
    console.log(path);
    const url = `${common.getCustomLoginPageSettings}`;
    const data = new FormData();
    data.append('path', "http://"+path);
    //data.append('path', "http://skyed.schoolsonweb.com");
    fetch(common.apiBase+url, {
      method: "POST",
      body: data
    }).then(res => res.json())
    .then(
        (result) => {
        this.setState({saveSettingsData: result.data});
        localStorage.setItem('loginPageSettings', JSON.stringify(result.data));
        this.setAllSettingsInLocalStorage();
        }
    )
  }


  setAllSettingsInLocalStorage = () =>{
    let getData = localStorage.getItem('loginPageSettings');
    console.log("getData");
    console.log(getData);

    let filteredFaviconURL = this.state.saveSettingsData.filter(data => {
      return data.key === 'fav_icon';
    }); 

    let filteredLanguages = this.state.saveSettingsData.filter(data => {
      return data.key === 'institute_languages';
    }); 

    let filteredLogoURL = this.state.saveSettingsData.filter(data => {
      return data.key === 'logo_url';
    });  

    let filteredPrimaryColor = this.state.saveSettingsData.filter(data => {
      return data.key === 'primary_color';
    });  

    let filteredSecondaryColor = this.state.saveSettingsData.filter(data => {
      return data.key === 'secondary_color';
      
    }); 

    let filteredPrimaryLightColor = this.state.saveSettingsData.filter(data => {
      return data.key === 'primary_color_light';
      
    }); 

    let filteredLoginBanner = this.state.saveSettingsData.filter(data => {
      return data.key === 'login_banner';
      
    }); 

    if(filteredLanguages){
      let setLanguages = filteredLanguages.map((n) => n.value);
      localStorage.setItem('institute_languages', JSON.stringify(setLanguages[0]));
    }

    if(filteredFaviconURL){
      let setFavicon = filteredFaviconURL.map((n) => n.value);
      localStorage.setItem('favicon_url', JSON.stringify(setFavicon[0]));
    }

    if(filteredLoginBanner){
      let setLoginBanner = filteredLoginBanner.map((n) => n.value);
      localStorage.setItem('login_banner', JSON.stringify(setLoginBanner[0]));
    }

    if(filteredPrimaryLightColor){
      let setPrimaryLightColor = filteredPrimaryLightColor.map((n) => n.value);
      localStorage.setItem('primary_color_light', setPrimaryLightColor[0]);
    }

    if(filteredSecondaryColor){
      let setSecondaryColor = filteredSecondaryColor.map((n) => n.value);
      localStorage.setItem('secondary_color', setSecondaryColor[0]);
    }

    if(filteredPrimaryColor){
      let setPrimaryColor = filteredPrimaryColor.map((n) => n.value);
      localStorage.setItem('primary_color', setPrimaryColor[0]);
      
    }

    if(filteredLogoURL){
      let setLogoURL = filteredLogoURL.map((n) => n.value);
      localStorage.setItem('logo_url', setLogoURL[0]);
      this.setState({logourl:setLogoURL[0]})
    }

    window.addEventListener('storage', () => {
      // When local storage changes do something like a refresh
      window.location.reload(true)
     
    });
    document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color') != 'undefined' ?  localStorage.getItem('primary_color'): themePrimaryColor);
    document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color')!= 'undefined' ? localStorage.getItem('secondary_color') : themeSecondaryColor);
    document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light')!= 'undefined' ?  localStorage.getItem('primary_color_light'): themePrimaryLightColor);
  
  }


  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"login");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      this.setState({ saveLanguageWiseData: response?.data});
      this.setState({ saveLanguageWiseData: response?.data});
      
    }
  }
  
  getWordpressCourses = async (e) =>{
    
    var apiurl = 'https://onlinevgu.com/wp-json/v1/api/get_registration_courses';
    var username = 'qa';
    var password = 'Wm^&sfs5R0Z(GFXuZgL4f4^4';
    let userPassCombination = username + ':' + password;
    let encodedPass = base64.encode(userPassCombination);

    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token).toString('base64');
    const config = {
      headers:{
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      auth: {
        username: 'qa',
        password: 'Wm^&sfs5R0Z(GFXuZgL4f4^4'
      }
    };
    await axios.get(apiurl,
      {headers: {
        'Authorization': 'Basic ' + encodedPass},
        auth: {
          username: 'qa',
          password: 'Wm^&sfs5R0Z(GFXuZgL4f4^4'
        }
      }).then(res => {
        console.log(res.data.resultData);
        this.setState({ saveRegisterCourses: res.data.resultData});
        console.log(this.state.saveRegisterCourses.reverse());
      }).catch(err => { console.error('error', err.message) })
    
    }


  onChangeUsername = (e) => this.setState({ username: e.target.value })

  onChangePassword = (e) => this.setState({ password: e.target.value })

  hideChange = () => this.setState({ hide: !this.state.hide })

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  }

  handleChange = (e) => {    
    const { name, value } = e.target;    
    this.setState({ [name]: value });    
  
  }

  startTimer = (leftTime) => {
    var totaltime = 60;
    if(leftTime){
      counter =  totaltime - leftTime;
    }

    if(leftTime >=  totaltime){
      this.setState({loginDisabled: false});
      localStorage.removeItem('mistakeTimerStartTime');
    }else{
      this.setState({loginDisabled: true});
    }

    var counter = leftTime ?  counter : totaltime;
    console.log("counter", counter);
    // if(leftTime !== null ){
    //   this.setState({loginDisabled: false});
    //  }
    setInterval(() => {
      counter--;
      if (counter >= 0) {
        let span = document.getElementById("loginDisabledTimer");
        span.innerHTML = counter.toString();
      }
    
      if (counter === 0) {
        localStorage.setItem('mistakeCount',0);
        this.setState({mistakeCount:JSON.parse(localStorage.getItem('mistakeCount'))});
        this.setState({loginDisabled:false});
        clearInterval(counter);
        localStorage.removeItem("mistakeTimerStartTime");
      }
    
    }, 1000);
}

 
  onSubmit = async () => {
    const { username, password} = this.state;
    if(this.state.mistakeCount != 5){
      this.setState({mistakeCount: ++this.state.mistakeCount});
    }
    
    if(this.state.mistakeCount === 5 || this.state.mistakeCount > 5){
      this.setState({loginDisabled: true});
      localStorage.setItem("mistakeTimerStartTime", Date.now());
      this.startTimer();
    }
    // alert(this.state.mistakeCount);
    localStorage.setItem("mistakeCount" , this.state.mistakeCount);
    console.log("mistakeCount",this.state.mistakeCount);
    
    await this.props.userLogin(username, password,localStorage.getItem('lang'),0);
  }

  getTimeDifferenceInSeconds = (timestamp1, timestamp2) => {
    const time1 = Math.floor(timestamp1 / 1000);
    const time2 = Math.floor(timestamp2 / 1000);;
    if(timestamp2 === null){
      return null
    }
    const differenceInSeconds = Math.abs((time2 - time1));
    console.log("differenceInSeconds",differenceInSeconds);
    return differenceInSeconds;
}


  tabs1 = () => {
    this.setState({tab1:true})
    this.setState({tab2:false});
    this.setState({tab1active:true});
    this.setState({tab2active:false});
  }

  tabs2 = () => {
    this.setState({tab1:false})
    this.setState({tab2:true})
    this.setState({tab1active:false});
    this.setState({tab2active:true});
  }

  handleKeyPressStep1 = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitStep1();
    }
  }

  handleSubmitStep1 = () => {  
    console.log("Auua")  
    if (this.handleFormValidationStep1()) {    
        console.log('You have been successfully Step One Completed.');
        this.setState({steponeisvisible : false});
        this.setState({steptwoisvisible : true});
        //console.log("Step 1 data -->" + this.state.username + this.state.emailid + this.state.mobileno + this.state.steponeisdisable, this.state.completedStep1) 
        console.log("Step 1 data -->" + this.state.mobileno + this.state.steponeisvisible, this.state.steptwoisvisible) 
        //this.setState(this.initialState);   
    }    
  }

  handleFormValidationStep1() {    
    //const { username, emailid, mobileno} = this.state; 
    const {mobileno} = this.state; 
    let formErrors = {};    
    let formIsValid = true;    
  
    
  //Phone number    
  // if (!mobileno) {    
  //   formIsValid = false;    
  //   formErrors["mobilenoErr"] = "Mobile number is required.";    
  // }    
  // else {    
  //   var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;    
  //   if (!mobPattern.test(mobileno)) {    
  //       formIsValid = false;    
  //       formErrors["mobilenoErr"] = "Invalid mobile number.";    
  //   }    
  // }  
  
  
  if (!mobileno) {    
    formIsValid = false;    
    formErrors["mobilenoErr"] = "Mobile number is required.";    
  }    
  else {    
    //var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;    
    // if (mobileno.length === 0) {    
    //     formIsValid = false;    
    //     formErrors["mobilenoErr"] = "Invalid mobile number.";    
    // }
  
    if (mobileno.length < 10) {    
      formIsValid = false;    
      formErrors["mobilenoErr"] = "Invalid mobile number";    
  }  
  
  } 
  
  
  this.setState({ formErrors: formErrors }); 
  return formIsValid;
  
  }
  onChangeMobileNumber = (e) =>{
    this.setState({mobileNumber: e.target.value});
    console.log(this.state.mobileNumber);
  }
  submitMobileNumber = async (event) => {
    console.log("saveCountryCodess")
    console.log(this.state.saveCountryCode);
    const regex = /^[1-9]{1}[0-9]{9}$/;
    const regex2 = /^[0-9]{0,15}$/;
    let groupCodeMobile  = this.state.saveCountryCode+this.state.mobileNumber;
    let groupCodeMobileLength = groupCodeMobile.length;
    console.log("groupCodeMobileLength")
    console.log(groupCodeMobileLength);
    console.log("mobileNumber")
    console.log(this.state.mobileNumber);

    if(groupCodeMobileLength >= 7 && groupCodeMobileLength <= 15 && !isNaN(this.state.mobileNumber,localStorage.getItem('lang'))){
      this.setState({errorMessage: ""});
      let response = await this.props.mobileOTPSend(this.state.saveCountryCode+this.state.mobileNumber,localStorage.getItem('lang'));
      if(response !== undefined && response.status == 1) {
        console.log("otp Send ho gya");
        this.setState({forOTPVerify  : true});
      }
      return true;
    }else{
      if(this.state.mobileNumber.length < 4){
        this.setState({errorMessage: this.state.saveLanguageWiseData.please_enter_valid_mobile});
      }
      if(this.state.mobileNumber.length  > 10){
        this.setState({errorMessage: this.state.saveLanguageWiseData.please_enter_valid_mobile});
      }
      if(this.state.mobileNumber == ''){
        this.setState({errorMessage: this.state.saveLanguageWiseData.mob_not_blank});
      }
      if(isNaN(this.state.mobileNumber)){
        this.setState({errorMessage: this.state.saveLanguageWiseData.please_enter_digit});
      }else{
        this.setState({errorMessage: this.state.saveLanguageWiseData.please_enter_valid_mobile});
      }
      return false;
    }
  
  }

  onEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      this.submitMobileNumber();
    }
  }

  GetOTPandValidate = async (event) => {
    console.log("get OTP Numbers");
    console.log(this.state.OTPvalues);
    let getValues = this.state.OTPvalues.join('');
    console.log("getValues");
    console.log(getValues);
    console.log("Mobile N0");
    console.log("saveMobileNo");
    console.log(this.state.saveCountryCode);
    console.log(JSON.stringify(this.state.mobileNumber));
    let join = this.state.saveCountryCode+this.state.mobileNumber;
    console.log("Join")
    console.log(join)
    console.log("validate APi Call");
    //let response = await this.props.mobileOTPValidate(this.state.mobileNumber,getValues,'','','','');
    let response = await this.props.mobileOTPValidate(join,getValues,'','','','',localStorage.getItem('lang'));
    if(response !== undefined && response.status == 1) {
      console.log("API Response");
      console.log(response);
    }

  }


  onChangeOTPEnter= (event) => {
    const {name} = event.target;
    let {OTPvalues} = this.state;
    
    var a = document.getElementById("otp1"),
    b = document.getElementById("otp2"),
    c = document.getElementById("otp3"),
    d = document.getElementById("otp4");
    
    if(event.target.value !== ''){
      OTPvalues.push(event.target.value);
      this.setState({OTPvalues});
      console.log(this.state.OTPvalues);
      console.log(this.state.OTPvalues.length);
    }else{
      console.log("elase");
      OTPvalues.pop('');
      this.setState({OTPvalues});
      console.log(this.state.OTPvalues);
      console.log(this.state.OTPvalues.length);
    }
    

    a.onkeyup = function() {
      if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
          b.focus();
      }
  }
  
    b.onkeyup = function() {
        if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
            c.focus();
        }
    }

    c.onkeyup = function() {
      if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
          d.focus();
      }
    }
  }


  loginWithUserName = (event) => {
    this.setState({loginWithOTPTab:false})
    this.setState({registerNow:false})

  }

  loginWithOTPLink = (event) =>{
    this.setState({loginWithOTPTab:true})
    this.setState({registerNow:false})
  }

  registerNow = (event) =>{
    this.setState({loginWithOTPTab:false})
    this.setState({registerNow:true})
  }

  onCountryCodeChange = (e) =>{
    const selectedIndex = e.target.options.selectedIndex;
    const getCode = e.target.options[selectedIndex].getAttribute('data-code')
    this.setState({saveCountryCode:getCode})
    this.setState({registerSaveCountryCode:getCode})
    console.log("registerSaveCountryCode");
    console.log(getCode);
  }

  onFullNameChange = (e) =>{
    this.setState({registerfName:e.target.value})
  }

  onCourseChange = (e) =>{
    this.setState({registerCourse:e.target.value})
  }

  isValidEmail = (email) =>{
    return /\S+@\S+\.\S+/.test(email);
  }

  onChangeEmail= (e) => {
    this.setState({registerEmail: e.target.value});
   
  }

  onChangeMobile= (e) => {
    this.setState({registerMobile: e.target.value});
    
   
  }

 
  onRegisterSubmit = () => {
    let error = 0;
    const { registerfName, registerEmail,registerCourse,registerMobile,registerSaveCountryCode } = this.state;
    //await this.props.userLogin(username, password,0);
    let groupCodeMobile  = this.state.registerSaveCountryCode+this.state.registerMobile;
    let groupCodeMobileLength = groupCodeMobile.length;


    if(groupCodeMobileLength >= 7 && groupCodeMobileLength <= 15 && !isNaN(this.state.registerMobile)){
      this.setState({registerMobileError: ""});
    }else{
      this.setState({registerMobileError: ""});
      this.setState({registerMobileError: this.state.saveLanguageWiseData.please_enter_valid_mobile});
      error = 1;
    }

    if (!this.isValidEmail(registerEmail)) {
      this.setState({registerEmailError: ""});
      this.setState({registerEmailError: this.state.saveLanguageWiseData.please_enter_valid_email});
      error = 1;
    } else {
      this.setState({registerEmailError: ""});
      
    }

    if (!registerfName) {
      this.setState({registerFullNameError: ""});
      this.setState({registerFullNameError: this.state.saveLanguageWiseData.please_enter_full_name});
      error = 1;
    }else {
      this.setState({registerFullNameError: ""});
    }

    if (!registerCourse) {
      this.setState({registerCourseError: ""});
      this.setState({registerCourseError: this.state.saveLanguageWiseData.please_choose_course});
      error = 1;
    }else {
      this.setState({registerCourseError: ""});
    }

    if(error == 1){
      return false;
   }

   this.registerNowFunction();
    
  }


  

  registerNowFunction = async (e) =>{
    const { registerfName, registerEmail,registerCourse,registerMobile,registerSaveCountryCode } = this.state;
    var apiurl = 'https://onlinevgu.com/wp-json/v1/api/user_signup_through_mobile';
    const data = { email: registerEmail, city: "",full_name: registerfName,mobile: registerMobile, center:registerCourse,member_type:1,Location:"",countryCode:"+"+registerSaveCountryCode + ' india'};
    await axios.post(apiurl, data,
      {headers: {
        "Access-Control-Allow-Origin": "*",
        'usersignupkey': `Ajkli@#12987FDGJH!`,
      },
      }).then(response => {
        console.log(response.data.resultData);
        console.log(response.data.resultData);
        if(response?.data.resultData?.auth_token){
          //this.props.history.push('/mycoach/');
          //this.getLearnerProfile();
          localStorage.setItem('logindetails', JSON.stringify(response.data.resultData.login_response));
          localStorage.setItem('token', response.data.resultData.login_response.auth_token);
          localStorage.setItem('name', response.data?.resultData.login_response.name);
          localStorage.setItem('preferredGrid', 'true');
          localStorage.setItem('user_id',  response.data.resultData.login_response.id);
          localStorage.setItem('username',  response.data.resultData.login_response.username);
          localStorage.setItem('dp',response.data.resultData.login_response.dashboard_type);
          localStorage.setItem('widget_alignment_size',response.data.resultData.login_response.widget_alignment.size);
          localStorage.setItem('widget_alignment_alignment',response.data.resultData.login_response.widget_alignment.alignment);
          //localStorage.setItem('lang',this.state.getLanguageFromLocal);
        

          if(response.data.resultData.login_response.center_details?.is_center_admin === true) {
            localStorage.setItem('is_admin', 'true');
            localStorage.setItem('username',response.data.resultData.login_response.username);
            localStorage.setItem('center_id', response.data.resultData.login_response.center_details?.center_id);
            Service.updateToken();
            window.location.href = '/admin/institute';
          }
          else if(response.data?.resultData.login_response.is_faculty === true) {
            
            localStorage.setItem('is_faculty', 'true');
            localStorage.setItem('username',response.data.resultData.login_response.username);
            localStorage.setItem('is_faculty_admin',"false")
            localStorage.setItem('center_id', response.data.resultData.login_response.center_details?.center_id);
            Service.updateToken();
            window.location.reload();
          }
          else {
            localStorage.setItem('is_faculty', 'false');
            localStorage.setItem('on_login', 1);
            localStorage.setItem('center_id', response.data.resultData.login_response.center_id);
            Service.updateToken();
            
            if(localStorage.getItem("gmail_source") == "1"){
              window.location.href = '/view-mapping'+localStorage.getItem("search");
            }else{
              window.location.reload();
            }
            
          }
          
        }else{
          console.log(response?.data.errors[0]);
          store.addNotification({
            title: 'Error',
            message: `${response?.data.errors[0]}`,
            type: 'danger',
            container: 'top-right',
            dismiss: {
              duration: 3000,
            }
          })
        }
      }).catch(err => { console.error('error', err.message) })
    
  }

    getLearnerProfile = async () => {
      const response = await this.props.getLearnerProfile();
      this.setState({ profile: response.data, loading: false,
          no_data: response.data.message == this.state.saveLanguageWiseData.not_assign_in_course
            ? true : false })
      this.setState({new_login:response.data.new_login,userid:response.data.email,uname:response.data.username,username:response.data.name,email:response.data.email,mobile:response.data.mobile,profile_picture:response.data.profile_picture,saveCountryCode:response.data.country_code});
        
      this.timer = setInterval(()=>this.scheduledOn(), 1000)
  
  
  
    }

    languageChange = (option) => {
      localStorage.setItem('lang',option.target.value);
      window.location.reload();
    }


    filteredLogoURL = () => {
      let filteredLogoURL = this.state.saveSettingsData.filter(data => {
        return data.key === 'logo_url';
      });  

      if(filteredLogoURL){
        let getLogoURL = filteredLogoURL.map((n) => n.value);
        return getLogoURL[0];
      }
   }


   

  render() {
    const  lang = localStorage.getItem('lang')||'en';
    const isEnabled = this.state.OTPvalues.length == 4;
    const { mobilenoErr} = this.state.formErrors;

    // const filteredLogoURL = this.state.saveSettingsData.filter(data => {
    //   let filterData =  data.key === 'logo_url';
    //   console.log("filterData");
    //   console.log(JSON.stringify(filterData));
    // });

    return (
      <div className="login-div">
        <ReactNotifications />
        <div className="login_left">
          
          {localStorage.getItem('login_banner') !=  'undefined' && localStorage.getItem('login_banner') ?
          <Carousel enableAutoPlay autoPlaySpeed={20000}>
          {JSON.parse(localStorage.getItem('login_banner')).length && JSON.parse(localStorage.getItem('login_banner')).length > 0 && JSON.parse(localStorage.getItem('login_banner')).map((data, index) => (
            <items>
              <div className="login-image">
              <img src={data.url} alt="img"/>
              </div>
              <div className="login-title">
                {data.title}
                <span>{data.desc}</span>
              </div>
            </items>
          ))}
          </Carousel>
          : ''}
          
         {/* <items>
              <div className="login-image">
              <img src={require("../../public/images/"+config.loginSiderBanner2)} alt="img"/>
              </div>
              <div className="login-title">
                {this.state.saveLanguageWiseData.india_strongest_mentorship_ecosystem}
                <span>{this.state.saveLanguageWiseData.learn_from_subject_matter_expects_and_realtime_live_classes}</span>
              </div>
            </items>
            <items>
              <div className="login-image">
              <img src={require("../../public/images/"+config.loginSiderBanner3)} alt="img"/>
              </div>
              <div className="login-title">
              {this.state.saveLanguageWiseData.a_truly_world_class_learning}
                <span>{this.state.saveLanguageWiseData.future_ready_curriculum_designed_to_ensure_success}</span>
              </div>
            </items>
            <items>
              <div className="login-image">
              <img src={require("../../public/images/"+config.loginSiderBanner4)} alt="img"/>
              </div>
              <div className="login-title">
              {this.state.saveLanguageWiseData.extensive_practice_programs}
                <span>{this.state.saveLanguageWiseData.competitive_opportunities_simulated_to_practice_real_time_exams}</span>
              </div>
            </items> */}
            
            
          
        </div>
        <div className="login_right">
         {this.state.saveInstituteLanguages && this.state.saveInstituteLanguages !== 'undefined' && JSON.parse(this.state.saveInstituteLanguages).length > 1 &&
          <div className="languageTranslator">
            <div className='block'>
            <label>{this.state.saveLanguageWiseData.choose_language}</label>
            <select onChange={this.languageChange} value={lang}>
              {this.state.saveInstituteLanguages.length && JSON.parse(this.state.saveInstituteLanguages).map((languageOption) => ( 
                <option value={languageOption.code} key={languageOption.code}>{languageOption.label}</option>
              ))}
              </select>
            
             
              {/* <select onChange={this.languageChange} value={lang} Style="display:none">
                <option value="en">English</option>
                <option value="ar">عربي</option>
                <option value="gu">ગુજરાતી</option>
                <option value="hi">हिंदी</option>
              </select> */}
            </div>
            
          </div>
          }
        <div className='login-form-outer'>
          <div className="login_header">
            {/* <span><img src={Logo} alt="logo"/></span> */}
            {/* <span><img src={Logo} alt="logo"/></span> */}
            {/* <span><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span> */}
            {localStorage.getItem('logo_url') != 'undefined' ? 
              <span><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
            : 
            <span><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span>
            }

            

        	</div>
          {this.state.loginDisabled &&
           <div className='disabledMessage'>Please try after <span id='loginDisabledTimer'></span> seconds</div>
           }    
          {/* <div className="login-sub-title">
            Welcome to Schools On Web
        	</div> */}
           {this.state.loginWithOTPTab && !this.state.registerNow && 
           <div className={this.state.loginDisabled ? 'OTPFormWrapper loginDisabled': 'OTPFormWrapper'}>
            <div className="login_title">{this.state.saveLanguageWiseData?.login_with_mobile}</div>
            {!this.state.forOTPVerify &&
              <div className="step1">
                <div className="block">
                <select defaultValue="IN" onChange={this.onCountryCodeChange} data-flag="true">
                {countries.map((countryOptions) => (
                <option value={countryOptions.cca2} key={countryOptions.cca2} data-code={countryOptions.code}>{`${countryOptions.flag} ${countryOptions.name}`}</option>
              ))}
              </select>
                <input type="tel" className='mobileInput' onKeyPress={this.onEnterKeyPress} value={this.state.mobileNumber} name="mobileNumber"  onChange={this.onChangeMobileNumber}  placeholder={this.state.saveLanguageWiseData.enter_your_mobile_no}/>
                </div>
                {this.state.errorMessage !== '' && <div className="errorMsg">{this.state.errorMessage}</div>}
                <div className='buttonBlock'>
                    <button onClick={this.submitMobileNumber}>{this.state.saveLanguageWiseData.send_otp}</button>
                </div>
              </div>
              }
              {this.state.forOTPVerify &&
              <div class="step2">
                <div className='title'>{this.state.saveLanguageWiseData.enter_your_4_digit}</div>
                 <div className='inputBlock'>
                   <input type="text" id="otp1"  name="OTPValue-1" maxlength={1}  onChange={this.onChangeOTPEnter}/>
                   <input type="text" id="otp2"  name="OTPValue-2" maxlength={1} onChange={this.onChangeOTPEnter}/>
                   <input type="text" id="otp3"  name="OTPValue-3" maxlength={1} onChange={this.onChangeOTPEnter}/>
                   <input type="text" id="otp4"  name="OTPValue-4" maxlength={1} onChange={this.onChangeOTPEnter}/>
                 </div>
                 <div className='expireOTP'>{this.state.saveLanguageWiseData.otp_expires_in_30_minutes}</div>
                <div className='buttonBlock'>
                    <a onClick={this.submitMobileNumber}>{this.state.saveLanguageWiseData.resend_otp}</a>
                    <button onClick={this.GetOTPandValidate} disabled={!isEnabled}>{this.state.saveLanguageWiseData.submit}</button>
                </div>
              </div>
              }
            <div className="orDivider">
                  <span>{this.state.saveLanguageWiseData.or}</span>
              </div>
            <div className='loginWithUserNameLink' onClick={this.loginWithUserName} title={this.state.saveLanguageWiseData.login_with_username_password}><span>{this.state.saveLanguageWiseData.login_with_username_password}</span></div> 
            <div className='signUpLink' Style="display:none">
                <p>{this.state.saveLanguageWiseData.dont_have_account}</p>
                <span onClick={this.registerNow} title={this.state.saveLanguageWiseData.signup}>{this.state.saveLanguageWiseData.signup}</span>
            </div> 
            
            <div className="addDownloadWrapper">
            {this.state.saveLanguageWiseData.app_store}
                  <div>
                  <a title="Google Play Store" href="https://play.google.com/store/apps/details?id=com.imaginxp&referrer=utm_source%3Dixp_live_web%26utm_medium%3Dweb_app_login%26utm_campaign%3Dorganic_web_app" target="_blank"><img src={GPS} alt="GPS"/></a>
                  <a title="Apple Play Store" href="https://apps.apple.com/app/apple-store/id1577929243?pt=123328305&ct=web_app_login&mt=8" target="_blank"><img src={APS} alt="APS"/></a>
                  </div>
                </div>
            </div>
           }  

           
          
           {!this.state.loginWithOTPTab && !this.state.registerNow && 
          <div className= {this.state.loginDisabled ? 'login-form loginDisabled': 'login-form'}>
            <div className="login_title">{this.state.saveLanguageWiseData.login_with_username}</div>
              

          {/* <div className="form-heading">
            Login
          </div> */}
            {/* <div className="tabsWrapper">
              <Link onClick={this.tabs1} className= {this.state.tab1active ? 'active':''}>Email Login</Link>
              <Link onClick={this.tabs2} className= {this.state.tab2active ? 'active':''}>Mobile Login</Link>
            </div> */}
            <div className= {this.state.tab1 ? 'visible login-form1':'notvisible login-form1'}>
                <div className="input_field">
                  <input className='userNameInput' type="email" placeholder={this.state.saveLanguageWiseData.username} 
                    onKeyPress={this.handleKeyPress} onChange={this.onChangeUsername} value={this.state.username}/>
                </div>
                <div className="input_field">
                  <input className='passwordInput' type={this.state.hide ? "password" : "text"} 
                    placeholder={this.state.saveLanguageWiseData.password} onKeyPress={this.handleKeyPress}
                    onChange={this.onChangePassword} value={this.state.password}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                <div className="login-actions">
                  <Link to={'/forgot-password'}>{this.state.saveLanguageWiseData?.forgot_password}</Link>
                  <button className='login' onClick={this.onSubmit}>
                    {this.state.saveLanguageWiseData.login}
                  </button>
                </div>
             </div>

            <div className= {this.state.tab2 ? 'visible login-form2':'notvisible login-form2'}>
                 <div className= {this.state.steponeisvisible ? 'isvisible otp-login-field':'isnotvisible otp-login-field'}>
                 <input type="number" name="mobileno" placeholder={this.state.saveLanguageWiseData.enter_your_mobile_no} 
                    onKeyPress={this.handleKeyPressStep1} onChange={this.handleChange}   value={this.state.mobileno}  className={mobilenoErr ? ' showError' : ''}/>
                    {mobilenoErr &&    
                      <div className="error-msg">{mobilenoErr}</div>    
                    }  
                    <button onClick={()=>this.handleSubmitStep1()}>{this.state.saveLanguageWiseData.send_otp}</button>   
                  </div>
                  <div className= {this.state.steptwoisvisible ? 'isvisible otp-login-field':'isnotvisible otp-login-field'}>
                  <div className="otp-login-field">
                    <input type="number"   placeholder={this.state.saveLanguageWiseData.enter_otp}/>
                 </div> 
                 <div className="otp-block">
                    <div></div>
                    <Link>{this.state.saveLanguageWiseData.resend_otp}</Link>
                 </div> 
                 <div className="otp-login-field">
                    <button>{this.state.saveLanguageWiseData.login}</button>   
                  </div> 
                  </div> 
                {/* <div className="login-actions">
                  <Link to={'/forgot-password'}>Forgot Password?</Link>
                  <button className="isdisable">
                    Login
                  </button>
                </div> */}
            </div>
            {/*<div className="register-block">
            Not a member yet? <Link to={'/register'}>Register Now</Link>
            </div>*/}
            <div className="orDivider" Style="display:none">
                  <span>{this.state.saveLanguageWiseData.or}</span>
              </div>
            <div className='loginWithOTPLink' Style="opacity:0; visibility:hidden" onClick={this.loginWithOTPLink} title={this.state.saveLanguageWiseData?.login_with_mobile}>{this.state.saveLanguageWiseData?.login_with_mobile}</div> 
           
          </div>
            }

            {this.state.registerNow && 
            <div className='registerForm'>
                <div className="login_title">{this.state.saveLanguageWiseData.register}</div>
                <div className= {this.state.tab1 ? 'visible login-form1':'notvisible login-form1'}>
                <div className="input_field"><input onChange={this.onFullNameChange} className='userNameInput' type="text" placeholder={this.props.saveLanguageWiseData.fullname}/></div>
                {this.state.registerFullNameError !== '' && <div className="errorMsg">{this.state.registerFullNameError}</div>}
                  <div className="input_field">
                    <input className='userNameInput' onChange={this.onChangeEmail} type="email" placeholder={this.props.saveLanguageWiseData.email_address}/>
                  </div>
                  {this.state.registerEmailError !== '' && <div className="errorMsg">{this.state.registerEmailError}</div>}
                 
                  <div className="select_field">
                  <select defaultValue="IN" onChange={this.onCountryCodeChange} data-flag="true">
                    {countries.map((countryOptions) => (
                    <option value={countryOptions.cca2} key={countryOptions.cca2} data-code={countryOptions.code}>{`${countryOptions.flag} ${countryOptions.name}`}</option>
                  ))}
              </select>
                <input type="tel" className='mobileInput' onChange={this.onChangeMobile}   name="mobileNumber"  placeholder={this.state.saveLanguageWiseData.enter_your_mobile_no}/>
                  </div>
                  {this.state.registerMobileError !== '' && <div className="errorMsg">{this.state.registerMobileError}</div>}
                  <div className="selectField">
                  {/* <select onChange={this.onCourseChange}>
                      <option value="">{this.state.saveLanguageWiseData.select_course}</option>
                      {this.state.saveRegisterCourses.map((courseOption) => (
                        <option value={courseOption}>{courseOption}</option>
                      ))}
                  </select> */}
                  </div>
                  {this.state.registerCourseError !== '' && <div className="errorMsg">{this.state.registerCourseError}</div>}
                  <div className="login-actions">
                   <button className='login' onClick={this.onRegisterSubmit}>{this.state.saveLanguageWiseData.signup}</button>
                  </div>
              </div>
              <div className='loginWithOTPLink' onClick={this.loginWithOTPLink} title={this.props.saveLanguageWiseData.login_with_mobile}>{this.state.saveLanguageWiseData.login_with_mobile}</div> 
            </div>
              }
              
          </div>
         

        </div>
        </div>
      
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userLogin,mobileOTPSend,mobileOTPValidate,callRegsiterAPI,getLearnerProfile,getLanguageWiseData,getCustomLoginPageSettings} = actions;
  return {
    userLogin: (username, password,language) => dispatch(userLogin(username, password,language)),
    mobileOTPSend: (mobile,language) => dispatch(mobileOTPSend(mobile,language)),
    mobileOTPValidate: (mobile,otp,device_id,device_type,device_token,fcm_token,language) => dispatch(mobileOTPValidate(mobile,otp,device_id,device_type,device_token,fcm_token,language)),
   //callRegsiterAPI: (email, city,full_name,mobile,center,member_type,Location,countryCode) => dispatch(callRegsiterAPI(email, city,full_name,mobile,center,member_type,Location,countryCode)),
   getLearnerProfile: () => dispatch(getLearnerProfile()),
   getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
   getCustomLoginPageSettings:(path) => dispatch(getCustomLoginPageSettings(path))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);