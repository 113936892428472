import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, ViberShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, EmailIcon, ViberIcon } from 'react-share';
import axios from 'axios';
import common from '../../common';
import actions from '../../redux/actions';
import OOPS from '../../public/icons/WiredMobile.png';
import Search from '../../public/icons/search.svg';
import Edit from '../../public/icons/edit.svg';
import Edit1 from '../../public/icons/white-pencil.svg';
import Notification from '../../public/icons/notification.svg';
import Profile from '../../public/icons/profile.svg';
import Classroom from '../../public/icons/classroom.svg';
import Live from '../../public/icons/live.svg';
import Weblink from '../../public/icons/weblink.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import Setting from '../../public/icons/setting.svg';
import Next from '../../public/icons/next.svg';
import ForgotImage from '../../public/icons/Group2701.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import REFER from '../../public/icons/refer.png';
import { store } from 'react-notifications-component';
import validator from 'validator';
import Chapter from '../../public/icons/chapter.svg';
import Note from '../../public/icons/notes.svg';
import Practise from '../../public/icons/practise.svg';
import Subtopic from '../../public/icons/subtopic.svg';
import Topic from '../../public/icons/topic.svg';
import Unit from '../../public/icons/unit.svg';
import Logout from '../../public/icons/power-button-svgrepo-com.svg';
import Video from '../../public/icons/video.svg';
import Empty from '../../components/error/Empty';
import profileArrow from '../../public/images/down-arrow.png'
import profileIcon from '../../public/icons/profile-icon.png'
import askicon from '../../public/icons/mycoach-ask-icon.png';
import feedbackIcon from '../../public/icons/feedback-icon.svg';
import questionnaireIcon from '../../public/icons/questionnaire-icon.svg';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import Email from '../../public/icons/email.png';
import SUSHANTLOGO from '../../public/icons/sushantu.jpeg';
import c_video from '../../public/icons/c_video.png';
//import profileArrow from '../../public/images/down-arrow.png'
//import profileIcon from '../../public/icons/profile-icon.png'
//import askicon from '../../public/icons/mycoach-ask-icon.png';
//import feedbackIcon from '../../public/icons/feedback-icon.svg';
import questiondayHeader from '../../public/images/questionoftheday-header.svg'
import questiondayLeftBG from '../../public/images/questionoftheday-bg.png';
import cilogo from '../../public/images/cilogo.jpg';
import phonecall from '../../public/icons/phonecall.png';
import GPS from '../../public/icons/google-play.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';
import APS from '../../public/icons/apple-store-icon.png';
import countries from '../../pages/login/countries.json'
import attendanceIcon from '../../public/icons/attendance-report-video-icon.svg';
import InternetSpeedTest from '../../pages/facultyReports/SpeedTest';
import { props } from 'lodash/fp';

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}



const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  navigation: false,
  arrows: true,
  focusOnSelect: false,
  draggable: false,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: "linear",
  appendDots: dots => (
    <div>
      <ul> {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div>
      {i + 1}
    </div>
  )

};

let popupWaitCounter = 0

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      mobile: '',
      dropdisplay: false,
      dropdisplay2: false,
      profile: {},
      no_data: false,
      loading: false,
      open: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      ques: false,
      message: '',
      hide1: true,
      hide2: true,
      hide: true,
      userid: '',
      imageURL: null,
      image: null,
      comment: "",
      message: "",
      message_status: 0,
      isValiated: true,
      error: "",
      password: "",
      new_password: "",
      confirm_password: "",
      uname: "",
      profile_picture: "",
      text: '',
      seconds: new Date(),
      searchList: [],
      questionListing: [],
      questionOption: [],
      view_result: {},
      show_result: false,
      questionListingArray: [],
      notificationCount: 0,
      if_selected: false,
      seconds: new Date(),
      is_faculty: localStorage.getItem('is_faculty') === 'true' ? true : false,
      mobileview: false,
      new_login: false,
      is_error_found: false,
      is_error_found_login: false,
      error_message: '',
      error_code: 200,
      classTagsData: [],
      saveCountryCode: '',
      totalMobileLength: '',
      mobileErrorMsg: '',
      disableBtn: false,
      saveLocale: '',
      saveInstituteLanguages: localStorage.getItem('institute_languages'),
      learnerNotificationArray: [],
      showSystemSpecification: false

    }

    this.mounted = true;
    this.nextArrow = this.nextArrow.bind(this);
    this.previousArrow = this.previousArrow.bind(this);
  }

  myfunction = (e) => {
    if (e.target.id == "csk-set-karo") {
      this.redirectToCSK()
    }
    if (e.target.id == "imaginxp-set-karo") {
      this.redirectToImaginxp()
    }
    if (e.target.id == "referral-set-karo") {
      this.redirectToReferal()
    }
  }

  componentDidMount() {
    this.validatePage()
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('click', this.myfunction);
    this.learnerNotificationLogs(0, 1);

    this.getLearnerProfile();

    this.setState({ comment: "Check out exclusive Schools on Web app - For schools by schools. Check out with Schools administration to enroll you in application" })

    document.body.addEventListener('click', this.myHandler);


    if (localStorage.getItem("on_login") == 1) {
      this.setState({ ques: true })
    }

    if (localStorage.getItem('lang')) {
      let language = localStorage.getItem('lang');
      console.log("language", language);
      this.fetchLanguageWiseData(language);
    }

    // setTimeout(()=>{
    //   console.log('+++++++++++++++++++++++++');
    //   this.setState({is_error_found:parseInt(localStorage.getItem("is_error_found")) ? true:false})
    //   this.setState({is_error_found_login:parseInt(localStorage.getItem("is_error_found_login")) ? true:false})
    //   this.setState({error_message:localStorage.getItem("error_message")})
    //   this.setState({error_code:parseInt(localStorage.getItem("error_code"))})
    // },500)

    //setTimeout(this.initPopupAgain.bind(this), 100);

    //this.getQODList();

    //this.userNotificationLogs();

    //if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    this.validatePage()
    //}
    //this.userNotificationLogs();

    this.switchClassTags()

  }

  learnerNotificationLogs = async (is_read, pagenumber) => {
    const response = await this.props.userNotificationLogs(is_read, pagenumber);

    if (response != 'undefined') {
      console.log("REPONSE", JSON.stringify(response?.data.pagination))
      this.setState({ learnerNotificationArray: response?.data?.pagination ? response?.data?.pagination?.count : "" })
    }
  }


  fetchLanguageWiseData = async (language) => {
    const response = await this.props.getLanguageWiseData(language, "learner");
    if (response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData', JSON.stringify(response?.data))


    }
  }

  switchClassTags = async () => {
    const response = await this.props.switchClassTags();
    if (response && response.status == 1) {
      this.setState({ classTagsData: response.data });
      console.log(this.state.classTagsData)
    }
  }

  validatePage = () => {
    if (localStorage.getItem('token')) {
      localStorage.setItem("is_error_found", 0)
      localStorage.setItem("is_error_found_login", 0)
      axios.get(`${common.apiBase}${common.authTokenValidate}`,
        {
          headers: {
            'Authorization': 'token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status == 0) window.location.href = '/login';
        }).catch(err => {
          // console.log('------Error', JSON.stringify(err));
          // console.log('error', err.status)
          if (err.message == 'Request failed with status code 403') {
            localStorage.setItem("is_error_found", 1)
            localStorage.setItem("error_message", "Your session expired! Please login again")
            localStorage.setItem("error_code", 403)
            this.setState({ is_error_found: true, error_message: "Your session expired! Please login again", error_code: 403, is_error_found_login: true })

          } else if (err.status == 'Network Error') {
            this.setState({ is_error_found: true, error_message: "Your network connection is lost! Please try after some time", error_code: 402 })
          } else {
            let getCenterID = localStorage.getItem("center_id");
            let getUserRole = localStorage.getItem("is_admin");
            console.log(getCenterID);
            console.log(getUserRole);
            if (getCenterID == 5 && !getUserRole) {
              localStorage.clear()
              window.location.href = 'https://sia.mic.gov.in';
            } else {
              localStorage.clear()
              window.location.href = '/login';
            }
            // localStorage.clear()
            // window.location.href='/login';
          }
          localStorage.setItem("is_error_found_login", 1)
        })
    }
  }


  initPopupAgain = () => {
    popupWaitCounter++
    console.log('+++++++++++++++++++++++++');
    this.setState({ is_error_found: parseInt(localStorage.getItem("is_error_found")) ? true : false })
    this.setState({ is_error_found_login: parseInt(localStorage.getItem("is_error_found_login")) ? true : false })
    this.setState({ error_message: localStorage.getItem("error_message") })
    this.setState({ error_code: parseInt(localStorage.getItem("error_code")) })

    if (popupWaitCounter <= 30) {
      setTimeout(this.initPopupAgain.bind(this), 1000);
    }
  }



  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.mounted = false;
  }


  nextArrow() {

    let { questionListingArray } = this.state

    questionListingArray.splice(0, 1)
    this.setState({ questionListingArray, show_result: false })

    if (this.state.if_selected) {
      document.getElementsByClassName("notification-box")[0].style.display = "block"
      return 1
    }

    this.slider.slickNext()
    //questionListingArray.length == 0 ? this.setState({ques:false}) :this.slider.slickNext() ;

  }
  previousArrow() {
    if (this.state.if_selected) {
      document.getElementsByClassName("notification-box")[0].style.display = "block"
      return 1
    }

    this.slider.slickPrev();

  }

  getQODList = async () => {
    const response = await this.props.qodStudentList()

    if (response != undefined) {
      this.setState({ questionListing: response.data.filter(p => (p.selected_option_id == "" || p.selected_option_id == null) && (new Date(p.due_date).getTime() >= new Date().getTime())), questionListingArray: response.data.map((val) => val?.id), last_question_id: response.data[response.data.length - 1]?.id })
      if (response.data.filter(p => (p.selected_option_id == "" || p.selected_option_id == null) && (new Date(p.due_date).getTime() >= new Date().getTime()))?.length) {
        if (localStorage.getItem("on_login") == 1) {
          this.setState({ ques: true })
        }
      }
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  setMyRef = (node) => {
    this.myRef = node;
  }

  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    this.setState({
      profile: response?.data, loading: false,
      no_data: response?.data?.message == "You are not assigned to any class and section, Please contact Admin for assigning into class"
        ? true : false
    })
    this.setState({ new_login: response?.data?.new_login, userid: response?.data?.email, uname: response?.data?.username, username: response?.data?.name, email: response?.data?.email, mobile: response?.data?.mobile, profile_picture: response?.data?.profile_picture, saveCountryCode: response?.data?.country_code ? response?.data?.country_code : "91" });

    this.timer = setInterval(() => this.scheduledOn(), 1000)



  }

  scheduledOn = () => {
    const { seconds } = this.state

    let dt1 = new Date();

    let secs = (dt1.getTime() / 1000) - (seconds.getTime() / 1000);

    var diff = dt1.getTime() / (1000 * 60) - seconds.getTime() / (1000 * 60);

    if (Math.round(secs) === 10 && this.state.new_login) {
      if (!localStorage.getItem("on_first_login_popup")) {
        localStorage.setItem("on_first_login_popup", 1)
        //if(!profile.name || !profile.email|| !profile.mobile)
        this.setState({ open5: true })
      }
    }

    /*if(Math.round(diff) === 30){
      if(!profile.name || !profile.email|| !profile.mobile)
        this.setState({open5:true})
    }*/
    //console.log("onsched"+diff)
  }

  onChangeUsername = (e) => this.setState({ username: e.target.value })
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    validator.isEmail(e.target.value) ? this.setState({ isValiated: true }) : this.setState({ isValiated: false });

  }
  onChangeMobile = (e) => {
    console.log(e.target.value)
    let getSaveCountryCode = this.state.saveCountryCode;

    console.log("getSaveCountryCode", getSaveCountryCode)

    if (isNaN(e.target.value)) return true;
    this.setState({ mobile: e.target.value });
    let totalLength = getSaveCountryCode.length + e.target.value.length;
    this.setState({ totalMobileLength: totalLength });
    console.log("totalLength", totalLength);

    if (totalLength >= 7 && totalLength <= 15) {
      console.log("condition true");
      this.setState({ disableBtn: true });
    } else {
      this.setState({ mobileErrorMsg: "please enter valid mobile no" });
      console.log("condition false");
      this.setState({ disableBtn: false });
    }




  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdisplay: false })
    }
    if (this.myRef && !this.myRef.contains(event.target)) {
      this.setState({ dropdisplay2: false })
    }
  }

  onSubmit = async () => {
    let image;

    if (this.state.profile_picture === "" || this.state.profile_picture === null) {
      image = this.state.image;
    } else {
      image = this.state.profile_picture;
    }
    const response = await this.props.updateProfile(this.state.username, this.state.email, this.state.mobile, image, this.state.saveCountryCode ? this.state.saveCountryCode : '91');
    this.setState({ open: false, open5: false });
  }

  dropchange = () => {
    this.setState({
      dropdisplay: !this.state.dropdisplay
    });
  }

  dropchange2 = () => {
    this.setState({
      dropdisplay2: !this.state.dropdisplay2
    });
  }

  changePassword = async (username, password, new_password) => {
    const response = await this.props.changePassword(username, password, new_password, localStorage.getItem('lang'));
    if (response != undefined) {
      this.setState({ open2: false });
      this.props.userLogin(username, new_password, 0);
    }
  }


  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value, confirm_password: '' })
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({ confirm_password: e.target.value });

    e.target.value === this.state.new_password ? this.setState({ is_password_correct: 1 }) : this.setState({ is_password_correct: 0 });
  }

  contactUs = () => {
    window.open("https://schoolsonweb.com/contact/", "_blank");
  }

  termsAndCondition = () => {
    window.open("https://schoolsonweb.com/terms/", "_blank");
  }

  reportAnIssue = () => {
    window.open("https://schoolsonweb.com/report-an-issue/", "_blank");
  }

  handleChange = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.images_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  addComment = (e) => {
    this.setState({ comment: e.target.value ? e.target.value : this.state.comment });
  }

  removeImage = () => {

    this.setState({ image: null, imageURL: null });
    this.setState({ profile_picture: "" });
    document.getElementById('img-input').value = "";
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })

  show2 = () => {
    this.getLearnerProfile();
    this.setState({ open: true });
  }
  show4 = () => {
    this.setState({ open2: true, password: '', new_password: '', confirm_password: '', is_password_correct: 0 });
  }
  show5 = () => this.setState({ open3: true });

  show7 = async () => {
    const response = await this.props.qodStudentList()

    if (response != undefined) {
      this.setState({ questionListing: response.data.filter(p => (p.selected_option_id == "" || p.selected_option_id == null) && (new Date(p.due_date).getTime() >= new Date().getTime())), questionListingArray: response.data.map((val) => val?.id), last_question_id: response.data[response.data.length - 1]?.id })
    }
    this.setState({ ques: true })
  }

  close = () => this.setState({ open: false });
  close2 = () => this.setState({ open2: false });
  close3 = () => this.setState({ open3: false });
  close4 = () => this.setState({ open4: false });

  close6 = () => this.setState({ open5: false });

  close5 = () => {
    this.setState({ ques: false });
    localStorage.setItem("on_login", 0)
  }

  show6 = async (e) => {
    //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
    this.setState({ text: e.target.value })
    let response = await this.props.getSearchList(e.target.value);
    this.setState({ searchList: response?.data });

    if (response?.data && response?.data != undefined) {
      var element = document.getElementById("myDIV");
      element.classList.add("open");
      document.getElementsByClassName("tab-content")[0].style.display = "block";
    } else {
      var element = document.getElementById("myDIV");
      element.classList.remove("open");
      document.getElementsByClassName("tab-content")[0].style.display = "none";
    }
  }

  addText = (e) => {
    this.setState({ text: e.target.value })
  }

  getColor = (content) => {
    if (content != undefined) {
      if (content === 4 || content === 5) {
        return {}
      }
      else {
        return { backgroundColor: '#e2f2ff' };
      }
    }
  }

  getColorCode = (option_id, is_correct, selected_option_id) => {

    //console.log(selected_option_id + ""+ is_correct)
    if (is_correct && selected_option_id && selected_option_id == option_id) {
      //console.log("yes")
      return { border: "1px solid #0ec346" }
    }

    if (!is_correct && selected_option_id && selected_option_id == option_id) {
      //console.log("no")
      return { border: "1px solid #e80e2c" }
    }

    if (is_correct && selected_option_id) {
      return { border: "1px solid #0ec346" }
    }

  }

  onOptionChange = (id) => {

    let elmts = document.getElementsByClassName("selected");

    for (let i = 0; i < elmts.length; i++) {
      //console.log(elmts[i].getAttribute("id"))
      if (elmts[i].getAttribute("id") != id) {
        elmts[i].classList.remove("selected")
      }
    }

    this.setState({ if_selected: false })

    let element = document.getElementById(id)

    if (element.classList.contains("selected")) {
      element.classList.remove("selected");
      this.setState({ if_selected: false })
      document.getElementsByClassName("notification-box")[0].style.display = "none"
    } else {
      element.classList.add("selected");
      this.setState({ if_selected: true })
    }
    this.setState({ questionOption: id })
  }

  saveAttempt = async (id, showresult) => {

    const { questionOption, seconds, view_result, show_result } = this.state

    let { questionListingArray } = this.state

    questionListingArray.splice(0, 1)
    this.setState({ if_selected: false, questionListingArray })
    document.getElementsByClassName("notification-box")[0].style.display = "none"

    let time_taken = new Date();

    time_taken = (time_taken.getTime() / 1000) - (seconds.getTime() / 1000)

    const response = await this.props.qodQuestionSubmit(id, questionOption, "", parseInt(time_taken))

    if (showresult == "true") {
      view_result[id] = response.data?.resultData;
      this.setState({ view_result, show_result: true })
    }
    //else questionListingArray.length == 0 ? this.setState({ques:false}) :this.slider.slickNext() ;
  }

  viewResult = (id) => {
    const { view_result } = this.state

    let correct_option_id = view_result[id].correct_option_id.toString()

    let selected_option_id = view_result[id].selected_option_id.toString()

    console.log(correct_option_id)
    console.log(selected_option_id)
    if (parseInt(correct_option_id) === parseInt(selected_option_id)) {
      document.getElementById(selected_option_id).style.border = "1px solid #0ec346"
    }

    if (parseInt(correct_option_id) != parseInt(selected_option_id)) {
      document.getElementById(correct_option_id).style.border = "1px solid #0ec346"
      document.getElementById(selected_option_id).style.border = "1px solid #e80e2c"
    }

    //console.log(view_result)
  }

  mobileclick() {
    var element = document.getElementById("bodyParam");
    element.classList.add('mobileview');

  }

  mobileOverlayclick() {
    var element = document.getElementById("bodyParam");
    element.classList.remove('mobileview');
  }

  redirectToCSK = async (slug = "", domain = "https://careersetkaro.com") => {
    const response = await this.props.getwpUserToken();
    this.setState({ token: response.data ? response.data.token : null });
    const username = localStorage.getItem("username")

    //if(slug)
    window.open(`${domain}/1.php?token=${response.data?.token}&username=${username}&slug=${slug}`, "_self");
  }

  redirectToReferal = async (slug = "referral", domain = "https://careersetkaro.com") => {
    const response = await this.props.getwpUserToken();
    this.setState({ token: response.data ? response.data.token : null });
    const username = localStorage.getItem("username")

    /* console.log(`${domain}/1.php?token=${response.data?.token}&username=${username}&page=${slug}`)
     return false*/
    //if(slug)
    window.open(`${domain}/1.php?token=${response.data?.token}&username=${username}&page=${slug}`, "_self");
  }

  redirectToImaginxp = async (slug = "", domain = "https://imaginxp.com") => {
    const response = await this.props.getwpUserToken();
    this.setState({ token: response.data ? response.data.token : null });
    const username = localStorage.getItem("username")

    //if(slug)
    window.open(`${domain}/1.php?token=${response.data?.token}&username=${username}&slug=${slug}`, "_self");
  }

  userNotificationLogs = async () => {
    const response = await this.props.userNotificationLogs();

    if (response != undefined) {
      this.setState({ notificationCount: response.data.data.length })
    }
  }

  openMailTo = (mail) => {
    window.location = `mailto:${mail}`
  }

  getUserLogout = (user) => {
    const widgetList = this.props.widgets

    widgetList.map(widget => caches.delete('widgetList' + widget.id))

    this.props.userLogout()
  }

  onResourceLoad = () => {
    if (this.state.is_error_found_login) {
      localStorage.clear()
      window.location.href = '/login';
    } else {
      window.location.reload()
    }
  }



  classtagsChange = (e) => {
    console.log(e.target.value);
    let batchId = parseInt(e.target.value);
    if (batchId) {
      const response = this.props.switchCourse(batchId)
      if (response != undefined) {
        console.log("response")
        console.log({ response })
      }
    }

  }

  onCountryCodeChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const getCode = e.target.options[selectedIndex].getAttribute('data-code')
    this.setState({ saveCountryCode: getCode });

  }

  languageUpdateInUserProfile = async (language) => {
    const response = await this.props.updateProfile('', '', '', '', '', language);
    if (response !== undefined && response.status == 1) {
      console.log("languageUpdateInUserProfile");
      console.log(response?.data);
      this.setState({ saveLocale: response?.data.saveLocale });
      window.location.reload();
    }
  }


  languageChange = (option) => {
    localStorage.setItem('lang', option.target.value);
    this.languageUpdateInUserProfile(option.target.value);
  }


  toggleSystemSpec = () => {
    this.setState({
      showSystemSpecification: !this.state.showSystemSpecification
    });
  }
  render() {

    const { questionListing, last_question_id } = this.state
    const dropstyle = this.state.dropdisplay ? { display: 'block' } : { display: 'none' };
    const dropstyle2 = this.state.dropdisplay2 ? { display: 'block' } : { display: 'none' };
    const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
    const isEnabled3 = this.state.questionOption ? true : false;
    //const isEnabled2 = !this.state.username || !this.state.email  || ((this.state.mobile + '').length != 10 && (this.state.mobile != null) && (this.state.mobile + '').length != 0) || !this.state.isValiated  
    //const isEnabled2 = !this.state.username || !this.state.email  || !this.state.mobile ||  !this.state.disableBtn || !this.state.isValiated  
    const isEnabled2 = !this.state.username || !this.state.email || !this.state.mobile || !this.state.isValiated
    console.log(this.state.disableBtn);
    const shareUrl = "https://schoolsonweb.com/";
    if (this.state.loading) {
      return (
        <div className="app-header">
          <div className="app-search-head">
            <img src={Search} alt="search" />
            <input placeholder="Search" />
          </div>
        </div>
      )
    }
    return (
      <div className={this.props.dashboard_type == "dynamic" ? "app-header widget-dashboard" : "app-header"}>
        <div className="mobileOverlay" onClick={this.mobileOverlayclick}></div>
        <div className="forMobile">
          <a href="javascript:void(0);" onClick={this.mobileclick}>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>

        <ReactNotifications />
        <div className="notification-box">please save selected option first</div>
        <Popup open={false} closeOnDocumentClick onClose={this.close5} className="qod-list">
          <span className="close" onClick={this.close5}>&#x2715;</span>

          <div>
            <div className="questionOfTheDayDialog">

              <div className="questionOfTheDayDialog-container">
                <div className="dialogLeft">
                  <div className="popup-header">Question<br />of the day</div>
                  <div className="view-history"><a className="skipLink" href="/learner/qodhistory" target="_blank">View History</a></div>
                  <img src={questiondayLeftBG} />
                </div>
                <div className="dialogRight">
                  <div className="shape1"></div>
                  <div className="shape2"></div>

                  <div className="SliderContent">
                    <Slider ref={c => (this.slider = c)} {...settings}>
                      {questionListing?.length ? questionListing.map((question, index) =>
                        <div className="item">
                          {/* <span className="close">&#x2715;</span>  */}
                          <div className="questionOfTheDayDialog-content">
                            <div className="right-content"><img src={question.question_image} /></div>
                            <div className="left-content">{question.statement}</div>
                          </div>
                          <div className="questionOfTheDayDialog-inner">
                            <ol>
                              {question?.options && question?.options.map((ques, i) =>
                                <li onClick={() => this.onOptionChange(ques.statements[0]?.id)} id={ques.statements[0]?.id} style={this.getColorCode(ques.statements[0]?.id, ques.is_correct, question?.selected_option_id?.toString())}>
                                  <div className="left-content"><img src={common.addFileUrl(ques.statements[0]?.image)} /></div>
                                  <div className="right-content">{ques.statements[0]?.statement}</div>
                                </li>
                              )}
                            </ol>
                            <div className="questionOfTheDayDialog-actions">
                              {!this.state.show_result ? <><button disabled={!isEnabled3} onClick={() => this.saveAttempt(question.id, question.show_result)}>Submit</button>
                                {question.id != last_question_id ? <a className="skipLink" onClick={this.nextArrow}>Skip</a> : ""} </> : ""}

                              {this.state.show_result ? <> <button disabled={!isEnabled3} onClick={() => this.viewResult(question.id)}>View Result</button>
                                {question.id != last_question_id ? <a className="skipLink" onClick={this.nextArrow}>Next</a> : ""} </> : ""}
                              {/* <button>Review</button> */}

                            </div>
                            <div className="review-content" Style="display:none;">
                              <div className="popup-header">Review</div>
                              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                              <div className="questionOfTheDayDialog-reviewactions">
                                <div className="gotit-link"><a href="javascript:void(0);">Ok, I got it</a></div>
                                <div>
                                  <button>Close</button>
                                  <button>Back</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : <div className="no-scheduled-question">No Scheduled Questions there !!</div>}
                    </Slider>
                    {questionListing?.length ? <div className="questionOfTheDayDialog-navigation">
                      {/* <button onClick={this.previousArrow}>Prev</button>
                        <button onClick={this.nextArrow}>Next</button> */}
                    </div> : ""}
                  </div>

                </div>

              </div>
            </div>
          </div>

        </Popup>


        {/*<div className="app-search-head">
          <img src={Search} alt="search"/>
          <input type="search" placeholder="Search study material" onChange={this.addText}/>
          <button onClick = {this.show6}> Search </button>
        </div>*/}

        <div className="search-wrapper" style={{ "opacity": 0, "visibility": "hidden", "display": "none" }}>
          <div className="search-container">
            <input type="search" placeholder="Search study material" onChange={this.show6} onFocus={this.handleUsernameFocus} />
            <div className="search-overlay-wrapper" id="myDIV">
              <div className="search-overlay-container">
                {/*<div className="tabs" id="tabs">
                <a href="javascript:void(0);" id="tab1">All</a>
                <a href="javascript:void(0);" id="tab2">Notes</a>
                <a href="javascript:void(0);" id="tab3">Video</a>
                <a href="javascript:void(0);" id="tab4">Pratice</a>
              </div>*/}

                <div className="tab-content" id="tab1C">
                  <ul>
                    {this.state?.searchList?.length ? this.state?.searchList?.map((data, index) =>
                      <li>
                        <a href=""><i><img src={data._source.type == "Notes" ? Note : data._source.type == "Video" ? Video : data._source.type == "Assessment" ? Practise : ""} /></i><Link className="" style={this.getColor(4)}
                          to={{
                            pathname: !this.state.is_faculty
                              ? `/learner/classroom/${data._source.subject_id}`
                              : `/faculty/classroom/${data._source.subject_id}`,
                            state: { table_id: data._source.ref_id }
                          }}
                          onClick={() => this.props.getContentPreview(data._id, data._source.content_subtypeId)}>
                          <div className="">
                            <span>{data._source.title}</span>
                          </div>
                        </Link></a>
                      </li>
                    ) : ""
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<Popup open={true} closeOnDocumentClick onClose={this.close6} className="complete-details">
          <div>
            <div className="content-state">
              Hey {this.props.learnerprofile?.name}, <br/><br/>

              {this.props.learnerprofile?.institute_settings.dashboard_type == 'dynamic' ? "Welcome to the Design Thinking and Innovation program for teachers brought to you by CBSE and AICTE, to set the pace and to help you with course better, we have planned a short pre - assessment for you, we highly recommend that you take the assessment now!":"Your coach is trying to reach out to you, for your career path. Please share your details to create a bridge between your career path and your personal coach.Click 'ok' to update."}

            </div>
            <div className="content-button">
              {this.props.learnerprofile?.institute_settings.dashboard_type == 'dynamic' ?<a href="/learner/classroom/436" className="go-to-assessment">Go to Assessment</a>:<button onClick={this.show2}>Ok</button> }
              <button onClick={this.close6}>Cancel</button>
            </div>
          </div>
        </Popup>*/}

        <Popup className="iserrorfound" open={this.state.is_error_found} closeOnDocumentClick={false}>
          <div><img src={OOPS} alt="oops" width="200px" height="124px" /></div>
          {/* <div className="ohno">Oops!</div> */}
          <div className="connection_failed">{this.state.error_message}</div>
          <div style={{ marginTop: "20px" }}>
            <button onClick={this.onResourceLoad}>Try Again Now!</button>
          </div>
        </Popup>

        <Popup className="iserrorfound" open={this.state.is_error_found} closeOnDocumentClick={false}>
          <div><img src={OOPS} alt="oops" width="200px" height="124px" /></div>
          {/* <div className="ohno">Oops!</div> */}
          <div className="connection_failed">{this.state.error_message}</div>
          <div style={{ marginTop: "20px" }}>
            <button onClick={this.onResourceLoad}>Try Again Now!</button>
          </div>
        </Popup>

        <Popup open={this.state.open4} closeOnDocumentClick onClose={this.close4} className="marks-verification">
          {!this.state?.searchList?.length ? <><span>No Content Found!!</span><Empty /></> :
            <div className="marksVerification">
              <div className="header-top"><span>Searched Content</span></div>
              <hr />

              <div className="question-table">
                <div className="firstChild">
                  <div>Type</div>
                  <div>title</div>
                  <div>description</div>
                  <div>score</div>
                </div>

                {this.state?.searchList?.length && this.state?.searchList?.map((data, index) =>
                  <div className="secondChild">
                    <div>{data._source.type}</div>
                    <div>
                      <Link className="" style={this.getColor(4)}
                        to={{
                          pathname: !this.state.is_faculty
                            ? `/learner/classroom/${data._source.subject_id}`
                            : `/faculty/classroom/${data._source.subject_id}`,
                          state: { con: 256 }
                        }}
                        onClick={() => this.props.getContentPreview(data._id, 4)}>
                        <div className="">
                          <span>{data._source.title}</span>
                        </div>
                      </Link>
                    </div>
                    <div>{data._source.description}</div>
                    <div>{data._score}</div>
                  </div>
                )}
              </div>
              <hr />
              <div className="footer-bottom">

              </div>
            </div>}
        </Popup>

        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="shareapp">

          <div className="share-app-div"> Share App</div>
          <div className="social-share">
            <div>
              <textarea onKeyUp={this.addComment}>Check out exclusive Schools on Web app - For schools by schools. Check out with Schools administration to enroll you in application</textarea>
            </div>
            <div className="social-buttons">
              <FacebookShareButton
                url={shareUrl}
                quote={this.state.comment}
                hashtag="#SchoolOnWeb">
                <FacebookIcon logoFillColor="white" />
              </FacebookShareButton>
              <LinkedinShareButton
                url={shareUrl}
                title={"Schoool On Web"}
                description={this.state.comment}>
                <LinkedinIcon logoFillColor="white" />
              </LinkedinShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={this.state.comment}
                hashtag="#programing joke">
                <TwitterIcon logoFillColor="white" />
              </TwitterShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title={this.state.comment}
              >
                <WhatsappIcon logoFillColor="white" />
              </WhatsappShareButton >

              <EmailShareButton
                url={shareUrl}
                subject={"School On Web"}
                body={this.state.comment}>
                <EmailIcon logoFillColor="white" />
              </EmailShareButton >
            </div>
          </div>
        </Popup>

        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content-edit-pofile">
          <div className="popup-header">
            {this.props.saveLanguageWiseData.edit_profile}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs-edit-profile">
              <input type="text" placeholder={this.props.saveLanguageWiseData.name} value={this.state.username}
                onKeyPress={this.handleKeyPress} onChange={this.onChangeUsername} />
              <input type="email" placeholder={this.props.saveLanguageWiseData.email_address} value={this.state.email}
                onKeyPress={this.handleKeyPress} onChange={this.onChangeEmail} />
            </div>
            <div className="popup-inputs-edit-profile">
              <select defaultValue={this.state.saveCountryCode == null ? "91" : this.state.saveCountryCode} onChange={this.onCountryCodeChange} data-flag="true">
                {countries.map((countryOptions) => (
                  <option value={countryOptions.code} key={countryOptions.cca2} data-code={countryOptions.code}>{`${countryOptions.flag} ${countryOptions.name}`}</option>
                ))}
              </select>
              {/* <input placeholder="Mobile No." type="text" className='input' onInput={this.onChangeMobile}
                maxLength={10} value={this.state.mobile} pattern="[0-9]*"/> */}
              <input placeholder={this.props.saveLanguageWiseData.mobile_no} type="text" className='input' onInput={this.onChangeMobile}
                value={this.state.mobile} />
              <div className="mobileErrorMsg">{this.mobileErrorMsg}</div>
              {this.state.image === '' || this.state.image === null
                ? <div className="img-prev">
                  {this.state.profile_picture && <span onClick={this.removeImage}>&#x2715;</span>}
                  <div onClick={() => this.fileInput.click()}><img src={this.state.profile_picture ? common.addFileUrl(this.state.profile_picture) : ImgHolder} alt="Prev" /></div>
                </div> : <div className="img-prev">
                  <span onClick={this.removeImage}>&#x2715;</span>
                  <img src={common.addFileUrl(this.state.imageURL)} alt="Prev" />
                </div>}
              <div className="input_field" Style="display:none">
                <input accept="image/*" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput} />
              </div>
            </div>
            <div className="popup-inputs-edit-profile">

            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.onSubmit} disabled={isEnabled2 ? "disabled" : ""}>{this.props.saveLanguageWiseData.update}</button>
          </div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="change-password">
          <div Style="display: flex;width: 100%;" className="changePasswordWrapper">
            <div Style="width: 60%" className="leftImage">
              <img src={ForgotImage} alt="change-password" Style="width: 106%;" />
            </div>
            <div Style="padding-top:81px;" className="RightCnt">
              {<div className="fw_input fw_input2">

                <div className="input_field">
                  <input type={this.state.hide ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.current_password} name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword} />
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see" />
                      : <img src={See} alt="hide" />}
                  </div>
                </div>
                <div className="input_field">
                  <input type={this.state.hide1 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.new_password} name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword} />
                  <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see" />
                      : <img src={See} alt="hide" />}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct ? "border-bottom: 1px solid green" : "border-bottom: 1px solid var(--primary)"}>
                  <input type={this.state.hide2 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.confirm_password} name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword} />
                  <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see" />
                      : <img src={See} alt="hide" />}
                  </div>
                </div>

                <div className="button-class">
                  <button onClick={() => this.changePassword(this.props.logindetails.username, this.state.password, this.state.new_password)} disabled={isEnabled ? "disabled" : ""}>
                    {this.props.saveLanguageWiseData.change_password}
                  </button>
                </div>
              </div>}
            </div>
          </div>
        </Popup>
        {
          this.state.showSystemSpecification && <div className='studioProjectDialog open'>
            <div className='studioProjectDialogContainer selectedBlogContent'>
              <InternetSpeedTest closePop={this.toggleSystemSpec} />
            </div>
          </div>
        }
        <div className="profile">



          <div ref={this.setMyRef} className="TopHeaderLinks">




            <div style={{ "display": "flex", "width": "100%" }} dangerouslySetInnerHTML={{ __html: this.props.learnerprofile?.institute_settings?.header_html }} />

            <div className='viewAttendanceReport'><a href='/learner/view-attendance-report'><i className='fas fa-calendar-alt'></i>Attendance Report</a></div>
            <div style={{ width: '200px' }} className="profile-option" onClick={this.toggleSystemSpec}>
              <span style={{ color: '#fff', padding: "8px 12px", width: '200px' }} className='colorbg'>
                Test Internet Connection
              </span>
            </div>
            {this.state.saveInstituteLanguages && this.state.saveInstituteLanguages !== 'undefined' && this.state.saveInstituteLanguages && JSON.parse(this.state.saveInstituteLanguages).length > 1 &&
              <div className="languageTranslator learner">
                <div className='block'>
                  <label>{this.props.saveLanguageWiseData.choose_language}</label>
                  <select onChange={this.languageChange} value={this.props.logindetails.language ? this.props.logindetails.language : localStorage.getItem('lang')}>
                    {this.state.saveInstituteLanguages.length && JSON.parse(this.state.saveInstituteLanguages).map((languageOption) => (
                      <option value={languageOption.code} key={languageOption.code}>{languageOption.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            }

            {/* <div className="languageTranslator learner">
            <div className='block'>
            <label>{this.props.saveLanguageWiseData.choose_language}</label>
              <select onChange={this.languageChange} value={this.props.logindetails.language ? this.props.logindetails.language : localStorage.getItem('lang')}>
                <option value="en">English</option>
                <option value="ar">عربي</option>
                <option value="gu">ગુજરાતી</option>
                <option value="hi">हिंदी</option>
              </select>
            </div>
          </div> */}


            {this.state.classTagsData && this.state.classTagsData.length > 0 &&
              <div className="switchCourseDD">
                {/* <label>My Courses</label> */}

                <select onChange={this.classtagsChange}>
                  <option value="">{this.props.saveLanguageWiseData.select_category}</option>
                  {this.state.classTagsData.map((items, index) =>
                    <>
                      {items.tag != null &&
                        <optgroup label={items.tag}>
                          {items.class.map((items, index) =>
                            <option value={items.batch_id} key={index}>{items.label}</option>
                          )}
                        </optgroup>
                      }
                    </>
                  )}
                </select>

              </div>
            }
            <div className="headerNotificationLink"><a href="/learner/notifications"><i className="far fa-bell"></i><span>{this.state.learnerNotificationArray}</span></a></div>

            <div className="appDownload">
              <a title="Google Play Store" href="https://play.google.com/store/apps/details?id=com.imaginxp&referrer=utm_source%3Dixp_live_web%26utm_medium%3Dweb_app_login%26utm_campaign%3Dorganic_web_app" target="_blank"><img src={GPS} alt="GPS" /></a>
              <a title="Apple Play Store" href="https://apps.apple.com/app/apple-store/id1577929243?pt=123328305&ct=web_app_login&mt=8" target="_blank"><img src={APS} alt="APS" /></a>
            </div>

            {/* <div className="profile-option" onClick={this.dropchange2}>
              <img src={Setting} alt="Setting"/>
            </div> */}
            <div style={{ "display": "flex", "width": "10%", cursor: "pointer" }}><div><img src={Logout} alt={this.props.saveLanguageWiseData.logout} style={{ "width": "20px" }} /></div><div style={{ "width": "80px", "marginLeft": "3px" }} onClick={this.getUserLogout}>{this.props.saveLanguageWiseData.logout}</div></div>



            <div className="dropdown-content2-setting" style={dropstyle2}>
              <div className="drop-profile-setting">
                <div className="settings" onClick={this.reportAnIssue}>
                  <div style={{ "width": "252px" }}>Report a Problem</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings">
                  <div style={{ "width": "252px" }} onClick={this.show2}>{this.props.saveLanguageWiseData.edit_profile}</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings" onClick={this.show4}>
                  <div style={{ "width": "252px" }}>{this.props.saveLanguageWiseData.change_password}</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings" onClick={this.show5}>
                  <div style={{ "width": "252px" }}>Share App</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings" onClick={this.termsAndCondition}>
                  <div style={{ "width": "252px" }}>Terms and Condiion</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings" onClick={this.contactUs}>
                  <div style={{ "width": "252px" }}>Contact Us</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings" onClick={this.show7}>
                  <div style={{ "width": "252px" }}>Question Of the Day</div><div><img src={Next} alt="Next" /></div>
                </div>
                <div className="settings">
                  <div style={{ "width": "252px" }} onClick={this.getUserLogout}>Logout</div><div><img src={Next} alt="Next" /></div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="profile-option notifications-count">
            <Link to={'/learner/notifications'}>
              <span className="count">{this.props.notifications.length}</span>
              <span className="menu-icon"><img src={Notification} alt="img"/></span>
            </Link>
          </div>*/}

          {!this.state.no_data && <div id="dropper" ref={this.setWrapperRef}>
            <div className="profile-option" onClick={this.dropchange}>
              {this.props.learnerprofile?.profile_picture === ''
                || this.props.learnerprofile?.profile_picture === null
                ? <img src={profileIcon} alt="img" />
                : <div style={{ backgroundImage: `url(${common.addFileUrl(this.props.learnerprofile?.profile_picture)})` }}></div>}
              {/* {this.state?.profile?.name} */}
            </div>
            <div className="dropdown-content2" style={dropstyle}>
              <div className="drop-profile">

                {this.props.learnerprofile?.profile_picture === ''
                  || this.props.learnerprofile?.profile_picture === null
                  ? <img src={Profile} alt="img" />
                  : <div className="dpp-prof-img learnerProfile"
                    style={{ backgroundImage: `url(${common.addFileUrl(this.props.learnerprofile?.profile_picture)})` }}></div>}
                <div className="drop-profile-creds">
                  <span>{this.state?.profile?.name}</span>

                  <div>
                    <span id="edit-prof" title={this.props.saveLanguageWiseData.edit_profile} onClick={this.show2}>
                      <img src={Edit1} alt="edit" />
                    </span>
                    {this.props.learnerprofile?.classs.label} -
                    {this.props.learnerprofile?.batches.map((items, index) =>
                      items.id === this.props.learnerprofile.default_batch_id ? items.label : ""
                    )}

                  </div>
                </div>

              </div>
              {(this.props.learnerprofile?.mobile?.length !== 0
                && this.props.learnerprofile?.mobile !== null) && <div className="prof-options-div">
                  <span>{this.props.saveLanguageWiseData.mobile_no}</span>
                  {this.props.learnerprofile?.mobile}
                </div>}
              {(this.props.learnerprofile?.username?.length !== 0
                && this.props.learnerprofile?.username !== null) && <div className="prof-options-div">
                  <span>{this.props.saveLanguageWiseData.login_id}</span>
                  {this.props.learnerprofile?.username}
                </div>}
              {(this.props.learnerprofile?.email?.length !== 0
                && this.props.learnerprofile?.email !== null) && <div className="prof-options-div">
                  <span>{this.props.saveLanguageWiseData.email_address}</span>
                  {this.props.learnerprofile?.email}
                </div>}

              {(this.props.learnerprofile?.profile?.center?.length !== 0
                && this.props.learnerprofile?.center !== null) && <div className="prof-options-div">
                  <span>{this.props.saveLanguageWiseData.center}</span>
                  {this.props.learnerprofile?.center}
                </div>}

              <div className="click-prof" onClick={this.show4}>
                {this.props.saveLanguageWiseData.change_password}
              </div><br />
              <div className="click-prof" >
                <a href='/learner/certificates'>My Certificates</a>
              </div><br />


              <div className="click-prof" onClick={this.getUserLogout}>
                {this.props.saveLanguageWiseData.logout}
              </div>
            </div>
          </div>}

        </div>

      </div>
    )
  }

}



const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    notifications: state.classroomReducer.notifications,
    learnerprofile: state.commonReducer.logindetails,
    dashboard_type: state.commonReducer.dashboard_type,
    logindetails: state.commonReducer.logindetails,
    widgets: state.getReducer.widgets,
    saveLanguageWiseData: state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userLogout, userLogin, getwpUserToken, qodQuestionSubmit, userNotificationLogs, qodStudentList, getLearnerProfile, updateProfile, changePassword, getSearchList, getContentPreview, switchClassTags, switchCourse, getLanguageWiseData } = actions;
  //const { userLogout, qodQuestionSubmit,userNotificationLogs,qodStudentList,getLearnerProfile ,updateProfile,changePassword,getSearchList,getContentPreview,} = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    qodQuestionSubmit: (qod_question_id, selected_option_id, solution, time_taken) => dispatch(qodQuestionSubmit(qod_question_id, selected_option_id, solution, time_taken)),
    qodStudentList: () => dispatch(qodStudentList()),
    userLogout: () => dispatch(userLogout()),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getSearchList: (text) => dispatch(getSearchList(text)),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype)),
    changePassword: (username, otp, password, language) => dispatch(changePassword(username, otp, password, language)),
    updateProfile: (username, email, mobile, photo, countrycode, locale) => dispatch(updateProfile(username, email, mobile, photo, countrycode, locale)),
    userLogin: (username, password, is_xp_signup) => dispatch(userLogin(username, password, is_xp_signup)),
    switchClassTags: () => dispatch(switchClassTags()),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),
    getLanguageWiseData: (language, page) => dispatch(getLanguageWiseData(language, page)),
    userNotificationLogs: (is_read, page) => dispatch(userNotificationLogs(is_read, page)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
