import React, { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import reportsLoader from '../../public/icons/loader.png';
import Trash from '../../public/icons/trash.svg';
import actions from '../../redux/actions';
import { Link } from 'react-router-dom';

import './BlogPost.css';
import { CreateBlog } from './CreateBlog';


const BlogPost = (props) => {
    const { isLoading, postDoubt, getBlogPost, deleteForum, getFacultyProfile } = props
    const [title, setTitle] = useState()
    const [blogs, setBlogs] = useState([])
    const [filteredBlogs, setFiteredBlogs] = useState([])
    const [deleteId, setDeleteId] = useState(null)
    const [deleting, setDeleting] = useState(false);
    const [loaderShow, setloaderShow] = useState(true);
    const [selectedClassId, setSelectedClassId] = useState(null)
    const [selectedSubjectId, setSelectedSubjectId] = useState(null)
    const [subjectList, setSubjectList] = useState(JSON.parse(localStorage.getItem('logindetails'))?.subjects)
    const [paginationDetail, setPaginationDetail] = useState({
        "current_page": 0,
        "total_page": 0,
        "count": 0,
        "page_size": 0
    })
    const userId = JSON.parse(localStorage.getItem('logindetails'))?.id

    const [byMe, setByMe] = useState(false)
    const blogPostTypeId = 4
    const isFaculty = window.location.href.includes('app/faculty/blog')

    const [editData, setEditData] = useState(null)



    // const getSubjectList = async () => {
    //     const response = await getFacultyProfile()
    //     const allClass = new Set([]);
    //     response.data.subjects.map((subject) => {
    //         allClass.add(subject.class_name)
    //     })
    //     setSubjectList(allClass)
    // }



    const getData = async (selectedSubjectId, selectedClassId, pageNumber) => {
        const resp = await getBlogPost(selectedSubjectId, selectedClassId, pageNumber, blogPostTypeId);
        setBlogs(resp?.data?.forums);
        setPaginationDetail(resp?.data?.pagination)
        setloaderShow(false)
    }

    // useEffect(()=> {
    //     getSubjectList()
    // }, )
    useEffect(() => {

        if (selectedSubjectId != null)
            getData(selectedSubjectId, selectedClassId, paginationDetail?.current_page)
        else {
            getData(null, null, paginationDetail?.current_page)
        }


    }, [selectedSubjectId, paginationDetail?.current_page])

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',

        };
        return date.toLocaleString('en-US', options);
    };

    const [showAddBlog, setShowAddBlog] = useState(false)
    const [selectedContent, setSelectedContent] = useState(false)

    const toggleAddBlogPopup = () => {
        setShowAddBlog((prev) => !prev)
    }
    const closeSelectedContent = () => {
        setSelectedContent(null)
    }



    const postBlog = async (selectedSubjectId, editorValue, title, tags) => {
        const response = await postDoubt(selectedSubjectId, editorValue, null, null, 65, blogPostTypeId, title, tags);
        toggleAddBlogPopup(false)
        getData(null, null, paginationDetail?.current_page)
        setSelectedSubjectId(null)
        setSelectedClassId(null)
        setByMe(false)
        // if (selectedSubjectId != null)
        //     getData(selectedSubjectId, selectedClassId, paginationDetail?.current_page)
        // else {
        //     getData(null, null, paginationDetail?.current_page)
        // }
    }
    const handleRowClick = async (data) => {
        setSelectedContent(data)
    }

    const handleDeleteBlog = async () => {
        setDeleting(true)
        try {
            const response = await deleteForum(deleteId)
            if (response != undefined) {
                setloaderShow(false)
                setBlogs((prev) => prev.filter((e) => e.id != deleteId))
                filteredBlogs((prev) => prev.filter((e) => e.id != deleteId))
            }
        } catch (error) {
            console.log("error while deleting blog post ", error)
        }
        setDeleting(false)
        setDeleteId(null)

    }


    const handleSubjectChange = (e) => {
        console.log("handleSubjectChange",e.target.value);
        if(e.target.value !== null){
            console.log("handleSubjectChange Inner",e.target.value);
            setSelectedSubjectId(e.target.value)
        }
       
    }
    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        // setPageNumber(pageNumber)
        setPaginationDetail({ ...paginationDetail, 'current_page': pageNumber })
        // this.setState({onLoad:true})
        // //this.show4(pageNumber);

        // this.setState({current_page: pageNumber});
        // this.getAssignment(this.props.match.params.id,pageNumber)
    }


    const handleToggleByMe = () => {
        setByMe(prev => !prev)
    }
    useEffect(() => {
        setFiteredBlogs(
            blogs?.filter((blog) => byMe == true ? blog?.user?.id == userId : true)
        )
    }, [byMe])

    useEffect(() => {
        setFiteredBlogs(blogs)
    }, [blogs])

    return (

        <div className='app-body'>
            <div className={isFaculty ? "blog-post-container app-div faculty" : "blog-post-container app-div"}>
                <div className='action-btn'>
                    <h1>Blogs</h1>

                    <div className='actions' style={{ display: "flex", gap: "8px" }}>

                        <div style={{ display: "flex", gap: "8px" }}>


                            {isFaculty && <><button onClick={handleToggleByMe} style={{ height: '36px', opacity: byMe ? '0.6' : '1' }} >All</button>
                                <button onClick={handleToggleByMe} style={{ height: '36px', width: "118px", opacity: byMe ? '1' : '0.6' }}>By Me</button></>
                            }
                            <select value={selectedSubjectId} onChange={handleSubjectChange}>
                                <option value=''>Select Subject</option>
                                {subjectList && subjectList.map((subject) => <option key={subject?.id} value={subject?.id}>{subject?.label}</option>)}
                            </select>
                        </div>
                        {window.location.href.includes('app/faculty/blog') && <button onClick={toggleAddBlogPopup} type="button" className='add-blog' >Add Blog</button>}

                    </div>
                </div>

                <div className='blog-list'>

                    {loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}

                    {!loaderShow && <table>
                        <thead>
                            <tr>
                                <th>Added On</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBlogs && filteredBlogs.map((data, index) => (
                                <tr>
                                    <td>{formatDate(data.added_on)}</td>
                                    <td key={data?.id}>{data?.title ?? "Untitled"}</td>
                                    <td>{data?.user?.name}</td>
                                    <td>{data?.subject}</td>
                                    <td>

                                        <span onClick={() => handleRowClick(data)} title='Preview' className="fa fa-eye"></span>
                                        {isFaculty && data?.user?.id == userId && <span style={{ marginLeft: isFaculty && data?.user?.id == userId ? '20px' : '0px' }} onClick={() => setDeleteId(data?.id)} title='Delete' className="fa fa-trash"></span>}
                                        {/* {isFaculty && data?.user?.id == userId && <span style={{ marginLeft: isFaculty && data?.user?.id == userId ? '20px' : '0px' }} onClick={() => setEditData(data)} title='Edit' className="fa fa-edit"></span>} */}
                                        <Link style={{display: "inline"}} className="single-doubt-left" to={{
                                            pathname: `/faculty/doubt-responses/${data.id}`,
                                            state: {
                                                question: data,
                                                title: "Blog",
                                            }
                                        }}>

                                        {window.location.href.includes('app/faculty/blog') && <span style={{ marginLeft: '20px' }} title='Edit' className="fa fa-reply"></span> }
                                        </Link>

                                    </td>

                                </tr>
                            ))}
                            {filteredBlogs && filteredBlogs.length == 0 && <td colSpan={5}><div className=' marginTop60 noContentMessage'>No Blog Found</div></td>}

                        </tbody>
                    </table>
                    }
                    <Popup open={deleteId != null} closeOnDocumentClick onClose={() => setDeleteId(null)}
                        className="popup-content3">
                        <div className="delete-up">
                            <img src={Trash} alt="delete" />
                            Are you sure you want to<br />delete?
                        </div>
                        <div className="delete-down">
                            <button onClick={() => setDeleteId(null)}>
                                Cancel
                            </button>
                            {
                                deleting ? <div style={{ width: "auto" }} className='LoadingMessage'><img src={reportsLoader} /></div> : <button onClick={handleDeleteBlog}>
                                    Delete
                                </button>
                            }
                        </div>
                    </Popup>
                </div>




                {
                    selectedContent && <div className='studioProjectDialog open'>
                        <div className='studioProjectDialogContainer selectedBlogContent'>
                            <span onClick={closeSelectedContent} className='close'>X</span>
                            <h1 className='preview-title'>{selectedContent?.title}</h1>
                            <h3 className=''>Author: {selectedContent?.user?.name ?? ""}</h3>
                            <h3 className=''>Date: {formatDate(selectedContent?.added_on ?? "")}</h3>
                            <div className='selected-content' dangerouslySetInnerHTML={{ __html: selectedContent?.description }}></div>

                        </div>
                    </div>
                }
                {showAddBlog && <CreateBlog editData={editData} toggleAddBlogPopup={() => setShowAddBlog((prev) => !prev)} defaultSelectedSubjectId={selectedSubjectId} handleSubmit={postBlog} />}

                {/* {
                    showAddBlog && <div className='studioProjectDialog open'>
                        <div className='studioProjectDialogContainer '>
                            <span onClick={toggleAddBlogPopup} className='close'>X</span>
                            <div className='editor-form'>
                                <select value={selectedSubjectId} onChange={handleSubjectChange}>
                                    <option value={null}>Select Subject</option>
                                    {subjectList && subjectList.map((subject) => <option key={subject?.id} value={subject?.id}>{subject?.label}</option>)}
                                </select>
                                <input value={title} onChange={handleTitle} placeholder="Title" />
                                <ReactQuill theme="snow" value={editorValue} onChange={setValue} />
                            </div>
                            <div className='action-btn'>

                                <button className="submit-btn" onClick={handleSubmit} type="button" >Submit</button>
                            </div>

                        </div>
                    </div>
                } */}

                {(paginationDetail?.total_page * paginationDetail?.page_size) && (paginationDetail?.total_page * paginationDetail?.page_size) >= paginationDetail?.page_size ? <div className="pagination-list fixed-pagination noStyle">
                    <Pagination
                        activePage={paginationDetail?.current_page}
                        itemsCountPerPage={paginationDetail?.page_size}
                        totalItemsCount={paginationDetail?.total_page * paginationDetail?.page_size}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}

                    />



                </div> : ''}
            </div>
        </div>

    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.commonReducer.loading,
        logindetails: state.commonReducer.logindetails,
        saveLanguageWiseData: state.commonReducer.languageData
    }
}

const mapDispatchToProps = (dispatch) => {
    const { postDoubt, getBlogPost, deleteForum, getFacultyProfile } = actions;
    return {
        postDoubt: (subject_id, description, media, doubt_forum_id, class_id, type, title, tags) => dispatch(postDoubt(subject_id, description, media, doubt_forum_id, class_id, type, title, tags)),
        getBlogPost: (subject_id, class_id, pageNumber, type) => dispatch(getBlogPost(subject_id, class_id, pageNumber, type)),
        deleteForum: (forum_id) => dispatch(deleteForum(forum_id)),
        getFacultyProfile: () => dispatch(getFacultyProfile()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);