import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import './CollegeMetaInfoList.css';
import { render } from '@testing-library/react';
import Slider from 'react-slick';

const CollegeMetaInfoList = (props) => {
    const { getCollegeDetails,selectedImages } = props;
    const [collegeInfo, setCollegeInfo] = useState(null);

    useEffect(() => {
        const fetchCollegeInfo = async () => {
            try {
                const collegeInfoResp = await getCollegeDetails(3);
                if (collegeInfoResp?.data) {
                    setCollegeInfo(collegeInfoResp.data);
                } else {
                    setCollegeInfo(null);
                }
            } catch (error) {
                console.error("Error fetching college info:", error);
                setCollegeInfo(null);
            }
        };
        fetchCollegeInfo();
    }, [getCollegeDetails]);

    const handleBrochureDownload = (brochureUrl) => {
        const link = document.createElement('a');
        link.href = brochureUrl;
        link.download = 'brochure.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };



    return (
        <div className="admin-div">
            <div className="heading-part2">
                {collegeInfo ? (
                    <div className="college-info-container">
                        {/* Banner with College Logo */}
                        <div className="banner-section">
                            <img
                                className="college-banner"
                                src={collegeInfo?.banner_url}
                                alt="College banner"
                            />
                            <img
                                className="college-logo"
                                src={collegeInfo?.logo}
                                alt="College logo"
                            />
                        </div>


                        <div className="college-details">
                            <h2>{collegeInfo?.about_college}</h2>


                            <div className='checkbox-row'>
                                <p><strong>Why Study Here:</strong> {collegeInfo?.why_to_study_here}</p>
                                  <div style={{ borderLeft: "1px solid #000", height: "20px", margin: "0 10px" }}></div>
                                <p><strong>Address:</strong> {collegeInfo?.address}</p>

                            </div>
                            <div className='checkbox-row' >
                                <p><strong>Ownership:</strong> {collegeInfo?.ownership}</p>
                                <div style={{ borderLeft: "1px solid #000", height: "20px", margin: "0 10px" }}></div>
                                <p><strong>Courses Offered:</strong> {collegeInfo?.courses_offers}</p>

                            </div>
                            <div className='checkbox-row'>
                                <p><strong>Seats Available:</strong> {collegeInfo?.number_of_seat}</p>
                                <div style={{ borderLeft: "1px solid #000", height: "20px", margin: "0 10px" }}></div>
                                <p><strong>Fee Range:</strong> {collegeInfo?.fee_range}</p>
                                <div style={{ borderLeft: "1px solid #000", height: "20px", margin: "0 10px" }}></div>

                                <p> <strong>World Ranking: {collegeInfo?.world_ranking}</strong></p>

                            </div>




                            <div className="action-buttons">
                                <button className="save-btn">Edit</button>

                                <button
                                    className="brochure-btn"
                                    onClick={() => handleBrochureDownload(collegeInfo?.brochure)}
                                    disabled={!collegeInfo?.brochure}
                                >
                                    Download Brochure
                                </button>
                            </div>


                        </div>
                        <div className="carousel-section">
                            <Slider {...slickSettings}>
                                {selectedImages && selectedImages.length > 0 ? (
                                    selectedImages.map((image, index) => (
                                        <div key={index} className="carousel-slide">
                                            <img
                                                className="carousel-image"
                                                src={image.preview}
                                                alt={`Uploaded Slide ${index}`}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No uploaded images to display</p>
                                )}
                            </Slider>
                        </div>

                    </div>
                ) : (
                    <p>No College Info available</p>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    const { getCollegeDetails } = actions;
    return {
        getCollegeDetails: (center_id) => dispatch(getCollegeDetails(center_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegeMetaInfoList);
