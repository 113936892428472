import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Profile from '../../public/icons/user-profile.svg';
import Invite from '../../public/icons/invite.svg';
import Invitew from '../../public/icons/invite-w.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DocumentLoader from '../../public/icons/loader.png';
import Download from '../../public/icons/down-arrow.svg';
import Linkedin from '../../public/icons/linkedin-icon.png';
import Instagram from '../../public/icons/instagram-icon.png';
import File from '../../public/icons/file-icon.png';
class Teachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      faculty_id: null,
      image: '',
      imageURL: '',
      name: '',
      code: '',
      email: '',
      mobile: '',
      password: '',
      section: '',
      classs: '',
      class_centers: [],
      class_center: '',
      batch: '',
      subject: '',
      center_id: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      branch: '',
      faculties: [],
      branches: [],
      centers: [],
      classes: [],
      class_names: [],
      section_names: [],
      subject_names: [],
      filterClasses: [],
      sections: [],
      subjects: [],
      subject_classs: {},
      section_classs: {},
      mail_type: 2,
      user_ids: [],
      all_classes: [],
      all_sections: [],
      all_subjects: [],
      dis_branch: '',
      dis_code: '',
      invite: false,

      openuploadDocuSection: false,
      uploadDocuimageURL: null,
      uploadDocuimage: '',
      openuploadDocuDialog: false,
      uploadDocuType: '',
      uploadDocuUserId: '',
      getAllDocumentsList: [],
      uploadDocuTypeImage: false,
      uploadDocumentLoader: false,
      linkedinURL: '',
      instagramURL: '',
      openDeleteDocuDialog: false,
      saveDeleteDocuid: ''
    }
  }

  componentDidMount() {
    this.getFacultyList(this.state.center_id);
    this.getCenterList();
    this.getClassList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = () => {
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if (modifyURL) {
      console.log("modifyURL", modifyURL);
      console.log("languagessss", language);
      this.fetchPageWiseData(language, modifyURL);
    }

  }

  fetchPageWiseData = async (language, modifyURL) => {
    const response = await this.props.getPageLanguageWiseData(language, modifyURL);
    if (response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData', JSON.stringify(response?.data))


    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  show = () => this.setState({
    open: true, center_id: localStorage.getItem('center_id'),
    name: '', email: '', mobile: '', code: ''
  });

  close = () => this.setState({ open: false });

  // show2 = (val) => {
  //   this.setState({ open2: true, faculty_id: val.id,
  //                     name: val.user.name === undefined || val.user.name === null ? '' : val.user.name,
  //                     email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
  //                     mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile,
  //                     code: val.employee_code === undefined || val.employee_code === null ? '' : val.employee_code,
  //                     imageURL: null,
  //                     image: val.user.profile_picture,
  //                     dis_code: val.employee_code === undefined || val.employee_code === null ? '' : val.employee_code,
  //                     branch: val.user.center, password: '',
  //                     all_classes: val?.class_details, all_sections: val?.section,
  //                     all_subjects: val?.subjects, classs: '', subject: '', section: '',
  //                     filterClasses: this.state.classes.filter(classs => classs.center === val.user.center),
  //                     dis_branch: val.user.branch.name });
  //   this.allSectionsList(val?.class_details);
  //   this.allSubjectsList(val?.class_details);
  // }

  show2 = async (val) => {

    const response = await this.props.facultyDetailsByID(val.id);
    if (response !== undefined) {
      console.log("edit response")
      console.log(response);
      this.setState({ getEditData: response.data });
      console.log(this.state.getEditData);
      this.setState({
        open2: true, faculty_id: val.id,

        name: this.state.getEditData.user.name === undefined || this.state.getEditData.user.name === null ? '' : this.state.getEditData.user.name,
        email: this.state.getEditData.user.email === undefined || this.state.getEditData.user.email === null ? '' : this.state.getEditData.user.email,
        mobile: this.state.getEditData.user.mobile === undefined || this.state.getEditData.user.mobile === null ? '' : this.state.getEditData.user.mobile,
        code: this.state.getEditData.employee_code === undefined || this.state.getEditData.employee_code === null ? '' : this.state.getEditData.employee_code,
        imageURL: null,
        image: this.state.getEditData.user.profile_picture,
        dis_code: this.state.getEditData.employee_code === undefined || this.state.getEditData.employee_code === null ? '' : this.state.getEditData.employee_code,
        branch: this.state.getEditData.user.center, password: '',
        all_classes: this.state.getEditData?.class_details, all_sections: this.state.getEditData?.section,
        all_subjects: this.state.getEditData?.subjects, classs: '', subject: '', section: '',
        filterClasses: this.state.classes.filter(classs => classs.center === this.state.getEditData.user.center),
        dis_branch: this.state.getEditData.user.branch.name, linkedinURL: this.state.getEditData.linked_in === undefined || this.state.getEditData.linked_in === null ? '' : this.state.getEditData.linked_in, instagramURL: this.state.getEditData.instagram === undefined || this.state.getEditData.instagram === null ? '' : this.state.getEditData.instagram
      });
      this.allSectionsList(this.state.getEditData?.class_details);
      this.allSubjectsList(this.state.getEditData?.class_details);
    }
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  bulkInvite = () => this.setState({
    invite: true,
    user_ids: this.state.faculties.map(function (obj) { return obj.id })
  });

  closeInvite = () => this.setState({ invite: false, user_ids: [] });

  getFacultyList = async (center) => {
    const response = await this.props.getFacultyList(center);
    if (response !== undefined) {
      this.setState({ faculties: response.data });
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if (response !== undefined) {
      this.setState({ branches: response.data, centers: response.data });
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if (response !== undefined) {
      this.setState({
        classes: response.data, class_names: response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        )
      });
    }
    // this.allSectionClasses(response.data);
    this.allSubjectClasses(response.data[0]?.id);
  }

  // allSectionClasses = async (classes) => {

  // }

  allSubjectClasses = async (class_id) => {
    const response = await this.props.getSubjectList2(this.state.class_centers[class_id], class_id);
    this.setState({
      subject_classs: response?.data?.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.classs }), {})
    })
  }

  allSectionsList = async (classes) => {
    var result = [];
    var result_names = {};
    var response = null;
    var section_classs = {};
    for (let i = 0; i < classes.length; i++) {
      response = await this.props.getSectionList2(this.state.class_centers[classes[i].id], classes[i].id);
      result = result.concat(response.data);
      section_classs = {
        ...section_classs, ...response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: classes[i].id }), {})
      }
      result_names = {
        ...result_names, ...response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
      };
    }
    this.setState({ sections: result, section_names: result_names, section_classs: section_classs });
  }

  allSubjectsList = async (classes) => {
    var result = [];
    var response = null;
    for (let i = 0; i < classes.length; i++) {
      response = await this.props.getSubjectList2(this.state.class_centers[classes[i].id], classes[i].id);
      result = result.concat(response.data.filter(sub => sub.class_details.id === parseInt(classes[i].id)));
    }
    this.setState({
      subjects: result, subject_names: response?.data?.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
    });
  }

  addSectionList = async (class_center, class_id) => {
    var sections = this.state.sections;
    var section_names = this.state.section_names;
    var section_classs = this.state.section_classs;
    const response = await this.props.getSectionList2(class_center, class_id);
    if (response !== undefined) {
      sections = sections.concat(response.data);
      section_classs = {
        ...section_classs, ...response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: parseInt(class_id) }), {})
      }
      section_names = {
        ...section_names, ...response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
      };
      this.setState({
        sections: sections, section_names: section_names,
        section_classs: section_classs
      });
    }
  }

  addSubjectList = async (val, classs) => {
    var subjects = this.state.subjects;
    var subject_names = this.state.subject_names;
    const response = await this.props.getSubjectList2(val, classs);
    if (response !== undefined && classs !== '') {
      subjects = subjects.concat(response.data.filter(sub => sub.class_details.id === parseInt(classs)));
      subject_names = {
        ...subject_names, ...response.data.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.label }), {})
      };
      this.setState({ subjects: subjects, subject_names: subject_names });
    }
  }

  updatingSelectedSections = (classs) => {
    console.log(this.state.all_sections);
    console.log(classs);
    console.log(this.state.section_classs);
    var sections = this.state.all_sections;
    sections = sections.filter(item => !(parseInt(item?.classs) === parseInt(classs))
      && !(parseInt(item?.phase?.id) === parseInt(classs)))
    this.setState({ all_sections: sections });
  }

  updatingSelectedSubjects = (classs) => {
    var subjects = this.state.all_subjects;
    subjects = subjects.filter(item =>
      !(parseInt(this.state.subject_classs[item?.id]) === parseInt(classs)))
    this.setState({ all_subjects: subjects });
  }

  filterCenterChange = (e) => {
    if (e.target.value === 'Filter by Center') {
      this.setState({ filterCenter: '' });
      this.getFacultyList(this.state.center_id);
    }
    else {
      this.setState({ filterCenter: e.target.value });
      this.getFacultyList(e.target.value);
    }
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  mobileChange = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  passwordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  branchChange = (e) => {
    if (e.target.value === 'Select Branch' || e.target.value === 'Edit Branch') {
      this.setState({ branch: '' })
    }
    else {
      this.setState({ branch: e.target.value })
    }
  }

  addClass = (e) => {
    var arr = this.state.all_classes;
    let x = 0;
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].id) === parseInt(e.target.value)) {
        x = 1;
        break;
      }
    }
    if (x === 0) {
      arr.push({
        id: e.target.value,
        label: this.state.class_names[e.target.value]
      })
      this.addSectionList(this.state.class_centers[e.target.value], e.target.value);
      this.addSubjectList(this.state.class_centers[e.target.value], e.target.value);
    }
    this.setState({
      classs: '', section: '', subject: '', all_classes: arr,
      class_center: this.state.class_centers[e.target.value]
    });
  }

  removeClass = (id) => {
    var arr = this.state.all_classes;
    this.updatingSelectedSections(id);
    this.updatingSelectedSubjects(id);
    for (let i = 0; i < this.state.all_classes.length; i++) {
      if (parseInt(arr[i].id) === parseInt(id)) {
        arr.splice(i, 1);
        this.allSectionsList(arr);
        this.allSubjectsList(arr);
        break;
      }
    }
    this.setState({ all_classes: arr });
  }

  sectionChange = (e) => {
    var arr = this.state.all_sections;
    let x = 0;
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].batch_id) === parseInt(e.target.value)) {
        x = 1;
        break;
      }
    }
    if (x === 0) {
      arr.push({
        batch_id: e.target.value,
        batch: this.state.section_names[e.target.value],
        classs: this.state.section_classs[e.target.value]
      })
    }
    this.setState({ section: '', all_sections: arr })
  }

  removeSection = (id) => {
    var arr = this.state.all_sections;
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].batch_id) === parseInt(id)) {
        arr.splice(i, 1);
      }
    }
    this.setState({ all_sections: arr });
  }

  subjectChange = (e) => {
    var arr = this.state.all_subjects;
    let x = 0;
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].id) === parseInt(e.target.value)) {
        x = 1;
        break;
      }
    }
    if (x === 0) {
      arr.push({
        id: e.target.value,
        label: this.state.subject_names[e.target.value]
      })
    }
    this.setState({ subject: '', all_subjects: arr })
  }

  removeSubject = (id) => {
    var arr = this.state.all_subjects;
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].id) === parseInt(id)) {
        arr.splice(i, 1);
      }
    }
    this.setState({ all_subjects: arr });
  }

  handleChange = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  newFaculty = async () => {
    const { name, email, code, mobile, password, branch, linkedinURL, instagramURL } = this.state;
    if (common.validateEmail(email)) {
      await this.props.createFaculty(name, email, code, mobile == '' ? null : mobile, password, branch, linkedinURL == '' ? null : linkedinURL, instagramURL == '' ? null : instagramURL);
      this.setState({ open: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: `${this.props.pagewiseLanguageData.enter_valid_email}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  updateFaculty = async () => {
    const { faculty_id, name, email, mobile, password, image, all_classes, all_sections,
      all_subjects, code, linkedinURL, instagramURL } = this.state;
    if (common.validateEmail(email)) {
      await this.props.updateFaculty(faculty_id, name, email, mobile, password, image,
        all_classes, all_sections, all_subjects, code, linkedinURL, instagramURL);
      this.setState({ open2: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: `${this.props.pagewiseLanguageData.enter_valid_email}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  deleteFaculty = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteFaculty(d_id, center_id);
    this.setState({ opend: false });
  }

  sendInvite = async (val) => {
    const { mail_type } = this.state;
    await this.props.sendInvite(mail_type, [val]);
  }

  sendBulkInvite = async () => {
    const { mail_type, user_ids } = this.state;
    await this.props.sendInvite(mail_type, user_ids);
    this.setState({ user_ids: [], invite: false })
  }

  changeIds = (val) => {
    if (this.state.user_ids.indexOf(val) !== -1) {
      const index = this.state.user_ids.indexOf(val);
      this.state.user_ids.splice(index, 1);
    }
    else {
      this.state.user_ids.push(val);
    }
  }


  //New Functions For Upload Documents Start here
  openUploadDocumentsSection = (faculty) => {
    const { uploadDocuUserId, openuploadDocuSection } = this.state;
    console.log("function call");
    console.log(faculty);
    this.setState({ openuploadDocuSection: true });
    this.setState({ uploadDocuUserId: faculty.user_id });
    this.getAllDocuments(faculty.user_id);
    console.log(uploadDocuUserId);

  }

  getAllDocuments = async (user_id) => {
    console.log(user_id);
    this.setState({ uploadDocumentLoader: true })
    const response = await this.props.teacherUploadDoucmentList(user_id);
    if (response !== undefined) {
      console.log(response);
      this.setState({ uploadDocumentLoader: false })
      console.log(response.data);
      this.setState({ getAllDocumentsList: response.data })
    }


  }


  closeUploadDocumentsSection = () => {
    this.setState({ openuploadDocuSection: false });
  }
  uploadDocumentChange = (event) => {
    console.log("files", event.target.files[0]);
    console.log(event.target.files[0].type?.split('/')[0])
    if (event.target.files[0].type?.split('/')[0] === 'image') {
      console.log("image uplload Hui hai");
      this.setState({ uploadDocuTypeImage: true })
    }
    if (event.target.files[0]) {
      this.setState({
        uploadDocuimage: event.target.files[0],
        uploadDocuimageURL: URL.createObjectURL(event.target.files[0])
      })
    }
  }


  openUploadDocumentsDialog = () => {
    this.setState({ openuploadDocuDialog: true });
    this.setState({ uploadDocuimageURL: '' });
    this.setState({ uploadDocuType: '' });
    this.setState({ uploadDocuTypeImage: false })
    this.setState({ uploadDocuimage: "" })
  }

  closeUploadDocumentsDialog = () => {
    this.setState({ openuploadDocuDialog: false });
    this.setState({ uploadDocuimageURL: '' });
    this.setState({ uploadDocuType: '' });
    this.setState({ uploadDocuTypeImage: false })
    this.setState({ uploadDocuimage: "" })
  }
  uploadDocumentSelectChange = (event) => {
    const { uploadDocuType, uploadDocuimageURL } = this.state;
    console.log(event.target.value);
    this.setState({ uploadDocuType: event.target.value });

  }
  saveUploadDocuments = async () => {
    console.log("save action");
    const { uploadDocuType, uploadDocuimageURL, uploadDocuUserId, uploadDocuimage } = this.state;
    console.log(uploadDocuType)
    console.log(uploadDocuimageURL);
    console.log(uploadDocuUserId);
    this.setState({ openuploadDocuDialog: false });
    const response = await this.props.teacherUploadDoucment(uploadDocuUserId, uploadDocuimage, uploadDocuType);

    setTimeout(() => this.getAllDocuments(this.state.uploadDocuUserId), 1000);


  }

  getFormattedDocumentDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  deleteDocument = (id) => {
    this.setState({ openDeleteDocuDialog: true });
    this.setState({ saveDeleteDocuid: id });
    console.log(id);
    //const response = await this.props.teacherUploadDoucmentDelete(id);
    //this.getAllDocuments(this.state.uploadDocuUserId);
  }

  cancelDoucmentActions = () => {
    this.setState({ openDeleteDocuDialog: false });
    this.setState({ saveDeleteDocuid: "" });
  }

  deleteDoucmentActions = async () => {
    const { saveDeleteDocuid } = this.state;
    console.log(saveDeleteDocuid);
    this.setState({ openDeleteDocuDialog: false });
    const response = await this.props.teacherUploadDoucmentDelete(saveDeleteDocuid);
    this.getAllDocuments(this.state.uploadDocuUserId);
  }


  documentsDownload = (file) => {
    console.log(file);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = encodeURI(file);
    hiddenElement.target = '_blank';
    hiddenElement.download = '';
    hiddenElement.click();

  }

  linkedinChange = (e) => {
    this.setState({ linkedinURL: e.target.value })
  }

  instagramChange = (e) => {
    this.setState({ instagramURL: e.target.value })
  }

  //New Functions For Upload Documents ends here

  render() {
    const isEnabled = this.state.name.length > 0 && this.state.email.length > 0 && this.state.code.length > 0 &&
      ((this.state.mobile + '').length === 10 || (this.state.mobile + '').length === 0) && this.state.password.length > 0
      && this.state.branch !== '' && this.state.branch !== '';
    const isEnabled2 = this.state.name.length > 0 && this.state.email.length > 0 && this.state.code.length > 0 &&
      ((this.state.mobile + '').length === 10 || (this.state.mobile + '').length === 0);
    const isEnabled3 = this.state.user_ids.length > 0;
    const isEnabled5 = this.state.uploadDocuType != '' && this.state.uploadDocuimageURL != null && this.state.uploadDocuimageURL != '';
    if (this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder={this.props.pagewiseLanguageData.search_teachers} />

          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.teachers}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"} />
              <Shimmer className={"list-item"} />
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"} />
              <Shimmer className={"grid-item"} />
            </div>}
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.teachers}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_teachers} />
        {/* Upload Documents Section Start From here */}

        <Popup open={this.state.openDeleteDocuDialog} closeOnDocumentClick onClose={this.cancelDoucmentActions}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete} />
            {this.props.pagewiseLanguageData.delete_document_message}
          </div>
          <div className="delete-down">
            <button onClick={this.cancelDoucmentActions}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteDoucmentActions}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>

        <div className={this.state.openuploadDocuSection ? 'uploadDocumentWrapper open' : 'uploadDocumentWrapper'}>
          <div className="uploadDocumentBlock">
            <div className='title'>{this.props.pagewiseLanguageData.your_documents} <div className='uploadActions'><span onClick={this.closeUploadDocumentsSection}>{this.props.pagewiseLanguageData.cancel}</span><span onClick={this.openUploadDocumentsDialog}>{this.props.pagewiseLanguageData.add_documents}</span></div></div>
            <div className='uploadDocumentMain'>
              <div className='uploadDocumentLeft'>
                {this.state.uploadDocumentLoader &&
                  <div className='uploadDocumentLoader'><img src={DocumentLoader} /></div>
                }
                {!this.state.uploadDocumentLoader &&
                  <ol>
                    {this.state.getAllDocumentsList && this.state.getAllDocumentsList.map((data, index) =>
                      <li key={index}>
                        <div className='documentType'>{data.type}</div>
                        <div className='documentDate'>{this.props.pagewiseLanguageData.added_on} - <span>{this.getFormattedDocumentDate(data.added_on)}</span></div>
                        <div className='documentActions'><span onClick={() => this.deleteDocument(data.id)}><img src={Delete} />{this.props.pagewiseLanguageData.delete}</span> <span onClick={() => this.documentsDownload(data.file)}><img src={Download} />{this.props.pagewiseLanguageData.download}</span></div>
                      </li>
                    )}
                    {!this.state.getAllDocumentsList.length &&
                      <li className='noDataFound'>
                        <div className='noDataFound'>{this.props.pagewiseLanguageData.no_documents_found}</div>

                      </li>
                    }
                  </ol>
                }
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.openuploadDocuDialog ? 'uploadDocumentDialog open' : 'uploadDocumentDialog'}>
          <div className='uploadDocumentContainer'>
            <div className='dialogClose'><span onClick={this.closeUploadDocumentsDialog}>X</span></div>
            <div className='title'>{this.props.pagewiseLanguageData.upload_your_documents}</div>
            <div className='formWrapper'>
              <div className='formBlock'>
                <select value={this.state.uploadDocuType} onChange={this.uploadDocumentSelectChange}>
                  <option value="">{this.props.pagewiseLanguageData.select_type}</option>
                  <option value="resume">{this.props.pagewiseLanguageData.faculty_resume}</option>
                  <option value="agreement">{this.props.pagewiseLanguageData.faculty_signed_agreement}</option>
                  <option value="aadharcard">{this.props.pagewiseLanguageData.aadhar_card}</option>
                  <option value="pancard">{this.props.pagewiseLanguageData.pan_card}</option>
                </select>
              </div>
              <div className='formBlock'><span onClick={() => this.uploadDocu.click()}>{this.props.pagewiseLanguageData.choose_file}</span><input style={{ display: 'none' }} type="file" id="docu-input"
                onChange={this.uploadDocumentChange} ref={uploadDocu => this.uploadDocu = uploadDocu} /></div>
            </div>
            <div className='uploadDocuFileName'>{this.state.uploadDocuimage?.name}</div>
            {this.state.uploadDocuTypeImage &&
              <div className='uploadDocumentPreview'><img src={this.state.uploadDocuimageURL} /></div>
            }
            <div className='uploadActions'><span className={!isEnabled5 ? 'is_disabled' : ''} onClick={this.saveUploadDocuments}>{this.props.pagewiseLanguageData.submit}</span></div>
          </div>
        </div>
        {/* Upload Documents Section Start From here */}
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}>
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_faculty}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.faculty_name} type="text" onChange={this.nameChange} />
              <input placeholder={this.props.pagewiseLanguageData.employee_code} type="text" onChange={this.codeChange} />
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.email_id} type="email" onChange={this.emailChange} />
              <input placeholder={this.props.pagewiseLanguageData.mobile_no} type="text" onInput={this.mobileChange}
                maxLength={10} value={this.state.mobile} pattern="[0-9]*" />
            </div>
            <div className="popup-inputs">
              <select value={this.state.branch} onChange={this.branchChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_branch}
                </option>
                {this.state.branches.map((branch, index) =>
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                )}
              </select>
              <input placeholder={this.props.pagewiseLanguageData.set_password} type="password" onChange={this.passwordChange} />
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.linkedin_url} type="text" onChange={this.linkedinChange} />
              <input placeholder={this.props.pagewiseLanguageData.instagram_url} type="text" onInput={this.instagramChange} />
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newFaculty}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete" />
            {this.props.pagewiseLanguageData.are_you_sure_you_want_to_delete_this_faculty}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteFaculty}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className={this.state.openuploadDocuSection ? 'main-part hide' : 'main-part'}>
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                {this.state.open2 ? <h1>{this.props.pagewiseLanguageData.edit_faculty}</h1> : <h1>{this.props.pagewiseLanguageData.faculties}</h1>}
              </div>
            </div>
            <div className="heading-part2">
              {this.state.open2 === false ? <>
                {this.state.invite ? <>
                  <button id="button-reverse" onClick={this.closeInvite}>
                    {this.props.pagewiseLanguageData.cancel}
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkInvite}>
                    {this.props.pagewiseLanguageData.send}
                  </button>
                </> : <>
                  <span onClick={this.bulkInvite}>
                    <img src={Invitew} alt={this.props.pagewiseLanguageData.send_invite} />{this.props.pagewiseLanguageData.send_invite}
                  </span>
                  <span onClick={this.show}>
                    <img src={Add} alt={this.props.pagewiseLanguageData.add_new} />{this.props.pagewiseLanguageData.add_new}
                  </span>
                  {!this.state.list ? <span onClick={() => this.changeView('false')}>
                    <img src={List} alt="img" />{this.props.pagewiseLanguageData.list_view}
                  </span> : <span onClick={() => this.changeView('true')}>
                    <img src={Grid} alt="img" />{this.props.pagewiseLanguageData.grid_view}
                  </span>}
                </>}
              </> : null}
            </div>
          </div>
          <div className="sub-filter">
            {!this.state.open2 && <select defaultValue={parseInt(this.state.filterCenter)}
              onChange={this.filterCenterChange}>
              {this.state.centers.map(center =>
                <option key={center.id} value={center.id}>{center.name}</option>
              )}
            </select>}
          </div>
          {this.state.faculties.length === 0 ? <Empty /> : <>
            {this.state.open2 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === '' || this.state.image === null
                  ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                    <img id="img-holder" src={ImgHolder} alt="DP" />
                  </div> : <div className="edit-dp" style={this.state.imageURL === null
                    ? { backgroundImage: "url(" + common.addFileUrl(this.state.image) + ")" }
                    : { backgroundImage: "url(" + this.state.imageURL + ")" }}>
                    <span onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                <input style={{ display: 'none' }} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput} />
                <div className="edit-sub-div-1">
                  <input placeholder={this.props.pagewiseLanguageData.faculty_name} value={this.state.name} type="text"
                    onChange={this.nameChange} />
                  <input placeholder={this.props.pagewiseLanguageData.email_id} value={this.state.email} type="email"
                    onChange={this.emailChange} />
                </div>
              </div>
              <div className="edit-div-2">
                <input placeholder={this.props.pagewiseLanguageData.mobile_no} value={this.state.mobile} type="text"
                  onInput={this.mobileChange} maxLength={10} pattern="[0-9]*" />
                <input placeholder={this.props.pagewiseLanguageData.password} type="password" onChange={this.passwordChange} />
              </div>
              <div className="edit-div-2">
                <input type="text" placeholder={this.props.pagewiseLanguageData.linkedin_url} value={this.state.linkedinURL} onChange={this.linkedinChange} />
                <input type="text" placeholder={this.props.pagewiseLanguageData.instagram_url} value={this.state.instagramURL} onChange={this.instagramChange} />
              </div>
              <div className="edit-div-2">
                <div>
                  {this.state.dis_branch}
                </div>
                <div>
                  {this.state.dis_code}
                </div>
              </div>
              <div className="edit-div-5">
                <select value={this.state.classs} onChange={this.addClass}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.add_class}
                  </option>
                  {this.state.filterClasses.map((classs, index) =>
                    <option value={classs.id} key={index}>
                      {classs.label}
                    </option>
                  )}
                </select>
                <div className="edit-div-tray">
                  {this.state.all_classes.map(classs =>
                    <div>
                      {classs.label}
                      <span onClick={() => this.removeClass(classs.id)}>&#x2715;</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="edit-div-5">
                <select value={this.state.section} onChange={this.sectionChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.add_section}
                  </option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id} key={index}>
                      {section.label}
                    </option>
                  )}
                </select>
                <div className="edit-div-tray">
                  {this.state.all_sections.map(section =>
                    <div>
                      {section.batch}
                      <span onClick={() => this.removeSection(section.batch_id)}>&#x2715;</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="edit-div-5">
                <select value={this.state.subject} onChange={this.subjectChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.add_subject}
                  </option>
                  {this.state.subjects.map((subject, index) =>
                    <option value={subject.id} key={index}>
                      {subject.label}
                    </option>
                  )}
                </select>
                <div className="edit-div-tray">
                  {this.state.all_subjects.map(subject =>
                    <div>
                      {subject.label}
                      <span onClick={() => this.removeSubject(subject.id)}>&#x2715;</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close2}>
                  {this.props.pagewiseLanguageData.cancel}
                </button>
                <button disabled={!isEnabled2} onClick={this.updateFaculty}>
                  {this.props.pagewiseLanguageData.save}
                </button>
              </div>
            </div> : <>
              {this.state.list ? <div className="list-head list-assessment">
                <div className="list-title">
                  {this.props.pagewiseLanguageData.name}
                </div>
                <div className="list-email">
                  {this.props.pagewiseLanguageData.email}
                </div>
                <div className="list-section">
                  {this.props.pagewiseLanguageData.faculty_code}
                </div>
                <div className="list-faculty">
                  {this.props.pagewiseLanguageData.center}
                </div>
                <div className="list-action">
                  {this.props.pagewiseLanguageData.actions}
                </div>
              </div> : null}
              {this.state.list ? <div className="the-list the-assessment-list">
                {this.state.faculties.map((faculty, index) =>
                  <div className="list-item" key={faculty.id}>
                    <div className="title-item3">
                      {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(faculty.id)}
                        defaultChecked={this.state.user_ids.indexOf(faculty.id) !== -1 ? true : false} />}
                      {faculty.user.profile_picture === null || faculty.user.profile_picture === ''
                        ? <img src={Profile} alt="dp" /> : <div className="dp_div"
                          style={{ backgroundImage: "url(" + common.addFileUrl(faculty.user.profile_picture) + ")" }}>
                        </div>}
                      <div>
                        {faculty.user.name}
                        <div className='socialIcons'>
                          {faculty.linked_in && <a href={faculty.linked_in} target="_blank"><img src={Linkedin} /></a>}
                          {faculty.instagram && <a href={faculty.instagram} target="_blank"><img src={Instagram} /></a>}
                        </div>
                      </div>
                    </div>
                    <div className="email-item">
                      {faculty.user.email}
                    </div>
                    <div className="section-item">
                      {faculty.employee_code}
                    </div>
                    <div className="faculty-item">
                      {faculty.user.branch.name}
                    </div>
                    <div className="action-item">
                      <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(faculty)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit} />
                      </span>
                      <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(faculty.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete} />
                      </span>
                      <span title={this.props.pagewiseLanguageData.documents} onClick={() => this.openUploadDocumentsSection(faculty)}>
                        <img src={File} alt={this.props.pagewiseLanguageData.documents} />
                      </span>
                      <div title={this.props.pagewiseLanguageData.send_invite} id="send-invite"
                        onClick={() => this.sendInvite(faculty.id)}>
                        <img src={Invite} alt={this.props.pagewiseLanguageData.send_invite} />{this.props.pagewiseLanguageData.send_invite}
                      </div>
                    </div>
                  </div>
                )}
              </div> : <div className="the-grid">
                {this.state.faculties.map((faculty, index) =>
                  <div className="grid-item" key={faculty.id}>
                    <div className="grid-up grid-up-v1">
                      <div className="title-grid4">
                        {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(faculty.id)}
                          defaultChecked={this.state.user_ids.indexOf(faculty.id) !== -1 ? true : false} />}
                        {faculty.user.profile_picture === null || faculty.user.profile_picture === ''
                          ? <img src={Profile} alt="dp" /> : <div className="dp_div"
                            style={{ backgroundImage: "url(" + common.addFileUrl(faculty.user.profile_picture) + ")" }}>
                          </div>}
                        <div>
                          {faculty.user.name}
                          <span className='facultyUserEmail'>{faculty.user.email}</span>
                          <div className='socialIcons'>
                            {faculty.linked_in && <a href={faculty.linked_in} target="_blank"><img src={Linkedin} /></a>}
                            {faculty.instagram && <a href={faculty.instagram} target="_blank"><img src={Instagram} /></a>}
                          </div>
                        </div>
                      </div>
                      <div className="grid-actions">
                        <div className='gridAcions'>
                          <span title="Edit" onClick={() => this.show2(faculty)}>
                            <img src={Edit} alt="edit" />
                            <i>{this.props.pagewiseLanguageData.edit}</i>
                          </span>
                          <span title="Delete" onClick={() => this.showd(faculty.id)}>
                            <img src={Delete} alt="delete" />
                            <i>{this.props.pagewiseLanguageData.delete}</i>
                          </span>
                          <span className='uploadDocumentLink' onClick={() => this.openUploadDocumentsSection(faculty)}>
                            <img src={File} alt="Documents" />
                            <i>{this.props.pagewiseLanguageData.documents}</i>
                          </span>
                          <span title="Invite" id="send-invite"
                            onClick={() => this.sendInvite(faculty.id)}>
                            <img src={Invite} alt="invite" />
                            <i>{this.props.pagewiseLanguageData.send_invite}</i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>}
            </>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyList, createFaculty, updateFaculty, getCenterList, getClassList2,
    getSectionList2, getSubjectList2, deleteFaculty, sendInvite, facultyDetailsByID, teacherUploadDoucment, teacherUploadDoucmentList, teacherUploadDoucmentDelete, getPageLanguageWiseData } = actions;
  return {
    sendInvite: (mail_type, user_ids) => dispatch(sendInvite(mail_type, user_ids)),
    createFaculty: (name, email, code, mobile, password, center_id, linked_in, instagram) => dispatch(createFaculty(name, email, code, mobile, password, center_id, linked_in, instagram)),
    updateFaculty: (faculty_id, name, email, mobile, password, profile_picture, classes, batches, subjects, employee_code, linked_in, instagram) => dispatch(updateFaculty(faculty_id, name, email, mobile, password, profile_picture, classes, batches, subjects, employee_code, linked_in, instagram)),
    getFacultyList: (center_id) => dispatch(getFacultyList(center_id)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getCenterList: () => dispatch(getCenterList()),
    getClassList2: () => dispatch(getClassList2()),
    getSubjectList2: (center_id, class_id) => dispatch(getSubjectList2(center_id, class_id)),
    deleteFaculty: (id, center_id) => dispatch(deleteFaculty(id, center_id)),
    facultyDetailsByID: (id) => dispatch(facultyDetailsByID(id)),
    teacherUploadDoucment: (user_id, file, type) => dispatch(teacherUploadDoucment(user_id, file, type)),
    teacherUploadDoucmentList: (user_id) => dispatch(teacherUploadDoucmentList(user_id)),
    teacherUploadDoucmentDelete: (id) => dispatch(teacherUploadDoucmentDelete(id)),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
