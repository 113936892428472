import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Select from 'react-select'
import actions from '../../redux/actions';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import JoditEditor from 'jodit-react';

const options = [
  { label: 'Chocolate' },
  { label: 'Strawberry' },
  { label: 'Vanilla' }
]

class FirebaseNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_faculty_admin: false,
      title: '',
      body: '',
      batch_ids: {},
      imageURL: '',
      scheduled_date: '',
      is_schedule: '',
      notification_type: '',
      classes: [],
      classesFilter: [],
      center_id: localStorage.getItem('center_id'),
      sections: [],
      classidsave: '',
      batchidssave: '',
      selectedOptionsArray: []
    }
  }

  componentDidMount() {
    this.setState({ is_faculty_admin: localStorage.getItem("is_faculty_admin") })
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);

    this.getClassList();
  }



  onTitleChange = (e) => {
    this.setState({ title: e.target.value });
  }

  onJoditTextChange = (value) => {
    this.setState({ body: value });
  }
  onBodyChange = (e) => {
    this.setState({ body: e.target.value });
  }
  onBatchIDChange = (e) => {
    this.setState({ batch_ids: e.target.value });
  }
  onImageURLChange = (e) => {
    this.setState({ imageURL: e.target.value });
  }
  onScheduleDateChange = (e) => {
    this.setState({ scheduled_date: e.target.value });
  }

  onTypeChange = (e) => {
    this.setState({ notification_type: e.target.value });
  }


  onClassChange = (e) => {
    this.setState({ classidsave: e.target.value });
    this.setState({ batchidssave: '' });
    // alert(e.target.value)
    if (e.target.value) {
      this.getSectionList(e.target.value);
    }
  }

  getSectionList = async (classid) => {
    this.setState({ sections: '' });
    const response = await this.props.getSectionList(this.state.center_id, classid, 1, this.state.page_url);
    if (response !== undefined) {
      console.log(response?.data);
      if (response?.data) {
        var newdata = response?.data.map((o) => ({
          ...o,
          value: o.id
        }))
        this.setState({ sections: newdata });
      }
    }
  }

  batchChange = (selectedOptions) => {
    console.log(selectedOptions);
    let arr = [];
    this.setState({ batchidssave: '' });
    selectedOptions.map((item, i) => {
      // now you have access to the attributes's values
      console.log(item);
      // you can save them in variable 
      // declare a varibale 'arr' and push the required value to it
      arr.push(item.id); // for example
      this.state.selectedOptionsArray.push(item.id); // for example
      console.log(arr);
      this.setState({ batchidssave: arr.join(',') });


    })
  }


  onSendClick = async (e) => {
    const { title, body, batchidssave, imageURL, scheduled_date, notification_type, classidsave } = this.state;
    //const response = await this.props.scheduleFBNotifications(title, body, batch_ids, imageURL, scheduled_date,0);
    const response = await this.props.scheduleFBNotifications(title, body, batchidssave, imageURL, '', notification_type, 1, classidsave);
    if (response != undefined) {
      console.log(response);
    }
  }

  onScheduledClick = async (e) => {
    const { title, body, batchidssave, imageURL, scheduled_date, notification_type, classidsave } = this.state;
    //const response = await this.props.scheduleFBNotifications(title, body, batch_ids, imageURL, scheduled_date,1);
    const response = await this.props.scheduleFBNotifications(title, body, batchidssave, imageURL, scheduled_date, notification_type, 1, classidsave);
    if (response != undefined) {
      console.log(response);
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList();
    if (response !== undefined) {
      this.setState({ classes: response.data, classesFilter: response.data });
    }
  }

  render() {



    const config = {
      readonly: false, // Enable editing
      toolbar: true, // Show toolbar
      height: 400,
      minHeight: 400,
      buttons: [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'link', 'image',
      ], // Limited toolbar options including HTML preview

      toolbarSticky: false, // Disable sticky toolbar
      removeButtons: ['fullsize'] // Remove full-screen button
    };

    const isEnabled = this.state.title.length > 0 && this.state.body.length > 0 && this.state.classidsave.length > 0 && this.state.batchidssave.length > 0 && this.state.selectedOptionsArray.length > 0 && this.state.notification_type.length > 0;
    if (this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes" />
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Notifications</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>

          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>Notifications</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder="Search classes" />
        <div className="main-part">
          <div className='firbaseNotificationsWrapper'>
            <h1>Notifications</h1>
            <div className='formBlock'>
              <label>Title<span className='required'>*</span></label>
              <div className='block'>
                <input type="text" onChange={this.onTitleChange} placeholder="Enter title" />
              </div>
            </div>

            <div className='formBlock'>
              <label>Type<span className='required'>*</span></label>
              <div className='block'>
                <select name='type' onChange={this.onTypeChange} >
                  <option value="">Select Type</option>
                  <option value="1">Email</option>
                  <option value="2">SMS</option>
                  <option value="3">Mobile App Push</option>
                  <option value="4">In App</option>
                  {/* <option value="5">Push Notification</option> */}
                </select>
              </div>
            </div>
            <div className='formBlock'>
              <label>Body<span className='required'>*</span></label>
              <div className='block'>
                {
                  this.state.notification_type == 1 ? <JoditEditor
                    value={this.state.body}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => this.onJoditTextChange(newContent)} // preferred to use only this option to update the content for performance reasons
                  /> : <textarea type="text" onChange={this.onBodyChange} placeholder="Enter Body"></textarea>
                }

                <div>(Placeholder:&#123;studentFullName&#125;, &#123;email&#125; , &#123;mobile&#125; , &#123;userId&#125;)</div>
              </div>
            </div>
            {/* <div className='formBlock'>
                    <label>Batch ID</label>
                    <div className='block'>
                      <textarea type="text" onChange={this.onBatchIDChange}  placeholder="Enter Batch Ids (comma seperated)"></textarea>
                    </div>
                </div> */}
            <div className='formBlock'>
              <label>Image url</label>
              <div className='block'>
                <input type="text" onChange={this.onImageURLChange} placeholder="Enter Image URL" />
              </div>
            </div>
            <div className='formBlock'>
              <label>Select Class<span className='required'>*</span></label>
              <div className='block'>
                <select onChange={this.onClassChange} defaultValue={this.state.classes}>
                  <option value={null}>
                    Select class
                  </option>
                  {this.state.classes.map(classed =>
                    <option key={classed.id} value={classed.id}>
                      {classed.label}
                    </option>
                  )}
                </select>
                {/* {JSON.stringify(this.state.sections)} */}
                {/* {this.state.batchidssave} */}
                {this.state.sections && this.state.sections?.length > 0 &&
                  <div>
                    <label>Select Batch <span className='required'>*</span></label>
                    <Select
                      options={this.state.sections}
                      placeholder="Select Batch"
                      onChange={this.batchChange}
                      isMulti
                    />
                  </div>
                }
              </div>
            </div>

            <div className='formBlock'>
              <label>Schedule Date</label>
              <div className='block'>
                <input type="datetime-local" onChange={this.onScheduleDateChange} placeholder="Enter Date time" />
              </div>
            </div>
            <div className='formActions'>
              <button onClick={this.onSendClick} disabled={!isEnabled} >Send</button>
              <button onClick={this.onScheduledClick} disabled={!isEnabled} className='marginLeft20'>Schedule</button>
            </div>

          </div>


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, scheduleFBNotifications, getClassList, getSectionList } = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    scheduleFBNotifications: (title, body, batch_ids, image, scheduled_date, channel_id, class_id, is_schedule) => dispatch(scheduleFBNotifications(title, body, batch_ids, image, scheduled_date, channel_id, class_id, is_schedule)),
    getClassList: () => dispatch(getClassList()),
    getSectionList: (center_id, class_id, show_all, page) => dispatch(getSectionList(center_id, class_id, show_all, page)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseNotifications);
