import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
import axios from 'axios';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import reportsLoader from '../../public/icons/loader.png';
import  DataTable from 'react-data-table-component';

class CourseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      batch_id:this.props.location.state?.batch_id,
      classs: parseInt(this.props.match.params.classs),
      class_id: this.props.location.state?.class_id,
      center_id: localStorage.getItem('center_id'),
      token: localStorage.getItem('token'),
      name: '',
      code: '',
      order: '',
      description: '',
      page_url:this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin:false,
      session:0,
      getlistingData:[],
      loaderShow:true,

      openDialog: false,
      openThanksDialog: false,
      groupTitle: '',
      numberOfGroups: 0,
      noOfStudents: this.props.location.state?.no_of_students,
      session_id: this.props.location.state?.session_id,
      username: localStorage.getItem('name'),
      groupPurpose:'',
      breakOutRoomName:'',
      csvFileName:'',
      csvFileOnly:'',
      groupFileError:false,
      openConfirmationGrpupDialog:false,
      columnData:[],
      reportList: null,
      saveClassid:null
    }
  }

  componentDidMount() {
    localStorage.setItem("page_url",this.state.page_url)

  //  this.getListingData();
   this.getClassList();
  }

 
 
	getClassList = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classes: response.data,});
    }
  }

  getListingData = async () => {
    this.setState({loaderShow:true});
    const response = await this.props.breakoutRoomListing(this.state.class_id);
    if(response?.status == 1){
      this.setState({loaderShow:false});
      this.setState({getlistingData:response?.data})
  }
  }
  


  OpenThanksDialog = () => {
    this.setState({ openThanksDialog: true })
  }


  ThanksCloseDialog = () => {
    this.setState({ openThanksDialog: false })

  }


  OpenDialog = (section) => {
    this.setState({ openDialog: true })
  }

  CloseDialog = () => {
    this.setState({ openDialog: false });
  }



  handlePurposeChange= (e) => {
      console.log(e.target.value);
      this.setState({ groupPurpose: e.target.value })
  }



  handleBreakOutNameChange= (e) => {
    console.log(e.target.value);
    this.setState({ breakOutRoomName: e.target.value })
}

  handleSubmit =  (event) => {
    console.log("groupPurpose" , this.state.groupPurpose);
    console.log("breakOutRoomName" , this.state.breakOutRoomName);
    console.log("File" , this.state.csvFileOnly);
    console.log("File Name" , this.state.csvFileName);


    // const response = await this.props.createBreakoutRoom(this.state.formBlocks);
    // if(response?.status == 1){
    //  console.log("response", response);
    // }
  };
  
  formatDate = (getdate) => {
    const timestamp = getdate;
    const dateObj = new Date(timestamp);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: false });

    const now = new Date();
    const diffInSeconds = Math.floor((now - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInSeconds < 60) {
        return "(Just now)";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr ago`;
    } else if (diffInDays === 1) {
        return "Yesterday";
    } else if (diffInDays < 30) {
        return `${formattedDate} ${formattedTime}`;
    } else if (diffInMonths < 12) {
        return `${formattedDate} ${formattedTime}`;
    } else {
        return `${formattedDate} ${formattedTime}`;
    }
};


connectStudentsFileClick = () => {
  document.getElementById("connectStudentsFile").click();
}


handleConnectStudent(event) {
  const file = event.target.files[0];
  if (file) {
      // Check the file type
      const fileType = file.type.split('/')[1];
      if (fileType === 'csv' || fileType === 'vnd.ms-excel') {
          const fileURL = URL.createObjectURL(file);
          console.log(file);
          console.log(file.name);
          console.log(fileURL);
          this.setState({ csvFileName: file.name});
          this.setState({ csvFileOnly: file});
          this.setState({ groupFileError: false });
      } else {
          //alert('Please upload a valid CSV file.');
          this.setState({ groupFileError: true });
          
      }
  }
}

handleClassChange = (e) => {
  this.getAssessmentReport(e.target.value)
}


getAssessmentReport = async (class_id) => {
  const response = await this.props.getAssessmentReport(parseInt(class_id), 'course_completion',parseInt(0))
  console.log(response);
  console.log(response?.status);
  this.setState({ saveClassid: class_id })
  if (response?.status == 1) {
    console.log(response);
    let columnData = Object.keys(response.data.length > 0 && response.data[0]).map(val => ({name:val.toUpperCase(), selector: row => row[val]}));
    console.log('columnData');
    console.log(columnData);
    this.setState({columnData:columnData})
    this.setState({ reportList: response?.data })
  }
}


downloadReportClick = async () => {
  try {
    console.log("saveClassid", this.state.saveClassid);
    
    axios.post(`${common.apiBase}${common.getAssessmentReport}`,
    {'class_id': parseInt(this.state.saveClassid), 'download_type': parseInt(1), 'type':'course_completion'},
     {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        let dateObj= new Date();
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        // hiddenElement.target = '_blank';
        hiddenElement.download = dateObj.toISOString()+'.csv';
        hiddenElement.click();
      }).catch(err => { console.error('error', err.message) })
  } catch (error) {
    console.error("Error in downloadReportClick:", error);
  }
}



  
    



  render() {
    const isEnabled = this.state.groupPurpose && this.state.breakOutRoomName && this.state.csvFileName && this.state.groupFileError == '';
    const customStyles = {
      headCells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '14px',
            fontWeight: 700,
        },
      },
      cells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '16px',
            fontWeight: 400,
        },
      },

    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{`Course Dashboard`}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder={this.props.pagewiseLanguageData.search_sections}/>
       


        <div className="main-part">
          <div className="sub-head"  style={{ marginTop:'0px', marginBottom: "20px"}}>
            <div className="heading-part1">
              <div className="heading marginBottomZero">
                <h1>Course Dashboard</h1>
              </div>
            </div>
           </div>
          <div className='blog-list-table' style={{height: "100%"}}>
          

              {/* <img src={LearnerblogPostImage} /> */}

              <select style={{minWidth: "150px", marginBottom:'20px', maxWidth: "250px"}} onChange={this.handleClassChange}>
                <option value='0'>Select Class</option>
                {this.state.classes.map((classs, index) =>
                <option  key={index} value={classs.id}>{classs.label}</option>
                )}
                </select>

                <div className='downloadReportBtn'><button onClick={this.downloadReportClick}>Download Report</button></div>  

                {this.state.reportList && this.state.columnData.length > 0 ? (
                <DataTable
                  columns={this.state.columnData}
                  data={this.state.reportList}
                  customStyles={customStyles}
                  highlightOnHover
                  pagination
                />
              ) : (
                <div className='noDataFound'>No data available</div>
              )}
              </div>


                </div>
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentReport,createSection, updateSection, getSectionList, getClassList,getPageLanguageWiseData,breakoutRoomListing,createBreakoutRoom,getCenterList} = actions;
  return {
    getSectionList: (center_id, class_id,show_all,page) => dispatch(getSectionList(center_id, class_id,show_all,page)),
     getClassList: () => dispatch(getClassList()),
    getCenterList: () => dispatch(getCenterList()),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    breakoutRoomListing: (class_id) => dispatch(breakoutRoomListing(class_id)),
    createBreakoutRoom: (title,description,center_id,class_id,session_id,no_of_groups,topic) => dispatch(createBreakoutRoom(title,description,center_id,class_id,session_id,no_of_groups,topic)),
    getAssessmentReport: (class_id, type, download_type) => dispatch(getAssessmentReport(class_id, type, download_type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDashboard);
