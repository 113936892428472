import React, { useEffect, useState } from 'react';
import './certificate_styles.css'
import { connect } from 'react-redux';
import actions from '../../redux/actions';


const ExpandingGrid = (props) => {
    const { getCertificateList } = props;

    const [certificateList, setCertificateList] = useState([])


    useEffect(() => {
        const getAllCertificate = async () => {
            const response = await getCertificateList()
            setCertificateList(response.data)


        }
        getAllCertificate()
    }, [])


    return (
        <div className="app-body">
            <div className='app-div widthAuto'>
                <div className="container marginTopZero">
                    <h2>My Certificate</h2>
                </div>

                <div className="flex-container">
                    {certificateList && certificateList.map((certificate, index) => (
                        <div key={index} className="certificate">
                          <img src={certificate?.certificate_url}/>                 
                          <h3>{certificate?.program}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {


    }
}

const mapDispatchToProps = (dispatch) => {
    const { getCertificateList } = actions;
    return {
        getCertificateList: () => dispatch(getCertificateList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandingGrid);